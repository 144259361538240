import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import viewOfferIcon from '../../../assets/images/viewOffer.png';
import path from '../../../assets/images/path.png';

import './offerDetailsModal.scss';

import * as RestSvc from '../../../libraries/offer-services';
import * as urlTypes from '../../../libraries/offer-service-urls';
import Toaster from '../../../Components/Toaster/Toaster';
import ModalPopup from '../../../Components/ModalPopup/ModalPopup';
import appLabels from '../../../utils/appLabels';

const {
	common: commonLabels,
	page: { offerConfiguratorCS: pageLabels }
} = appLabels;

const OfferDetails = ({ offerDetails }) => {
	if (!offerDetails) {
		return null;
	}
	/* detailsList to view on top of modal list*/
	const detailsList = [
		'promotionId',
		'promotionTitle',

		'programId',
		'createdBy',
		'createdOn',
		'modifiedBy',
		'modifiedOn',
		'deleteFlag',
		'source',
		'createdUid',
		'modifiedUid',

		'promotionDescription',
		'effectiveStartDate',
		'effectiveEndDate',
		'travelStartDate',
		'travelEndDate',
		'termsAndConditions',
		'genre',
		'businessUnit',
		'brandName',
		'limitedUse', // 'promotionType',
		'cohortEligibility',
		'promotionApplicability',
		'promotionApplicabilityLimit',
		'benefitType',
		'benefitSubType',
		'mov',
		'flatRewardValue',
		'percentRewardValue',
		'maxRewardPerTransaction',
		'isOverlapping',
		'couponBasedOffer',
		'couponType',
		'genericCouponCode',
		'redemptionLimitPerCard',
		'minTotalTransactionForOfferTriggerTier1',
		'minTotalTransactionForOfferTriggerTier2',
		'minTotalTransactionForOfferTriggerTier3',
		'minTotalTransactionForOfferTriggerTier4',
		'maxTransactionForOfferEligibility',
		'minTotalSpendReqForOffrTriggerTier1',
		'minTotalSpendReqForOffrTriggerTier2',
		'minTotalSpendReqForOffrTriggerTier3',
		'minTotalSpendReqForOffrTriggerTier4',
		'maxTotalSpendForOffrEligibility',
		'maxBenefitPerCardAcrossTransaction',
		'maxBenefitPerCustomerAcrossTransaction',
		'maxRewardAmountAcrossTransaction'
	];

	/* systemAttributes to view on bottom of modal list*/
	const systemAttributes = [
		'programId',
		'createdBy',
		'createdOn',
		'modifiedBy',
		'modifiedOn',
		'deleteFlag',
		'source',
		'createdUid',
		'modifiedUid'
	];

	const offerDetailsObj = { ...offerDetails };
	const { businessUnit } = offerDetailsObj;
	const list = detailsList.map((key, ind) => {
		let value = offerDetailsObj[key];
		if (key === 'promotionId') {
			value = value?.split(':')[1] || value;
		} else if (
			[
				'effectiveStartDate',
				'effectiveEndDate',
				'createdOn',
				'modifiedOn',
				'travelStartDate',
				'travelEndDate'
			].includes(key)
		) {
			value = value ? moment(value).format('DD-MM-YYYY HH:mm') : value;
		} else if (key === 'promotionType') {
			value = offerDetailsObj['limitedUse'];
		} else if (key === 'brandName') {
			if (value.length > 1 && value[0] === businessUnit) {
				delete value[0];
			}
			value = value.filter((val) => val).join(', ');
		} else if (key === 'benefitType') {
			value = Array.isArray(value) ? value.join(', ') : value;
		}
		delete offerDetailsObj[key];

		return (
			<div key={key} className={`row ${systemAttributes.includes(key) ? 'highlighter' : ''}`}>
				<div className="col-4 font-weight-bold text-right">
					{pageLabels?.[key] || key} :
				</div>
				<div className="col">
					{value}
					{key === 'promotionId' && (
						<CopyToClipboard
							text={value}
							onCopy={() => {
								Toaster({
									type: 'info',
									message: commonLabels.copyToClipboardSussesMsg
								});
							}}
						>
							<span className="pointer" style={{ padding: 5 }}>
								<img
									src={path}
									title={pageLabels.copyOfferID}
									alt={pageLabels.copyOfferID}
									className="imagePath highlight"
								/>
							</span>
						</CopyToClipboard>
					)}
				</div>
			</div>
		);
	});

	/* const extraList = [];
	for (const key in offerDetailsObj) {
		if (['eligibilityDetails', 'channelList'].includes(key)) {
			break;
		}
		extraList.push(
			<div key={key} className="row ">
				<div className="col-4 font-weight-bold text-right">
					{pageLabels?.[key] || key} :
				</div>
				<div className="col">{offerDetailsObj[key]}</div>
			</div>
		);
	} */

	return (
		<div className="details-list">
			{list}
			{/* {extraList} */}
		</div>
	);
};

const OfferDetailsModal = ({ offer, showLoadingButton, programId }) => {
	const [offerDetails, setOfferDetails] = useState(null);
	const [showModal, setShowModal] = useState(false);

	const modalCloseHandler = () => {
		setShowModal(false);
		setOfferDetails(null);
	};

	const getOfferDetails = ({ offerId }) => {
		const dataToSend = {
			getPromotionJsonRequest: {
				promotionId: offerId
			}
		};

		showLoadingButton(true);
		const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;
		RestSvc.postData(urlTypes.offers_maintenance_cs.offerDetailsLite, dataToSend, svPromUrl)
			.then((response) => {
				showLoadingButton(false);
				const result = response.data.getPromotionJsonResponse;
				if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });
					return;
				}
				setShowModal(true);
				setOfferDetails(JSON.parse(result.promotionJson)?.PromotionPolicyLite);
			})
			.catch((error) => {
				showLoadingButton(false);
				setShowModal(false);
				console.error(error);
			});
	};

	return (
		<div className="offer-details-modal">
			<span
				className="pointer"
				onClick={() => {
					getOfferDetails({ offerId: offer.promotionId });
				}}
			>
				<img
					alt="View offer details"
					src={viewOfferIcon}
					title="View offer details"
					className="highlight"
				/>
			</span>
			<ModalPopup
				title={'Offer Details'}
				customClasses="offer-details-modal-popup"
				closeHandler={modalCloseHandler}
				showModal={showModal}
				size="xl"
				scrollableEnable
			>
				<OfferDetails offerDetails={offerDetails} />
			</ModalPopup>
		</div>
	);
};

OfferDetailsModal.propTypes = {
	offer: PropTypes.shape({
		promotionId: PropTypes.string.isRequired,
		programId: PropTypes.string.isRequired
	}),
	showLoadingButton: PropTypes.func.isRequired
};

export default OfferDetailsModal;
