import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './createOffer.scss';
import { Formik } from 'formik';
import EligibilityComponentST from './components/EligibilityComponentST';

import * as RestSvc from '../../libraries/offer-services';
import * as urlTypes from '../../libraries/offer-service-urls';
import { isObjectEmpty, trimDate, getOfferFlowType } from '../../utils/helperFunction';
import * as Yup from 'yup';
import omitEmpty from 'omit-empty';
import chevronDown from '../../assets/images/chevron-down.png';
import { Link, Element } from 'react-scroll';
import { ConfirmationModal } from '../../MainContent/ConfirmationModal';
import { InformationModal } from '../../MainContent/InformationModal';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import Toaster from '../../Components/Toaster/Toaster';

//Local const
const localConst = {
	offerMaintainance: 'Offer Maintenance',
	basicDetails: 'Basic Details',
	PromotionType: 'Promotion Type',
	description: 'Description',
	combinationPromotion: 'Combination with other Promotions',
	shares: 'Shares',
	redemptionSettings: 'Redemption Settings',
	others: 'Others',
	applicationEligibility: 'Applicable Eligibility',
	channelEligibility: 'Channel Eligibility',
	privilegeCustomer: 'Privilege Customer Eligibility',
	anonymousCostomer: 'Anonymous Customer Eligibility',
	employeeEligibility: 'Employee Eligibility',
	loyaltyCustomer: 'Loyalty Customer Eligibility',
	customerSegments: 'Customer Segments Eligibility',
	loyaltyCard: 'LoyaltyCard Customer Eligibility',
	happyHours: 'Happy Hours',
	collectionRequired: 'Collection Required',
	eligibilitySettings: 'Eligibility Settings'
};

let initialValues = {
	scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity: {
		exclusions: {
			excludedItemIDList: {
				excludedItemID: []
			},
			merchandizeHierarchyGroup: []
		},
		coupons: {},
		paymentEligibility: {
			allowAggAndIssuer: false,
			applicableOn: ''
		},
		thresholdTypeCode: '',
		priceModificationMethodCode: '',
		scale: [],
		maxTriggersPerTransaction: '',
		transactionRewardQuantity: '',
		benefitType: ''
	}
};

const SignupSchema = Yup.object().shape({
	scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity: Yup.object().shape({
		thresholdTypeCode: Yup.string().required('Please provide a Threshold Type value'),
		quantityLimit: Yup.string().when('thresholdTypeCode', {
			is: (benefitType) => {
				if (
					benefitType == 'SCALE_BASKET_LIMIT_QTY_THRESHOLD' ||
					benefitType == 'SCALE_BASKET_LIMIT_QTY_AND_AMOUNT_THRESHOLD'
				) {
					return benefitType;
				}
			},
			then: Yup.string()
				.required('Please define a Quantity Limit value')
				.matches(
					/^(?=.*[1-9])\d{0,6}$/,
					'Quantity Limit value should be between 1 to 999999'
				)
				.test(
					'val_test',
					'Quantity Limit value should be between 1 to 999999',
					function (value) {
						return 1 <= parseInt(value) && parseInt(value) <= 999999;
					}
				)
		}),

		coupons: Yup.object().shape({
			couponThresholdQuantity: Yup.string().when('couponType', {
				is: (couponType) => {
					if (
						couponType == 'GENERIC' ||
						couponType == 'PERSONALIZED' ||
						couponType == 'TARGETED'
					) {
						return couponType;
					}
				},
				then: Yup.string()
					.matches(
						/^[0-9]+$/,
						'Required Coupon Quantity must be between 1 and six 9s (both inclusive)'
					)
					.test(
						'val_test',
						'Required Coupon Quantity must be between 1 and six 9s (both inclusive)',
						function (value) {
							return 1 <= parseInt(value) && parseInt(value) <= 999999;
						}
					)
			}),

			coupon: Yup.array()
				.of(
					Yup.object().shape({
						couponId: Yup.string()
							.matches(
								/^.[a-zA-Z0-9]+$/,
								'Coupon Id length should be greater than 2 and less than 50 (both inclusive). Only Alphanumeric is supported.'
							)
							.min(
								2,
								'Coupon Id length should be greater than 2 and less than 50 (both inclusive). Only Alphanumeric is supported.'
							)
							.max(
								50,
								'Coupon Id length should be greater than 2 and less than 50 (both inclusive). Only Alphanumeric is supported.'
							)
							.required(
								'Coupon Id length should be greater than 2 and less than 50 (both inclusive). Only Alphanumeric is supported.'
							),
						couponLimit: Yup.string()
							.matches(
								/^[0-9]+$/,
								'Required: Redemption Limit per coupon must be between 1 and nine 9s (both inclusive)'
							)
							.test(
								'val_test',
								'Required: Redemption Limit per coupon must be between 1 and nine 9s (both inclusive)',
								function (value) {
									return 1 <= parseInt(value) && parseInt(value) <= 999999999;
								}
							),
						expireOn: Yup.object().shape({
							date: Yup.date(),
							/* .min(
                new Date(),
                "Entered validity date and time cannot be lesser than the promotion start date and time"
              ), */
							days: Yup.string().when('couponExpiry', {
								is: 'Days',
								then: Yup.string()
									.required('Please define a days value')
									.matches(
										/^(?=.*[1-9])\d{0,3}$/,
										'Please enter a value greater than 1 to 999.'
									)
							}),
							months: Yup.string().when('couponExpiry', {
								is: 'Months',
								then: Yup.string()
									.required('Please define a months value')
									.matches(
										/^(?=.*[1-9])\d{0,3}$/,
										'Please enter a value greater than 1 to 999.'
									)
							}),
							hours: Yup.string().when('couponExpiry', {
								is: 'Hours',
								then: Yup.string().required('Please define a hours value')
							})
						})
					})
				)
				.unique('Coupon Id Already Exists.', (a) => a.couponId)
		}),

		paymentEligibility: Yup.object().shape({
			applicableOn: Yup.string(),
			paymentThresholdTypeCode: Yup.string(),
			payment: Yup.object().when('applicableOn', {
				is: (applicableOn) => {
					if (applicableOn == 'TENDER_VALUE' || applicableOn == 'TRANSACTION_VALUE') {
						return applicableOn;
					}
				},
				then: Yup.object().shape({
					paymentInformation: Yup.string(),
					paymentGroup: Yup.array().of(
						Yup.object().shape({
							// aggregators: Yup.array().required("Please define an aggregator"),
						})
					)
				})
			})
		}),

		benefitType: Yup.string().required('Required: Benefit Type must be selected'),
		priceModificationMethodCode: Yup.string().when('benefitType', {
			is: (benefitType) => {
				if (
					benefitType == 'INSTANT' ||
					benefitType == 'CASHBACK' ||
					benefitType == 'LOYALTY_POINTS' ||
					benefitType == 'FREEBIE' ||
					benefitType == 'EMI'
				) {
					return benefitType;
				}
			},
			then: Yup.string().required('Please define price modification method code value')
		}),

		//MARKDOWN KIT_MARKDOWN
		/* newPriceAmount: Yup.string().when("priceModificationMethodCode", {
      // is:  priceModificationMethodCode => ("MARKDOWN" || "KIT_MARKDOWN"),
      is: (priceModificationMethodCode) => {
        if (
          priceModificationMethodCode == "MARKDOWN" ||
          priceModificationMethodCode == "KIT_MARKDOWN"
        ) {
          return priceModificationMethodCode;
        }
      },
      then: Yup.string()
        .required("Please define new price amount value")
        .matches(
          /^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
          "Price Modification Amount should be between 0.01 to 999999.99"
        ),
    }), */

		/*    cashback: Yup.object().shape({
      creditBy: Yup.object().shape({
        date: Yup.date().min(
          new Date(),
          "Date should not be less than the Current Date"
        ),
        days: Yup.string().matches(/^[0-9]+$/, "Invalid"),
        months: Yup.string().matches(/^[0-9]+$/, "Invalid"),
      }),
      expireOn: Yup.object().shape({
        date: Yup.date().min(
          new Date(),
          "Date should not be less than the Current Date"
        ),
        days: Yup.string().matches(/^[0-9]+$/, "Invalid"),
        months: Yup.string().matches(/^[0-9]+$/, "Invalid"),
      }),
    }),  */

		/* Loyalty: Yup.string().when("priceModificationMethodCode", {
      is: (priceModificationMethodCode) => {
        if (
          priceModificationMethodCode == "LOYALTY_POINTS_MULTIPLIER" ||
          priceModificationMethodCode ==
          "APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS" ||
          priceModificationMethodCode ==
          "APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE"
        ) {
          return priceModificationMethodCode;
        }
      },
      then: Yup.object().shape({
        loyaltyMultiplierPoint: Yup.string()
          .required("Please define loyalty multiplier point value")
          .matches(
            /^(?=.*[1-9])\d{0,6}$/,
            "Loyalty Multiplier Point should be between 1 to 999999"
          ),
        expireOn: Yup.object().shape({
          date: Yup.date().min(
            new Date(),
            "Date should not be less than the Current Date"
          ),
          days: Yup.string().matches(/^[0-9]+$/, "Invalid"),
          months: Yup.string().matches(/^[0-9]+$/, "Invalid"),
        }),
      }),
    }), */
		triggerOperatorValue: Yup.string().when('triggerOperator', {
			//  is: triggerOperator => ("<" || ">" || ">=" || "<=" || "="),
			is: (triggerOperator) => {
				if (
					triggerOperator == '<' ||
					triggerOperator == '=' ||
					triggerOperator == '>' ||
					triggerOperator == '>=' ||
					triggerOperator == '<='
				) {
					return triggerOperator;
				}
			},
			then: Yup.string()
				.required('Please define price range value')
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price range value should be between 0.01 to 999999.99'
				)
		}),
		triggerOperatorValueFrom: Yup.string().when('triggerOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value From should be between 0.01 to 999999.99'
				)
				.required('Please define price range value from')
				.test(
					'triggerOperatorValueFrom_test',
					'Price Range Value From should be less than the Price Range Value To',
					function (value) {
						const { triggerOperatorValueTo } = this.parent;
						return (
							parseFloat(value) < triggerOperatorValueTo ||
							parseFloat(value) == triggerOperatorValueTo
						);
					}
				)
		}),
		triggerOperatorValueTo: Yup.string().when('triggerOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value To should be between 0.01 to 999999.99'
				)
				.required('Please define price range value to')
				.test(
					'triggerOperatorValueTo_test',
					'Price Range Value To should not less than the Price Range Value From',
					function (value) {
						const { triggerOperatorValueFrom } = this.parent;
						return (
							parseFloat(value) > triggerOperatorValueFrom ||
							parseFloat(value) == triggerOperatorValueFrom
						);
					}
				)
		}),
		rewardOperatorValue: Yup.string().when('rewardOperator', {
			// is: rewardOperator => ("<" || ">" || ">=" || "<=" || "="),
			is: (rewardOperator) => {
				if (
					rewardOperator == '<' ||
					rewardOperator == '=' ||
					rewardOperator == '>' ||
					rewardOperator == '>=' ||
					rewardOperator == '<='
				) {
					return rewardOperator;
				}
			},
			then: Yup.string()
				.required('Please define Price range value')
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price range value should be between 0.01 to 999999.99'
				)
		}),
		rewardOperatorValueFrom: Yup.string().when('rewardOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value From should be between 0.01 to 999999.99'
				)
				.required('Please define price range value from')
				.test(
					'RewardOperatorValueFrom_test',
					'Price Range Value From should be less than the Price Range Value To',
					function (value) {
						const { rewardOperatorValueTo } = this.parent;
						return (
							parseFloat(value) < rewardOperatorValueTo ||
							parseFloat(value) == rewardOperatorValueTo
						);
					}
				)
		}),
		rewardOperatorValueTo: Yup.string().when('rewardOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value To should be between 0.01 to 999999.99'
				)
				.required('Please define price range value to')
				.test(
					'rewardOperatorValueTo_test',
					'Price Range Value To should not less than the Price Range Value From',
					function (value) {
						const { rewardOperatorValueFrom } = this.parent;
						return (
							parseFloat(value) > rewardOperatorValueFrom ||
							parseFloat(value) == rewardOperatorValueFrom
						);
					}
				)
		})
	})
});

const EligibilityForm = ({ ...props }) => {
	//state and props
	const [productTableData, setProductTableData] = useState([]);
	const location = useLocation();

	let history = useHistory();
	let myparam = {};
	if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
		myparam = props.auditDetails;
	} else {
		myparam = location.state?.params;
	}

	const [initialData, setInitialData] = useState({});
	const [informationModalShow, setInformationModalShow] = useState(false);
	const [informationMsg, setInformationMsg] = useState('');
	const [informationTitle] = useState('Error');
	const myparam2 = location.state?.MTMBProdTableDatafromBasicDetails;

	useEffect(() => {
		if (myparam) {
			if (
				JSON.parse(myparam).promotionPolicy.priceDerivationRuleEligibility
					.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity != undefined
			) {
				const prevData = JSON.parse(myparam).promotionPolicy.priceDerivationRuleEligibility;
				//for modify
				let multibyProductData =
					prevData.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.scale;
				multibyProductData.map((val, index) => {
					val['id'] = index;
				});

				if (multibyProductData.length && myparam2 === false) {
					//productTableData = multibyProductData;
					setProductTableData(multibyProductData);
					prevData.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.scale = [];
					// resetting to avoid the mismatch, when user goes to basic details and again comes back to eligibility during modify flow
				}
				if (myparam2 === null) {
					prevData.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.exclusions.excludedItemIDList.excludedItemID =
						[];
					prevData.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.exclusions.merchandizeHierarchyGroup =
						[];
				}
				setInitialData(prevData);
			} else {
				initialValues.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.exclusions.excludedItemIDList.excludedItemID =
					[];
				initialValues.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.exclusions.merchandizeHierarchyGroup =
					[];
				setInitialData(initialValues);
			}
		}
		if (myparam2) {
			setProductTableData(JSON.parse(myparam2));
		}

		return () => {
			setProductTableData([]);
		};
	}, []);

	const validateFormikExcludedData = (values) => {
		let isNonFormikDataValid = true;
		if (!productTableData.length) {
			setInformationMsg('Please enter the benifit table data');
			setInformationModalShow(true);
			isNonFormikDataValid = false;
		} else {
			let err = '';

			if (productTableData[0]['thresholdQuantity'] == '') {
				err = 'Error';
			}
			if (
				values.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
					.thresholdTypeCode == 'SCALE_BASKET_LIMIT_QTY_AND_AMOUNT_THRESHOLD' ||
				values.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
					.thresholdTypeCode == 'SCALE_BASKET_QTY_AND_VALUE_THRESHOLD'
			) {
				if (productTableData[0]['thresholdAmount'] == '') {
					err = 'Error';
				}
			}
			if (
				values.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
					?.paymentEligibility?.applicableOn
			) {
				if (productTableData[0]['paymentThresholdAmount'] == '') {
					err = 'Error';
				}
			}
			if (productTableData[0]['benefit'].hasOwnProperty('priceModificationPercent')) {
				if (productTableData[0]['benefit']['priceModificationPercent'] == '') {
					err = 'Error';
				}
			} else if (productTableData[0]['benefit'].hasOwnProperty('priceModificationAmount')) {
				if (productTableData[0]['benefit']['priceModificationAmount'] == '') {
					err = 'Error';
				}
			} else if (productTableData[0]['benefit'].hasOwnProperty('newPriceAmount')) {
				if (productTableData[0]['benefit']['newPriceAmount'] == '') {
					err = 'Error';
				}
			} else if (productTableData[0]['benefit'].hasOwnProperty('freeTextMessage')) {
				if (productTableData[0]['benefit']['freeTextMessage'] == '') {
					err = 'Error';
				}
			} else if (
				productTableData[0]['benefit']['loyalty'].hasOwnProperty('loyaltyAdditionalPoint')
			) {
				if (productTableData[0]['benefit']['loyalty']['loyaltyAdditionalPoint'] == '') {
					err = 'Error';
				}
			} else if (
				productTableData[0]['benefit']['loyalty'].hasOwnProperty('loyaltyMultiplier')
			) {
				if (productTableData[0]['benefit']['loyalty']['loyaltyMultiplier'] == '') {
					err = 'Error';
				}
			}
			if (err == 'Error') {
				setInformationMsg('Please enter the benifit table data');
				setInformationModalShow(true);
				isNonFormikDataValid = false;
			}
		}
		return isNonFormikDataValid;
	};

	//local functions

	const validatePaymentData = (consolidatedData) => {
		let isNonFormikDataValid1 = true;
		const Pay =
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility;
		//const AppOn = Pay.
		if (!(Object.keys(Pay).length === 0) && Pay.constructor === Object) {
			const { applicableOn } =
				consolidatedData.promotionPolicy.priceDerivationRuleEligibility
					.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
					?.paymentEligibility || {};
			if (applicableOn) {
				if (
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
						.paymentEligibility?.payment?.paymentInformation
				) {
					const { paymentMode, provider, paymentType, paymentSubType } =
						consolidatedData.promotionPolicy.priceDerivationRuleEligibility
							.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
							.paymentEligibility?.payment?.paymentInformation || {};

					let Arr = [];
					if (paymentMode) Arr.push(paymentMode);
					if (provider) Arr.push(provider);
					if (paymentType) Arr.push(paymentType);
					if (paymentSubType) Arr.push(paymentSubType);
					//debugger
					if (Arr.length < 1) {
						setInformationMsg('Please enter payment details');
						setInformationModalShow(true);
						isNonFormikDataValid1 = false;
					}
				} else if (
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
						.paymentEligibility?.payment?.paymentGroup == undefined
				) {
					setInformationMsg('Please enter payment details');
					setInformationModalShow(true);
					isNonFormikDataValid1 = false;
				}
			}
		}
		return isNonFormikDataValid1;
	};

	const submitConsolidatedForm = (values) => {
		const consolidatedData = getConsolidatedData(JSON.parse(JSON.stringify(values)));
		if (validateFormikExcludedData(values)) {
			if (validatePaymentData(consolidatedData)) {
				let businessUnitFunctionID = JSON.parse(
					sessionStorage.getItem('taggedBrandToLoggedInUser')
				).businessUnitGroupFunctionID;
				const newConsolidatedData = omitEmpty(consolidatedData);
				const svPromUrl = JSON.parse(
					sessionStorage.getItem('applctnUrls')
				).savePromotionsUrl;

				let offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));

				if (offerFlow.type == 'modify-offer-flow') {
					let dataToSend = {
						modifyPromotionRequest: {
							user: {
								userID: sessionStorage.getItem('loggedInUserId')
							},
							promotionId: offerFlow.slctdPromotionId,
							inputJson: JSON.stringify(newConsolidatedData).replace(/\"/g, '"')
						}
					};
					props.showLoadingButton(true);
					RestSvc.postData(
						urlTypes.save_promotion_end_points.modifyPromotionUrl,
						dataToSend,
						svPromUrl
					)
						.then((response) => {
							props.showLoadingButton(false);
							var result = response.data.modifyPromotionResponse;
							if (result.businessError) {
								Toaster({ message: result.businessError.errorMessage });
							} else {
								if (
									consolidatedData?.promotionPolicy.priceDerivationRuleEligibility
										?.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
										?.paymentEligibility?.isBinRangePromotion
								) {
									if (
										JSON.parse(offerFlow.data).promotionPolicy
											.priceDerivationRuleEligibility
											?.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
											?.paymentEligibility?.isBinRangePromotion
									) {
										Toaster({
											type: 'warn',
											message:
												'Please remember you have modified a BIN promotion, if required revisit your BIN setup.'
										});
									} else {
										Toaster({
											type: 'warn',
											message:
												'Please ensure to upload BINs as the promotion is modified into BIN promotion.'
										});
									}
								}
								const promotionSuccessMsg =
									"Promotion '" +
									result.promotionId +
									"' is modified successfully.";
								Toaster({ type: 'success', message: promotionSuccessMsg });
								props.setDisableItem(false);

								history.push('/MainPage/offerconfigurator');
							}
						})
						.catch((error) => {
							props.showLoadingButton(false);
							console.error(error);
						});
				} else {
					let dataToSend = {
						savePromotionJsonRequest: {
							user: {
								userID: sessionStorage.getItem('loggedInUserId')
							},
							businessUnitGroupFunctionID: businessUnitFunctionID,
							inputJson: JSON.stringify(newConsolidatedData).replace(/\"/g, '"')
							//"inputJson": inputJson
						}
					};
					props.showLoadingButton(true);
					RestSvc.postData(
						urlTypes.save_promotion_end_points.savePromotionJsonUrl,
						dataToSend,
						svPromUrl
					)
						.then((response) => {
							props.showLoadingButton(false);
							var result = response.data.savePromotionJsonResponse;
							if (result.businessError) {
								Toaster({ message: result.businessError.errorMessage });
							} else {
								if (
									consolidatedData?.promotionPolicy
										?.priceDerivationRuleEligibility
										?.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
										?.paymentEligibility?.isBinRangePromotion
								) {
									Toaster({
										type: 'warn',
										message:
											'Please ensure to upload BINs for the BIN based promotion created. '
									});
								}
								const promotionSuccessMsg =
									"Promotion '" + result.promotionId + "' is saved successfully.";
								Toaster({ type: 'success', message: promotionSuccessMsg });
								props.setDisableItem(false);

								history.push('/MainPage/offerconfigurator');
							}
						})
						.catch((error) => {
							props.showLoadingButton(false);
							console.error(error);
						});
				}
			}
		}
	};

	const getConsolidatedData = (values) => {
		let consolidatedData = JSON.parse(myparam);
		// consolidatedData.promotionPolicy.promotionID = consolidatedData.promotionPolicy.programID + consolidatedData.promotionPolicy.promotionID;

		if (values.hasOwnProperty(undefined)) {
			delete values.undefined;
		}
		let ProdData = JSON.parse(JSON.stringify(productTableData));
		ProdData.map(function (v) {
			delete v.id;
		});
		consolidatedData.promotionPolicy.priceDerivationRuleEligibility = values;
		//debugger;
		consolidatedData.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.scale =
			ProdData; //productTableData;

		consolidatedData.promotionPolicy.promotionID = consolidatedData.promotionPolicy.promotionID
			? consolidatedData.promotionPolicy.programID +
			  ':' +
			  consolidatedData.promotionPolicy.promotionID
			: '';
		//debugger
		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.coupons.coupon !=
			undefined
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.coupons.coupon =
				trimDate(
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.coupons.coupon,
					'expireOn',
					'couponExpiry'
				);
		}

		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.cashback != undefined
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.cashback =
				trimDate(
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.cashback,
					'creditBy',
					'cashbackCreditData'
				);
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.cashback =
				trimDate(
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.cashback,
					'expireOn',
					'cashbackExpireData'
				);
		}
		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.loyalty != undefined
		) {
			// consolidatedData.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.benefit.loyalty =
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.loyalty =
				trimDate(
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.loyalty,
					'expireOn',
					'loyaltyExpireData'
				);
		}
		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility
				?.payment?.paymentGroup != undefined
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility.payment.paymentGroup.map(
				(item) => {
					item.issuers &&
						item.issuers.map((item1) => {
							if (item1.cashbackInformation) {
								item1.cashbackInformation = trimDate(
									item1.cashbackInformation,
									'creditBy',
									'cashbackCreditData'
								);
								item1.cashbackInformation = trimDate(
									item1.cashbackInformation,
									'expireOn',
									'cashbackExpireData'
								);
							}
							if (item1.emi) {
								item1.emi = trimDate(
									item1.emi,
									'coolOffPeriod',
									'coolOffPeriodType'
								);
							}
						});
				}
			);
		}
		if (
			!consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility
				?.applicableOn
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility.allowAggAndIssuer =
				undefined;
		}

		//consolidatedData.promotionPolicy.priceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.scale[].benifit = productTableData.header3;

		/* if (!isObjectEmpty(productTableData)) {
      try {
        const productData = { ...productTableData };
        let newPriceDerivationRuleEligibility =
          consolidatedData.promotionPolicy.priceDerivationRuleEligibility;
        //debugger;
        const newGroup = newPriceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.scale.map(
          (response, i) => {
            //debugger;
            return {
              ...response,
              header3: productData.scale[i].benifit.header3,
            };
          }
        );
        //debugger;
        newPriceDerivationRuleEligibility.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.scale = newGroup;
        consolidatedData.promotionPolicy.priceDerivationRuleEligibility = newPriceDerivationRuleEligibility;
      } catch (err) {
        
      }
    } */

		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.blackOutPeriod[0]={};
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay=[];
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay=[];
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate=[];
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate=[];
		// if(consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity&&consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity.date==""){
		//     consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity.date= undefined
		// }
		// if(!consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.customerActivationRequired){
		//     consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity= undefined
		// }

		consolidatedData = JSON.parse(
			JSON.stringify(consolidatedData, function (key, value) {
				return key !== 'selectedCoupon' ? value : undefined;
			})
		);

		return consolidatedData;
	};
	/*  const gotoCreateOffer = async () => {
    const consolidatedData = getConsolidatedData();
    await history.push("/MainPage/offerconfigurator/offerBasicDetails", {
      params: JSON.stringify(consolidatedData),
    });
  }; */
	const gotoCreateOffer = async (values) => {
		// const consolidatedData = getConsolidatedData(values);

		const dataFromBasicDetails = JSON.parse(myparam);
		dataFromBasicDetails.promotionPolicy.priceDerivationRuleEligibility = values;
		const consolidatedData = dataFromBasicDetails;

		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditView) {
			await history.push('/MainPage/auditMaintenance/offerBasicDetails', {
				params: JSON.stringify(consolidatedData),
				MTMBProdTableData: JSON.stringify(productTableData)
			});
		} else {
			await history.push('/MainPage/offerconfigurator/offerBasicDetails', {
				params: JSON.stringify(consolidatedData),
				MTMBProdTableData: JSON.stringify(productTableData)
			});
		}
	};

	const onProductTableChanged = (val) => {
		//debugger;
		setProductTableData(val);
	};

	const onMouseLeaveHandler = () => {
		props.setMousePlace('');
	};

	const linkText = {
		elibilitySettings: [
			'Payment Conditions',
			'Purchase and Reward Conditions',
			'Benefit Settings',
			'Exclusions Settings',
			'Coupon Conditions'
		]
	};

	const [confirmationModalShow, setCnfrmatnModalShow] = useState(false);
	const showCancelConfirmation = () => {
		setCnfrmatnModalShow(true);
	};

	const confirmationModalClose = () => {
		setCnfrmatnModalShow(false);
	};

	const proceedToOperation = () => {
		props.setDisableItem(false);
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditView) {
			history.push('/MainPage/auditMaintenance');
		} else {
			history.push('/MainPage/offerconfigurator');
		}
	};
	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	return (
		<div className="main-container create-offer-container">
			<div className="header-flex">
				<div className="back-div" onClick={showCancelConfirmation}>
					<div className="pr-2">
						<img src={chevronDown} alt="" />
					</div>
					<div>Back</div>
				</div>

				<div className="header-text">{getOfferFlowType()}</div>
			</div>
			<div className="flex-mainContent">
				<div className="left-navigation br-right">
					<div className="text-header">Transaction Scale</div>
					{linkText.elibilitySettings.map((val, ind) => (
						<div key={ind} className="text-content">
							<Link
								// activeClass="activePromotionSection"
								activeClass=""
								className={
									'scrollTo' + val.replace(/\s/g, '') == props.curntMousePlace
										? 'activePromotionSection'
										: ''
								}
								to={'scrollTo' + val.replace(/\s/g, '')}
								spy={true}
								smooth={true}
								duration={250}
								containerId="containerElement"
							>
								{val}
							</Link>
						</div>
					))}
				</div>
				<Element
					id="containerElement"
					className="right-content"
					onMouseLeave={onMouseLeaveHandler}
				>
					<>
						{!isObjectEmpty(initialData) && (
							<Formik
								initialValues={initialData}
								onSubmit={async (values) => {
									await new Promise((resolve) => setTimeout(resolve, 500));
									await submitConsolidatedForm(values);
								}}
								validateOnChange={
									false
								} /* transaction scale for PaymentConditionForm browser hang prevent */
								validationSchema={SignupSchema}
							>
								{(props) => {
									const { isSubmitting, handleSubmit } = props;
									return (
										<form onSubmit={handleSubmit}>
											<div className="item-title">
												{localConst.eligibilitySettings}
											</div>
											<EligibilityComponentST
												data={productTableData}
												onProductTableChanged={onProductTableChanged}
												fullValue={myparam}
											/>
											<div className="footer">
												<button
													type="button"
													className="goTo-basicDetails mr-3"
													onClick={async () => {
														await gotoCreateOffer(props.values);
													}}
												>
													Go To Basic Details
												</button>
												{offerFlow.type == 'view-offer-flow' ? null : (
													<button
														type="submit"
														className="submit-class"
														disabled={isSubmitting}
													>
														Submit
													</button>
												)}
											</div>
										</form>
									);
								}}
							</Formik>
						)}
					</>
				</Element>
			</div>
			{confirmationModalShow ? (
				<ConfirmationModal
					show={confirmationModalShow}
					onHide={confirmationModalClose}
					onProceed={proceedToOperation}
					confirmmsg={'Are you sure you want to navigate away?'}
				/>
			) : null}
			{informationModalShow ? (
				<InformationModal
					show={informationModalShow}
					onHide={() => {
						setInformationModalShow(false);
					}}
					infoMsg={informationMsg}
					infoTitle={informationTitle}
				/>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		curntMousePlace: state.currentMousePlace
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setMousePlace: (val) => dispatch({ type: actionTypes.MOUSE_PLACE, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityForm);
