import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import moment from 'moment';

import './offerFilterModal.scss';
import resetIcon from '../../../assets/images/undo.png';
import advsearch from '../../../assets/images/adv-search.png';

import * as RestSvc from '../../../libraries/offer-services';
import * as urlTypes from '../../../libraries/offer-service-urls';

import ModalPopup from '../../../Components/ModalPopup/ModalPopup';
import appLabels from '../../../utils/appLabels';
import { FormikGroup } from '../../../Components/Input/FormikControl';
import {
	dateValidator,
	dbDateFormat,
	fieldValidator,
	validDateFormat
} from '../../GenerateCoupon/GenerateCouponForm';
import Toaster from '../../../Components/Toaster/Toaster';
import appConstants from '../../../utils/appConstants';

const {
	common: commonLabels,
	page: {
		offerConfiguratorCS: {
			offerFilter: { formLabels }
		}
	}
} = appLabels;

const { CS_benefitType, genre } = appConstants;

const selectLabels = {
	benefitType: { ...CS_benefitType, '': 'All' },
	genre: { ...genre, '': 'All' },
	promotionType: {
		'': 'All',
		Limited: 'Limited',
		Unlimited: 'Unlimited'
	},
	brand: {
		'': 'All'
	}
};
const getSelectOptions = (name, brandList = []) => {
	const options = {
		benefitType: ['', 'NeuCoins', 'Discount', 'Cashback', 'Coupon'],
		genre: ['', 'In-Market', 'Category-led', 'Platform level'],
		promotionType: ['', 'Limited', 'Unlimited'],
		brand: brandList
	};

	const selectedOptions =
		options[name]?.map((val) => {
			const _val = brandList.length ? val.replace(/_/g, ' ') : val;
			return {
				value: val,
				label: selectLabels[name]?.[_val] || _val
			};
		}) || [];

	return selectedOptions;
};

const OfferFilterModal = forwardRef(function OfferFilterModal(
	{ programId, showLoadingButton, searchResultHandler },
	ref
) {
	const [showModal, setShowModal] = useState(false);
	const [brandList, setBrandList] = useState([]);
	const [searchFormVal, setSearchFormVal] = useState(null);

	const initialValues = {
		programId,
		businessUnitFunctionID: JSON.parse(sessionStorage.getItem('taggedBrandToLoggedInUser'))
			.businessUnitGroupFunctionID,
		benefitType: getSelectOptions('benefitType')[0].value,
		startDate: '',
		endDate: '',
		genre: getSelectOptions('genre')[0].value,
		promotionType: getSelectOptions('promotionType')[0].value,
		brand: []
	};

	useEffect(() => {
		fetchBrandList();
	}, []);

	useImperativeHandle(
		ref,
		() => {
			return {
				/* fetch filter again with filter modal */
				fetchOffers: () => searchOffers(searchFormVal)
			};
		},
		[searchFormVal]
	);

	const fetchBrandList = () => {
		showLoadingButton(true);

		RestSvc.getData(urlTypes.offers_maintenance_cs.fetchBrandsForCSView)
			.then((response) => {
				const result = response.data.brandsProgramIdMapCsViewRes;
				if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });
					return;
				}
				setBrandList(result?.brandsList);
				showLoadingButton(false);
			})
			.catch((error) => {
				showLoadingButton(false);
				console.error(error);
			});
	};

	const filterCloseHandler = () => {
		setShowModal(false);
	};

	const searchOffers = (searchFormValue) => {
		const {
			programId,
			businessUnitFunctionID,
			benefitType,
			genre,
			promotionType,
			startDate,
			endDate,
			brand
		} = searchFormValue;

		const payLoad = {
			searchPromotionRequestLite: {
				programId,
				businessUnitFunctionID,
				benefitType: benefitType || null,
				genre: genre || null,
				promotionType: promotionType || null,
				startDate: startDate
					? moment(startDate, validDateFormat[0]).format(validDateFormat[3])
					: null,
				endDate: endDate
					? moment(endDate, validDateFormat[0]).format(validDateFormat[3])
					: null,
				brand: brand.length ? brand.map(({ value }) => value).join(',') : null
			}
		};

		const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;
		showLoadingButton(true);
		searchResultHandler?.([]);
		RestSvc.postData(urlTypes.offers_maintenance_cs.searchPromotionLite, payLoad, svPromUrl)
			.then((response) => {
				showLoadingButton(false);
				const result = response.data.searchPromotionResponse;
				if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });
				} else {
					searchResultHandler?.(result);
					filterCloseHandler();
				}
			})
			.catch((error) => {
				showLoadingButton(false);
				console.error(error);
			});
	};

	const onSubmit = (values, { setSubmitting }) => {
		setSubmitting(false);
		setSearchFormVal(values);
		searchOffers(values);
	};

	const formControls = (formik) => {
		const { startDate, endDate } = formik.values;
		const inputs = [
			[
				{
					control: 'select',
					...formLabels.genre,
					options: getSelectOptions('genre'),
					name: 'genre',
					controlClass: 'vertical-group'
				},
				{
					control: 'select',
					...formLabels.promotionType,
					options: getSelectOptions('promotionType'),
					name: 'promotionType',
					controlClass: 'vertical-group'
				}
			],
			[
				{
					control: 'datetime',
					...formLabels.startDate,
					name: 'startDate',
					controlClass: 'vertical-group',
					validate: fieldValidator([
						dateValidator({
							max: dbDateFormat(endDate)
						})
					])
				},
				{
					control: 'datetime',
					...formLabels.endDate,
					name: 'endDate',
					controlClass: 'vertical-group',
					validate: fieldValidator([
						dateValidator({
							min: dbDateFormat(startDate)
						})
					])
				}
			],
			[
				{
					control: 'select',
					...formLabels.benefitType,
					options: getSelectOptions('benefitType'),
					name: 'benefitType',
					controlClass: 'vertical-group'
					// inputClass: 'customSelectIcon'
				},
				{
					control: 'multiSelect',
					...formLabels.brand,
					options: getSelectOptions('brand', brandList),
					name: 'brand',
					controlClass: 'vertical-group'
				}
			]
		];

		return <FormikGroup inputs={inputs} />;
	};

	return (
		<div className="offer-filter-modal">
			<button
				type="button"
				className="btnNxtPrev roboto-b-16 filter-btn"
				onClick={() => {
					setShowModal(true);
				}}
			>
				Advanced Search
				<img className="ml-2" alt="Advanced Search" src={advsearch} />
			</button>
			<ModalPopup
				customClasses="offer-filter-modal-popup"
				title="Search Offer"
				showModal={showModal}
				closeHandler={filterCloseHandler}
				size="lg"
				centered={false}
				modalSide="right"
				hideCloseBtn
			>
				<Formik initialValues={{ ...initialValues, ...searchFormVal }} onSubmit={onSubmit}>
					{(formik) => {
						return (
							<Form>
								{formControls(formik)}

								<div className="d-flex justify-content-end form-btn-container">
									<button
										type="button"
										className="grey-button roboto-b-16 bg-transparent ml-2"
										onClick={() => {
											formik.resetForm({ values: initialValues });
										}}
									>
										<img
											src={resetIcon}
											className="mr-1"
											alt={commonLabels.reset}
										/>
										{commonLabels.reset}
									</button>
									<button
										type="button"
										className="btnCancel roboto-b-16 ml-2"
										onClick={filterCloseHandler}
									>
										{commonLabels.cancel}
									</button>
									<button
										type="submit"
										disabled={
											!formik.isValid ||
											formik.isSubmitting ||
											formik.values.patterns?.length === 0
										}
										className="btnNxtPrev roboto-b-16 ml-2"
									>
										{commonLabels.submit}
									</button>
								</div>
							</Form>
						);
					}}
				</Formik>
			</ModalPopup>
		</div>
	);
});

OfferFilterModal.propTypes = {
	programId: PropTypes.string,
	showLoadingButton: PropTypes.func,
	searchResultHandler: PropTypes.func
};

export default OfferFilterModal;
