import React from 'react';
import PropTypes from 'prop-types';
import appConfig from '../../../utils/appConfig';

const { hostNames, flashSaleTemplates } = appConfig;

const isProd = () => {
	const { hostname = null } = window?.location || {};
	return hostname === hostNames.prod;
};

const OfferTemplate = ({ title = 'Click to download template', label = 'Download Template' }) => {
	const templateUrl = flashSaleTemplates[isProd() ? 'prod' : 'lower'];
	return (
		<div className="align-items-center d-flex channelLink">
			<a href={templateUrl} title={title}>
				{label}
			</a>
		</div>
	);
};

OfferTemplate.propTypes = {
	title: PropTypes.string,
	label: PropTypes.string
};

export default OfferTemplate;
