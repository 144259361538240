import React from 'react';
import { withRouter } from 'react-router-dom';
import Input from '../Components/Input/Input';
import './login.scss';
import ChangePasswordModal from '../MainContent/ChangePasswordModal';
import * as RestSvc from '../libraries/offer-services';
import { connect } from 'react-redux';
import * as actionTypes from '../store/actions';
import * as urlTypes from '../libraries/offer-service-urls';

import Graphics from './../assets/images/Graphics-new.png';
import ConfirmationModal from '../MainContent/ConfirmationModal';
import Toaster from '../Components/Toaster/Toaster';
import { invokeRefreshTokenTimer } from '../utils/helperFunction';

class LoginPage extends React.Component {
	initialState = {
		loginForm: {
			username: {
				elementType: 'input',
				elementConfig: {
					type: 'text',
					placeholder: 'Enter your username',
					labelname: 'User Name'
				},
				value: '',
				validation: {
					required: true
				},
				attrValidation: {},
				validationMessage: '',
				valid: false,
				touched: false
			},
			password: {
				elementType: 'input',
				elementConfig: {
					type: 'password',
					placeholder: 'Enter your password',
					labelname: 'Password'
				},
				value: '',
				validation: {
					required: true
				},
				attrValidation: {},
				validationMessage: '',
				valid: false,
				touched: false
			}
		},
		formIsValid: false,
		changePwdModalShow: false,
		needPwdchange: '',
		confirmationModalShow: false,
		confirmMsg: '',
		resultvar: ''
	};

	state = this.initialState;

	componentDidMount() {
		window.dispatchEvent(new CustomEvent('navigationhandler'));
	}

	handleFormReset = () => {
		this.setState(() => this.initialState);
	};
	onChangeClick = () => {
		alert('ChangePwd clicked');
	};

	proceedToOperation = () => {
		this.handleFormReset();
		this.setState({
			confirmationModalShow: false,
			changePwdModalShow: true
		});
	};

	confirmationModalClose = () => {
		this.setState({
			confirmationModalShow: false
		});
		this.login(this.state.resultvar);
	};

	onLoginSubmit = (event) => {
		event.preventDefault();

		const {
			loginForm: { username, password }
		} = this.state;

		var dataToSend = {
			offersLoginRequest: {
				userID: username.value,
				password: btoa(password.value)
			}
		};

		this.props.showLoadingButton(true);
		RestSvc.postData(urlTypes.user_maintenance.performLogin, dataToSend)
			.then((response) => {
				this.props.showLoadingButton(false);
				var result = response.data.offersLoginResponse;

				if (result.authenticationDetails.warningCode === 'WARN_SC_02') {
					this.props.showLoadingButton(false);

					this.setState({
						confirmationModalShow: true,
						resultvar: result,
						confirmMsg:
							result.authenticationDetails.warningMessage +
							'. do you want to change password now?'
					});
				} else if (
					result.authenticationDetails.isCredentialValid &&
					result.authenticationDetails.warningCode !== 'WARN_SC_01' &&
					result.authenticationDetails.errorCode !== 'ERR_SC_07'
				) {
					this.login(result);
				} else if (result.authenticationDetails.warningCode === 'WARN_SC_01') {
					Toaster({ message: result.authenticationDetails.warningMessage });
					this.handleFormReset();
					this.setState({ changePwdModalShow: true });
				} else {
					Toaster({ message: result.authenticationDetails.errorMessage });
					this.handleFormReset();
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	};

	login(result) {
		sessionStorage.setItem(
			'tokens',
			JSON.stringify(result.authenticationDetails.token.accessToken)
		);
		sessionStorage.setItem('refreshToken', result.authenticationDetails.token.refreshToken);
		sessionStorage.setItem('loggedInUserId', result.authenticationDetails.userInfo.operatorID);
		this.props.setFullName(result.authenticationDetails.userInfo);
		this.props.showLoadingButton(true);
		const dataToSend = {
			getOperatorDetailRequest: {
				operatorID: result.authenticationDetails.userInfo.operatorID
			}
		};
		invokeRefreshTokenTimer();
		RestSvc.postData(urlTypes.user_maintenance.userDetails, dataToSend)
			.then((response) => {
				this.props.showLoadingButton(false);
				this.props.setLevelID(response.data.SearchOperatorResponse.level.levelID);
				this.props.setLevelHierarchy(
					response.data.SearchOperatorResponse.level.hierarchicalLevel
				);
				sessionStorage.setItem(
					'taggedBrandToLoggedInUser',
					JSON.stringify(
						response.data.SearchOperatorResponse.businessUnitGroupFunctionList[0]
					)
				);
				this.props.setLoggedInBrand(
					response.data.SearchOperatorResponse.businessUnitGroupFunctionList[0]
				);
				this.fetchPermissions(
					response.data.SearchOperatorResponse.operator.operatorID,
					response.data.SearchOperatorResponse.level.levelID
				);
				this.props.history.push('/MainPage/levelmaintenance');
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	}

	inputChangedHandler = (event, isValidObj, inputIdentifier) => {
		const updatedLoginForm = { ...this.state.loginForm };
		const updatedFormElement = { ...updatedLoginForm[inputIdentifier] };
		updatedFormElement.value = event.target.value;
		updatedFormElement.valid = isValidObj.value;
		updatedFormElement.validationMessage = isValidObj.message;
		updatedFormElement.touched = true;
		updatedLoginForm[inputIdentifier] = updatedFormElement;
		let formIsValid = true;
		for (let inputIdentifier in updatedLoginForm) {
			formIsValid = updatedLoginForm[inputIdentifier].valid && formIsValid;
		}
		this.setState({ loginForm: updatedLoginForm, formIsValid: formIsValid });
	};

	fetchPermissions = (opID, LevelID) => {
		this.props.showLoadingButton(true);
		const dataToSend = {
			getAllResourcesForAssignmentRequest: {
				operatorID: opID,
				level: {
					levelID: LevelID
				},
				resource: {
					applicationCode: 'OFFER_CONFIGURATOR'
				}
			}
		};
		RestSvc.postData(urlTypes.create_level_maintenance.fetchResources, dataToSend)
			.then((response) => {
				this.props.showLoadingButton(false);
				const allResources =
					response.data?.getAllResourcesForAssignmentResponse?.resource?.resources;
				const assignedResource = allResources
					.filter((val) => val.isAssigned)
					.map((val) => val.resourceCode);
				this.props.setAssignedResources(assignedResource);
			})
			.catch((error) => {
				this.props.showLoadingButton(false);

				console.error(error);
			});
	};
	render() {
		let changePwdModalClose = () => this.setState({ changePwdModalShow: false });

		const formElementsArray = [];
		for (let key in this.state.loginForm) {
			formElementsArray.push({
				id: key,
				config: this.state.loginForm[key]
			});
		}
		return (
			<div className="loginParentDiv row">
				<div className="col">
					<div className="container containerClass">
						<div className="wlcm-text">
							Welcome to <strong>Offer Configurator!</strong>
						</div>
						<div className="m-0 row ssrow">
							<div className="col-md-6 d-flex align-items-center justify-content-center contentCol1">
								<img className="imageClass" src={Graphics} alt="img" />
							</div>
							<div className="col-md-6 contentCol2">
								<div className="signinText signIn-text">
									Sign in to your account
								</div>
								<form onReset={this.handleFormReset} onSubmit={this.onLoginSubmit}>
									<div className="loginform">
										<div key={formElementsArray[0].id} className="">
											<Input
												key={formElementsArray[0].id}
												elementType={
													formElementsArray[0].config.elementType
												}
												elementConfig={
													formElementsArray[0].config.elementConfig
												}
												value={formElementsArray[0].config.value}
												invalid={!formElementsArray[0].config.valid}
												shouldValidate={
													formElementsArray[0].config.validation
												}
												attrValidation={
													formElementsArray[0].config.attrValidation
												}
												touched={formElementsArray[0].config.touched}
												changed={(ev, isValidObj) =>
													this.inputChangedHandler(
														ev,
														isValidObj,
														formElementsArray[0].id
													)
												}
											/>
										</div>

										<div key={formElementsArray[1].id} className="">
											<Input
												key={formElementsArray[1].id}
												elementType={
													formElementsArray[1].config.elementType
												}
												elementConfig={
													formElementsArray[1].config.elementConfig
												}
												togglePassword
												value={formElementsArray[1].config.value}
												invalid={!formElementsArray[1].config.valid}
												shouldValidate={
													formElementsArray[1].config.validation
												}
												attrValidation={
													formElementsArray[1].config.attrValidation
												}
												touched={formElementsArray[1].config.touched}
												changed={(ev, isValidObj) =>
													this.inputChangedHandler(
														ev,
														isValidObj,
														formElementsArray[1].id
													)
												}
											/>
										</div>
									</div>
									<div className="text-primary pt-3">
										<a
											href="/#"
											className="changePwdlink"
											onClick={(e) => {
												e.preventDefault();
												this.setState({ changePwdModalShow: true });
											}}
										>
											<u>Change Password</u>
										</a>
									</div>

									<div>
										<button
											type="submit"
											disabled={!this.state.formIsValid}
											className="buttonClass login-btn-style"
										>
											<span className="signinButton">Sign In</span>
										</button>
									</div>
								</form>
							</div>
						</div>
						{this.state.changePwdModalShow ? (
							<ChangePasswordModal
								show={this.state.changePwdModalShow}
								onHide={changePwdModalClose}
								operatorid=""
							/>
						) : null}
						{this.state.confirmationModalShow ? (
							<ConfirmationModal
								show={this.state.confirmationModalShow}
								onHide={this.confirmationModalClose}
								onProceed={this.proceedToOperation}
								confirmmsg={this.state.confirmMsg}
							/>
						) : null}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		loggedInBrand: state.loggedInBrand
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setFullName: (val) => dispatch({ type: actionTypes.FULL_NAME, value: val }),
		setAssignedResources: (val) =>
			dispatch({ type: actionTypes.ASSIGNED_RESOURCES, value: val }),
		setLevelID: (val) => dispatch({ type: actionTypes.LOGGEDIN_LEVELID, value: val }),
		setLevelHierarchy: (val) =>
			dispatch({ type: actionTypes.LOGGEDIN_LEVELHIERARCHY, value: val }),
		setLoggedInBrand: (val) => dispatch({ type: actionTypes.LOGGEDIN_BRAND, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginPage));
