import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import './fileButtonGroup.scss';

import uploadIcon from '../../../assets/images/couponUploadNew.png';
import binDeleteIcon from '../../../assets/images/binDelete-32.png';

const FileButton = ({
	customClasses = '',
	fileHandler,
	btnDisabled = false,
	fileBtnID = 'fileBtnID',
	fileIcon = 'fileDelete',
	labels: { btnLabel = 'Upload', btnTitle = 'Upload File' }
}) => {
	const fileInput = useRef();
	return (
		<button
			type="button"
			className={`${customClasses} fileBtn d-flex align-items-center`}
			style={{
				fontSize: '13px',
				outline: 'none',
				border: '0px'
			}}
			disabled={btnDisabled}
			title={btnTitle}
			onClick={() => {
				fileInput.current.click();
			}}
		>
			<span className="btn font-weight-bold" style={{ fontSize: '13px' }}>
				{btnLabel}
			</span>
			<span className={`${fileIcon} icon ml-2`}></span>
			<input
				ref={fileInput}
				type="file"
				id={fileBtnID}
				style={{ display: 'none' }}
				onChange={(event) => {
					fileHandler(event, () => {
						fileInput.current.value = '';
					});
				}}
			/>
		</button>
	);
};

FileButton.propTypes = {
	customClasses: PropTypes.string,
	fileHandler: PropTypes.func,
	btnDisabled: PropTypes.bool,
	fileBtnID: PropTypes.string,
	fileIcon: PropTypes.oneOf(['fileDelete', 'fileUpload']),
	labels: PropTypes.shape()
};

const FileButtonContainer = ({ customClasses = '', children }) => {
	return <div className={`file-button-group-container d-flex ${customClasses}`}>{children}</div>;
};

FileButtonContainer.propTypes = {
	customClasses: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

const UploadFileBtn = ({
	customClasses = '',
	fileHandler,
	btnDisabled,
	fileBtnID = 'fileBtnID',
	fileIcon = 'fileUpload',
	labels: { btnLabel = 'Upload' }
}) => {
	const iconSrc = {
		fileUpload: uploadIcon,
		fileDelete: binDeleteIcon
	};
	const fileInput = useRef();

	return (
		<button
			type="button"
			className={`${customClasses} upload-file-button fileBtn roboto-b-16`}
			onClick={() => {
				fileInput.current.click();
			}}
			disabled={btnDisabled}
		>
			{btnLabel}
			<span className="ml-2">
				<img alt={btnLabel} src={iconSrc[fileIcon]} />
			</span>
			<input
				ref={fileInput}
				type="file"
				id={fileBtnID}
				className="d-none"
				onChange={(event) => {
					fileHandler(event, () => {
						fileInput.current.value = '';
					});
				}}
			/>
		</button>
	);
};

UploadFileBtn.propTypes = {
	customClasses: PropTypes.string,
	fileHandler: PropTypes.func,
	btnDisabled: PropTypes.bool,
	fileBtnID: PropTypes.string,
	fileIcon: PropTypes.oneOf(['fileUpload', 'fileDelete']),
	labels: PropTypes.shape()
};

export { FileButtonContainer, FileButton, UploadFileBtn };
