import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import CustomInputText from './customfields/CustomInputText';
import CustomSelect from './customfields/CustomSelect';
import CustomDateTime from './customfields/CustomDateTime';
import CustomHoursPicker from './customfields/CustomHoursPicker';
import CustomInputRadio from './customfields/CustomInputRadio';
import * as RestSvc from '../../../libraries/offer-services';

import * as urlTypes from '../../../libraries/offer-service-urls';
import { InformationModal } from '../../../MainContent/InformationModal';
import PaymentGroup from './PaymentGroup';
//const paymentConditionFormSchema = Yup.object().shape({});
import EmiTable from './EmiTable';
import appConstants from '../../../utils/appConstants';
import Toaster from '../../../Components/Toaster/Toaster';

const localConst = {
	applicableOn: 'Applicable on',
	subApplicableOn:
		'Determines if the promotion is applicable on the payment made through the tendered value or on the transaction condition',
	paymentThresholdCode: 'Payment Threshold Code',
	subPaymentThresholdCode: 'Determines the type of threshold for the payment',
	paymentThresholdAmount: 'Payment Threshold Amount',
	subPaymentThresholdAmount:
		'Determines the minimum threshold amount for the Payment Mode specified',
	paymentMode: 'Payment Mode',
	subPaymentMode: 'Defines the Payment eligibility Mode for which the promotion is applicable',
	emiType: 'EMI Type',
	subEmiType: 'Defines the types of EMI',
	provider: 'Provider',
	subProvider: 'Defines the Payment eligibility Provider for which the promotion is applicable',
	paymentType: 'Payment Type',
	subpaymentType: 'Defines the Payment eligibility Type for which the promotion is applicable',
	paymentSubType: 'Payment Sub Type',
	subPaymentSubType:
		'Defines the Payment eligibility SubType for which the promotion is applicable',
	coolOffPeriod: 'Cool Off Period'
};

const dropdownValues = {
	applicableOn: [
		{
			value: '',
			name: 'Select'
		},
		{
			value: 'TENDER_VALUE',
			name: 'Tender Value'
		},
		{
			value: 'TRANSACTION_VALUE',
			name: 'Transaction Value'
		}
	],
	paymentThreshold: [
		/*  {
      value: "",
      name: "Select",
    }, */
		{
			value: 'PAYMENT_AMOUNT_THRESHOLD',
			name: 'Payment Amount Threshold'
		}
	],
	emi: [
		{
			value: '',
			name: 'Select'
		},
		{
			value: 'NO_COST_EMI',
			name: 'No Cost EMI'
		},
		{
			value: 'ONE_EMI_FREE',
			name: 'One EMI Free'
		}
	],
	coolOff: [
		{
			value: '',
			name: 'Select'
		},
		// {
		//   value: "Date",
		//   name: "Date",
		// },
		{
			value: 'Months',
			name: 'Months'
		},
		{
			value: 'Days',
			name: 'Days'
		},
		{
			value: 'Years',
			name: 'Years'
		}
	],
	collectionValidity: [
		{ value: '', name: 'Select one' },
		{
			value: 'Date',
			name: 'Date'
		},
		{
			value: 'Months',
			name: 'Months'
		},
		{
			value: 'Days',
			name: 'Days'
		},
		{
			value: 'Hours',
			name: 'Hours'
		}
	]
};

const getApplicableOnDropdown = () => {
	return dropdownValues.applicableOn.map((val, index) => {
		return (
			<option key={index + ''} value={val.value}>
				{val.name}
			</option>
		);
	});
};
const getThresholdDropdown = () => {
	return dropdownValues.paymentThreshold.map((val, index) => {
		return (
			<option key={index + ''} value={val.value}>
				{val.name}
			</option>
		);
	});
};

const getEmiDropdown = (businessUnitGroupFunctionID) => {
	let emiTypes = [...dropdownValues.emi];
	if (businessUnitGroupFunctionID === appConstants.buBrand.CROMA.BUID) {
		emiTypes = [
			...emiTypes,
			{
				value: 'STANDARD_AND_LCE',
				name: 'Standard & LCE'
			}
		];
	}
	return emiTypes.map((val, index) => (
		<option key={index + ''} value={val.value}>
			{val.name}
		</option>
	));
};

const getCoolOffDropdown = () => {
	return dropdownValues.coolOff.map((val, index) => {
		return (
			<option key={index + ''} value={val.value}>
				{val.name}
			</option>
		);
	});
};
const PaymentConditionForm = ({
	name,
	label,
	required,
	smallText,
	option1,
	option2,
	label1,
	label2,
	...props
}) => {
	const [field] = useField(props);
	const valueEligbility = props.customName;
	const { setFieldValue } = useFormikContext();

	const filednamepmtelg =
		valueEligbility == 'groupMultibuyEligbility'
			? field.value.groupMultibuyEligbility.paymentEligibility
			: valueEligbility == 'packageMultibuyEligibility'
			? field.value.packageMultibuyEligibility.paymentEligibility
			: valueEligbility == 'multibuyEligbility'
			? field.value.multibuyEligbility.paymentEligibility
			: valueEligbility == 'merchandizeHierarchyEligibility'
			? field.value.merchandizeHierarchyEligibility.paymentEligibility
			: valueEligbility == 'earnBurnEligibility'
			? field.value.earnBurnEligibility.paymentEligibility
			: valueEligbility == 'totalPurchaseMarketBasedPriceDerivationRuleEligibiity'
			? field.value.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.paymentEligibility
			: valueEligbility == 'scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity'
			? field.value.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity
					.paymentEligibility
			: valueEligbility == 'scaledPriceEligibility'
			? field.value.scaledPriceEligibility.paymentEligibility
			: valueEligbility == 'mtmbEligibility'
			? field.value.mtmbEligibility.paymentEligibility
			: undefined;
	const fieldbenefit =
		valueEligbility == 'groupMultibuyEligbility'
			? field.value.groupMultibuyEligbility.benefit
			: valueEligbility == 'packageMultibuyEligibility'
			? field.value.packageMultibuyEligibility.benefit
			: valueEligbility == 'multibuyEligbility'
			? field.value.multibuyEligbility.benefit
			: valueEligbility == 'merchandizeHierarchyEligibility'
			? field.value.merchandizeHierarchyEligibility.benefit
			: valueEligbility == 'totalPurchaseMarketBasedPriceDerivationRuleEligibiity'
			? field.value.totalPurchaseMarketBasedPriceDerivationRuleEligibiity.benefit
			: valueEligbility == 'scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity'
			? field.value.scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity.benefit
			: valueEligbility == 'scaledPriceEligibility'
			? field.value.scaledPriceEligibility
			: valueEligbility == 'earnBurnEligibility'
			? field.value.earnBurnEligibility.benefit
			: undefined;

	// const [singlePayment, setSinglePayment] = useState(!filednamepmtelg?.payment?.paymentGroup);
	const [paymentModeOps, setPaymentModeOps] = useState([]);
	const [paymentProviderOps, setPaymentProviderOps] = useState([]);
	const [paymentTypeOps, setPaymentTypeOps] = useState([]);
	// const [paymentSubTypeOps, setPaymentSubTypeOps] = useState([]);
	const [aggregatorOps, setAggregatorOps] = useState([]);
	const [informationModalShow, setInformationModalShow] = useState(false);
	const [informationMsg] = useState('');
	const [informationTitle] = useState('');
	const didMountRef = useRef(false);

	const [show, setShow] = useState(false);

	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	let newJson = '';
	if (isAuditFlw) {
		newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));
		//
		// let OldPG =filednamepmtelg.payment.paymentGroup?filednamepmtelg.payment.paymentGroup:[];
		// let NewPG = newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility].paymentEligibility.payment.paymentGroup ? newJson.promotionPolicy.priceDerivationRuleEligibility[valueEligbility].paymentEligibility.payment.paymentGroup: [];
		// let tempRemoved = getAdded(OldPG, NewPG);
		// let tempAdded = getAdded(NewPG, OldPG)
		// let tempReplacedNew = getReplaced(NewPG, OldPG, tempAdded, tempRemoved)
		// // let tempReplacedOld = getReplaced(OldPG,NewPG,tempRemoved,tempAdded)
		//
		// //
	}

	const customValidateApplicableOn = (value) => {
		let error;
		if (filednamepmtelg.allowAggAndIssuer) {
			if (!value) {
				error = 'Please define Applicable On';
			}
		}
		return error;
	};
	const getPaymentConfigurations = () => {
		RestSvc.getData(urlTypes.offers_maintenance.getPaymentConfigurations)
			.then((response) => {
				var result = response.data.getPaymentConfigurationsResponse;
				if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });
					setShow(true);
				} else {
					if (result.paymentConfiguration) {
						result.paymentConfiguration.map((item) => {
							if (item.paymentConfigurationKey == 'PaymentMode') {
								let temp = [{ value: '', label: 'Select' }];
								item.paymentConfigurationValues.map((item) => {
									temp.push({ label: item.configName, value: item.configValue });
								});
								setPaymentModeOps(temp);
							}
							if (item.paymentConfigurationKey == 'Provider') {
								let temp = [{ value: '', label: 'Select' }];
								item.paymentConfigurationValues.map((item) => {
									temp.push({ label: item.configName, value: item.configValue });
								});
								setPaymentProviderOps(temp);
							}
							if (item.paymentConfigurationKey == 'PaymentType') {
								let temp = [{ value: '', label: 'Select' }];
								item.paymentConfigurationValues.map((item) => {
									temp.push({ label: item.configName, value: item.configValue });
								});
								setPaymentTypeOps(temp);
							}
							if (item.paymentConfigurationKey == 'PaymentSubType') {
								let temp = [];
								item.paymentConfigurationValues.map((item) => {
									temp.push({ label: item.configName, value: item.configValue });
								});
								// setPaymentSubTypeOps(temp);
							}
							if (item.paymentConfigurationKey == 'GateWay_Aggregator') {
								// let temp=[]
								// item.paymentConfigurationValues.map((item,i)=>{
								//   temp.push({"label":item.configName,"value":item.configValue})
								// })
								setAggregatorOps(item.paymentConfigurationValues);
							}
						});
						// setTimeout(() => {
						setShow(true);
						// }, 2000);
					}
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};
	const informationModalClose = () => {
		setInformationModalShow(false);
	};

	useEffect(() => {
		if (isAuditFlw) {
			if (
				filednamepmtelg.allowAggAndIssuer !=
				newJson?.promotionPolicy?.priceDerivationRuleEligibility[valueEligbility]
					?.paymentEligibility?.allowAggAndIssuer
			) {
				let tempName1 = `${valueEligbility}.paymentEligibility.allowAggAndIssuer`;
				$('[name="' + tempName1 + '"]')
					.closest('.container')
					.nextAll()
					.addClass('hide-nochange-section-for-audit');
			}

			if (
				filednamepmtelg.isBinRangePromotion !=
				newJson?.promotionPolicy?.priceDerivationRuleEligibility[valueEligbility]
					?.paymentEligibility?.isBinRangePromotion
			) {
				let tempName2 = `${valueEligbility}.paymentEligibility.isBinRangePromotion`;
				$('[name="' + tempName2 + '"]')
					.closest('.container')
					.nextAll()
					.addClass('hide-nochange-section-for-audit');
			}

			if (
				newJson?.promotionPolicy?.priceDerivationRuleEligibility[valueEligbility]
					?.paymentEligibility?.applicableOn != filednamepmtelg?.applicableOn
			) {
				let tempName = `${valueEligbility}.paymentEligibility.applicableOn`;
				$('[name="' + tempName + '"]')
					.closest('.container')
					.nextAll()
					.addClass('hide-nochange-section-for-audit');
			}
		}
	}, []);

	useEffect(() => {
		if (
			JSON.parse(props.fullVal).promotionPolicy.promotionApplicableEligibility
				.promotionActivationEligibility.multimapPromotion
		) {
			filednamepmtelg.allowAggAndIssuer = false;
			filednamepmtelg.applicableOn = '';
			filednamepmtelg.paymentThresholdTypeCode = undefined;
			filednamepmtelg.payment = undefined;
			filednamepmtelg.isBinRangePromotion = undefined;
		}
		// if(filednamepmtelg?.payment?.paymentGroup){
		//   setSinglePayment(false);
		// }else{
		//   setSinglePayment(true);
		// }

		//Commented since Amount Capping is hidden
		if (props.fullVal && JSON.parse(props.fullVal).promotionPolicy.rewardLimit) {
			if (filednamepmtelg?.payment?.emi?.emiScale != undefined) {
				filednamepmtelg.payment.emi.emiScale.map((item) => {
					item.cappedAmount = JSON.parse(props.fullVal).promotionPolicy.rewardLimit;
				});
			}
			if (filednamepmtelg?.payment?.paymentGroup) {
				filednamepmtelg.payment.paymentGroup.map((item) => {
					item.issuers &&
						item.issuers.map((item1) => {
							if (item1?.emi?.emiScale != undefined) {
								item1.emi.emiScale.map((a) => {
									a.cappedAmount = JSON.parse(
										props.fullVal
									).promotionPolicy.rewardLimit;
								});
							}
						});
				});
			}
		}
		if (filednamepmtelg?.payment?.paymentGroup) {
			filednamepmtelg.payment.paymentGroup.map((item) => {
				if (item.issuers == undefined) {
					item.issuers = [
						{
							issuerPaymentMode: filednamepmtelg.isBinRangePromotion ? 'CARD' : '',
							issuerPaymentType: '',
							issuerPaymentSubType: '',
							issuerProvider: '',
							cashbackInformation: {
								isCashbackSame: true,
								paymentInformation: {
									paymentMode: '',
									provider: '',
									paymentSubType: '',
									paymentType: ''
								},
								creditBy: {},
								expireOn: {}
							}
						}
					];
				}
			});
		}
		setFieldValue('', '');
		getPaymentConfigurations();
		// let paymentConfiguration=[
		//   {
		//     "paymentConfigurationKey": "GateWay_Aggregator",
		//     "paymentConfigurationValues": [
		//       {
		//         "configValue": "AXIS",
		//         "configId": 1,
		//         "configName": "AXIS Bank"
		//       },
		//       {
		//         "configValue": "ICICI",
		//         "configId": 2,
		//         "configName": "ICICI Bank"
		//       },
		//       {
		//         "configValue": "JUSTPAY",
		//         "configId": 3,
		//         "configName": "JUSTPAY"
		//       }
		//     ]
		//   },
		//   {
		//     "paymentConfigurationKey": "PaymentMode",
		//     "paymentConfigurationValues": [
		//       {
		//         "configValue": "CARD",
		//         "configName": "CARD"
		//       },
		//       {
		//         "configValue": "NETBANKING",
		//         "configName": "NETBANKING"
		//       },
		//       {
		//         "configValue": "WALLET",
		//         "configName": "WALLET"
		//       }
		//     ]
		//   },
		//   {
		//     "paymentConfigurationKey": "Provider",
		//     "paymentConfigurationValues": [
		//       {
		//         "configValue": "SBI_BANK",
		//         "configName": "SBI_BANK"
		//       },
		//       {
		//         "configValue": "ICICI_BANK",
		//         "configName": "ICICI_BANK"
		//       }
		//     ]
		//   },
		//   {
		//     "paymentConfigurationKey": "PaymentType",
		//     "paymentConfigurationValues": [
		//       {
		//         "configValue": "CREDIT",
		//         "configName": "CREDIT"
		//       },
		//       {
		//         "configValue": "DEBIT",
		//         "configName": "DEBIT"
		//       }
		//     ]
		//   },
		//   {
		//     "paymentConfigurationKey": "PaymentSubType",
		//     "paymentConfigurationValues": [
		//       {
		//         "configValue": "AXIS",
		//         "configName": "AXIS Bank"
		//       },
		//       {
		//         "configValue": "AXIS",
		//         "configName": "AXIS Bank"
		//       },
		//       {
		//         "configValue": "AXIS",
		//         "configName": "AXIS Bank"
		//       }
		//     ]
		//   }
		// ];
		// paymentConfiguration.map((item)=>{
		//   if(item.paymentConfigurationKey=="PaymentMode"){
		//     let temp=[]
		//     item.paymentConfigurationValues.map((item,i)=>{
		//       temp.push({"label":item.configName,"value":item.configValue})
		//     })
		//     setPaymentModeOps(temp)
		//   }
		//   if(item.paymentConfigurationKey=="Provider"){
		//     let temp=[]
		//     item.paymentConfigurationValues.map((item,i)=>{
		//       temp.push({"label":item.configName,"value":item.configValue})
		//     })
		//     setPaymentProviderOps(temp)
		//   }
		//   if(item.paymentConfigurationKey=="PaymentType"){
		//     let temp=[]
		//     item.paymentConfigurationValues.map((item,i)=>{
		//       temp.push({"label":item.configName,"value":item.configValue})
		//     })
		//     setPaymentTypeOps(temp)
		//   }
		//   if(item.paymentConfigurationKey=="PaymentSubType"){
		//     let temp=[]
		//     item.paymentConfigurationValues.map((item,i)=>{
		//       temp.push({"label":item.configName,"value":item.configValue})
		//     })
		//     setPaymentSubTypeOps(temp)
		//   }
		//   if(item.paymentConfigurationKey=="GateWay_Aggregator"){
		//     setAggregatorOps(item.paymentConfigurationValues)
		//   }
		// })
	}, []);
	useEffect(() => {
		if (filednamepmtelg.applicableOn && !filednamepmtelg.payment) {
			if (
				valueEligbility != 'scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity' &&
				valueEligbility != 'scaledPriceEligibility'
			) {
				filednamepmtelg.payment = { paymentThresholdAmount: '0.01' };

				setFieldValue('', '');
			} else {
				filednamepmtelg.payment = {};
			}
			if (filednamepmtelg.allowAggAndIssuer && !filednamepmtelg.payment.paymentGroup) {
				filednamepmtelg.payment.paymentGroup = [
					{
						aggregators: [],
						issuers: [
							{
								issuerPaymentMode: filednamepmtelg.isBinRangePromotion
									? 'CARD'
									: '',
								issuerPaymentType: '',
								issuerPaymentSubType: '',
								issuerProvider: '',
								cashbackInformation: {
									isCashbackSame: true,
									paymentInformation: {
										paymentMode: '',
										provider: '',
										paymentSubType: '',
										paymentType: ''
									},
									creditBy: {},
									expireOn: {}
								}
							}
						]
					}
				];
			}
		}

		if (filednamepmtelg.applicableOn == '' || filednamepmtelg.applicableOn == undefined) {
			filednamepmtelg.paymentThresholdTypeCode = undefined;
			setFieldValue('', '');
		} else if (filednamepmtelg.applicableOn && !filednamepmtelg.paymentThresholdTypeCode) {
			filednamepmtelg.paymentThresholdTypeCode = 'PAYMENT_AMOUNT_THRESHOLD';
			setFieldValue('', '');
		}

		//Single Payment
		if (!filednamepmtelg.allowAggAndIssuer) {
			if (
				filednamepmtelg.payment &&
				filednamepmtelg.payment.paymentInformation &&
				filednamepmtelg.payment?.paymentInformation?.emiType
			) {
				if (valueEligbility == 'totalPurchaseMarketBasedPriceDerivationRuleEligibiity') {
					if (
						filednamepmtelg.payment?.paymentInformation?.emiType == 'NO_COST_EMI' ||
						filednamepmtelg.payment?.paymentInformation?.emiType == 'STANDARD_AND_LCE'
					) {
						if (
							field.value.promotionalOfferReward?.itemPriceDerivationRule?.benefit
								?.benefitType != 'EMI'
						) {
							field.value.promotionalOfferReward.itemPriceDerivationRule.benefit.priceModificationMethodCode =
								undefined;
						}
						setFieldValue(
							`promotionalOfferReward.itemPriceDerivationRule.benefit.benefitType`,
							undefined
						);
						setFieldValue(
							`promotionalOfferReward.itemPriceDerivationRule.benefit.benefitType`,
							'EMI'
						);
						if (
							!field.value.promotionalOfferReward.itemPriceDerivationRule.benefit
								.priceModificationMethodCode ||
							field.value.promotionalOfferReward.itemPriceDerivationRule.benefit
								.priceModificationMethodCode == 'ONE_EMI_FREE_PERCENTAGE_OFF'
						)
							setFieldValue(
								`promotionalOfferReward.itemPriceDerivationRule.benefit.priceModificationMethodCode`,
								''
							);
					} else if (
						filednamepmtelg.payment?.paymentInformation?.emiType == 'ONE_EMI_FREE'
					) {
						if (
							field.value.promotionalOfferReward?.itemPriceDerivationRule?.benefit
								?.benefitType != 'EMI'
						) {
							field.value.promotionalOfferReward.itemPriceDerivationRule.benefit.priceModificationMethodCode =
								undefined;
						}
						setFieldValue(
							`promotionalOfferReward.itemPriceDerivationRule.benefit.benefitType`,
							undefined
						);
						setFieldValue(
							`promotionalOfferReward.itemPriceDerivationRule.benefit.benefitType`,
							'EMI'
						);
						if (
							!field.value.promotionalOfferReward.itemPriceDerivationRule.benefit
								.priceModificationMethodCode ||
							field.value.promotionalOfferReward.itemPriceDerivationRule.benefit
								.priceModificationMethodCode == 'EMI_PERCENTAGE_OFF' ||
							field.value.promotionalOfferReward.itemPriceDerivationRule.benefit
								.priceModificationMethodCode == 'EMI_APPORTIONED_PERCENTAGE_OFF'
						)
							setFieldValue(
								`promotionalOfferReward.itemPriceDerivationRule.benefit.priceModificationMethodCode`,
								'ONE_EMI_FREE_PERCENTAGE_OFF'
							);
					}
				} else {
					if (
						filednamepmtelg.payment?.paymentInformation?.emiType == 'NO_COST_EMI' ||
						filednamepmtelg.payment?.paymentInformation?.emiType == 'STANDARD_AND_LCE'
					) {
						if (fieldbenefit.benefitType != 'EMI') {
							fieldbenefit.priceModificationMethodCode = undefined;
						}
						setFieldValue(`${valueEligbility}.benefit.benefitType`, undefined);
						setFieldValue(`${valueEligbility}.benefit.benefitType`, 'EMI');
						if (
							!fieldbenefit.priceModificationMethodCode ||
							fieldbenefit.priceModificationMethodCode ==
								'ONE_EMI_FREE_PERCENTAGE_OFF'
						)
							setFieldValue(
								`${valueEligbility}.benefit.priceModificationMethodCode`,
								''
							);
					} else if (
						filednamepmtelg.payment?.paymentInformation?.emiType == 'ONE_EMI_FREE'
					) {
						if (fieldbenefit.benefitType != 'EMI') {
							fieldbenefit.priceModificationMethodCode = undefined;
						}
						setFieldValue(`${valueEligbility}.benefit.benefitType`, undefined);
						setFieldValue(`${valueEligbility}.benefit.benefitType`, 'EMI');
						if (
							!fieldbenefit.priceModificationMethodCode ||
							fieldbenefit.priceModificationMethodCode == 'EMI_PERCENTAGE_OFF' ||
							fieldbenefit.priceModificationMethodCode ==
								'EMI_APPORTIONED_PERCENTAGE_OFF'
						)
							setFieldValue(
								`${valueEligbility}.benefit.priceModificationMethodCode`,
								'ONE_EMI_FREE_PERCENTAGE_OFF'
							);
					}
				}
				if (filednamepmtelg.payment.emi == undefined) {
					filednamepmtelg.payment.emi = { emiScale: [] };
					filednamepmtelg.payment.emi.emiScale = [
						{
							tenurePeriodInMonths: '',
							interestRate: '',
							cappedAmount: JSON.parse(props.fullVal).promotionPolicy.rewardLimit
								? JSON.parse(props.fullVal).promotionPolicy.rewardLimit
								: ''
						}
					];
				} else if (filednamepmtelg.payment.emi.emiScale == undefined) {
					filednamepmtelg.payment.emi.emiScale = [
						{
							tenurePeriodInMonths: '',
							interestRate: '',
							cappedAmount: JSON.parse(props.fullVal).promotionPolicy.rewardLimit
								? JSON.parse(props.fullVal).promotionPolicy.rewardLimit
								: ''
						}
					];
				}
			} else if (
				filednamepmtelg.payment &&
				filednamepmtelg.payment.paymentInformation &&
				filednamepmtelg.payment.paymentInformation.emiType == ''
			) {
				if (filednamepmtelg.payment.emi.coolOffPeriod) {
					filednamepmtelg.payment.emi.coolOffPeriod.coolOffPeriodType = '';
				}
				if (valueEligbility == 'totalPurchaseMarketBasedPriceDerivationRuleEligibiity') {
					if (
						field?.value?.promotionalOfferReward?.itemPriceDerivationRule?.benefit
							?.benefitType == 'EMI'
					) {
						setFieldValue(
							`promotionalOfferReward.itemPriceDerivationRule.benefit.benefitType`,
							''
						);
					}
				} else {
					if (fieldbenefit.benefitType == 'EMI')
						setFieldValue(`${valueEligbility}.benefit.benefitType`, '');
				}
			}
			if (
				filednamepmtelg.payment &&
				filednamepmtelg.payment.paymentInformation &&
				!filednamepmtelg.payment.paymentInformation.emiType &&
				filednamepmtelg.payment.emi &&
				filednamepmtelg.payment.emi.emiScale != undefined
			) {
				if (filednamepmtelg.payment.emi.coolOffPeriod != undefined) {
					filednamepmtelg.payment.emi.emiScale = undefined;
				} else {
					filednamepmtelg.payment.emi = undefined;
				}
			}
		}
		//MultiPayment
		else {
			if (filednamepmtelg.payment && filednamepmtelg.payment?.emiType) {
				if (valueEligbility == 'totalPurchaseMarketBasedPriceDerivationRuleEligibiity') {
					if (
						filednamepmtelg.payment?.emiType == 'NO_COST_EMI' ||
						filednamepmtelg.payment?.emiType === 'STANDARD_AND_LCE'
					) {
						if (
							field.value.promotionalOfferReward?.itemPriceDerivationRule?.benefit
								?.benefitType != 'EMI'
						) {
							field.value.promotionalOfferReward.itemPriceDerivationRule.benefit.priceModificationMethodCode =
								undefined;
						}
						setFieldValue(
							`promotionalOfferReward.itemPriceDerivationRule.benefit.benefitType`,
							undefined
						);
						setFieldValue(
							`promotionalOfferReward.itemPriceDerivationRule.benefit.benefitType`,
							'EMI'
						);
						if (
							!field.value.promotionalOfferReward.itemPriceDerivationRule.benefit
								.priceModificationMethodCode ||
							field.value.promotionalOfferReward.itemPriceDerivationRule.benefit
								.priceModificationMethodCode == 'ONE_EMI_FREE_PERCENTAGE_OFF'
						)
							setFieldValue(
								`promotionalOfferReward.itemPriceDerivationRule.benefit.priceModificationMethodCode`,
								''
							);
					} else if (filednamepmtelg.payment?.emiType == 'ONE_EMI_FREE') {
						if (
							field.value.promotionalOfferReward?.itemPriceDerivationRule?.benefit
								?.benefitType != 'EMI'
						) {
							field.value.promotionalOfferReward.itemPriceDerivationRule.benefit.priceModificationMethodCode =
								undefined;
						}
						setFieldValue(
							`promotionalOfferReward.itemPriceDerivationRule.benefit.benefitType`,
							undefined
						);
						setFieldValue(
							`promotionalOfferReward.itemPriceDerivationRule.benefit.benefitType`,
							'EMI'
						);
						if (
							!field.value.promotionalOfferReward.itemPriceDerivationRule.benefit
								.priceModificationMethodCode ||
							field.value.promotionalOfferReward.itemPriceDerivationRule.benefit
								.priceModificationMethodCode == 'EMI_PERCENTAGE_OFF' ||
							field.value.promotionalOfferReward.itemPriceDerivationRule.benefit
								.priceModificationMethodCode == 'EMI_APPORTIONED_PERCENTAGE_OFF'
						)
							setFieldValue(
								`promotionalOfferReward.itemPriceDerivationRule.benefit.priceModificationMethodCode`,
								'ONE_EMI_FREE_PERCENTAGE_OFF'
							);
					}
				} else {
					if (
						filednamepmtelg.payment?.emiType == 'NO_COST_EMI' ||
						filednamepmtelg.payment?.emiType === 'STANDARD_AND_LCE'
					) {
						if (fieldbenefit.benefitType != 'EMI') {
							fieldbenefit.priceModificationMethodCode = undefined;
						}
						setFieldValue(`${valueEligbility}.benefit.benefitType`, undefined);
						setFieldValue(`${valueEligbility}.benefit.benefitType`, 'EMI');
						if (
							!fieldbenefit.priceModificationMethodCode ||
							fieldbenefit.priceModificationMethodCode ==
								'ONE_EMI_FREE_PERCENTAGE_OFF'
						)
							setFieldValue(
								`${valueEligbility}.benefit.priceModificationMethodCode`,
								''
							);
					} else if (filednamepmtelg.payment?.emiType == 'ONE_EMI_FREE') {
						if (fieldbenefit.benefitType != 'EMI') {
							fieldbenefit.priceModificationMethodCode = undefined;
						}
						setFieldValue(`${valueEligbility}.benefit.benefitType`, undefined);
						setFieldValue(`${valueEligbility}.benefit.benefitType`, 'EMI');
						if (
							!fieldbenefit.priceModificationMethodCode ||
							fieldbenefit.priceModificationMethodCode == 'EMI_PERCENTAGE_OFF' ||
							fieldbenefit.priceModificationMethodCode ==
								'EMI_APPORTIONED_PERCENTAGE_OFF'
						)
							setFieldValue(
								`${valueEligbility}.benefit.priceModificationMethodCode`,
								'ONE_EMI_FREE_PERCENTAGE_OFF'
							);
					}
				}
				for (let i = 0; i < filednamepmtelg.payment?.paymentGroup.length; i++) {
					for (
						let j = 0;
						j < filednamepmtelg.payment?.paymentGroup[i].issuers?.length;
						j++
					) {
						if (filednamepmtelg.payment.paymentGroup[i].issuers[j].emi == undefined) {
							filednamepmtelg.payment.paymentGroup[i].issuers[j].emi = {
								emiScale: []
							};
							filednamepmtelg.payment.paymentGroup[i].issuers[j].emi.emiScale = [
								{
									tenurePeriodInMonths: '',
									interestRate: '',
									cappedAmount: JSON.parse(props.fullVal).promotionPolicy
										.rewardLimit
										? JSON.parse(props.fullVal).promotionPolicy.rewardLimit
										: ''
								}
							];
						} else if (
							filednamepmtelg.payment.paymentGroup[i].issuers[j].emi.emiScale ==
							undefined
						) {
							filednamepmtelg.payment.paymentGroup[i].issuers[j].emi.emiScale = [
								{
									tenurePeriodInMonths: '',
									interestRate: '',
									cappedAmount: JSON.parse(props.fullVal).promotionPolicy
										.rewardLimit
										? JSON.parse(props.fullVal).promotionPolicy.rewardLimit
										: ''
								}
							];
						}
					}
				}
				setFieldValue('', '');
			} else if (filednamepmtelg.payment && filednamepmtelg.payment.emiType == '') {
				for (let i = 0; i < filednamepmtelg.payment?.paymentGroup.length; i++) {
					if (filednamepmtelg.payment.paymentGroup[i].emi?.coolOffPeriod) {
						filednamepmtelg.payment.paymentGroup[
							i
						].emi.coolOffPeriod.coolOffPeriodType = '';
					}
				}
				if (valueEligbility == 'totalPurchaseMarketBasedPriceDerivationRuleEligibiity') {
					if (
						field?.value?.promotionalOfferReward?.itemPriceDerivationRule?.benefit
							?.benefitType == 'EMI'
					) {
						setFieldValue(
							`promotionalOfferReward.itemPriceDerivationRule.benefit.benefitType`,
							''
						);
					}
				} else {
					if (fieldbenefit.benefitType == 'EMI')
						setFieldValue(`${valueEligbility}.benefit.benefitType`, '');
				}
			}
			//Remove emi and cooloff when emi type not selected
			for (let i = 0; i < filednamepmtelg.payment?.paymentGroup?.length; i++) {
				for (let j = 0; j < filednamepmtelg.payment?.paymentGroup[i].issuers?.length; j++) {
					// if (
					//   filednamepmtelg.payment &&
					//   (!filednamepmtelg.payment?.emiType) &&
					//   filednamepmtelg.payment.paymentGroup[i].emi &&
					//   filednamepmtelg.payment.paymentGroup[i].emi.emiScale != undefined
					// ) {
					//   if (filednamepmtelg.payment.paymentGroup[i].emi.coolOffPeriod != undefined) {
					//     filednamepmtelg.payment.paymentGroup[i].emi.emiScale = undefined;
					//   } else {
					//     filednamepmtelg.payment.paymentGroup[i].emi = undefined;
					//   }
					// }
					if (filednamepmtelg.payment && !filednamepmtelg.payment.emiType) {
						filednamepmtelg.payment.paymentGroup[i].issuers[j].emi = undefined;
					}
				}
			}
			if (valueEligbility == 'totalPurchaseMarketBasedPriceDerivationRuleEligibiity') {
				if (
					!field?.value?.promotionalOfferReward?.itemPriceDerivationRule?.benefit ||
					(field?.value?.promotionalOfferReward?.itemPriceDerivationRule?.benefit &&
						field?.value?.promotionalOfferReward?.itemPriceDerivationRule?.benefit
							?.benefitType != 'CASHBACK')
				) {
					if (filednamepmtelg?.payment?.paymentGroup) {
						filednamepmtelg.payment.paymentGroup.map((a) => {
							a.issuers &&
								a.issuers.map((b) => {
									b.cashbackInformation = undefined;
								});
						});
					}
				}
			} else {
				if (!fieldbenefit || (fieldbenefit && fieldbenefit.benefitType != 'CASHBACK')) {
					if (filednamepmtelg?.payment?.paymentGroup) {
						filednamepmtelg.payment.paymentGroup.map((a) => {
							a.issuers &&
								a.issuers.map((b) => {
									b.cashbackInformation = undefined;
								});
						});
					}
				}
			}
		}
		/* todo: PaymentConditionForm browser hang prevent remove below line*/
		setFieldValue('', '');
	}, [filednamepmtelg]);

	useEffect(() => {
		if (!filednamepmtelg.allowAggAndIssuer) {
			if (filednamepmtelg.payment && filednamepmtelg.payment.paymentGroup) {
				filednamepmtelg.payment.paymentGroup = undefined;
			}
			if (filednamepmtelg.payment && filednamepmtelg.payment.emiType)
				filednamepmtelg.payment.emiType = undefined;
			filednamepmtelg.isBinRangePromotion = undefined;
		} else {
			if (!filednamepmtelg.payment) {
				filednamepmtelg.payment = {};
			}
			if (filednamepmtelg.payment.paymentInformation) {
				filednamepmtelg.payment.paymentInformation = undefined;
			}
			if (filednamepmtelg.payment.emi) {
				filednamepmtelg.payment.emi = undefined;
			}
			if (!filednamepmtelg.payment.paymentGroup) {
				filednamepmtelg.payment.paymentGroup = [
					{
						aggregators: [],
						issuers: [
							{
								issuerPaymentMode: filednamepmtelg.isBinRangePromotion
									? 'CARD'
									: '',
								issuerPaymentType: '',
								issuerPaymentSubType: '',
								issuerProvider: '',
								cashbackInformation: {
									isCashbackSame: true,
									paymentInformation: {
										paymentMode: '',
										provider: '',
										paymentSubType: '',
										paymentType: ''
									},
									creditBy: {},
									expireOn: {}
								}
							}
						]
					}
				];
			}
			if (!filednamepmtelg.isBinRangePromotion) filednamepmtelg.isBinRangePromotion = false;
		}
		setFieldValue('', '');
	}, [filednamepmtelg.allowAggAndIssuer]);
	useEffect(() => {
		if (didMountRef.current) {
			if (filednamepmtelg.isBinRangePromotion) {
				filednamepmtelg.payment && filednamepmtelg.payment.paymentGroup.splice(1);
				filednamepmtelg.payment &&
					filednamepmtelg.payment.paymentGroup &&
					filednamepmtelg.payment.paymentGroup.map((item) => {
						item.aggregators = [];
						if (filednamepmtelg.payment.emiType) {
							item.issuers = [
								{
									issuerPaymentMode: 'CARD',
									issuerPaymentType: '',
									issuerPaymentSubType: '',
									issuerProvider: '',
									cashbackInformation:
										(fieldbenefit && fieldbenefit.benefitType == 'CASHBACK') ||
										field.value.promotionalOfferReward?.itemPriceDerivationRule
											?.benefit?.benefitType == 'CASHBACK'
											? {
													isCashbackSame: true,
													paymentInformation: {
														paymentMode: '',
														provider: '',
														paymentSubType: '',
														paymentType: ''
													},
													creditBy: {},
													expireOn: {}
											  }
											: undefined,
									emi: {
										emiScale: [
											{
												tenurePeriodInMonths: '',
												interestRate: '',
												cappedAmount: JSON.parse(props.fullVal)
													.promotionPolicy.rewardLimit
													? JSON.parse(props.fullVal).promotionPolicy
															.rewardLimit
													: ''
											}
										]
									}
								}
							];
						} else {
							item.issuers = [
								{
									issuerPaymentMode: 'CARD',
									issuerPaymentType: '',
									issuerPaymentSubType: '',
									issuerProvider: '',
									cashbackInformation:
										(fieldbenefit && fieldbenefit.benefitType == 'CASHBACK') ||
										field.value.promotionalOfferReward?.itemPriceDerivationRule
											?.benefit?.benefitType == 'CASHBACK'
											? {
													isCashbackSame: true,
													paymentInformation: {
														paymentMode: '',
														provider: '',
														paymentSubType: '',
														paymentType: ''
													},
													creditBy: {},
													expireOn: {}
											  }
											: undefined
								}
							];
						}
					});
			} else {
				if (filednamepmtelg.allowAggAndIssuer) {
					filednamepmtelg.payment && filednamepmtelg.payment.paymentGroup.splice(1);
					filednamepmtelg.payment &&
						filednamepmtelg.payment.paymentGroup &&
						filednamepmtelg.payment.paymentGroup.map((item) => {
							item.aggregators = [];
							if (filednamepmtelg.payment.emiType) {
								item.issuers = [
									{
										issuerPaymentMode: '',
										issuerPaymentType: '',
										issuerPaymentSubType: '',
										issuerProvider: '',
										cashbackInformation:
											(fieldbenefit &&
												fieldbenefit.benefitType == 'CASHBACK') ||
											field.value.promotionalOfferReward
												?.itemPriceDerivationRule?.benefit?.benefitType ==
												'CASHBACK'
												? {
														isCashbackSame: true,
														paymentInformation: {
															paymentMode: '',
															provider: '',
															paymentSubType: '',
															paymentType: ''
														},
														creditBy: {},
														expireOn: {}
												  }
												: undefined,
										emi: {
											emiScale: [
												{
													tenurePeriodInMonths: '',
													interestRate: '',
													cappedAmount: JSON.parse(props.fullVal)
														.promotionPolicy.rewardLimit
														? JSON.parse(props.fullVal).promotionPolicy
																.rewardLimit
														: ''
												}
											]
										}
									}
								];
							} else {
								item.issuers = [
									{
										issuerPaymentMode: '',
										issuerPaymentType: '',
										issuerPaymentSubType: '',
										issuerProvider: '',
										cashbackInformation:
											(fieldbenefit &&
												fieldbenefit.benefitType == 'CASHBACK') ||
											field.value.promotionalOfferReward
												?.itemPriceDerivationRule?.benefit?.benefitType ==
												'CASHBACK'
												? {
														isCashbackSame: true,
														paymentInformation: {
															paymentMode: '',
															provider: '',
															paymentSubType: '',
															paymentType: ''
														},
														creditBy: {},
														expireOn: {}
												  }
												: undefined
									}
								];
							}
						});
				}
			}
			setTimeout(() => {
				setShow(false);
			}, 500);
			setTimeout(() => {
				setShow(true);
			}, 500);
		} else {
			didMountRef.current = true;
		}
	}, [filednamepmtelg.isBinRangePromotion]);

	useEffect(() => {
		if (fieldbenefit?.benefitType == 'CASHBACK') {
			if (filednamepmtelg?.payment?.paymentGroup) {
				filednamepmtelg.payment.paymentGroup.map((a) => {
					a.issuers &&
						a.issuers.map((b) => {
							if (b.cashbackInformation == undefined)
								b.cashbackInformation = {
									isCashbackSame: true,
									paymentInformation: {
										paymentMode: '',
										provider: '',
										paymentSubType: '',
										paymentType: ''
									},
									creditBy: {},
									expireOn: {}
								};
						});
				});
			}
		}
	}, [fieldbenefit?.benefitType]);
	useEffect(() => {
		if (
			field.value.promotionalOfferReward?.itemPriceDerivationRule?.benefit?.benefitType ==
			'CASHBACK'
		) {
			if (filednamepmtelg?.payment?.paymentGroup) {
				filednamepmtelg.payment.paymentGroup.map((a) => {
					a.issuers &&
						a.issuers.map((b) => {
							if (b.cashbackInformation == undefined)
								b.cashbackInformation = {
									isCashbackSame: true,
									paymentInformation: {
										paymentMode: '',
										provider: '',
										paymentSubType: '',
										paymentType: ''
									},
									creditBy: {},
									expireOn: {}
								};
						});
				});
			}
		}
	}, [field.value.promotionalOfferReward?.itemPriceDerivationRule?.benefit?.benefitType]);
	return (
		<>
			<div
				className={
					JSON.parse(props.fullVal).promotionPolicy.promotionApplicableEligibility
						.promotionActivationEligibility.multimapPromotion
						? 'd-none'
						: 'main'
				}
			>
				<CustomInputRadio
					name={`${valueEligbility}.paymentEligibility.allowAggAndIssuer`}
					label={'Allow Aggregator and Issuers'}
					required={true}
					label1="Yes"
					label2="No"
					option1={true}
					option2={false}
					smallText={''}
				/>
				{/* <div class="container">
        <div class="rowContainer">
          <div class="customLabelContainer">
            <label htmlFor="forprogramID" class="col	col-form-label">Payment Type<span class="requiredRed">*</span></label>
          </div>
          <div class="customInputContainer">
            <div class="row mg-5">
              <input  type="radio" value={singlePayment} checked={singlePayment} onChange={()=>{setSinglePayment(true)}} />
              Single Payment<input  type="radio" class="radioMargin" value={!singlePayment}  checked={!singlePayment} onChange={()=>{setSinglePayment(false)}} />
              MultiPayment
            </div>
          </div>
        </div>
      </div> */}
				{filednamepmtelg.allowAggAndIssuer ? (
					<CustomInputRadio
						name={`${valueEligbility}.paymentEligibility.isBinRangePromotion`}
						label={'Is Bin Range Promotion'}
						required={true}
						label1="Yes"
						label2="No"
						option1={true}
						option2={false}
						smallText={''}
					/>
				) : null}
				<CustomSelect
					name={`${valueEligbility}.paymentEligibility.applicableOn`}
					label={localConst.applicableOn}
					options={getApplicableOnDropdown()}
					smallText="Determines if the promotion is applicable on the payment made through the tendered value or on the transaction condition"
					required={filednamepmtelg.allowAggAndIssuer}
					validate={customValidateApplicableOn}
				/>

				{filednamepmtelg.applicableOn &&
				(filednamepmtelg.applicableOn == 'TENDER_VALUE' ||
					filednamepmtelg.applicableOn == 'TRANSACTION_VALUE') ? (
					<>
						<CustomSelect
							name={`${valueEligbility}.paymentEligibility.paymentThresholdTypeCode`}
							required={true}
							label={localConst.paymentThresholdCode}
							options={getThresholdDropdown()}
							smallText="Determines the type of threshold for the payment"
						/>
						{valueEligbility ==
							'scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity' ||
						valueEligbility == 'scaledPriceEligibility' ? undefined : (
							<CustomInputText
								name={`${valueEligbility}.paymentEligibility.payment.paymentThresholdAmount`}
								required={true}
								label={localConst.paymentThresholdAmount}
								smallText="Determines the minimum threshold amount for the Payment Mode specified"
							/>
						)}
						{!filednamepmtelg.allowAggAndIssuer ? (
							<>
								<CustomInputText
									name={`${valueEligbility}.paymentEligibility.payment.paymentInformation.paymentMode`}
									required={false}
									label={localConst.paymentMode}
									smallText="Defines the Payment eligibility Mode for which the promotion is applicable"
								/>
								{valueEligbility ==
									'scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity' ||
								valueEligbility == 'scaledPriceEligibility' ||
								valueEligbility == 'earnBurnEligibility' ||
								valueEligbility == 'mtmbEligibility' ? undefined : (
									<CustomSelect
										name={`${valueEligbility}.paymentEligibility.payment.paymentInformation.emiType`}
										label={localConst.emiType}
										options={getEmiDropdown(
											props.loggedInBrand?.businessUnitGroupFunctionID
										)}
										smallText="Defines the types of EMI"
									/>
								)}
								<CustomInputText
									name={`${valueEligbility}.paymentEligibility.payment.paymentInformation.provider`}
									required={false}
									label={localConst.provider}
									smallText="Defines the Payment eligibility Provider for which the promotion is applicable"
								/>
								<CustomInputText
									name={`${valueEligbility}.paymentEligibility.payment.paymentInformation.paymentType`}
									required={false}
									label={localConst.paymentType}
									smallText="Defines the Payment eligibility Type for which the promotion is applicable"
								/>
								<CustomInputText
									name={`${valueEligbility}.paymentEligibility.payment.paymentInformation.paymentSubType`}
									required={false}
									label={localConst.paymentSubType}
									smallText="Defines the Payment eligibility SubType for which the promotion is applicable"
								/>
								{filednamepmtelg.payment &&
								filednamepmtelg.payment.paymentInformation &&
								filednamepmtelg.payment.paymentInformation.emiType ? (
									//Table

									isAuditFlw ? (
										<>
											<div className="box-blackout-period-new lightBlue">
												<div
													className={
														filednamepmtelg.payment.emi?.emiScaleAdded
															?.length > 0
															? 'issuerOuterBox mb-3 auditHide addedSection'
															: 'd-none'
													}
												>
													<label className="font-weight-bold">
														Added (
														{
															filednamepmtelg.payment.emi
																?.emiScaleAdded?.length
														}
														)
													</label>
													<EmiTable
														filednamepmtelg={filednamepmtelg}
														fullVal={props.fullVal}
														valueEligbility={valueEligbility}
														emiScaleType={'emiScaleAdded'}
													/>
												</div>
												<div
													className={
														filednamepmtelg.payment.emi?.emiScaleRemoved
															?.length > 0
															? 'issuerOuterBox mb-3 auditHide removedSection'
															: 'd-none'
													}
												>
													<label className="font-weight-bold">
														Removed (
														{
															filednamepmtelg.payment.emi
																?.emiScaleRemoved?.length
														}
														)
													</label>
													<EmiTable
														filednamepmtelg={filednamepmtelg}
														fullVal={props.fullVal}
														valueEligbility={valueEligbility}
														emiScaleType={'emiScaleRemoved'}
													/>
												</div>
												<div
													className={
														filednamepmtelg.payment.emi?.emiScale
															?.length > 0
															? 'issuerOuterBox mb-3'
															: 'd-none'
													}
												>
													<label className="font-weight-bold">
														Modified (
														{
															filednamepmtelg.payment.emi?.emiScale
																?.length
														}
														)
													</label>
													<EmiTable
														filednamepmtelg={filednamepmtelg}
														fullVal={props.fullVal}
														valueEligbility={valueEligbility}
														emiScaleType={'emiScale'}
													/>
												</div>
											</div>
										</>
									) : (
										<EmiTable
											filednamepmtelg={filednamepmtelg}
											fullVal={props.fullVal}
											valueEligbility={valueEligbility}
											emiScaleType={'emiScale'}
										/>
									)
								) : filednamepmtelg.payment ? (
									filednamepmtelg.payment.emi ? (
										(filednamepmtelg.payment.emi.emiScale = undefined)
									) : null
								) : null}

								{filednamepmtelg.payment &&
								filednamepmtelg.payment.paymentInformation &&
								filednamepmtelg.payment.paymentInformation.emiType ? (
									<div>
										<CustomSelect
											name={`${valueEligbility}.paymentEligibility.payment.emi.coolOffPeriod.coolOffPeriodType`}
											label={localConst.coolOffPeriod}
											options={getCoolOffDropdown()}
										/>
										{filednamepmtelg.payment &&
										filednamepmtelg.payment.emi &&
										filednamepmtelg.payment.emi.coolOffPeriod &&
										filednamepmtelg.payment.emi.coolOffPeriod
											.coolOffPeriodType == 'Date' ? (
											<>
												<CustomDateTime
													name={`${valueEligbility}.paymentEligibility.payment.emi.coolOffPeriod.date`}
													label="Date(yyyy-mm-dd)"
												/>
											</>
										) : filednamepmtelg.payment ? (
											filednamepmtelg.payment.emi ? (
												filednamepmtelg.payment.emi.coolOffPeriod ? (
													(filednamepmtelg.payment.emi.coolOffPeriod.date =
														undefined)
												) : null
											) : null
										) : null}

										{filednamepmtelg.payment &&
										filednamepmtelg.payment.emi &&
										filednamepmtelg.payment.emi.coolOffPeriod &&
										filednamepmtelg.payment.emi.coolOffPeriod
											.coolOffPeriodType == 'Months' ? (
											<>
												<CustomInputText
													name={`${valueEligbility}.paymentEligibility.payment.emi.coolOffPeriod.months`}
													required={true}
													label="Months"
												/>
											</>
										) : filednamepmtelg.payment ? (
											filednamepmtelg.payment.emi ? (
												filednamepmtelg.payment.emi.coolOffPeriod ? (
													(filednamepmtelg.payment.emi.coolOffPeriod.months =
														undefined)
												) : null
											) : null
										) : null}

										{filednamepmtelg.payment &&
										filednamepmtelg.payment.emi &&
										filednamepmtelg.payment.emi.coolOffPeriod &&
										filednamepmtelg.payment.emi.coolOffPeriod
											.coolOffPeriodType == 'Days' ? (
											<>
												<CustomInputText
													name={`${valueEligbility}.paymentEligibility.payment.emi.coolOffPeriod.days`}
													required={true}
													label="Days"
												/>
											</>
										) : filednamepmtelg.payment ? (
											filednamepmtelg.payment.emi ? (
												filednamepmtelg.payment.emi.coolOffPeriod ? (
													(filednamepmtelg.payment.emi.coolOffPeriod.days =
														undefined)
												) : null
											) : null
										) : null}
										{filednamepmtelg.payment &&
										filednamepmtelg.payment.emi &&
										filednamepmtelg.payment.emi.coolOffPeriod &&
										filednamepmtelg.payment.emi.coolOffPeriod
											.coolOffPeriodType == 'Years' ? (
											<>
												<CustomInputText
													name={`${valueEligbility}.paymentEligibility.payment.emi.coolOffPeriod.years`}
													required={true}
													label="Years"
												/>
											</>
										) : filednamepmtelg.payment ? (
											filednamepmtelg.payment.emi ? (
												filednamepmtelg.payment.emi.coolOffPeriod ? (
													(filednamepmtelg.payment.emi.coolOffPeriod.years =
														undefined)
												) : null
											) : null
										) : null}

										{filednamepmtelg.payment &&
										filednamepmtelg.payment.emi &&
										filednamepmtelg.payment.emi.coolOffPeriod &&
										filednamepmtelg.payment.emi.coolOffPeriod
											.coolOffPeriodType == 'Hours' ? (
											<>
												<CustomHoursPicker
													name={`${valueEligbility}.paymentEligibility.payment.emi.coolOffPeriod.hours`}
													required={true}
													label="Hours(HH:mm)"
													valueHour={
														filednamepmtelg.payment.emi.coolOffPeriod
															.hours
													}
												/>
											</>
										) : filednamepmtelg.payment ? (
											filednamepmtelg.payment.emi ? (
												filednamepmtelg.payment.emi.coolOffPeriod ? (
													(filednamepmtelg.payment.emi.coolOffPeriod.hours =
														undefined)
												) : null
											) : null
										) : null}
									</div>
								) : filednamepmtelg.payment ? (
									filednamepmtelg.payment.emi ? (
										(filednamepmtelg.payment.emi.emiScale = undefined)
									) : null
								) : null}
							</>
						) : (
							<>
								{/* MultiPayment Section */}
								{valueEligbility ==
									'scaledTotalPurchaseMarketBasedPriceDerivationRuleEligibiity' ||
								valueEligbility == 'scaledPriceEligibility' ||
								valueEligbility == 'earnBurnEligibility' ||
								valueEligbility == 'mtmbEligibility' ? undefined : (
									<CustomSelect
										name={`${valueEligbility}.paymentEligibility.payment.emiType`}
										label={localConst.emiType}
										options={getEmiDropdown(
											props.loggedInBrand?.businessUnitGroupFunctionID
										)}
										smallText="Defines the types of EMI"
									/>
								)}
								{valueEligbility == 'mtmbEligibility' ? undefined : isAuditFlw ? (
									<>
										<CustomInputRadio
											name={`${valueEligbility}.paymentEligibility.payment.IsBenefitCashback`}
											label={'Is Benefit Type Cashback'}
											label1="Yes"
											label2="No"
											option1={true}
											option2={false}
										/>
									</>
								) : (
									<>
										<div className="container">
											<div className="rowContainer">
												<div className="customLabelContainer">
													<label
														htmlFor="forprogramID"
														className="col	col-form-label"
													>
														Is Benefit Type Cashback
														<span className="requiredRed">*</span>
													</label>
												</div>
												<div className="customInputContainer">
													<div className="row mg-5">
														<input
															type="radio"
															value={
																fieldbenefit?.benefitType ==
																	'CASHBACK' ||
																field.value.promotionalOfferReward
																	?.itemPriceDerivationRule
																	?.benefit?.benefitType ==
																	'CASHBACK'
															}
															checked={
																fieldbenefit?.benefitType ==
																	'CASHBACK' ||
																field.value.promotionalOfferReward
																	?.itemPriceDerivationRule
																	?.benefit?.benefitType ==
																	'CASHBACK'
															}
															onChange={() => {
																if (
																	valueEligbility ==
																	'totalPurchaseMarketBasedPriceDerivationRuleEligibiity'
																) {
																	setFieldValue(
																		`promotionalOfferReward.itemPriceDerivationRule.benefit.benefitType`,
																		'CASHBACK'
																	);
																} else {
																	// setFieldValue(`${valueEligbility}.benefit.benefitType`,"CASHBACK");
																	fieldbenefit.benefitType =
																		'CASHBACK';
																	setFieldValue('', '');
																}
																filednamepmtelg.payment.emiType =
																	'';
																// if(filednamepmtelg?.payment?.paymentGroup){
																//   filednamepmtelg.payment.paymentGroup.map((a)=>{
																//     a.issuers&&a.issuers.map((b)=>{
																//       if(b.cashbackInformation==undefined)
																//       b.cashbackInformation={
																//         "isCashbackSame":true,
																//                         "paymentInformation":{
																//                            "paymentMode":"",
																//                            "provider":"",
																//                            "paymentSubType":"",
																//                            "paymentType":""
																//                         },
																//                         "creditBy":{

																//                         },
																//                         "expireOn":{

																//                         }
																//                      }
																//     })
																//    })
																// }
															}}
														/>
														Yes
														<input
															type="radio"
															className="radioMargin"
															value={
																(fieldbenefit?.benefitType ==
																	undefined ||
																	fieldbenefit?.benefitType !=
																		'CASHBACK') &&
																field.value.promotionalOfferReward
																	?.itemPriceDerivationRule
																	?.benefit?.benefitType !=
																	'CASHBACK'
															}
															checked={
																(fieldbenefit?.benefitType ==
																	undefined ||
																	fieldbenefit?.benefitType !=
																		'CASHBACK') &&
																field.value.promotionalOfferReward
																	?.itemPriceDerivationRule
																	?.benefit?.benefitType !=
																	'CASHBACK'
															}
															onChange={() => {
																if (
																	valueEligbility ==
																	'totalPurchaseMarketBasedPriceDerivationRuleEligibiity'
																) {
																	if (
																		field.value
																			.promotionalOfferReward
																			.itemPriceDerivationRule
																			.benefit.benefitType ==
																		'CASHBACK'
																	)
																		setFieldValue(
																			`promotionalOfferReward.itemPriceDerivationRule.benefit.benefitType`,
																			''
																		);
																} else {
																	if (
																		fieldbenefit.benefitType ==
																		'CASHBACK'
																	) {
																		fieldbenefit.benefitType =
																			'';
																		setFieldValue('', '');
																	}
																}
																if (
																	filednamepmtelg?.payment
																		?.paymentGroup
																) {
																	filednamepmtelg.payment.paymentGroup.map(
																		(a) => {
																			a.issuers &&
																				a.issuers.map(
																					(b) => {
																						b.cashbackInformation =
																							undefined;
																					}
																				);
																		}
																	);
																}
															}}
														/>
														No
													</div>
												</div>
											</div>
										</div>
									</>
								)}

								{/* <div className="row">
            <div className="col-md-4 font-weight-bold">
            Aggregators and Issuers
            </div>
            <div className="col-md-7"></div>
            <div className="col-md-1">
            <button class="addPlusBtn float-right mr-2" type="button" onClick={()=>{
              if(!filednamepmtelg.payment.paymentGroup){
                filednamepmtelg.payment.paymentGroup=[];
              }
              if(filednamepmtelg.payment.emiType){
                filednamepmtelg.payment.paymentGroup.push(
                  {
                    "aggregators":[],
                    "issuers":[{
                      "issuerPaymentMode":"",
                      "issuerPaymentType":"",
                      "issuerPaymentSubType":"",
                      "issuerProvider":"",
                      "cashbackInformation":{
                        "isCashbackSame":true,
                                        "paymentInformation":{
                                           "paymentMode":"",
                                           "provider":"",
                                           "paymentSubType":"",
                                           "paymentType":""
                                        },
                                        "creditBy":{
                                         
                                        },
                                        "expireOn":{
                                           
                                        }
                                     },
                   "emi":{
                    "emiScale":[
                      {
                        "tenurePeriodInMonths": "",
                        "interestRate": "",
                        "cappedAmount":JSON.parse(props.fullVal).promotionPolicy.rewardLimit?JSON.parse(props.fullVal).promotionPolicy.rewardLimit:""
                      },
                    ]
                   }
                   }],
              
                });
              }
              else{
                filednamepmtelg.payment.paymentGroup.push(
                  {
                    "aggregators":[],
                    "issuers":[{
                      "issuerPaymentMode":"",
                      "issuerPaymentType":"",
                      "issuerPaymentSubType":"",
                      "issuerProvider":"",
                      "cashbackInformation":{
                        "isCashbackSame":true,
                                        "paymentInformation":{
                                           "paymentMode":"",
                                           "provider":"",
                                           "paymentSubType":"",
                                           "paymentType":""
                                        },
                                        "creditBy":{
                                         
                                        },
                                        "expireOn":{
                                           
                                        }
                                     }
                   }],
                });
              }
            
              setFieldValue("", "");
          }}></button>
            </div>
          </div> */}
								{isAuditFlw ? (
									<>
										<div
											className={
												filednamepmtelg.payment.paymentGroupAdded?.length ==
													0 &&
												filednamepmtelg.payment.paymentGroupRemoved
													?.length == 0 &&
												filednamepmtelg.payment.paymentGroup?.length == 0
													? 'd-none'
													: 'box-blackout-period-new lightBlue'
											}
										>
											<div className="font-weight-bold ml-1">
												{'Aggregators and Issuers'}
											</div>
											<div
												className={
													filednamepmtelg.payment.paymentGroupAdded
														?.length > 0
														? 'issuerOuterBox mb-3 addedSection'
														: 'd-none'
												}
											>
												<label className="font-weight-bold">
													Added (
													{
														filednamepmtelg.payment.paymentGroupAdded
															?.length
													}
													)
												</label>
												<PaymentGroup
													filednamepmtelg={filednamepmtelg}
													valueEligbility={valueEligbility}
													fieldbenefit={fieldbenefit}
													show={show}
													setShow={setShow}
													paymentProviderOps={paymentProviderOps}
													paymentModeOps={paymentModeOps}
													paymentTypeOps={paymentTypeOps}
													aggregatorOps={aggregatorOps}
													pgType={'paymentGroupAdded'}
													fullVal={props.fullVal}
												/>
											</div>
											<div
												className={
													filednamepmtelg.payment.paymentGroupRemoved
														?.length > 0
														? 'issuerOuterBox mb-3 removedSection'
														: 'd-none'
												}
											>
												<label className="font-weight-bold">
													Removed (
													{
														filednamepmtelg.payment.paymentGroupRemoved
															?.length
													}
													)
												</label>
												<PaymentGroup
													filednamepmtelg={filednamepmtelg}
													valueEligbility={valueEligbility}
													fieldbenefit={fieldbenefit}
													show={show}
													setShow={setShow}
													paymentProviderOps={paymentProviderOps}
													paymentModeOps={paymentModeOps}
													paymentTypeOps={paymentTypeOps}
													aggregatorOps={aggregatorOps}
													pgType={'paymentGroupRemoved'}
													fullVal={props.fullVal}
												/>
											</div>
											<div
												className={
													filednamepmtelg.payment.paymentGroup?.length > 0
														? 'issuerOuterBox mb-3'
														: 'd-none'
												}
											>
												<label className="font-weight-bold">
													Modified (
													{filednamepmtelg.payment.paymentGroup?.length})
												</label>
												<PaymentGroup
													filednamepmtelg={filednamepmtelg}
													valueEligbility={valueEligbility}
													fieldbenefit={fieldbenefit}
													show={show}
													setShow={setShow}
													paymentProviderOps={paymentProviderOps}
													paymentModeOps={paymentModeOps}
													paymentTypeOps={paymentTypeOps}
													aggregatorOps={aggregatorOps}
													pgType={'paymentGroup'}
													fullVal={props.fullVal}
												/>
											</div>
										</div>
									</>
								) : (
									<PaymentGroup
										filednamepmtelg={filednamepmtelg}
										valueEligbility={valueEligbility}
										fieldbenefit={fieldbenefit}
										show={show}
										setShow={setShow}
										paymentProviderOps={paymentProviderOps}
										paymentModeOps={paymentModeOps}
										paymentTypeOps={paymentTypeOps}
										aggregatorOps={aggregatorOps}
										pgType={'paymentGroup'}
										fullVal={props.fullVal}
									/>
								)}
								{/* <PaymentGroup filednamepmtelg={filednamepmtelg} valueEligbility={valueEligbility} fieldbenefit={fieldbenefit}
          show={show} setShow={setShow} paymentProviderOps={paymentProviderOps} paymentModeOps={paymentModeOps}
          paymentTypeOps={paymentTypeOps} aggregatorOps={aggregatorOps} pgType={"paymentGroup"} fullVal={props.fullVal}/> */}
							</>
						)}
					</>
				) : (
					(filednamepmtelg.payment = undefined)
				)}
			</div>
			{informationModalShow ? (
				<InformationModal
					show={informationModalShow}
					onHide={informationModalClose}
					infoMsg={informationMsg}
					infoTitle={informationTitle}
				/>
			) : null}
		</>
	);
};

PaymentConditionForm.propTypes = {
	customName: PropTypes.string,
	fullVal: PropTypes.string,
	loggedInBrand: PropTypes.shape({})
};

/* const PaymentCondition = withSubForm(
  PaymentConditionForm,
  paymentConditionFormSchema
); */
export default PaymentConditionForm;
