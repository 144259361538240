import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './createOffer.scss';
import { Formik } from 'formik';
import EligibilityComponentMTMB from './components/EligibilityComponentMTMB';

import * as RestSvc from '../../libraries/offer-services';
import * as urlTypes from '../../libraries/offer-service-urls';
import { isObjectEmpty, trimDate, getOfferFlowType } from '../../utils/helperFunction';
import * as Yup from 'yup';
import omitEmpty from 'omit-empty';
import chevronDown from '../../assets/images/chevron-down.png';
import { Link, Element } from 'react-scroll';
import { ConfirmationModal } from '../../MainContent/ConfirmationModal';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import Toaster from '../../Components/Toaster/Toaster';

//Local const
const localConst = {
	offerMaintainance: 'Offer Maintenance',
	basicDetails: 'Basic Details',
	PromotionType: 'Promotion Type',
	description: 'Description',
	combinationPromotion: 'Combination with other Promotions',
	shares: 'Shares',
	redemptionSettings: 'Redemption Settings',
	others: 'Others',
	applicationEligibility: 'Applicable Eligibility',
	channelEligibility: 'Channel Eligibility',
	privilegeCustomer: 'Privilege Customer Eligibility',
	anonymousCostomer: 'Anonymous Customer Eligibility',
	employeeEligibility: 'Employee Eligibility',
	loyaltyCustomer: 'Loyalty Customer Eligibility',
	customerSegments: 'Customer Segments Eligibility',
	loyaltyCard: 'LoyaltyCard Customer Eligibility',
	happyHours: 'Happy Hours',
	collectionRequired: 'Collection Required',
	eligibilitySettings: 'Eligibility Settings'
};

let initialValues = {
	mtmbEligibility: {
		packageMultibuyEligibility: [
			{
				benefit: {
					benefitType: ''
				},
				group: [
					{
						multibuyProduct: [],
						trigger: true,
						getReward: true
					}
				],
				thresholdTypeCode: '',
				applyBenefitOn: 'SOURCE',
				multiTargetCondition: 'OR',
				mtmbType: 'PRODUCT'
				// eligibiltyTitle: '',// Commenting this code to remove sku and mtmb feature from release one. OFFERS-2203  mtmb
				// eligibiltyDescription: '' // Commenting this code to remove sku and mtmb feature from release one. OFFERS-2203  mtmb
			}
		],
		paymentEligibility: {
			allowAggAndIssuer: false,
			applicableOn: ''
		},
		coupons: {}
	}
};
Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
	return this.test('unique', message, function (list) {
		if (!list) list = [];
		return list.length === new Set(list.map(mapper)).size;
	});
});

const EligibilitySchema = Yup.object().shape({
	mtmbEligibility: Yup.object().shape({
		packageMultibuyEligibility: Yup.array().of(
			Yup.object().shape(
				{
					triggerOperatorValue: Yup.string().when('triggerOperator', {
						//  is: triggerOperator => ("<" || ">" || ">=" || "<=" || "="),
						is: (triggerOperator) => {
							if (
								triggerOperator == '<' ||
								triggerOperator == '=' ||
								triggerOperator == '>' ||
								triggerOperator == '>=' ||
								triggerOperator == '<='
							) {
								return triggerOperator;
							}
						},
						then: Yup.string()
							.required('Please define price range value')
							.matches(
								/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
								'Price range value should be between 0.01 to 999999.99'
							)
					}),
					triggerOperatorValueFrom: Yup.string().when('triggerOperator', {
						is: 'BETWEEN',
						then: Yup.string()
							.matches(
								/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
								'Price Range Value From should be between 0.01 to 999999.99'
							)
							.required('Please define price range value from')
							.test(
								'triggerOperatorValueFrom_test',
								'Price Range Value From should be less than the Price Range Value To',
								function (value) {
									const { triggerOperatorValueTo } = this.parent;
									return (
										parseFloat(value) < triggerOperatorValueTo ||
										parseFloat(value) == triggerOperatorValueTo
									);
								}
							)
					}),
					triggerOperatorValueTo: Yup.string().when('triggerOperator', {
						is: 'BETWEEN',
						then: Yup.string()
							.matches(
								/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
								'Price Range Value To should be between 0.01 to 999999.99'
							)
							.required('Please define price range value to')
							.test(
								'triggerOperatorValueTo_test',
								'Price Range Value To should not less than the Price Range Value From',
								function (value) {
									const { triggerOperatorValueFrom } = this.parent;
									return (
										parseFloat(value) > triggerOperatorValueFrom ||
										parseFloat(value) == triggerOperatorValueFrom
									);
								}
							)
					}),
					rewardOperatorValue: Yup.string().when('rewardOperator', {
						// is: rewardOperator => ("<" || ">" || ">=" || "<=" || "="),
						is: (rewardOperator) => {
							if (
								rewardOperator == '<' ||
								rewardOperator == '=' ||
								rewardOperator == '>' ||
								rewardOperator == '>=' ||
								rewardOperator == '<='
							) {
								return rewardOperator;
							}
						},
						then: Yup.string()
							.required('Please define price range value')
							.matches(
								/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
								'Price range value should be between 0.01 to 999999.99'
							)
					}),
					rewardOperatorValueFrom: Yup.string().when('rewardOperator', {
						is: 'BETWEEN',
						then: Yup.string()
							.matches(
								/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
								'Price Range Value From should be between 0.01 to 999999.99'
							)
							.required('Please define price range value from')
							.test(
								'RewardOperatorValueFrom_test',
								'Price Range Value From should be less than the Price Range Value To',
								function (value) {
									const { rewardOperatorValueTo } = this.parent;
									return (
										parseFloat(value) < rewardOperatorValueTo ||
										parseFloat(value) == rewardOperatorValueTo
									);
								}
							)
					}),
					rewardOperatorValueTo: Yup.string().when('rewardOperator', {
						is: 'BETWEEN',
						then: Yup.string()
							.matches(
								/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
								'Price Range Value To should be between 0.01 to 999999.99'
							)
							.required('Please define price range value to')
							.test(
								'rewardOperatorValueTo_test',
								'Price Range Value To should not less than the Price Range Value From',
								function (value) {
									const { rewardOperatorValueFrom } = this.parent;
									return (
										parseFloat(value) > rewardOperatorValueFrom ||
										parseFloat(value) == rewardOperatorValueFrom
									);
								}
							)
					}),

					thresholdTypeCode: Yup.string().required(
						'Please provide a Threshold Type value'
					),
					// eligibiltyTitle: Yup.string().required(
					// 	'Please define a eligibility title value'
					// ),
					// eligibiltyDescription: Yup.string().required(/*  */
					// 	'Please define a eligibility description value'
					// ) /* Commenting this code to remove sku and mtmb feature from release one. OFFERS-2203 mtmb */,
					groupTriggerCombinationCode: Yup.string().when('thresholdTypeCode', {
						is: !{},
						then: Yup.string().required(
							'Please define a Group Trigger Combination Code value'
						)
					}),

					groupRewardCombinationCode: Yup.string().when('thresholdTypeCode', {
						is: !{},
						then: Yup.string().required(
							'Please define a Group Reward Combination Code value'
						)
					}),

					quantityLimit: Yup.string().when('thresholdTypeCode', {
						is: 'PACKAGE_LIMIT_QTY_THRESHOLD',
						then: Yup.string()
							.required('Please define a Quantity Limit value')
							.matches(
								/^(?=.*[1-9])\d{0,6}$/,
								'Quantity Limit value must be between 1 to 999999'
							)
							.test(
								'val_test',
								'Quantity Limit value must be between 1 to 999999',
								function (value) {
									return 1 <= parseInt(value) && parseInt(value) <= 999999;
								}
							)
					}),

					groupRewardQuantity: Yup.string()
						.matches(
							/^(?=.*[1-9])\d{0,6}$/,
							'Reward quantity must be between 1 and 999999'
						)
						.test(
							'val_test',
							'Reward quantity must be between 1 and 999999',
							function (value) {
								return 1 <= parseInt(value) && parseInt(value) <= 999999;
							}
						),

					//add conditonal render
					groupThresholdQuantity: Yup.lazy(() =>
						Yup.string().when(
							['thresholdTypeCode', 'groupMaximumThresholdQuantity'],
							(thresholdTypeCode, groupMaximumThresholdQuantity, schema) => {
								if (
									thresholdTypeCode == 'PACKAGE_INTERVAL_QTY_THRESHOLD' ||
									thresholdTypeCode == 'PACKAGE_QTY_THRESHOLD' ||
									thresholdTypeCode == 'PACKAGE_LIMIT_QTY_THRESHOLD' ||
									thresholdTypeCode == 'PACKAGE_RANGE_QTY_THRESHOLD'
								) {
									if (thresholdTypeCode == 'PACKAGE_RANGE_QTY_THRESHOLD') {
										return schema
											.matches(
												/^(?=.*[1-9])\d{0,6}$/,
												'Purchased quantity must be between 1 and 999999'
											)
											.required('Please define purchased quantity value')
											.test(
												'val_test',
												'Maximum quantity threshold specified should be greater than minimum quantity purchased',
												function (value) {
													return (
														parseInt(value) <
															groupMaximumThresholdQuantity ||
														parseInt(value) ==
															groupMaximumThresholdQuantity
													);
												}
											);
									} else if (
										thresholdTypeCode == 'PACKAGE_INTERVAL_QTY_THRESHOLD' ||
										thresholdTypeCode == 'PACKAGE_QTY_THRESHOLD' ||
										thresholdTypeCode == 'PACKAGE_LIMIT_QTY_THRESHOLD'
									) {
										return schema
											.matches(
												/^(?=.*[1-9])\d{0,6}$/,
												'Purchased quantity must be between 1 and 999999'
											)
											.test(
												'val_test',
												'Purchased quantity must be between 1 and 999999',
												function (value) {
													return (
														1 <= parseInt(value) &&
														parseInt(value) <= 999999
													);
												}
											);
									}
								}
							}
						)
					),

					groupThresholdAmount: Yup.lazy(() =>
						Yup.string().when(
							['thresholdTypeCode', 'groupMaximumThresholdAmount'],
							(thresholdTypeCode, groupMaximumThresholdAmount, schema) => {
								if (
									thresholdTypeCode == 'PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
									thresholdTypeCode == 'PACKAGE_AMOUNT_THRESHOLD' ||
									thresholdTypeCode == 'PACKAGE_RANGE_AMOUNT_THRESHOLD'
								) {
									if (thresholdTypeCode == 'PACKAGE_RANGE_AMOUNT_THRESHOLD') {
										return schema
											.matches(
												/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
												'Amount Purchased must be between 0.01 to 999999.99'
											)
											.required('Please define purchased amount value')
											.test(
												'val_test',
												'Maximum amount threshold specified should be greater than minimum amount purchased',
												function (value) {
													return (
														parseFloat(value) <
															groupMaximumThresholdAmount ||
														parseFloat(value) ==
															groupMaximumThresholdAmount
													);
												}
											);
									} else if (
										thresholdTypeCode == 'PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
										thresholdTypeCode == 'PACKAGE_AMOUNT_THRESHOLD'
									) {
										return schema
											.matches(
												/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
												'Amount Purchased must be between 0.01 to 999999.99'
											)
											.test(
												'val_test',
												'Amount Purchased must be between 0.01 to 999999.99',
												function (value) {
													return (
														0.01 <= parseFloat(value) &&
														parseFloat(value) <= 999999.99
													);
												}
											);
									}
								}
							}
						)
					),

					groupMaximumThresholdAmount: Yup.lazy(() =>
						Yup.string().when(
							['thresholdTypeCode', 'groupThresholdAmount'],
							(thresholdTypeCode, groupThresholdAmount, schema) => {
								if (thresholdTypeCode == 'PACKAGE_RANGE_AMOUNT_THRESHOLD') {
									return schema
										.matches(
											/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
											'Maximum Amount Purchased must be between 0.01 to 999999.99'
										)
										.required('Please define maximum purchased amount value')
										.test(
											'val_test',
											'Maximum amount threshold specified should be greater than minimum amount purchased',
											function (value) {
												return (
													parseFloat(value) > groupThresholdAmount ||
													parseFloat(value) == groupThresholdAmount
												);
											}
										);
								}
							}
						)
					),

					groupMaximumThresholdQuantity: Yup.lazy(() =>
						Yup.string().when(
							['thresholdTypeCode', 'groupThresholdQuantity'],
							(thresholdTypeCode, groupThresholdQuantity, schema) => {
								if (thresholdTypeCode == 'PACKAGE_RANGE_QTY_THRESHOLD') {
									return schema
										.matches(
											/^(?=.*[1-9])\d{0,6}$/,
											'Maximum Purchased quantity must be between 1 and 999999'
										)
										.required('Please define maximum purchased quantity value')
										.test(
											'val_test',
											'Maximum quantity threshold specified should be greater than minimum quantity purchased',
											function (value) {
												return (
													parseInt(value) > groupThresholdQuantity ||
													parseInt(value) == groupThresholdQuantity
												);
											}
										);
								}
							}
						)
					),

					group: Yup.array().when('thresholdTypeCode', {
						is: (thresholdTypeCode) => {
							if (
								thresholdTypeCode == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ||
								thresholdTypeCode ==
									'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
								thresholdTypeCode == 'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
								thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD'
							) {
								return thresholdTypeCode;
							}
						},
						then: Yup.array().of(
							Yup.object().shape({
								getReward: Yup.boolean(),
								trigger: Yup.boolean(),
								memberGroupRewardQuantity: Yup.string().when('getReward', {
									is: true,
									then: Yup.string().matches(
										/^(?=.*[1-9])\d{0,6}$/,
										'Group Reward Quantity value should be between 1 to 999999'
									)
								}),
								memberGroupThresholdQuantity: Yup.string().when('trigger', {
									is: true,
									then: Yup.string()
										/*   .required("Please define a Group Threshold Quantity value") */
										.matches(
											/^(?=.*[1-9])\d{0,6}$/,
											'Group Threshold Quantity value should be between 1 to 999999'
										)
								}),
								memberGroupThresholdAmount: Yup.string().when('trigger', {
									is: true,
									then: Yup.string().matches(
										/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
										'Group Threshold Amount value should be between 0.01 to 999999.99'
									)
								})
							})
						)
					}),

					benefit: Yup.object().shape({
						benefitType: Yup.string(),
						//.required("Required: Benefit Type must be selected"),
						priceModificationMethodCode: Yup.string().when('benefitType', {
							is: 'INSTANT',
							then: Yup.string().required(
								'Please define a Price Modification Method Code value'
							)
						}),
						/* priceModificationPercent: Yup.string().when("benefitType", {
            is: "INSTANT",
            then: Yup.string()
              .required("Required")
              .matches(
                /^(100|([0-9][0-9]?(\.[0-9]{0,2})?))$/,
                "Price Modification Percent should be between 0.01 to 100"
              ),
          }), */
						priceModificationPercent: Yup.string().when('benefitType', {
							//is: benefitType =>  ("INSTANT" || "CASHBACK" || "FREEBIE" ),
							is: (benefitType) => {
								if (benefitType == 'INSTANT') {
									return benefitType;
								}
							},
							then: Yup.string().when('priceModificationMethodCode', {
								is: (priceModificationMethodCode) => {
									if (
										priceModificationMethodCode == 'PERCENTAGE_OFF' ||
										priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF'
									) {
										return priceModificationMethodCode;
									}
								},
								//  priceModificationMethodCode => ("PERCENTAGE_OFF" || "APPORTIONED_PERCENTAGE_OFF"),
								then: Yup.string()
									.required('Required')
									.matches(
										/^(?=.*[1-9])\d{0,4}(?:\.\d{0,2})?$/,
										'Price Modification Percent should be between 0.01 to 100.00'
									)
									.test(
										'percent_test',
										'Price Modification Percent should be between 0.01 to 100.00',
										function (value) {
											return parseFloat(value) < 100.01;
										}
									)
							})
						}),

						priceModificationAmount: Yup.string().when('priceModificationMethodCode', {
							is: (benefitType) => {
								if (
									benefitType == 'AMOUNT_OFF' ||
									benefitType == 'APPORTIONED_AMOUNT_OFF'
								) {
									return benefitType;
								}
							},
							then: Yup.string()
								.required('Please define a Price Modification Amount value')
								.matches(
									/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
									'Price Modification Amount should be between 0.01 to 999999.99'
								)
						}),
						newPriceAmount: Yup.string().when('priceModificationMethodCode', {
							is: (benefitType) => {
								if (benefitType == 'KIT_MARKDOWN' || benefitType == 'MARKDOWN') {
									return benefitType;
								}
							},
							then: Yup.string()
								.required('Please define a New Price Amount value')
								.matches(
									/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
									'Price Modification Amount should be between 0.01 to 999999.99'
								)
						})
					})
				},
				[
					'groupThresholdAmount',
					'groupThresholdQuantity',
					'groupMaximumThresholdQuantity',
					'groupMaximumThresholdAmount'
				]
			)
		),
		//  paymentEligibility: Yup.object().shape({}),

		paymentEligibility: Yup.object().shape({
			applicableOn: Yup.string(),
			paymentThresholdTypeCode: Yup.string().when('applicableOn', {
				is: (applicableOn) => {
					if (applicableOn == 'TENDER_VALUE' || applicableOn == 'TRANSACTION_VALUE') {
						return applicableOn;
					}
				},
				then: Yup.string().required('Please define a payment threshold typecode value'),
				otherwise: Yup.string()
			}),
			payment: Yup.object().when('paymentThresholdTypeCode', {
				is: 'PAYMENT_AMOUNT_THRESHOLD',
				then: Yup.object().shape({
					paymentThresholdAmount: Yup.string()
						.required('Please define a payment threshold amount value')
						.matches(
							/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
							'Payment Threshold Amount should be between 0.01 to 999999.99'
						),
					paymentInformation: Yup.string(),
					emiType: Yup.string(),
					paymentGroup: Yup.array().of(
						Yup.object().shape({
							// aggregators: Yup.array().required("Please define an aggregator"),
							issuers: Yup.array().of(
								Yup.object().shape({
									emi: Yup.object().shape({
										emiScale: Yup.array().of(
											Yup.object().shape({
												tenurePeriodInMonths: Yup.string()
													.required(
														'Please define a tenure period in months value'
													)
													.matches(
														/^(?=.*[1-9])\d{0,3}$/,
														'Tenure Period in Months should be between 1 to 999'
													),
												interestRate: Yup.string()
													.required(
														'Please define a value for Interest rates'
													)
													.matches(
														/^(?=.*[1-9])\d{0,4}(?:\.\d{0,2})?$/,
														'Tenure Percentage should be between 0.01 to 100.00'
													)
													.test(
														'percent_test',
														'Tenure Percentage should be between 0.01 to 100.00',
														function (value) {
															return parseFloat(value) < 100.01;
														}
													)
											})
										),
										coolOffPeriod: Yup.object().shape({
											coolOffPeriodType: Yup.string(),
											months: Yup.string().when('coolOffPeriodType', {
												is: 'Months',
												then: Yup.string()
													.required('Please define months value')
													.matches(
														/^(?=.*[1-9])\d{0,3}$/,
														'Months should be between 1 to 999'
													)
											}),
											days: Yup.string().when('coolOffPeriodType', {
												is: 'Days',
												then: Yup.string()
													.required('Please define days value')
													.matches(
														/^(?=.*[1-9])\d{0,3}$/,
														'Days should be between 1 to 999'
													)
											}),
											years: Yup.string().when('coolOffPeriodType', {
												is: 'Years',
												then: Yup.string()
													.required('Please define years value')
													.matches(
														/^(?=.*[1-9])\d{0,3}$/,
														'Years should be between 1 to 999'
													)
											})
										})
									})
								})
							)
						})
					)
				})
			})
		}),

		coupons: Yup.object().shape({
			couponThresholdQuantity: Yup.string().when('coupons', {
				is: (couponType) => {
					if (
						couponType == 'GENERIC' ||
						couponType == 'PERSONALIZED' ||
						couponType == 'TARGETED'
					) {
						return couponType;
					}
				},
				then: Yup.string()
					.matches(
						/^[0-9]+$/,
						'Required Coupon Quantity must be between 1 and six 9s (both inclusive)'
					)
					.test(
						'val_test',
						'Required Coupon Quantity must be between 1 and six 9s (both inclusive)',
						function (value) {
							return 1 <= parseInt(value) && parseInt(value) <= 999999;
						}
					)
			}),

			coupon: Yup.array()
				.of(
					Yup.object().shape({
						couponId: Yup.string()
							.matches(
								/^.[a-zA-Z0-9]+$/,
								'Coupon Id length should be greater than 2 and less than 50 (both inclusive). Only Alphanumeric is supported.'
							)
							.min(
								2,
								'Coupon Id length should be greater than 2 and less than 50 (both inclusive). Only Alphanumeric is supported.'
							)
							.max(
								50,
								'Coupon Id length should be greater than 2 and less than 50 (both inclusive). Only Alphanumeric is supported.'
							)
							.required(
								'Coupon Id length should be greater than 2 and less than 50 (both inclusive). Only Alphanumeric is supported.'
							),
						couponLimit: Yup.string()
							.matches(
								/^[0-9]+$/,
								'Required: Redemption Limit per coupon must be between 1 and nine 9s (both inclusive)'
							)
							.test(
								'val_test',
								'Required: Redemption Limit per coupon must be between 1 and nine 9s (both inclusive)',
								function (value) {
									return 1 <= parseInt(value) && parseInt(value) <= 999999999;
								}
							),
						expireOn: Yup.object().shape({
							date: Yup.date(),
							/*  .min(
                  new Date(),
                  "Entered validity date and time cannot be lesser than the promotion start date and time"
                ), */
							days: Yup.string().when('couponExpiry', {
								is: 'Days',
								then: Yup.string()
									.required('Please define a days value')
									.matches(
										/^(?=.*[1-9])\d{0,3}$/,
										'Please enter a value greater than 1 to 999.'
									)
							}),
							months: Yup.string().when('couponExpiry', {
								is: 'Months',
								then: Yup.string()
									.required('Please define a months value')
									.matches(
										/^(?=.*[1-9])\d{0,3}$/,
										'Please enter a value greater than 1 to 999.'
									)
							}),
							hours: Yup.string().when('couponExpiry', {
								is: 'Hours',
								then: Yup.string().required('Please define a hours value')
							})
						})
					})
				)
				.unique('Coupon Id Already Exists.', (a) => a.couponId)
		})
	})
});

const EligibilityForm = ({ handleSubmit, ...props }) => {
	//state and props
	const [MTMBProductTableData, setMTMBProductTableData] = useState({});
	const location = useLocation();

	let history = useHistory();
	// const myparam = location.state.params;
	let myparam = {};
	if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
		myparam = props.auditDetails;
	} else {
		myparam = location.state?.params;
	}
	const myparam2 = location.state?.MTMBProdTableDatafromBasicDetails;

	const [initialData, setInitialData] = useState({});

	const [confirmationModalShow, setCnfrmatnModalShow] = useState(false);
	// const [informationModalShow, setInformationModalShow] = useState(false);
	// const [informationMsg, setInformationMsg] = useState('');

	useEffect(() => {
		if (myparam) {
			if (
				JSON.parse(myparam).promotionPolicy.priceDerivationRuleEligibility
					.mtmbEligibility != undefined
			) {
				const prevData = JSON.parse(myparam).promotionPolicy.priceDerivationRuleEligibility;

				const mtmbProductData = prevData.mtmbEligibility.packageMultibuyEligibility;

				let appendableMtmbProdData = [];

				if (
					mtmbProductData[0]?.group[0]?.multibuyProduct ||
					mtmbProductData[0]?.group[0]?.merchandizeHierarchyGroup
				) {
					mtmbProductData.map((pkg, indexPkg) => {
						appendableMtmbProdData[indexPkg] = {
							group: []
						};

						pkg.group.map((grp, iGrp) => {
							appendableMtmbProdData[indexPkg].group[iGrp] = {
								multibuyProduct: [],
								merchandizeHierarchyGroup: []
							};
							if (grp.multibuyProduct && grp.multibuyProduct.length > 0) {
								appendableMtmbProdData[indexPkg].group[iGrp].multibuyProduct =
									grp.multibuyProduct;
							}
							if (
								grp.merchandizeHierarchyGroup &&
								grp.merchandizeHierarchyGroup.length > 0
							) {
								appendableMtmbProdData[indexPkg].group[
									iGrp
								].merchandizeHierarchyGroup = grp.merchandizeHierarchyGroup;
							}
							prevData.mtmbEligibility.packageMultibuyEligibility[indexPkg].group[
								iGrp
							].multibuyProduct = [];
							prevData.mtmbEligibility.packageMultibuyEligibility[indexPkg].group[
								iGrp
							].merchandizeHierarchyGroup = [];
							// resetting to avoid the mismatch, when user goes to basic details and again comes back to eligibility during modify flow
						});
					});
				} else {
					mtmbProductData.map((pkg, indexPkg) => {
						appendableMtmbProdData[indexPkg] = {
							group: []
						};
						pkg.group.map((grp, iGrp) => {
							appendableMtmbProdData[indexPkg].group[iGrp] = {
								multibuyProduct: [],
								merchandizeHierarchyGroup: []
							};
						});
					});
					// appendableMtmbProdData[0]={
					//   group: [{
					//     multibuyProduct: [],
					//     merchandizeHierarchyGroup:[]
					//   }],
					// }
				}

				setMTMBProductTableData({
					packageMultibuyEligibility: appendableMtmbProdData
				});
				setInitialData(prevData);
			} else {
				setInitialData(initialValues);
			}
		}
		if (myparam2) {
			setMTMBProductTableData(JSON.parse(myparam2));
		}
		return () => {
			setMTMBProductTableData({});
		};
	}, []);

	//local functions

	const validatePaymentData = (consolidatedData) => {
		let isNonFormikDataValid1 = true;
		const Pay =
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
				.paymentEligibility;

		if (!(Object.keys(Pay).length === 0) && Pay.constructor === Object) {
			const { applicableOn } =
				consolidatedData.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
					?.paymentEligibility || {};
			if (applicableOn) {
				const { paymentInformation } =
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
						?.paymentEligibility?.payment || {};
				const { paymentGroup } =
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
						?.paymentEligibility?.payment || {};
				if (paymentInformation == undefined && paymentGroup == undefined) {
					// setInformationMsg('Please enter payment details');
					// setInformationModalShow(true);
					isNonFormikDataValid1 = false;
				} else if (
					paymentInformation &&
					!(Object.keys(paymentInformation).length === 0) &&
					paymentInformation.constructor === Object
				) {
					const { paymentMode, provider, paymentType, paymentSubType } =
						consolidatedData.promotionPolicy.priceDerivationRuleEligibility
							.mtmbEligibility.paymentEligibility?.payment?.paymentInformation || {};

					let Arr = [];
					if (paymentMode) Arr.push(paymentMode);
					if (provider) Arr.push(provider);
					if (paymentType) Arr.push(paymentType);
					if (paymentSubType) Arr.push(paymentSubType);
					//debugger
					if (Arr.length < 1) {
						// setInformationMsg('Please enter payment details');
						// setInformationModalShow(true);
						isNonFormikDataValid1 = false;
					}
				}
			}
		}
		return isNonFormikDataValid1;
	};
	const submitConsolidatedForm = (values) => {
		// let consolidatedData = JSON.parse(myparam)
		//
		// consolidatedData.promotionPolicy.priceDerivationRuleEligibility = props.values
		const consolidatedData = getConsolidatedData(JSON.parse(JSON.stringify(values)));
		if (validatePaymentData(consolidatedData)) {
			// alert('hey'+JSON.stringify(consolidatedData))
			// postApi(apiURL, consolidatedData)
			//     .then((res) => {
			//         alert('sucess ' + JSON.stringify(res))
			//     })
			//     .catch((err) => {
			//         alert('failed ' + JSON.stringify(err))

			//     })
			let businessUnitFunctionID = JSON.parse(
				sessionStorage.getItem('taggedBrandToLoggedInUser')
			).businessUnitGroupFunctionID;
			const newConsolidatedData = omitEmpty(consolidatedData);

			const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;

			let offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));

			if (offerFlow.type == 'modify-offer-flow') {
				let dataToSend = {
					modifyPromotionRequest: {
						user: {
							userID: sessionStorage.getItem('loggedInUserId')
						},
						promotionId: offerFlow.slctdPromotionId,
						inputJson: JSON.stringify(newConsolidatedData).replace(/\"/g, '"')
					}
				};
				props.showLoadingButton(true);
				RestSvc.postData(
					urlTypes.save_promotion_end_points.modifyPromotionUrl,
					dataToSend,
					svPromUrl
				)
					.then((response) => {
						props.showLoadingButton(false);
						var result = response.data.modifyPromotionResponse;
						if (result.businessError) {
							Toaster({ message: result.businessError.errorMessage });
						} else {
							if (
								consolidatedData?.promotionPolicy.priceDerivationRuleEligibility
									?.mtmbEligibility?.paymentEligibility?.isBinRangePromotion
							) {
								if (
									JSON.parse(offerFlow.data).promotionPolicy
										.priceDerivationRuleEligibility?.mtmbEligibility
										?.paymentEligibility?.isBinRangePromotion
								) {
									Toaster({
										type: 'warn',
										message:
											'Please remember you have modified a BIN promotion, if required revisit your BIN setup.'
									});
								} else {
									Toaster({
										type: 'warn',
										message:
											'Please ensure to upload BINs as the promotion is modified into BIN promotion.'
									});
								}
							}
							const promotionSuccessMsg =
								"Promotion '" + result.promotionId + "' is modified successfully.";
							Toaster({ type: 'success', message: promotionSuccessMsg });
							props.setDisableItem(false);

							history.push('/MainPage/offerconfigurator');
						}
					})
					.catch((error) => {
						props.showLoadingButton(false);
						console.error(error);
					});
			} else {
				let dataToSend = {
					savePromotionJsonRequest: {
						user: {
							userID: sessionStorage.getItem('loggedInUserId')
						},
						businessUnitGroupFunctionID: businessUnitFunctionID,
						inputJson: JSON.stringify(newConsolidatedData).replace(/\"/g, '"')
						//"inputJson": inputJson
					}
				};
				props.showLoadingButton(true);
				RestSvc.postData(
					urlTypes.save_promotion_end_points.savePromotionJsonUrl,
					dataToSend,
					svPromUrl
				)
					.then((response) => {
						props.showLoadingButton(false);
						var result = response.data.savePromotionJsonResponse;
						if (result.businessError) {
							Toaster({ message: result.businessError.errorMessage });
						} else {
							if (
								consolidatedData?.promotionPolicy?.priceDerivationRuleEligibility
									?.mtmbEligibility?.paymentEligibility?.isBinRangePromotion
							) {
								Toaster({
									type: 'warn',
									message:
										'Please ensure to upload BINs for the BIN based promotion created. '
								});
							}
							const promotionSuccessMsg =
								"Promotion '" + result.promotionId + "' is saved successfully.";
							Toaster({ type: 'success', message: promotionSuccessMsg });
							props.setDisableItem(false);

							history.push('/MainPage/offerconfigurator');
						}
					})
					.catch((error) => {
						props.showLoadingButton(false);
						console.error(error);
					});
			}
		}
	};

	const getConsolidatedData = (values) => {
		let consolidatedData = JSON.parse(myparam);

		consolidatedData.promotionPolicy.promotionID = consolidatedData.promotionPolicy.promotionID
			? consolidatedData.promotionPolicy.programID +
			  ':' +
			  consolidatedData.promotionPolicy.promotionID
			: '';
		consolidatedData.promotionPolicy.priceDerivationRuleEligibility = values;
		if (!isObjectEmpty(MTMBProductTableData)) {
			try {
				const productData = { ...MTMBProductTableData };
				let newPriceDerivationRuleEligibility =
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility;

				const newPackageMultibuyEligibility =
					newPriceDerivationRuleEligibility.mtmbEligibility.packageMultibuyEligibility.map(
						(response, i) => {
							let newArr = { ...response };
							let groupArr;

							if (
								productData.packageMultibuyEligibility[i].group[0]
									.multibuyProduct &&
								productData.packageMultibuyEligibility[i].group[0].multibuyProduct
									.length > 0
							) {
								groupArr = newArr.group.map((res, j) => {
									return {
										...res,
										multibuyProduct:
											productData.packageMultibuyEligibility[i].group[j]
												.multibuyProduct
									};
								});
							} else {
								groupArr = newArr.group.map((res, j) => {
									return {
										...res,
										merchandizeHierarchyGroup:
											productData.packageMultibuyEligibility[i].group[j]
												.merchandizeHierarchyGroup
									};
								});
							}

							return { ...newArr, group: groupArr };
						}
					);
				newPriceDerivationRuleEligibility.mtmbEligibility.packageMultibuyEligibility =
					newPackageMultibuyEligibility;
				consolidatedData.promotionPolicy.priceDerivationRuleEligibility =
					newPriceDerivationRuleEligibility;
			} catch (err) {
				console.error(err);
			}
		}

		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.coupons
				.coupon != undefined
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.coupons.coupon =
				trimDate(
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
						.coupons.coupon,
					'expireOn',
					'couponExpiry'
				);
		}
		consolidatedData = JSON.parse(
			JSON.stringify(consolidatedData, function (key, value) {
				return key !== 'mtmbType' ? value : undefined;
			})
		);
		consolidatedData = JSON.parse(
			JSON.stringify(consolidatedData, function (key, value) {
				return key !== 'selectedCoupon' ? value : undefined;
			})
		);
		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
				.paymentEligibility?.payment?.paymentGroup != undefined
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.paymentEligibility.payment.paymentGroup.map(
				(item) => {
					item.issuers &&
						item.issuers.map((item1) => {
							if (item1.cashbackInformation) {
								item1.cashbackInformation = trimDate(
									item1.cashbackInformation,
									'creditBy',
									'cashbackCreditData'
								);
								item1.cashbackInformation = trimDate(
									item1.cashbackInformation,
									'expireOn',
									'cashbackExpireData'
								);
							}
							if (item1.emi) {
								item1.emi = trimDate(
									item1.emi,
									'coolOffPeriod',
									'coolOffPeriodType'
								);
							}
						});
				}
			);
		}
		if (
			!consolidatedData.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility
				.paymentEligibility?.applicableOn
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.mtmbEligibility.paymentEligibility.allowAggAndIssuer =
				undefined;
		}

		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.blackOutPeriod[0]={};
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay=[];
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay=[];
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate=[];
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate=[];
		// if(consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity&&consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity.date==""){
		//     consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity.date= undefined
		// }
		// if(!consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.customerActivationRequired){
		//     consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity= undefined
		// }

		return consolidatedData;
	};

	const gotoCreateOffer = async (values) => {
		const dataFromBasicDetails = JSON.parse(myparam);
		dataFromBasicDetails.promotionPolicy.priceDerivationRuleEligibility = values;

		const consolidatedData = dataFromBasicDetails;
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditView) {
			await history.push('/MainPage/auditMaintenance/offerBasicDetails', {
				params: JSON.stringify(consolidatedData),
				MTMBProdTableData: JSON.stringify(MTMBProductTableData)
			});
		} else {
			await history.push('/MainPage/offerconfigurator/offerBasicDetails', {
				params: JSON.stringify(consolidatedData),
				MTMBProdTableData: JSON.stringify(MTMBProductTableData)
			});
		}
	};

	const onMTMBProductTableChange = (val) => {
		setMTMBProductTableData(val);
	};
	const showCancelConfirmation = () => {
		setCnfrmatnModalShow(true);
	};

	const confirmationModalClose = () => {
		setCnfrmatnModalShow(false);
	};

	const proceedToOperation = () => {
		props.setDisableItem(false);
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditView) {
			history.push('/MainPage/auditMaintenance');
		} else {
			history.push('/MainPage/offerconfigurator');
		}
	};

	const linkText = {
		elibilitySettings: ['MTMB Eligibility', 'Payment Conditions', 'Coupon Conditions']
	};
	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	const onMouseLeaveHandler = () => {
		props.setMousePlace('');
	};

	return (
		<div
			className={
				getOfferFlowType() == 'View Offer'
					? 'main-container create-offer-container viewOfferContainer'
					: 'main-container create-offer-container'
			}
		>
			<div className="header-flex">
				<div className="back-div" onClick={showCancelConfirmation}>
					<div className="pr-2">
						<img src={chevronDown} alt="" />
					</div>
					<div>Back</div>
				</div>

				<div className="header-text">{getOfferFlowType()}</div>
			</div>
			<div className="flex-mainContent">
				<div className="left-navigation br-right">
					<div className="text-header">MTMB</div>
					{linkText.elibilitySettings.map((val, ind) => (
						<div key={ind} className="text-content">
							<Link
								// activeClass="activePromotionSection"
								activeClass=""
								className={
									'scrollTo' + val.replace(/\s/g, '') == props.curntMousePlace
										? 'activePromotionSection'
										: ''
								}
								to={'scrollTo' + val.replace(/\s/g, '')}
								spy={true}
								smooth={true}
								duration={250}
								containerId="containerElement"
							>
								{val}
							</Link>
						</div>
					))}
				</div>
				<Element
					id="containerElement"
					className="right-content"
					onMouseLeave={onMouseLeaveHandler}
				>
					<>
						{!isObjectEmpty(initialData) && (
							<Formik
								initialValues={initialData}
								onSubmit={async (values) => {
									await new Promise((resolve) => setTimeout(resolve, 500));
									await submitConsolidatedForm(values);
								}}
								validateOnChange={
									false
								} /* mtmb for PaymentConditionForm browser hang prevent */
								validationSchema={EligibilitySchema}
							>
								{(props) => {
									const { isSubmitting, handleSubmit } = props;
									return (
										<form onSubmit={handleSubmit}>
											<div className="item-title">
												{localConst.eligibilitySettings}
											</div>
											<EligibilityComponentMTMB
												onMTMBProductTableChange={onMTMBProductTableChange}
												dataMTMBtable={MTMBProductTableData}
												fullValue={myparam}
												submitCnt={props.submitCount}
											/>

											<div className="footer">
												<button
													type="button"
													className="goTo-basicDetails mr-3"
													onClick={async () => {
														await gotoCreateOffer(props.values);
													}}
												>
													Go To Basic Details
												</button>
												{offerFlow.type == 'view-offer-flow' ? null : (
													<button
														type="submit"
														className="submit-class"
														disabled={isSubmitting}
													>
														Submit
													</button>
												)}
											</div>
										</form>
									);
								}}
							</Formik>
						)}
					</>
				</Element>
			</div>
			{confirmationModalShow ? (
				<ConfirmationModal
					show={confirmationModalShow}
					onHide={confirmationModalClose}
					onProceed={proceedToOperation}
					confirmmsg={'Are you sure you want to navigate away?'}
				/>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		curntMousePlace: state.currentMousePlace
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setMousePlace: (val) => dispatch({ type: actionTypes.MOUSE_PLACE, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityForm);
