import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './createOffer.scss';
import { Formik } from 'formik';
import EligibilityComponent from './components/EligibilityComponentSIP';
import * as RestSvc from '../../libraries/offer-services';
import * as urlTypes from '../../libraries/offer-service-urls';
import { isObjectEmpty, getOfferFlowType } from '../../utils/helperFunction';
import * as Yup from 'yup';
import omitEmpty from 'omit-empty';
import chevronDown from '../../assets/images/chevron-down.png';
import { Link, Element } from 'react-scroll';
import { ConfirmationModal } from '../../MainContent/ConfirmationModal';
import { InformationModal } from '../../MainContent/InformationModal';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import Toaster from '../../Components/Toaster/Toaster';

//Local const
const localConst = {
	offerMaintainance: 'Offer Maintenance',
	basicDetails: 'Basic Details',
	PromotionType: 'Promotion Type',
	description: 'Description',
	combinationPromotion: 'Combination with other Promotions',
	shares: 'Shares',
	redemptionSettings: 'Redemption Settings',
	others: 'Others',
	applicationEligibility: 'Applicable Eligibility',
	channelEligibility: 'Channel Eligibility',
	privilegeCustomer: 'Privilege Customer Eligibility',
	anonymousCostomer: 'Anonymous Customer Eligibility',
	employeeEligibility: 'Employee Eligibility',
	loyaltyCustomer: 'Loyalty Customer Eligibility',
	customerSegments: 'Customer Segments Eligibility',
	loyaltyCard: 'LoyaltyCard Customer Eligibility',
	happyHours: 'Happy Hours',
	collectionRequired: 'Collection Required',
	eligibilitySettings: 'Eligibility Settings'
};

var multiBuyGroupTable = [];
// eslint-disable-next-line no-unused-vars
var merchandizeGroupTable = [];

let initialValues = {
	simplePromotionEligibility: {
		thresholdTypeCode: '',
		triggerCombinationCode: '',
		rewardCombinationCode: '',
		maxTriggersPerTransaction: '',
		benefit: {
			priceModificationMethodCode: '',

			benefitType: ''
		},
		multibuyProduct: [],
		merchandizeHierarchyGroup: []
	}
};

Yup.addMethod(Yup.array, 'unique', function (message, mapper = (a) => a) {
	return this.test('unique', message, function (list) {
		if (!list) list = [];
		return list.length === new Set(list.map(mapper)).size;
	});
});
const SignupSchema = Yup.object().shape({
	simplePromotionEligibility: Yup.object().shape({
		thresholdTypeCode: Yup.string().required('Please provide a Threshold Type value'),
		benefit: Yup.object().shape({
			benefitType: Yup.string().required('Required: Benefit Type must be selected'),
			priceModificationMethodCode: Yup.string().when('benefitType', {
				is: 'INSTANT' || 'CASHBACK' || 'LOYALTY_POINTS' || 'FREEBIE' || 'EMI',
				then: Yup.string().required('Required')
			}),

			priceModificationPercent: Yup.string().when('priceModificationMethodCode', {
				is: 'PERCENTAGE_OFF' || 'APPORTIONED_PERCENTAGE_OFF',
				then: Yup.string()
					.required('Required')
					.matches(
						/^(?=.*[1-9])\d{0,4}(?:\.\d{0,2})?$/,
						'Price Modification Percent should be between 0.01 to 100.00'
					)
					.test(
						'percent_test',
						'Price Modification Percent should be between 0.01 to 100.00',
						function (value) {
							return parseFloat(value) < 100.01;
						}
					)
			}),
			priceModificationAmount: Yup.string().when('priceModificationMethodCode', {
				is: 'AMOUNT_OFF' || 'APPORTIONED_AMOUNT_OFF',
				then: Yup.string()
					.required('Required')
					.matches(
						/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
						'Price Modification Amount should be between 0.01 to 999999.99'
					)
			}),
			//MARKDOWN KIT_MARKDOWN
			newPriceAmount: Yup.string().when('priceModificationMethodCode', {
				is: 'MARKDOWN' || 'KIT_MARKDOWN',
				then: Yup.string()
					.required('Required')
					.matches(
						/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
						'Price Modification Amount should be between 0.01 to 999999.99'
					)
			}),
			cashback: Yup.object().shape({
				creditBy: Yup.object().shape({
					date: Yup.date().min(
						new Date(),
						'Date should not be less than the Current Date'
					),
					days: Yup.string().matches(
						/^(?=.*[1-9])\d{0,3}$/,
						'Please enter a value greater than 1 to 999.'
					),
					months: Yup.string().matches(
						/^(?=.*[1-9])\d{0,3}$/,
						'Please enter a value greater than 1 to 999.'
					)
				}),
				expireOn: Yup.object().shape({
					date: Yup.date().min(
						new Date(),
						'Date should not be less than the Current Date'
					),
					days: Yup.string().matches(
						/^(?=.*[1-9])\d{0,3}$/,
						'Please enter a value greater than 1 to 999.'
					),
					months: Yup.string().matches(
						/^(?=.*[1-9])\d{0,3}$/,
						'Please enter a value greater than 1 to 999.'
					)
				})
			}),
			Loyalty: Yup.string().when('priceModificationMethodCode', {
				is: !{},
				then: Yup.object().shape({
					loyaltyMultiplier: Yup.string()
						.required('Required')
						.matches(
							/^(?=.*[1-9])\d{0,6}$/,
							'Loyalty Multiplier Point shoukd be between 1 to 999999'
						),
					ExpireOn: Yup.object().shape({
						date: Yup.date().min(
							new Date(),
							'Date should not be less than the Current Date'
						),
						days: Yup.string().matches(
							/^(?=.*[1-9])\d{0,3}$/,
							'Please enter a value greater than 1 to 999.'
						),
						months: Yup.string().matches(
							/^(?=.*[1-9])\d{0,3}$/,
							'Please enter a value greater than 1 to 999.'
						)
					})
				})
			})
		}),
		triggerOperatorValue: Yup.string().when('triggerOperator', {
			//  is: triggerOperator => ("<" || ">" || ">=" || "<=" || "="),
			is: (triggerOperator) => {
				if (
					triggerOperator == '<' ||
					triggerOperator == '=' ||
					triggerOperator == '>' ||
					triggerOperator == '>=' ||
					triggerOperator == '<='
				) {
					return triggerOperator;
				}
			},
			then: Yup.string()
				.required('Please define price range value')
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price range operator value should be between 0.01 to 999999.99'
				)
		}),
		triggerOperatorValueFrom: Yup.string().when('triggerOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value From should be between 0.01 to 999999.99'
				)
				.required('Please define price range value from')
				.test(
					'triggerOperatorValueFrom_test',
					'Price Range Value From should be less than the Price Range Value To',
					function (value) {
						const { triggerOperatorValueTo } = this.parent;
						return (
							parseFloat(value) < triggerOperatorValueTo ||
							parseFloat(value) == triggerOperatorValueTo
						);
					}
				)
		}),
		triggerOperatorValueTo: Yup.string().when('triggerOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value To should be between 0.01 to 999999.99'
				)
				.required('Please define price range value to')
				.test(
					'triggerOperatorValueTo_test',
					'Price Range Value To should not less than the Price Range Value From',
					function (value) {
						const { triggerOperatorValueFrom } = this.parent;
						return (
							parseFloat(value) > triggerOperatorValueFrom ||
							parseFloat(value) == triggerOperatorValueFrom
						);
					}
				)
		}),
		rewardOperatorValue: Yup.string().when('rewardOperator', {
			// is: rewardOperator => ("<" || ">" || ">=" || "<=" || "="),
			is: (rewardOperator) => {
				if (
					rewardOperator == '<' ||
					rewardOperator == '=' ||
					rewardOperator == '>' ||
					rewardOperator == '>=' ||
					rewardOperator == '<='
				) {
					return rewardOperator;
				}
			},
			then: Yup.string()
				.required('Please define Price range Operator Value')
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price range operator value should be between 0.01 to 999999.99'
				)
		}),
		rewardOperatorValueFrom: Yup.string().when('rewardOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value From should be between 0.01 to 999999.99'
				)
				.required('Please define price range value from')
				.test(
					'RewardOperatorValueFrom_test',
					'Price Range Value From should be less than the Price Range Value To',
					function (value) {
						const { rewardOperatorValueTo } = this.parent;
						return (
							parseFloat(value) < rewardOperatorValueTo ||
							parseFloat(value) == rewardOperatorValueTo
						);
					}
				)
		}),
		rewardOperatorValueTo: Yup.string().when('rewardOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value To should be between 0.01 to 999999.99'
				)
				.required('Please define price range value to')
				.test(
					'rewardOperatorValueTo_test',
					'Price Range Value To should not less than the Price Range Value From',
					function (value) {
						const { rewardOperatorValueFrom } = this.parent;
						return (
							parseFloat(value) > rewardOperatorValueFrom ||
							parseFloat(value) == rewardOperatorValueFrom
						);
					}
				)
		})
	})
});

const EligibilityForm = ({ ...props }) => {
	//state and props
	const [productTableData, setProductTableData] = useState([]);
	const [merchTableData, setMerchTableData] = useState([]);
	const location = useLocation();

	let history = useHistory();
	// const myparam = location.state.params;
	let myparam = {};
	if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
		myparam = props.auditDetails;
	} else {
		myparam = location.state?.params;
	}

	const myparam2 = location.state?.MTMBProdTableDatafromBasicDetails;
	const myparam4 = location.state?.SellingHierarchyTableDatafromBasicDetails;
	const [initialData, setInitialData] = useState({});
	const [confirmationModalShow, setCnfrmatnModalShow] = useState(false);
	const [informationModalShow, setInformationModalShow] = useState(false);
	const [informationMsg, setInformationMsg] = useState('');
	const [informationTitle] = useState('Error');
	useEffect(() => {
		if (myparam) {
			if (
				JSON.parse(myparam).promotionPolicy.priceDerivationRuleEligibility
					.simplePromotionEligibility != undefined
			) {
				const prevData = JSON.parse(myparam).promotionPolicy.priceDerivationRuleEligibility;

				const multibyProductData = prevData.simplePromotionEligibility.multibuyProduct;

				if (multibyProductData?.length && myparam2 === false) {
					multiBuyGroupTable = multibyProductData;
					setProductTableData(multiBuyGroupTable);
					prevData.simplePromotionEligibility.multibuyProduct = [];
					// resetting to avoid the mismatch, when user goes to basic details and again comes back to eligibility during modify flow
				}
				const merchandizeHierarchyGroupData =
					prevData.simplePromotionEligibility.merchandizeHierarchyGroup;
				if (merchandizeHierarchyGroupData?.length && myparam4 === false) {
					merchandizeGroupTable = merchandizeHierarchyGroupData;
					setMerchTableData(merchandizeHierarchyGroupData);
					prevData.simplePromotionEligibility.merchandizeHierarchyGroup = [];
					// resetting to avoid the mismatch, when user goes to basic details and again comes back to eligibility during modify flow
				}
				//prevData.simplePromotionEligibility.benefit.priceModificationPercent=undefined;
				//prevData.simplePromotionEligibility.benefit.priceModificationAmount=undefined;
				//prevData.simplePromotionEligibility.benefit.NewPriceAmount=undefined;

				setInitialData(prevData);
			} else {
				setInitialData(initialValues);
			}
		}
		if (myparam2) {
			setProductTableData(JSON.parse(myparam2));
			multiBuyGroupTable = JSON.parse(myparam2);
		}
		if (myparam4) {
			setMerchTableData(JSON.parse(myparam4));
			merchandizeGroupTable = JSON.parse(myparam4);
		}
		return () => {
			setProductTableData([]);
			multiBuyGroupTable = [];
			setMerchTableData([]);
			merchandizeGroupTable = [];
		};
	}, []);

	//local functions
	const validateFormikExcludedData = () => {
		let isNonFormikDataValid = true;

		if (!productTableData.length && !merchTableData.length) {
			setInformationMsg('Please enter the product or selling hierarchy data');
			setInformationModalShow(true);

			isNonFormikDataValid = false;
		}

		return isNonFormikDataValid;
	};

	const submitConsolidatedForm = (values) => {
		if (validateFormikExcludedData()) {
			const consolidatedData = getConsolidatedData(JSON.parse(JSON.stringify(values)));

			let businessUnitFunctionID = JSON.parse(
				sessionStorage.getItem('taggedBrandToLoggedInUser')
			).businessUnitGroupFunctionID;
			const newConsolidatedData = omitEmpty(consolidatedData);

			const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;

			let offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));

			if (offerFlow.type == 'modify-offer-flow') {
				let dataToSend = {
					modifyPromotionRequest: {
						user: {
							userID: sessionStorage.getItem('loggedInUserId')
						},
						promotionId: offerFlow.slctdPromotionId,
						inputJson: JSON.stringify(newConsolidatedData).replace(/\"/g, '"')
					}
				};
				props.showLoadingButton(true);
				RestSvc.postData(
					urlTypes.save_promotion_end_points.modifyPromotionUrl,
					dataToSend,
					svPromUrl
				)
					.then((response) => {
						props.showLoadingButton(false);
						var result = response.data.modifyPromotionResponse;
						if (result.businessError) {
							Toaster({ message: result.businessError.errorMessage });
						} else {
							const promotionSuccessMsg =
								"Promotion '" + result.promotionId + "' is modified successfully.";
							Toaster({ type: 'success', message: promotionSuccessMsg });
							props.setDisableItem(false);
							history.push('/MainPage/offerconfigurator');
						}
					})
					.catch((error) => {
						props.showLoadingButton(false);
						console.error(error);
					});
			} else {
				let dataToSend = {
					savePromotionJsonRequest: {
						user: {
							userID: sessionStorage.getItem('loggedInUserId')
						},
						businessUnitGroupFunctionID: businessUnitFunctionID,
						inputJson: JSON.stringify(newConsolidatedData).replace(/\"/g, '"')
						//"inputJson": inputJson
					}
				};
				props.showLoadingButton(true);
				RestSvc.postData(
					urlTypes.save_promotion_end_points.savePromotionJsonUrl,
					dataToSend,
					svPromUrl
				)
					.then((response) => {
						props.showLoadingButton(false);
						var result = response.data.savePromotionJsonResponse;
						if (result.businessError) {
							Toaster({ message: result.businessError.errorMessage });
						} else {
							const promotionSuccessMsg =
								"Promotion '" + result.promotionId + "' is saved successfully.";
							Toaster({ type: 'success', message: promotionSuccessMsg });
							props.setDisableItem(false);
							history.push('/MainPage/offerconfigurator');
						}
					})
					.catch((error) => {
						props.showLoadingButton(false);
						console.error(error);
					});
			}
		}
	};

	const getConsolidatedData = (values) => {
		let consolidatedData = JSON.parse(myparam);
		consolidatedData.promotionPolicy.promotionID = consolidatedData.promotionPolicy.promotionID
			? consolidatedData.promotionPolicy.programID +
			  ':' +
			  consolidatedData.promotionPolicy.promotionID
			: '';

		consolidatedData.promotionPolicy.priceDerivationRuleEligibility = values;
		consolidatedData.promotionPolicy.priceDerivationRuleEligibility.simplePromotionEligibility.rewardQuantity =
			undefined;
		consolidatedData.promotionPolicy.priceDerivationRuleEligibility.simplePromotionEligibility.thresholdQuantity =
			undefined;
		consolidatedData.promotionPolicy.priceDerivationRuleEligibility.simplePromotionEligibility.multibuyProduct =
			productTableData;
		consolidatedData.promotionPolicy.priceDerivationRuleEligibility.simplePromotionEligibility.merchandizeHierarchyGroup =
			merchTableData;
		consolidatedData.promotionPolicy.priceDerivationRuleEligibility.simplePromotionEligibility.benefit.priceModificationPercent =
			undefined;
		consolidatedData.promotionPolicy.priceDerivationRuleEligibility.simplePromotionEligibility.benefit.priceModificationAmount =
			undefined;
		consolidatedData.promotionPolicy.priceDerivationRuleEligibility.simplePromotionEligibility.benefit.newPriceAmount =
			undefined;

		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.blackOutPeriod[0]={};
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay=[];
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay=[];
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate=[];
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate=[];
		// if(consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity&&consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity.date==""){
		//     consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity.date= undefined
		// }
		// if(!consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.customerActivationRequired){
		//     consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity= undefined
		// }

		return consolidatedData;
	};
	/*  const gotoCreateOffer = async () => {
    const consolidatedData = getConsolidatedData();
    await history.push("/MainPage/offerconfigurator/offerBasicDetails", {
      params: JSON.stringify(consolidatedData),
    });
  }; */
	const gotoCreateOffer = async (values) => {
		const dataFromBasicDetails = JSON.parse(myparam);
		dataFromBasicDetails.promotionPolicy.priceDerivationRuleEligibility = values;

		const consolidatedData = dataFromBasicDetails;
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditView) {
			await history.push('/MainPage/auditMaintenance/offerBasicDetails', {
				params: JSON.stringify(consolidatedData),
				MTMBProdTableData: JSON.stringify(productTableData),
				SellingHierarchyTableData: JSON.stringify(merchTableData)
			});
		} else {
			await history.push('/MainPage/offerconfigurator/offerBasicDetails', {
				params: JSON.stringify(consolidatedData),
				MTMBProdTableData: JSON.stringify(productTableData),
				SellingHierarchyTableData: JSON.stringify(merchTableData)
			});
		}
	};
	const onProductTableChanged = (val) => {
		//debugger;

		setProductTableData(val);
	};
	const onMerchTableDataChange = (val) => {
		setMerchTableData(val);
	};
	const showCancelConfirmation = () => {
		setCnfrmatnModalShow(true);
	};

	const confirmationModalClose = () => {
		setCnfrmatnModalShow(false);
	};

	const proceedToOperation = () => {
		props.setDisableItem(false);
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditView) {
			history.push('/MainPage/auditMaintenance');
		} else {
			history.push('/MainPage/offerconfigurator');
		}
	};
	const linkText = {
		elibilitySettings: ['Benefit Settings']
	};
	const onMouseLeaveHandler = () => {
		props.setMousePlace('');
	};

	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	return (
		<div
			className={
				getOfferFlowType() == 'View Offer'
					? 'main-container create-offer-container viewOfferContainer'
					: 'main-container create-offer-container'
			}
		>
			<div className="header-flex">
				<div className="back-div" onClick={showCancelConfirmation}>
					<div className="pr-2">
						<img src={chevronDown} alt="" />
					</div>
					<div>Back</div>
				</div>

				<div className="header-text">{getOfferFlowType()}</div>
			</div>
			<div className="flex-mainContent">
				<div className="left-navigation br-right">
					<div className="text-header">Simple Product</div>
					{linkText.elibilitySettings.map((val, ind) => (
						<div key={ind} className="text-content">
							<Link
								//activeClass="activePromotionSection"
								activeClass=""
								className={
									'scrollTo' + val.replace(/\s/g, '') == props.curntMousePlace
										? 'activePromotionSection'
										: ''
								}
								to={'scrollTo' + val.replace(/\s/g, '')}
								spy={true}
								smooth={true}
								duration={250}
								containerId="containerElement"
							>
								{val}
							</Link>
						</div>
					))}
				</div>
				<Element
					id="containerElement"
					className="right-content"
					onMouseLeave={onMouseLeaveHandler}
				>
					<>
						{!isObjectEmpty(initialData) && (
							<Formik
								initialValues={initialData}
								onSubmit={async (values) => {
									await new Promise((resolve) => setTimeout(resolve, 500));
									await submitConsolidatedForm(values);
								}}
								validationSchema={SignupSchema}
							>
								{(props) => {
									const { isSubmitting, handleSubmit } = props;
									return (
										<form onSubmit={handleSubmit}>
											<div className="item-title">
												{localConst.eligibilitySettings}
											</div>
											<EligibilityComponent
												data={multiBuyGroupTable}
												onProductTableChanged={onProductTableChanged}
												onMerchTableDataChange={onMerchTableDataChange}
												data1={merchTableData}
												fullValue={myparam}
												dataMTMBtable={productTableData}
											/>

											<div className="footer">
												<button
													type="button"
													className="goTo-basicDetails mr-3"
													onClick={async () => {
														await gotoCreateOffer(props.values);
													}}
												>
													Go To Basic Details
												</button>
												{offerFlow.type == 'view-offer-flow' ? null : (
													<button
														type="submit"
														className="submit-class"
														disabled={isSubmitting}
													>
														Submit
													</button>
												)}
											</div>
										</form>
									);
								}}
							</Formik>
						)}
					</>
				</Element>
			</div>
			{confirmationModalShow ? (
				<ConfirmationModal
					show={confirmationModalShow}
					onHide={confirmationModalClose}
					onProceed={proceedToOperation}
					confirmmsg={'Are you sure you want to navigate away?'}
				/>
			) : null}
			{informationModalShow ? (
				<InformationModal
					show={informationModalShow}
					onHide={() => {
						setInformationModalShow(false);
					}}
					infoMsg={informationMsg}
					infoTitle={informationTitle}
				/>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		curntMousePlace: state.currentMousePlace
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setMousePlace: (val) => dispatch({ type: actionTypes.MOUSE_PLACE, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityForm);
