import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import './generateCoupon.scss';

import appLabels from '../../utils/appLabels';
import * as actionTypes from '../../store/actions';
import {
	generateCoupon,
	offers_maintenance,
	coupon_maintenance,
	user_maintenance
} from '../../libraries/offer-service-urls';
import * as RestSvc from '../../libraries/offer-services';
import ModalPopup from '../../Components/ModalPopup/ModalPopup';
import Toaster from '../../Components/Toaster/Toaster';
import GenerateCouponForm from './GenerateCouponForm';
import OfferTable from './OfferTable';
import CouponsList from './CouponsList';
import { downloadFile } from '../../utils/helperFunction';
import TabsContainer from '../../Components/TabsContainer/TabsContainer';
import CreateCouponForm from './CreateCouponForm';
import appConstants from '../../utils/appConstants';

const {
	defaultCouponLimit,
	couponSubType: { MULTIUSE }
} = appConstants;
const {
	common: commonLabels,
	page: { generateCoupon: pageLabels }
} = appLabels;

const getCouponBasePromo = ({ programId, businessUnitFunctionID }) => {
	const dataToSend = {
		searchPromotionRequest: {
			programId,
			status: 'Active',
			couponBased: 'coupon',
			businessUnitFunctionID
		}
	};
	const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;

	return RestSvc.postData(offers_maintenance.searchPromotion, dataToSend, svPromUrl);
};

const fetchCouponPatterns = ({ businessUnitFunctionID }) =>
	RestSvc.postData(generateCoupon.fetchCouponPatterns, {
		businessUnitCouponPatternRequest: { idBsngpFnc: businessUnitFunctionID }
	});

const fetchDisplayCoupons = ({ programId, businessUnitFunctionID }) =>
	RestSvc.postData(coupon_maintenance.getFlag, {
		getPromotionCouponCountRequest: {
			promotionId: programId,
			brandId: businessUnitFunctionID
		}
	});

const fetchPromoBatchIdNCouponCount = (promotionId) =>
	RestSvc.postData(generateCoupon.fetchPromotionBatchIdAndCouponCount, {
		promotionBatchLockRequest: {
			promotionId
		}
	});

const FormTabs = ({
	couponFormInitValues,
	configuredCount,
	selectCouponCount,
	couponPatterns,
	cancelGenerateCouponForm,
	generateCouponSubmitHandler,
	createCouponSubmitHandler,
	batchId
}) => {
	if (couponFormInitValues === null) {
		return null;
	}
	const { displayCoupons, isReplicate } = couponFormInitValues;
	const leftOverCombination = configuredCount - selectCouponCount;
	const props = {
		list: [
			{
				eventKey: 'generateCoupon',
				title: pageLabels.generateCoupon,
				component: (
					<div className="coupon-form-container col-md-8 offset-md-2">
						<GenerateCouponForm
							initValues={couponFormInitValues}
							patternOptions={couponPatterns}
							cancel={cancelGenerateCouponForm}
							submitHandler={generateCouponSubmitHandler}
							configuredCount={
								couponFormInitValues.displayCoupons
									? configuredCount
									: defaultCouponLimit
							}
							generatedCouponCount={selectCouponCount}
							batchId={batchId}
						/>
					</div>
				)
			},
			{
				eventKey: 'createCoupon',
				title: pageLabels.createCoupon,
				component: (
					<div className="create-coupon-form-container col-md-8 offset-md-2">
						<CreateCouponForm
							initValues={couponFormInitValues}
							patternOptions={couponPatterns}
							cancel={cancelGenerateCouponForm}
							submitHandler={createCouponSubmitHandler}
							configuredCount={
								couponFormInitValues.displayCoupons
									? configuredCount
									: defaultCouponLimit
							}
							maxCustomCouponLimit={
								leftOverCombination === 0 ? 1 : leftOverCombination
							}
							generatedCouponCount={selectCouponCount}
						/>
					</div>
				),
				disabled: !displayCoupons || isReplicate
			}
		],
		defaultTab: 'generateCoupon'
	};

	return <TabsContainer {...props} />;
};

const GenerateCoupon = (props) => {
	const [infoModal, setInfoModal] = useState({
		infoShow: false,
		infoTitle: pageLabels.generateCouponInfoTitle,
		infoMsg: null
	});
	const [offers, setOffers] = useState([]);
	const [couponFormInitValues, setCouponFormInitValues] = useState(null);
	const [couponPatterns, setCouponPatterns] = useState([]);
	const [configuredCount, setConfiguredCount] = useState(null);
	const [selectCouponCount, setSelectedCouponCount] = useState(0);
	const [batchId, setBatchId] = useState('');
	const couponsListRef = useRef();

	useEffect(() => {
		fetchInitialData();
		return () => {
			props?.generateCouponCloseHandler(false);
		};
	}, []);

	const fetchInitialData = async () => {
		/* get coupon base active promo */
		const { programId, businessUnitGroupFunctionID: businessUnitFunctionID } =
			props.loggedInBrand;

		props.showLoadingButton(true);

		try {
			const response = await RestSvc.fetchAll([
				getCouponBasePromo({ programId, businessUnitFunctionID }),
				fetchDisplayCoupons({ programId, businessUnitFunctionID }),
				fetchCouponPatterns({ businessUnitFunctionID })
			]);

			props.showLoadingButton(false);

			const [couponRes, displayRes, patternRes] = response;

			/* couponBasePromo response  handler*/
			const couponPromoRes = couponRes.data.searchPromotionResponse;

			if (couponPromoRes.businessError) {
				Toaster({ message: couponPromoRes.businessError.errorMessage });
			} else {
				setOffers(couponPromoRes.promotionPolicyDetails);
			}

			/* fetchDisplayCoupons response handler */
			const displayCountResult = displayRes.data.getPromotionCouponCountResponse;
			// const displayFlg = displayCountResult?.displayFlg;
			const configuredCount = displayCountResult.configuredCount;
			if (displayCountResult?.businessError) {
				Toaster({ message: displayCountResult.businessError.errorMessage });
				return;
			}
			setConfiguredCount(configuredCount);

			/* couponPattern response handler */
			const couponPatternRes = patternRes.data?.businessUnitCouponPatternResponse || {};
			const { businessCouponPatternList = [] } = couponPatternRes;

			if (couponPatternRes?.businessError) {
				Toaster({ message: couponPatternRes.businessError.errorMessage });
				return;
			}

			const _couponPatterns = [];
			let _ind = 0;
			businessCouponPatternList?.forEach(({ separator, pattern }, ind) => {
				const temp = [];
				const _separator = separator ? separator : '';
				const _pattern = pattern.split(',');
				_pattern.map((pat, i) => {
					_ind++;
					temp.push({
						ind: _ind,
						value: `${pat},${_separator}` /* pattern,separator  {string*/,
						label: `(${_separator || 'No Pattern'}) ${pat} `
					});
				});
				_couponPatterns.push(...temp);
			});

			setCouponPatterns(_couponPatterns);
		} catch (error) {
			props.showLoadingButton(false);
			console.error(error);
		}
	};

	const configureCouponCount = async (promotionId) => {
		props.showLoadingButton(true);
		try {
			const res = await fetchPromoBatchIdNCouponCount(promotionId);
			props.showLoadingButton(false);
			const result = res.data.PromotionBatchIdAndCountResponse || {};
			if (result.businessError) {
				Toaster({ message: result.businessError.errorMessage });
				return;
			}
			setSelectedCouponCount(result.couponCount);
			setBatchId(`${result.batchId}`);
		} catch (error) {
			props.showLoadingButton(false);
			console.error(error);
		}
	};

	const generateCouponActionHandler = (offer) => {
		const {
			promotionId,
			startDate,
			endDate,
			couponType,
			promotionType,
			isMultiuseCoupon,
			displayCoupons
		} = offer;
		setCouponFormInitValues({
			promotionId,
			startDate,
			expiryDate: endDate,
			couponType,
			promotionType,
			isMultiuseCoupon,
			displayCoupons
		});

		configureCouponCount(promotionId);
	};

	const downloadCouponsHandler = async (fileNameData = [], isZip = false) => {
		if (isZip && !fileNameData.length) {
			Toaster({ type: 'success', message: pageLabels.downloadEmptySelectedWarning });
			return false;
		}
		props.showLoadingButton(true);
		let response = {};
		try {
			if (isZip) {
				response = await RestSvc.postData(
					`${generateCoupon.downloadGenerateCoupons}${btoa(fileNameData)}`
				);
			} else {
				let dataToSend = {
					couponNotificationDetailsRequest: fileNameData
				};
				const svPromUrl = JSON.parse(
					sessionStorage.getItem('applctnUrls')
				).savePromotionsUrl;
				response = await RestSvc.postData(
					user_maintenance.notificationDownloadFile,
					dataToSend,
					svPromUrl
				);
			}
			props.showLoadingButton(false);
			if (response?.status == 200) {
				const result = response.data;
				if (response.headers?.['content-type']?.startsWith('application/json')) {
					Toaster({ message: result });
					return;
				}
				if (result?.businessError) {
					Toaster({ message: result.businessError.errorMessage });
					return;
				}
				Toaster({
					type: 'success',
					message: pageLabels.documentFileSuccessText
				});
				let fileName = `generated_coupons_${moment().format('DD-MM-YYYY_HH_mm_ss')}.zip`;
				if (!isZip) {
					fileName = fileNameData.filename;
				}
				downloadFile(result, fileName);
			} else {
				Toaster({ message: commonLabels.downloadsFailed });
			}
		} catch (error) {
			props.showLoadingButton(false);
			console.error(error);
		}
	};

	const replicateCouponsHandler = (data, isCustomCoupon = false) => {
		if (isCustomCoupon) {
			Toaster({ type: 'warn', message: pageLabels.actionBtnReplicateCouponDisableText });
			return;
		}

		const {
			promotionId,
			promotionStartDate,
			promotionEndDate,
			couponType,
			couponSubType, //isMultiuseCoupon ? 'MULTIUSE' : 'SINGLEUSE'
			displayCoupons = false,
			couponRedemptionLimit,
			couponExpiryDate,
			numberOfCoupons,
			couponLength,
			couponPattern,
			couponPatternSeparator = '',
			characterSetList,
			couponPrefix,
			couponSuffix
		} = data.generationDetails || {};

		setCouponFormInitValues({
			promotionId,
			expiryDate: promotionEndDate,
			couponType,
			isMultiuseCoupon: couponSubType === MULTIUSE,
			displayCoupons: displayCoupons === 'true',
			couponRedemptionLimit,
			couponExpiryDate,
			numberOfCoupons,
			couponLength,
			couponPattern,
			couponPatternSeparator,
			characterSetList,
			couponPrefix,
			couponSuffix,
			startDate: promotionStartDate,
			isReplicate: true
		});
		setInfoModal({ infoShow: false });
		configureCouponCount(promotionId);
	};

	const viewCouponsActionHandler = async (offer) => {
		const { promotionId } = offer;
		const promoID = promotionId.split(':')[1];
		props.showLoadingButton(true);
		const dataToSend = {
			GenerateCouponDetailsSummaryRequest: {
				promotionId
				// startDate: "2023-01-01 00:00:00",
				// endDate: "2023-04-01 00:00:00"
			}
		};

		try {
			const response = await RestSvc.postData(generateCoupon.fetchCoupons, dataToSend);
			props.showLoadingButton(false);
			const result = response.data;
			if (result?.businessError) {
				Toaster({ message: result.businessError.errorMessage });
				return;
			}
			let couponsList =
				result.GenerateCouponDetailsSummaryResponse?.generateCouponDetails || [];
			couponsList = couponsList.map(
				({ fileName, generationDetails = null, customCouponsDetails = null }, ind) => ({
					ind: ind + 1,
					fileName,
					isSelect: false,
					generationDetails,
					customCouponsDetails
				})
			);
			setInfoModal({
				infoShow: true,
				infoTitle: `${pageLabels.viewCouponsModalTitle} ${promoID}`,
				infoMsg: (
					<CouponsList
						ref={couponsListRef}
						couponsListData={couponsList}
						downloadCouponsHandler={downloadCouponsHandler}
						replicateCouponsHandler={replicateCouponsHandler}
					/>
				),
				size: 'lg',
				additionalActionBtn: (
					<button
						className="btn btn-outline-primary"
						onClick={() => {
							downloadCouponsHandler(
								couponsListRef.current
									.getSelectedCoupons()
									.map((val) => val.fileName),
								true
							);
						}}
					>
						{commonLabels.download}
					</button>
				)
			});
		} catch (error) {
			props.showLoadingButton(false);
			console.error(error);
		}
	};

	const cancelGenerateCouponForm = () => {
		setCouponFormInitValues(null);
		setInfoModal({ infoShow: false });
	};

	const generateCouponSubmitHandler = ({ formData }) => {
		const data = {
			generateCouponRequest: { ...formData, user: props.userFullName.operatorID }
		};
		props.showLoadingButton(true);
		RestSvc.postData(generateCoupon.createCoupon, data)
			.then((response) => {
				props.showLoadingButton(false);
				let result = response.data.uploadCouponResponse;

				const { status, businessExceptions } = result;

				if (result.businessError) {
					Toaster({ message: result.businessError?.errorMessage });
					return;
				}

				let infoMsg = [];
				const statusMsg = [];
				const errors = [];

				status && statusMsg.push(<div key="status-1">{status}</div>);

				businessExceptions?.forEach(({ code, errorMessage }, i) => {
					errors.push(<div key={i}>{errorMessage}</div>);
				});

				infoMsg = [...statusMsg, ...errors];

				if (infoMsg.length) {
					setInfoModal({
						infoShow: true,
						infoMsg: [...infoMsg],
						infoTitle: pageLabels.generateCouponInfoTitle
					});
				}

				if (!errors.length) {
					setCouponFormInitValues(null);
				}
			})
			.catch((error) => {
				props.showLoadingButton(false);
				console.error(error);
			});
	};

	const createCouponSubmitHandler = ({ formData }) => {
		const { programId } = props.loggedInBrand;

		const data = {
			CustomCouponsUploadRequest: {
				...formData,
				userId: `${props.userFullName.operatorID}:${programId}`
			}
		};

		props.showLoadingButton(true);
		RestSvc.postData(generateCoupon.uploadCustomCoupons, data)
			.then((response) => {
				props.showLoadingButton(false);
				let result = response.data.uploadCouponResponse;

				const { status, businessExceptions } = result;

				if (result.businessError) {
					Toaster({ message: result.businessError?.errorMessage });
					return;
				}

				let infoMsg = [];
				const statusMsg = [];
				const errors = [];

				status && statusMsg.push(<div key="status-1">{status}</div>);

				businessExceptions?.forEach(({ code, errorMessage }, i) => {
					errors.push(<div key={i}>{errorMessage}</div>);
				});

				infoMsg = [...statusMsg, ...errors];

				if (infoMsg.length) {
					setInfoModal({
						infoShow: true,
						infoMsg: [...infoMsg],
						infoTitle: pageLabels.generateCouponInfoTitle
					});
				}

				if (!errors.length) {
					setCouponFormInitValues(null);
				}
			})
			.catch((error) => {
				props.showLoadingButton(false);
				console.error(error);
			});
	};

	return (
		<div className="generate-coupon-container">
			<div className="row">
				<div className="col d-flex justify-content-between">
					<h1 className="roboto-b-24 m-0">{pageLabels.generateCoupon}</h1>
					<button
						type="button"
						className="btn btn-outline-primary btn-sm pt-0 pb-0"
						onClick={props?.generateCouponCloseHandler}
					>
						{`<  `}
						{commonLabels.back}
					</button>
				</div>
			</div>
			<hr />
			<div className="content">
				<FormTabs
					couponFormInitValues={couponFormInitValues}
					configuredCount={configuredCount}
					selectCouponCount={selectCouponCount}
					couponPatterns={couponPatterns}
					cancelGenerateCouponForm={cancelGenerateCouponForm}
					generateCouponSubmitHandler={generateCouponSubmitHandler}
					createCouponSubmitHandler={createCouponSubmitHandler}
					batchId={batchId}
				/>
				<OfferTable
					customClasses={couponFormInitValues === null ? '' : 'd-none'}
					offers={offers}
					generateCouponActionHandler={generateCouponActionHandler}
					viewCouponsActionHandler={viewCouponsActionHandler}
				/>
			</div>
			<ModalPopup
				title={infoModal.infoTitle}
				closeHandler={() => {
					setInfoModal({ infoShow: false });
				}}
				showModal={infoModal.infoShow}
				size={infoModal.size || 'sm'}
				additionalActionBtn={infoModal.additionalActionBtn || null}
			>
				{infoModal.infoMsg}
			</ModalPopup>
		</div>
	);
};

GenerateCoupon.propTypes = {
	viewArchiveOfferCloseHandler: PropTypes.func
};

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(GenerateCoupon);
