import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ErrorMessage, Field, FieldArray } from 'formik';
import moment from 'moment';
import Datetime from 'react-datetime';
import Select from 'react-select';

import './formikControl.scss';

const formLabels = {
	file: {
		labelText: 'Choose file'
	}
};

export const TextError = (props) => {
	const { name } = props;
	return (
		<ErrorMessage name={name}>
			{(errorMsg) => <span className="error text-danger">{errorMsg}</span>}
		</ErrorMessage>
	);
};

export const Helper = ({ text, lineHelper }) => {
	if (!text) {
		return null;
	}
	if (lineHelper) {
		return <small className="text-muted">{text}</small>;
	}

	return (
		<small className="helper-container">
			<div className="tooltipimg">
				<button className="tooltip-button btn br-50" type="button">
					<span className="tooltip-icon">?</span>
				</button>
				<span className="tooltiptext"> {text} </span>
			</div>
		</small>
	);
};

const Input = (props) => {
	const {
		label,
		name,
		placeholder,
		readOnly = false,
		required,
		helperText,
		extraElm = null,
		iconHelper = true,
		inputClass = '',
		children,
		...rest
	} = props;
	return (
		<Field id={name} name={name} {...rest}>
			{({
				field, // { name, value, onChange, onBlur }
				form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
				meta
			}) => (
				<div className="formik-input-group">
					<label htmlFor={name} className="formik-input-label col-form-label">
						{label}
						{required && <sup>*</sup>}
					</label>
					<div className="formik-input">
						<div className="input-wrapper">
							<input
								type="text"
								className={`form-control input-field ${inputClass} ${
									meta.touched && meta.error ? 'is-invalid' : ''
								}`}
								id={name}
								name={name}
								aria-describedby={name}
								placeholder={placeholder}
								readOnly={readOnly}
								{...field}
							/>
							{children}
							{iconHelper && <Helper text={helperText} />}
						</div>
						{!iconHelper && <Helper text={helperText} lineHelper />}
						{extraElm}
						<TextError name={name} />
					</div>
				</div>
			)}
		</Field>
	);
};

const Number = (props) => {
	const {
		label,
		name,
		placeholder,
		readOnly = false,
		required,
		helperText,
		min = null,
		max = null,
		extraElm = null,
		iconHelper = true,
		inputClass = '',
		children,
		...rest
	} = props;
	return (
		<Field id={name} name={name} {...rest}>
			{({
				field, // { name, value, onChange, onBlur }
				form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
				meta
			}) => (
				<div className="formik-input-group">
					<label htmlFor={name} className="formik-input-label col-form-label">
						{label}
						{required && <sup>*</sup>}
					</label>
					<div className="formik-input">
						<div className="input-wrapper">
							<input
								type="Number"
								className={`form-control input-field ${inputClass} ${
									meta.touched && meta.error ? 'is-invalid' : ''
								}`}
								id={name}
								name={name}
								aria-describedby={name}
								placeholder={placeholder}
								readOnly={readOnly}
								min={min}
								max={max}
								onWheel={(event) => {
									event.target.blur();
									event.stopPropagation();
									setTimeout(() => {
										event.target.focus();
									}, 0);
								}}
								{...field}
							/>
							{children}
							{iconHelper && <Helper text={helperText} />}
						</div>
						{!iconHelper && <Helper text={helperText} lineHelper />}
						{extraElm}
						<TextError name={name} />
					</div>
				</div>
			)}
		</Field>
	);
};
/* html select  */
const NativeSelect = (props) => {
	const {
		label,
		name,
		options,
		required,
		helperText,
		readOnly = false,
		iconHelper = true,
		extraElm = null,
		inputClass = '',
		multiSelect = false,
		size,
		...rest
	} = props;
	return (
		<Field id={name} name={name} {...rest}>
			{({
				field, // { name, value, onChange, onBlur }
				form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
				meta
			}) => (
				<div className="formik-input-group">
					<label htmlFor={name} className="formik-input-label col-form-label">
						{label} {required && <sup>*</sup>}
					</label>
					<div className="formik-input">
						<div className="input-wrapper">
							<select
								className={`form-control input-field ${inputClass} ${
									meta.touched && meta.error ? 'is-invalid' : ''
								}`}
								id={name}
								name={name}
								disabled={readOnly}
								multiple={multiSelect ? 'multiple' : false}
								size={size}
								{...field}
							>
								{options.map((opt, ind) => (
									<option key={opt.ind || opt.value} value={opt.value}>
										{opt.label}
									</option>
								))}
							</select>
							{iconHelper && <Helper text={helperText} />}
						</div>
						{!iconHelper && <Helper text={helperText} lineHelper />}
						{extraElm}
						<TextError name={name} />
					</div>
				</div>
			)}
		</Field>
	);
};

const MultiSelect = (props) => {
	const {
		label,
		name,
		placeholder = '',
		options,
		required,
		helperText,
		readOnly = false,
		iconHelper = true,
		extraElm = null,
		inputClass = '',
		multiSelect = true,
		size,
		...rest
	} = props;
	return (
		<Field id={name} name={name} {...rest}>
			{({
				field, // { name, value, onChange, onBlur }
				form: { setFieldValue, setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
				meta
			}) => {
				return (
					<div className="formik-input-group">
						<label htmlFor={name} className="formik-input-label col-form-label">
							{label} {required && <sup>*</sup>}
						</label>
						<div className="formik-input">
							<div className="input-wrapper">
								<Select
									className={`form-control input-field multi-select  p-0 ${inputClass}${
										meta.touched && meta.error ? 'is-invalid' : ''
									}`}
									isMulti={multiSelect}
									id={name}
									name={name}
									options={options}
									isDisabled={readOnly}
									closeMenuOnSelect={false}
									placeholder={placeholder}
									defaultValue={field.value} // {value:'', label: ''}
									components={{
										IndicatorSeparator: () => null
									}}
									onChange={(value) => {
										let fieldValue = value;
										// if (multiSelect) {
										// 	fieldValue = value.map(({ value }) => value);
										// }
										setFieldValue(name, fieldValue);
										setTimeout(() => setFieldTouched(name, true));
									}}
								/>
								{iconHelper && <Helper text={helperText} />}
							</div>
							{!iconHelper && <Helper text={helperText} lineHelper />}
							{extraElm}
							<TextError name={name} />
						</div>
					</div>
				);
			}}
		</Field>
	);
};

const CheckboxGroup = (props) => {
	const {
		label,
		name,
		options,
		required,
		helperText,
		readOnly = false,
		iconHelper = true,
		extraElm = null,
		inputClass = '',
		...rest
	} = props;
	return (
		<Field id={name} name={name} {...rest}>
			{({
				field, // { name, value, onChange, onBlur }
				form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
				meta
			}) => {
				return (
					<div className="formik-input-group">
						<div className="formik-input-label col-form-label">
							{label} {required && <sup>*</sup>}
						</div>
						<div className="formik-input">
							<div className="input-wrapper">
								<div className="checkbox-group input-field">
									{options.map((option, i) => {
										return (
											<div key={option.label} className="form-check">
												<input
													className={`form-check-input ${inputClass}`}
													type="checkbox"
													id={`${name}-${i}`}
													name={name}
													disabled={readOnly}
													{...field}
													value={option.value}
													checked={field.value.includes(option.value)}
												/>
												<label
													className="form-check-label"
													htmlFor={`${name}-${i}`}
												>
													{option.label}
												</label>
											</div>
										);
									})}
								</div>
								{iconHelper && <Helper text={helperText} />}
							</div>
							{!iconHelper && <Helper text={helperText} lineHelper />}
							{extraElm}
							<TextError name={name} />
						</div>
					</div>
				);
			}}
		</Field>
	);
};

const RadioButton = (props) => {
	const {
		label,
		name,
		options,
		required,
		helperText,
		readOnly = false,
		iconHelper = true,
		extraElm = null,
		inputClass = '',
		...rest
	} = props;
	return (
		<Field id={name} name={name} {...rest}>
			{({
				field, // { name, value, onChange, onBlur }
				form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
				meta
			}) => {
				return (
					<div className="formik-input-group">
						<div className="formik-input-label col-form-label">
							{label} {required && <sup>*</sup>}
						</div>
						<div className="formik-input">
							<div className="input-wrapper">
								<div className="radio-group input-field">
									{options.map((option, i) => {
										return (
											<div key={option.label} className="form-check">
												<input
													className={`form-check-input ${inputClass}`}
													type="radio"
													id={`${name}-${i}`}
													name={name}
													disabled={readOnly}
													{...field}
													value={option.value}
													checked={field.value === option.value}
												/>
												<label
													className="form-check-label"
													htmlFor={`${name}-${i}`}
												>
													{option.label}
												</label>
											</div>
										);
									})}
								</div>
								{iconHelper && <Helper text={helperText} />}
							</div>
							{!iconHelper && <Helper text={helperText} lineHelper />}
							{extraElm}
							<TextError name={name} />
						</div>
					</div>
				);
			}}
		</Field>
	);
};

const DateTimePicker = (props) => {
	const {
		label,
		name,
		placeholder,
		readOnly = false,
		required,
		helperText,
		extraElm = null,
		iconHelper = true,
		inputClass = '',
		...rest
	} = props;
	return (
		<Field id={name} name={name} {...rest}>
			{({ field, form, meta }) => {
				const { setFieldValue, setFieldTouched } = form;
				const { value } = field;
				const dateFormat = 'DD-MM-YYYY HH:mm';
				// 	var validDateFormat = [
				// 		'DD-MM-YYYY HH:mm',
				// 		'DD-MM-YYYY HH:mm:ss',
				// 		'YYYY-MM-DD HH:mm',
				// 		'YYYY-MM-DD HH:mm:ss',
				// ];

				/* used dateTimeValue function to format view on input */
				const dateTimeValue = () => {
					let dateTime = value;
					const isValidFomat = moment(dateTime, dateFormat, true).isValid();
					if (isValidFomat) {
						/* format so that calender has the given date */
						dateTime = moment(dateTime, dateFormat).format(dateFormat);
					}
					return dateTime;
				};

				const updateDateTime = (dateTime) => {
					const isValidFomat = moment(dateTime, dateFormat, true).isValid();
					let _dateTime = dateTime;
					if (isValidFomat) {
						_dateTime = moment(dateTime).format(dateFormat);
					}
					setFieldValue(name, _dateTime);
					setTimeout(() => setFieldTouched(name, true));
				};

				return (
					<div className="formik-input-group">
						<label htmlFor={name} className="formik-input-label col-form-label">
							{label}
							{required && <sup>*</sup>}
						</label>
						<div className="formik-input">
							<div className="input-wrapper">
								<Datetime
									dateFormat="DD-MM-YYYY"
									timeFormat="HH:mm"
									inputProps={{
										className: `form-control input-field  ${inputClass} ${
											meta.touched && meta.error ? 'is-invalid' : ''
										}`,
										disabled: readOnly,
										id: name,
										name,
										placeholder,
										autoComplete: 'off'
									}}
									value={dateTimeValue()}
									onBlur={updateDateTime}
									onChange={updateDateTime}
									// isValidDate={(current) => {
									// 	return current.isAfter(
									// 		moment().subtract(1, 'days')
									// 	);
									// }}
								/>
								{iconHelper && <Helper text={helperText} />}
							</div>
							{!iconHelper && <Helper text={helperText} lineHelper />}
							{extraElm}
							<TextError name={name} />
						</div>
					</div>
				);
			}}
		</Field>
	);
};

const FormikArray = (props) => {
	const {
		label,
		name,
		placeholder,
		readOnly = false,
		required,
		helperText,
		// type = 'text',
		// iconHelper = true,
		fieldKeys = [] /* show custom keys */,
		showAllElms = false,
		inputClass = '',
		children,
		...rest
	} = props;

	return (
		<div className="form-field-list">
			<FieldArray name={name} {...rest}>
				{(fieldArrayProps) => {
					const { remove, form } = fieldArrayProps;
					const { values = {} } = form;
					const _fields = values[name];
					return (
						<>
							{_fields.map((extraElm, index) => {
								if (showAllElms) {
									const _elmsArray = [];
									let ind = 0;
									const extraElmLength = Object.keys(extraElm).length;
									for (const property in extraElm) {
										ind++;
										let _input = {
											control: 'input',
											label: `${label} ${index + 1}`,
											name: property
												? `${name}[${index}].${property}`
												: `${name}[${index}]`,
											readOnly,
											inputClass,
											controlClass: ''
										};
										_elmsArray.push(
											<FormikControl key={`${property}-${index}`} {..._input}>
												{(ind === extraElmLength && (
													<button
														type="button"
														className="addDeleteBtn ml-1 btn-icon"
														onClick={() => remove(index)}
													/>
												)) ||
													null}
											</FormikControl>
										);
									}
									return _elmsArray;
								}

								const btnPostion = fieldKeys.length - 1;
								const elms = fieldKeys.map((elm, i) => {
									const input = {
										control: 'input',
										label: `${label} ${index + 1} ${i}`,
										name: elm
											? `${name}[${index}].${elm}`
											: `${name}[${index}]`,
										readOnly
									};
									return (
										<FormikControl key={`${input.label}`} {...input}>
											{i === btnPostion && (
												<button
													type="button"
													className="addDeleteBtn ml-2"
													onClick={() => remove(index)}
												/>
											)}
										</FormikControl>
									);
								});
								return elms;
							})}
						</>
					);
				}}
			</FieldArray>
		</div>
	);
};

const File = (props) => {
	const {
		label,
		name,
		placeholder,
		readOnly = false,
		required,
		helperText,
		iconHelper = true,
		accept,
		inputClass = '',
		children,
		...rest
	} = props;

	const [labelText, setLabelText] = useState(formLabels.file.labelText);

	return (
		<Field id={name} name={name} {...rest}>
			{({
				field, // { name, value, onChange, onBlur }
				form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
				meta
			}) => (
				<div className="formik-input-group">
					<span className="formik-input-label col-form-label">
						{label}
						{required && <sup>*</sup>}
					</span>
					<div className="formik-input">
						<div className="input-wrapper">
							<div className="custom-file">
								<label className="custom-file-label" htmlFor={name}>
									{values?.file ? labelText : formLabels.file.labelText}
								</label>
								<input
									type="file"
									className={`form-control input-field custom-file-input ${inputClass} ${
										meta.touched && meta.error ? 'is-invalid' : ''
									}`}
									id={name}
									name={name}
									aria-describedby={name}
									readOnly={readOnly}
									title={formLabels.file.labelText}
									accept={accept}
									{...field}
									onChange={(event) => {
										setLabelText(() => event.currentTarget.files[0].name);
										setFieldValue('file', event.currentTarget.files[0]);
									}}
								/>
								{children}
							</div>
							{iconHelper && <Helper text={helperText} />}
						</div>
						{!iconHelper && <Helper text={helperText} lineHelper />}
						<TextError name={name} />
					</div>
				</div>
			)}
		</Field>
	);
};

/* support for element component */
const ElmInput = (props) => {
	const {
		label,
		name,
		inputWrapperClass = '',
		required,
		helperText,
		extraElm = null,
		iconHelper = true,
		children,
		elm
	} = props;
	return (
		<div className={`formik-input-group ${inputWrapperClass}`}>
			<label htmlFor={name} className="formik-input-label col-form-label">
				{label}
				{required && <sup>*</sup>}
			</label>
			<div className="formik-input">
				<div className="input-wrapper">
					{elm}
					{children}
					{iconHelper && <Helper text={helperText} />}
				</div>
				{!iconHelper && <Helper text={helperText} lineHelper />}
				{extraElm}
				<TextError name={name} />
			</div>
		</div>
	);
};

const FormikControl = (props) => {
	const { controlClass = '', control = 'input', ...rest } = props;
	let controlElm = null;
	switch (control) {
		case 'input':
			controlElm = <Input {...rest} />;
			break;
		case 'number':
			controlElm = <Number {...rest} />;
			break;
		case 'select':
			controlElm = <NativeSelect {...rest} />;
			break;
		case 'multiSelect':
			controlElm = <MultiSelect {...rest} />;
			break;
		case 'checkbox':
			controlElm = <CheckboxGroup {...rest} />;
			break;
		case 'radio':
			controlElm = <RadioButton {...rest} />;
			break;
		case 'datetime':
			controlElm = <DateTimePicker {...rest} />;
			break;
		case 'fieldArray':
			controlElm = <FormikArray {...rest} />;
			break;
		case 'file':
			controlElm = <File {...rest} />;
			break;
		case 'elm':
			controlElm = <ElmInput {...rest} />;
			break;
		default:
			controlElm = props.extraElm;
	}
	return <div className={`formik-Control ${controlClass}`}>{controlElm}</div>;
};

/* wrap the input with inputs array */
const FormikGroup = ({ inputs = [], controlGroupClass = '' }, ind) => {
	return inputs?.map((extraElm, ind) => {
		if (Array.isArray(extraElm)) {
			let _customClass = controlGroupClass;
			if (typeof controlGroupClass === 'function') {
				_customClass = controlGroupClass(ind);
			}
			return (
				<div className={`formik-Control-wrapper ${_customClass}`} key={ind}>
					{extraElm.map((input, i) => {
						return <FormikControl key={`${ind}-${i}`} {...input} />;
					})}
				</div>
			);
		}
		return <FormikControl key={ind} {...extraElm} />;
	});
};

FormikGroup.propTypes = {
	controlGroupClass: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	]),
	inputs: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})])
	)
};

export { FormikGroup };

// FormikControl.defaultProps = {
// 	controlClass: '',
// 	control: 'input',
// 	readOnly: false,
// 	type: 'text',
// 	iconHelper: true
// };

FormikControl.propTypes = {
	controlClass: PropTypes.string,
	control: PropTypes.string.isRequired,
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	readOnly: PropTypes.bool,
	required: PropTypes.bool,
	helperText: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	]),
	type: PropTypes.string,
	iconHelper: PropTypes.bool,
	children: PropTypes.oneOfType([
		// Either a function
		PropTypes.func,
		// Or the instance of a DOM native element (see the note about SSR)
		PropTypes.shape({ current: PropTypes.instanceOf(Element) })
	]),

	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.shape({
					separator: PropTypes.string.isRequired,
					pattern: PropTypes.string.isRequired
				})
			])
		})
	)
};

export default FormikControl;
