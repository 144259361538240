import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Form, Formik } from 'formik';

import './OfferConfigCS.scss';

import searchIcon from '../../assets/images/grey-search.png';
import deleteIcon from '../../assets/images/delete.png';
import Delete from '../../assets/images/deleteIcon.png';

import * as RestSvc from '../../libraries/offer-services';
import * as actionTypes from '../../store/actions';
import * as urlTypes from '../../libraries/offer-service-urls';
import appLabels from '../../utils/appLabels';
import ReactTable, { textFilter, headerFormatter } from '../../Components/ReactTable/ReactTable';
import Toaster from '../../Components/Toaster/Toaster';
import { FormikGroup } from '../../Components/Input/FormikControl';
import OfferDetailsModal from './OfferDetailsModal/OfferDetailsModal';
import OfferFilterModal from './OfferFilterModal/OfferFilterModal';
import ModalPopup from '../../Components/ModalPopup/ModalPopup';
import { stringReplacer } from '../../utils/helperFunction';
import { FileButton, FileButtonContainer } from '../Components/FileButtonGroup/FileButtonGroup';

const {
	common: commonLabels,
	table: tableLabels,
	page: { offerConfiguratorCS: pageLabels }
} = appLabels;

const _enableBulkDelete = false;

const OfferCount = ({ offers = [], selectedOffer = [], deleteBulkOffer }) => {
	const offerLength = offers.length;
	if (!offerLength) {
		return null;
	}
	return (
		<div className="table-upper-section d-flex align-items-center">
			<p className="mb-2 roboto-r-14 grey right-section">
				{`${offerLength} Promotion${offerLength > 1 ? 's' : ''} Found`}
			</p>
			{_enableBulkDelete && (
				<div className="right-section" style={{ marginLeft: 'auto' }}>
					<div
						className={`pointer ${selectedOffer?.length ? '' : 'disabled'}`}
						onClick={() => {
							deleteBulkOffer(selectedOffer);
						}}
					>
						<img alt="" src={Delete} className="imageDelete highlight" />
					</div>
				</div>
			)}
		</div>
	);
};

const OffersTable = (props) => {
	return (
		<div className="table-container">
			<ReactTable {...props} />
		</div>
	);
};

const SearchFormIdForm = ({ submitHandler, formControls, showLoadingButton, programIdMap }) => {
	const getSearchOfferId = (promotionId, cb = () => {}) => {
		let brand = promotionId
			?.substring(0, promotionId.indexOf(promotionId.match(/\d/)))
			?.toUpperCase()
			.replace(/_/g, '');
		if (brand === 'ONEMG') {
			brand = '1MG';
		}
		const payLoad = {
			searchPromotionRequestLite: {
				programId: programIdMap[brand] || null,
				promotionId: promotionId.toUpperCase()
			}
		};

		submitHandler([]);
		showLoadingButton(true);
		const svPromUrl = JSON.parse(sessionStorage.getItem('applctnUrls')).savePromotionsUrl;
		RestSvc.postData(urlTypes.offers_maintenance_cs.searchPromotionLite, payLoad, svPromUrl)
			.then((response) => {
				showLoadingButton(false);
				const result = response.data.searchPromotionResponse;
				if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });
				} else {
					submitHandler(result);
				}
			})
			.catch((error) => {
				showLoadingButton(false);
				console.error(error);
			});
	};

	return (
		<div className="search-offer-id-form-container">
			<Formik
				initialValues={{
					searchOfferId: ''
				}}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					setSubmitting(false);
					values.searchOfferId.length && getSearchOfferId(values.searchOfferId);
					resetForm();
				}}
			>
				{(formik) => <Form>{formControls()}</Form>}
			</Formik>
		</div>
	);
};

const ConfirmModal = ({ showModal, closeHandler, deleteOfferHandler, offerIDs = [] }) => {
	return (
		<ModalPopup
			customClasses="confirmation-popup"
			title={pageLabels.deleteOfferConfirmTitle}
			showModal={showModal}
			closeHandler={closeHandler}
			hideCloseBtn
			modalVariant="warning"
			additionalActionBtn={
				<>
					<button
						type="button"
						className="btnCancel roboto-b-16"
						onClick={() => {
							closeHandler();
						}}
					>
						{commonLabels.no}
					</button>

					<button
						type="button"
						className="btnNxtPrev roboto-b-16"
						onClick={() => {
							deleteOfferHandler(offerIDs);
						}}
					>
						{commonLabels.yes}
					</button>
				</>
			}
		>
			{`${stringReplacer(
				pageLabels.deleteOfferConfirmText,
				'@offerID',
				offerIDs.join(', ').split(':')[1]
			)}`}
		</ModalPopup>
	);
};

const DeleteResMsg = ({ deleteOffersRes = [] }) => {
	const { successDeleteResult, failedDeleteResult, alreadyDeletedResult } = deleteOffersRes;

	const offersStatus = [];
	successDeleteResult?.promotionIds.forEach((id) => {
		offersStatus.push({ offerId: id.split(':')[1], status: 'Success' });
	});
	failedDeleteResult?.promotionIds.forEach((id) => {
		offersStatus.push({ offerId: id.split(':')[1], status: 'Fail' });
	});
	alreadyDeletedResult?.promotionIds.forEach((id) => {
		offersStatus.push({ offerId: id.split(':')[1], status: 'Already Deleted' });
	});

	return (
		<ReactTable
			columns={[
				{ dataField: 'offerId', text: tableLabels.promotionId },
				{ dataField: 'status', text: tableLabels.status }
			]}
			data={offersStatus}
			keyField="offerId"
			// paginationEnable= {true}
		/>
	);
};

const AlertPopup = ({
	customClasses = 'alert-popup',
	showModal,
	closeHandler,
	modalVariant,
	title = 'Information',
	msg = ''
}) => {
	return (
		<ModalPopup
			customClasses={customClasses}
			title={title}
			showModal={showModal}
			closeHandler={closeHandler}
			modalVariant={modalVariant}
		>
			{msg}
		</ModalPopup>
	);
};

const OfferConfiguratorCS = (props) => {
	const [searchedOffers, setSearchedOffers] = useState([]);
	const [sizePerPageList, setSizePerPageList] = useState([{ text: '50', value: 50 }]);
	const [programIdMap, setProgramIdMap] = useState([]);
	const [selectedOffer, setSelectedOffer] = useState([]);
	const [offerToDelete, setOfferToDelete] = useState([]); /* try to use selectedOffer */
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	/* use for PromoAssAlert, delete offer response */
	const [alertPopup, setAlertPopup] = useState({
		showModal: false,
		modalVariant: 'warning',
		title: 'Information',
		msg: ''
	});
	const offerFilterModalRef = useRef();

	useEffect(() => {
		getItemsPerPageForSearchedOffers();
		fetchBrandsForCSView();
	}, []);

	const getItemsPerPageForSearchedOffers = () => {
		props.showLoadingButton(true);

		RestSvc.getData(urlTypes.offers_maintenance.getConfigurationUrl)
			.then((response) => {
				props.showLoadingButton(false);
				const result = response.data.getConfigurationResponse;
				if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });

					setSizePerPageList([{ text: '50', value: 50 }]);
				} else {
					const pageList = result.configurationList
						.reduce((pre, cur, i) => {
							const newReducer = [...pre];
							if (
								cur.category === 'CONFIGURATOR_CONFIGURATION' &&
								!newReducer.includes(cur.value)
							) {
								newReducer.push(cur.value);
							}
							return newReducer;
						}, [])
						.sort((a, b) => {
							return a - b;
						});
					setSizePerPageList(
						pageList.map((val) => {
							return { text: val, value: parseInt(val) };
						})
					);
				}
			})
			.catch((error) => {
				props.showLoadingButton(false);
				console.error(error);
			});
	};

	const fetchBrandsForCSView = () => {
		props.showLoadingButton(true);

		RestSvc.getData(urlTypes.offers_maintenance_cs.fetchBrandsForCSView)
			.then((response) => {
				props.showLoadingButton(false);
				const result = response.data.brandsProgramIdMapCsViewRes;
				if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });
					return;
				}

				const prefixProgIdMap = result?.prefixProgIdMap;
				const brandProgMap = Object.fromEntries(
					Object.entries(prefixProgIdMap).map(([k, v]) => [
						k.replace(/_/g, ''),
						v
					]) /* replacing underscore with '' for brand so that fetch easy in searchPromotionLite program id */
				);
				setProgramIdMap(brandProgMap);
			})
			.catch((error) => {
				props.showLoadingButton(false);
				console.error(error);
			});
	};

	const uploadOfferHandler = (event, callBack) => {
		const fileList = event.target.files;
		const {
			showLoadingButton,
			userFullName: { operatorID },
			loggedInBrand: { businessUnitGroupFunctionID, programId }
		} = props;

		const user = `${operatorID}:${programId}`;
		const bsngp_fnc_id = businessUnitGroupFunctionID;

		const formData = new FormData();
		formData.append('file', fileList[0]);
		formData.append('user', user);
		formData.append('bsngp_fnc_id', bsngp_fnc_id);

		showLoadingButton(true);

		RestSvc.postData(urlTypes.offers_maintenance_cs.uploadPromotion, formData)
			.then((response) => {
				showLoadingButton(false);
				const result = response.data.uploadCouponResponse;

				if (result.businessError) {
					Toaster({ message: result.businessError?.description });
					return;
				}

				const { status, businessExceptions } = result;
				let infoMsg = [];
				const statusMsg = [];
				const errors = [];

				status && statusMsg.push(<div key="status-1">{status}</div>);

				businessExceptions?.forEach(({ code, description }, i) => {
					errors.push(<div key={i}>{description}</div>);
				});
				infoMsg = [...statusMsg, ...errors];
				if (infoMsg.length) {
					Toaster({ message: infoMsg, type: `${errors.length ? 'error' : 'success'}` });
				}
			})
			.catch((error) => {
				showLoadingButton(false);
				console.error(error);
			});
		callBack();
	};

	const deleteOfferHandler = async (offerIDs = []) => {
		setOfferToDelete(offerIDs);
		setShowConfirmModal(true);
	};

	const getPromotionAssociate = (offerID) => {
		const payLoad = {
			customerPromotionAssCountRequest: {
				promotionId: offerID
			}
		};

		props.showLoadingButton(true);
		return RestSvc.postData(
			urlTypes.offers_maintenance_cs.getCustomerPromotionAssCount,
			payLoad
		)
			.then((response) => {
				props.showLoadingButton(false);
				const result = response.data.customerPromotionAssCountResponse;
				if (result?.businessError) {
					Toaster({ message: result.businessError.errorMessage });
					return;
				}

				return result?.custPromoCount;
			})
			.catch((error) => {
				props.showLoadingButton(false);
				console.error(error);
			});
	};

	const confirmModalCloseHandler = () => {
		setShowConfirmModal(false);
	};

	const alertPopUpCloseHandler = () => {
		// resetAlertPopup
		setAlertPopup({
			showModal: false,
			modalVariant: 'warning',
			title: 'Information',
			msg: ''
		});

		setShowConfirmModal(false);
	};

	const deleteOffersHandler = async (offerIDs = []) => {
		const promoAssNum = await getPromotionAssociate(offerIDs.join(''));

		if (promoAssNum == undefined) {
			return;
		}

		if (promoAssNum > 0) {
			setAlertPopup({
				...alertPopup,
				showModal: true,
				title: pageLabels.promoAssAlertMsgTitle,
				msg: pageLabels.promoAssAlertMsg
			});
			return;
		}

		const payLoad = {
			deletePromotionRequest: {
				promotionId: offerIDs,
				userID: props.userFullName.operatorID
			}
		};

		props.showLoadingButton(true);

		RestSvc.postData(urlTypes.offers_maintenance_cs.deleteOffers, payLoad)
			.then((response) => {
				props.showLoadingButton(false);
				const result = response.data.deletePromotionResponseForCSView;
				if (result?.businessError) {
					Toaster({ message: result.businessError.errorMessage });
					return;
				}

				setAlertPopup({
					...alertPopup,
					showModal: true,
					modalVariant: 'success',
					title: pageLabels.deleteResModalTitle,
					msg: <DeleteResMsg deleteOffersRes={result} />
				});

				confirmModalCloseHandler();
				setOfferToDelete([]);
				setSelectedOffer([]);
				/* call search modal to fetch new data */
				offerFilterModalRef.current.fetchOffers();
			})
			.catch((error) => {
				props.showLoadingButton(false);
				console.error(error);
			});
	};

	const getTableData = () => {
		let noDataInfo = commonLabels.nothingToShow;

		const data = [...searchedOffers];

		const dateFormatter = ({ value, row, index }) => {
			return moment(value).format('DD-MM-YYYY HH:mm');
		};

		const actionFormatter = ({ value, row, index }) => {
			const enableDelete =
				row.source === 'FILE'
					? ''
					: 'disabled'; /* Delete button only enable for  CS BU  uploaded promotions. */
			return (
				<div className="action-container">
					<OfferDetailsModal offer={row} showLoadingButton={props.showLoadingButton} />
					<div title={`${enableDelete === '' ? 'Delete offer' : 'Delete Not Allowed'}`}>
						<span
							className={`pointer ${enableDelete}`}
							onClick={() => {
								deleteOfferHandler([row.promotionId]);
							}}
						>
							<img
								alt="Delete offer"
								className="highlight delete-icon"
								src={deleteIcon}
							/>
						</span>
					</div>
				</div>
			);
		};

		const onSelectedChange = (offerIds) => {
			setSelectedOffer(offerIds);
		};

		const selectRowOptions = {
			selected: selectedOffer,
			onSelect: (row, isSelect) => {
				let _selectedOffer = [...selectedOffer];
				if (!isSelect) {
					const removeIndex = _selectedOffer.indexOf(row?.promotionId);
					_selectedOffer.splice(removeIndex, 1);
				} else {
					_selectedOffer.push(row.promotionId);
				}
				onSelectedChange(_selectedOffer);
			},
			onSelectAll: (isSelect, rows) => {
				let _selectedOffer = [...selectedOffer];

				if (!isSelect) {
					_selectedOffer = [];
				} else {
					_selectedOffer = rows.map((val) => {
						return val.promotionId;
					});
				}
				onSelectedChange(_selectedOffer);
			},
			headerColumnStyle: {
				textAlign: 'center'
			},
			selectColumnStyle: {
				textAlign: 'center'
			}
		};

		const columns = [
			{
				dataField: 'promotionId',
				text: tableLabels.promotionId,
				headerAlign: 'center',
				headerFormatter,
				filter: textFilter(),
				formatter: (value, row, index) => value?.split(':')[1] || value
			},
			{
				dataField: 'promotionTitle',
				text: tableLabels.title,
				headerAlign: 'center',
				headerFormatter,
				filter: textFilter()
			},
			{
				dataField: 'businessUnit',
				text: tableLabels.businessUnit,
				headerAlign: 'center'
			},
			{
				dataField: 'limitedUse',
				text: tableLabels.promotionType,
				headerAlign: 'center',
				formatter: (value, row, index) => value
			},
			{
				dataField: 'benefitType',
				text: tableLabels.benefitType,
				headerAlign: 'center',
				formatter: (value, row, index) => (Array.isArray(value) ? value.join(', ') : value)
			},
			{
				dataField: 'effectiveStartDate',
				text: tableLabels.startDateTime,
				headerAlign: 'center',
				formatter: (value, row, index) =>
					dateFormatter({
						value,
						row,
						index
					})
			},
			{
				dataField: 'effectiveEndDate',
				text: tableLabels.endDateTime,
				headerAlign: 'center',
				formatter: (value, row, index) =>
					dateFormatter({
						value,
						row,
						index
					})
			},
			{
				dataField: 'couponType',
				text: tableLabels.couponType,
				headerAlign: 'center',
				formatter: (value, row, index) => value
			},
			{
				isDummyField: true,
				dataField: 'actionColumn',
				text: tableLabels.action,
				headerAlign: 'center',
				align: 'center',
				headerStyle: {
					width: '65px'
				},
				formatter: (value, row, index) =>
					actionFormatter({
						value,
						row,
						index
					})
			}
		];

		const expandRow = {
			renderer: (row) => (
				<>
					<div className="d-flex mb-2">
						<div className="pr-2">
							<span className="cGray mr-1">{tableLabels.description}:</span>
							<span className="">{row.promotionDescription || '-'}</span>
						</div>
					</div>
				</>
			)
		};

		return {
			columns: [...columns],
			data,
			keyField: 'promotionId',
			noDataInfo,
			paginationEnable: true,
			sizePerPageList: sizePerPageList,
			customClasses: 'offers-table',
			expandRow,
			searchBarEnable: true,
			selectRowOptions: _enableBulkDelete ? selectRowOptions : null
		};
	};

	const formControls = () => {
		const inputs = [
			{
				...pageLabels.offerFilter.formLabels.searchOfferId,
				control: 'input',
				name: 'searchOfferId',
				inputClass: 'search-offer-id',
				// controlClass: 'vertical-group',
				// required: true,
				// validate: fieldValidator(['require']),
				children: <img className="search-icon" alt="" src={searchIcon}></img>
			}
		];

		return <FormikGroup inputs={inputs} />;
	};

	return (
		<div className="offer-management-cs-screen">
			<div className="action-section d-flex flex-wrap" style={{ gap: '8px' }}>
				<FileButtonContainer>
					<FileButton
						fileHandler={uploadOfferHandler}
						fileBtnID="idLoadPromotionFromFile"
						fileIcon="fileUpload"
						labels={{
							btnLabel: pageLabels.uploadBtnText
						}}
					/>
				</FileButtonContainer>
				<SearchFormIdForm
					submitHandler={(offer) => {
						/* todo: this deleteFlag should be fix from backend api */
						let res = [];
						if (offer?.promoPolicyLiteList) {
							res = offer.promoPolicyLiteList?.filter((offer) => {
								return offer.deleteFlag !== 'Y';
							});
							if (offer.promoPolicyLiteList?.length > 0 && res.length === 0) {
								Toaster({ type: 'info', message: 'Offer Already Deleted' });
							}
						}
						/*todo: end */
						setSearchedOffers(res);
					}}
					formControls={formControls}
					showLoadingButton={props.showLoadingButton}
					programIdMap={programIdMap}
				/>
				<>
					<p className="d-flex align-items-center m-0 roboto-b-16"> OR </p>
					<OfferFilterModal
						ref={offerFilterModalRef}
						programId={props.loggedInBrand.programId}
						showLoadingButton={props.showLoadingButton}
						searchResultHandler={(offers) => {
							/* todo: this deleteFlag should be fix from backend api */
							let res = [];
							if (offers?.promoPolicyLiteList) {
								res = offers.promoPolicyLiteList?.filter((offer) => {
									return offer.deleteFlag !== 'Y';
								});
							}
							/*todo: end */
							setSearchedOffers(res);
						}}
					/>
					<a
						className="file-download-link d-flex align-items-center channelLink roboto-b-16"
						href="../CS_Upload_Promotion.xlsm"
						title={pageLabels.downloadTemplateTitle}
						download
					>
						<span className="download template-icon" />
					</a>
				</>
			</div>
			<hr />
			<div className="offer-content" style={{ height: '78.5vh', overflowY: 'auto' }}>
				{searchedOffers?.length ? (
					<>
						<OfferCount
							offers={searchedOffers}
							deleteBulkOffer={deleteOfferHandler}
							selectedOffer={selectedOffer}
						/>
						<OffersTable {...getTableData()} />
					</>
				) : null}
			</div>
			<ConfirmModal
				showModal={showConfirmModal}
				closeHandler={confirmModalCloseHandler}
				offerIDs={offerToDelete}
				deleteOfferHandler={deleteOffersHandler}
			/>

			<AlertPopup
				showModal={alertPopup.showModal}
				closeHandler={alertPopUpCloseHandler}
				modalVariant={alertPopup.modalVariant}
				title={alertPopup.title}
				msg={alertPopup.msg}
			/>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		loggedInBrand: state.loggedInBrand
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OfferConfiguratorCS));
