import React, { useRef } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
import moment from 'moment';

import { FormikGroup } from '../../Components/Input/FormikControl';
import resetIcon from '../../assets/images/undo.png';
import appLabels from '../../utils/appLabels';
import {
	dateValidator,
	dbDateFormat,
	fieldValidator,
	GeneratedCouponInfo,
	numberValidation,
	validDateFormat
} from './GenerateCouponForm';
import appConstants from '../../utils/appConstants';
import { stringReplacer } from '../../utils/helperFunction';
import Toaster from '../../Components/Toaster/Toaster';

const { multiuseCouponRedemptionLimit, singleUseCouponRedemptionLimit } = appConstants;

const {
	common: commonLabels,
	page: { generateCoupon: pageLabels }
} = appLabels;

const { formLabels } = pageLabels;

const customCouponsLengthValidation = (formik, maxCustomCouponLimit) => {
	return (value) => {
		const { customCouponList } = formik.values;
		let error = null;
		if (customCouponList.length >= maxCustomCouponLimit) {
			error = stringReplacer(
				pageLabels.customCouponsLengthError,
				'@maxLimit',
				maxCustomCouponLimit
			);
		}
		return error;
	};
};

const isCustomCodeValid = (formik, props) => {
	const {
		initValues: { startDate, expiryDate, isMultiuseCoupon },
		maxCustomCouponLimit = 10
	} = props;

	const couponRedemptionLimitMax = isMultiuseCoupon
		? multiuseCouponRedemptionLimit
		: singleUseCouponRedemptionLimit;

	const { couponCode, couponExpiryDate, couponRedemptionLimit } = formik.values;
	const isCouponCode = fieldValidator(['require'])(couponCode);
	const isExpiryDate = fieldValidator([
		'require',
		dateValidator({
			min: dbDateFormat(startDate),
			max: dbDateFormat(expiryDate)
		})
	])(couponExpiryDate);
	const isCouponLimit = fieldValidator([
		'require',
		numberValidation({ min: 1, max: couponRedemptionLimitMax })
	])(couponRedemptionLimit);
	const isCustomCouponLengthValidation = fieldValidator([
		customCouponsLengthValidation(formik, maxCustomCouponLimit)
	])();

	const isValid =
		isCouponCode === null &&
		isExpiryDate === null &&
		isCouponLimit === null &&
		isCustomCouponLengthValidation === null;
	return {
		isCouponCode,
		isExpiryDate,
		isCouponLimit,
		isCustomCouponLengthValidation,
		isValid
	};
};

const formControls = (formik, props, addPatternRefBtn) => {
	const {
		initValues: { expiryDate, isMultiuseCoupon, displayCoupons },
		configuredCount
	} = props;

	const couponRedemptionLimitMax = isMultiuseCoupon
		? multiuseCouponRedemptionLimit
		: singleUseCouponRedemptionLimit;
	const _couponSubType = isMultiuseCoupon ? 'MULTIUSE' : 'SINGLEUSE';

	let _couponRedemptionLimit = multiuseCouponRedemptionLimit;
	if (_couponSubType === 'MULTIUSE' && displayCoupons && configuredCount) {
		_couponRedemptionLimit = parseInt(configuredCount);
	} else if (_couponSubType === 'SINGLEUSE') {
		_couponRedemptionLimit = 1;
	}

	const isValidCustomCode = isCustomCodeValid(formik, props);

	let inputs = [
		[
			{
				control: 'input',
				...formLabels.promoID,
				name: 'promotionId',
				readOnly: true,
				controlClass: 'vertical-group'
			},
			{
				control: 'input',
				...formLabels.promoExpiryDate,
				name: 'promotionEndDate',
				readOnly: true,
				controlClass: 'vertical-group'
			}
		],
		[
			{
				control: 'input',
				...formLabels.couponType,
				name: 'couponType',
				readOnly: true,
				controlClass: 'vertical-group'
			},
			{
				control: 'input',
				...formLabels.couponSubType,
				name: 'couponSubType',
				readOnly: true,
				controlClass: 'vertical-group'
			}
		],
		[
			{
				control: 'input',
				...formLabels.couponCode,
				name: 'couponCode',
				controlClass: 'vertical-group',
				inputClass: (isValidCustomCode.isCouponCode && 'is-invalid') || '',
				extraElm: formik.touched.couponCode && isValidCustomCode.isCouponCode && (
					<span className="error text-danger">{isValidCustomCode.isCouponCode}</span>
				)
			},
			{
				control: 'datetime',
				...formLabels.couponExpiryDate,
				name: 'couponExpiryDate',
				controlClass: 'vertical-group',
				inputClass: (isValidCustomCode.isExpiryDate && 'is-invalid') || '',
				extraElm: isValidCustomCode.isExpiryDate && (
					<span className="error text-danger">{isValidCustomCode.isExpiryDate}</span>
				)
			},
			{
				control: 'number',
				min: 0,
				max: couponRedemptionLimitMax,
				...formLabels.couponRedemptionLimit,
				name: 'couponRedemptionLimit',
				readOnly: !isMultiuseCoupon,
				controlClass: 'vertical-group',
				inputClass: (isValidCustomCode.isCouponLimit && 'is-invalid') || '',
				extraElm: isValidCustomCode.isCouponLimit && (
					<span className="error text-danger">{isValidCustomCode.isCouponLimit}</span>
				),
				children: (
					<span
						title={(!isValidCustomCode.isValid && pageLabels.customCodeErrorText) || ''}
					>
						<button
							ref={addPatternRefBtn}
							type="button"
							className={`addPlusBtn ml-1 btn-icon ${
								isValidCustomCode.isValid ? '' : 'disabled'
							}`}
							disabled={!isValidCustomCode.isValid}
							title={pageLabels.addCustomCodeBtnText}
							name="addCouponBtn"
							onClick={() => {
								const {
									customCouponList,
									couponExpiryDate,
									couponRedemptionLimit,
									couponCode
								} = formik.values;
								const isExist = customCouponList.filter(
									({ couponId }) => couponId === couponCode
								);
								if (isExist.length) {
									Toaster({
										message: `${couponCode} ${pageLabels.alreadyExist}`,
										closeAll: true
									});
									return;
								}
								formik.setFieldValue('customCouponList', [
									...customCouponList,
									{
										couponId: couponCode,
										couponExpiryDate: moment(
											couponExpiryDate,
											validDateFormat[0]
										).format(validDateFormat[3]),
										couponLimit: couponRedemptionLimit
									}
								]);

								formik.setFieldValue('couponCode', '');
								formik.setFieldValue('couponExpiryDate', dbDateFormat(expiryDate));
								formik.setFieldValue(
									'couponRedemptionLimit',
									_couponRedemptionLimit
								);
							}}
						/>
					</span>
				)
			}
		],
		[
			{
				control: 'helper',
				name: 'helper',
				extraElm: (
					<>
						{formik.values.customCouponList.length === 0 && (
							<span className="error text-danger d-block">
								{pageLabels.customCodeErrorText}
							</span>
						)}
						{isValidCustomCode.isCustomCouponLengthValidation && (
							<span className="error text-danger d-block">
								{isValidCustomCode.isCustomCouponLengthValidation}
							</span>
						)}
					</>
				)
			}
		],
		[
			{
				control: 'fieldArray',
				name: 'customCouponList',
				label: 'customCouponList',
				// fieldKeys: 'couponCode',
				readOnly: true,
				showAllElms: true,
				controlClass: 'vertical-group'
			}
		]
	];

	return (
		<FormikGroup
			inputs={inputs}
			controlGroupClass={(ind) => {
				return [2, 4].includes(ind) ? 'row-col three-col' : '';
			}}
		/>
	);
};

const CreateCouponForm = (props) => {
	const addPatternRefBtn = useRef();
	const { initValues, patternOptions, configuredCount, generatedCouponCount, cancel } = props;
	const leftOverCombination = configuredCount - generatedCouponCount;

	const initFormValues = (initVal = {}) => {
		const { promotionId, expiryDate, couponType, isMultiuseCoupon, displayCoupons } = initVal;

		const _couponSubType = isMultiuseCoupon ? 'MULTIUSE' : 'SINGLEUSE';

		let _couponRedemptionLimit = multiuseCouponRedemptionLimit;
		if (_couponSubType === 'MULTIUSE' && displayCoupons && configuredCount) {
			_couponRedemptionLimit = parseInt(configuredCount);
		} else if (_couponSubType === 'SINGLEUSE') {
			_couponRedemptionLimit = 1;
		}

		const initialValues = {
			promotionId,
			promotionEndDate: expiryDate,
			couponType,
			couponSubType: _couponSubType,
			couponCode: '',
			couponExpiryDate: dbDateFormat(expiryDate),
			couponRedemptionLimit: _couponRedemptionLimit,
			customCouponList: []
		};
		return initialValues;
	};

	const onSubmit = (values, { setSubmitting }) => {
		setSubmitting(false);
		const { promotionId, couponType, customCouponList } = values;
		props.submitHandler?.({
			formData: {
				promotionId,
				couponType,
				customCouponList
			}
		});
	};

	return (
		<Formik initialValues={initFormValues(initValues, patternOptions)} onSubmit={onSubmit}>
			{(formik) => {
				return (
					<Form
						onKeyDown={(keyEvent) => {
							if (
								[
									'couponCode',
									'couponExpiryDate',
									'couponRedemptionLimit'
								].includes(keyEvent.target.name) &&
								(keyEvent.charCode || keyEvent.keyCode) === 13
							) {
								addPatternRefBtn.current.click();
								keyEvent.preventDefault();
							}
						}}
					>
						{formControls(formik, props, addPatternRefBtn)}

						<div className="d-flex justify-content-end align-items-center form-btn-container">
							<GeneratedCouponInfo
								generatedCouponCount={generatedCouponCount}
								leftOverCombination={leftOverCombination}
							/>
							<button
								type="button"
								className="grey-button roboto-b-16 bg-transparent ml-2"
								onClick={formik.resetForm}
							>
								<img src={resetIcon} className="mr-1" alt={commonLabels.reset} />
								{commonLabels.reset}
							</button>
							<button
								type="button"
								className="btnCancel roboto-b-16 ml-2"
								onClick={() => cancel()}
							>
								{commonLabels.cancel}
							</button>
							<button
								type="submit"
								disabled={
									!formik.isValid ||
									formik.isSubmitting ||
									formik.values.customCouponList.length === 0 ||
									leftOverCombination === 0
								}
								className="btnNxtPrev roboto-b-16 ml-2"
							>
								{commonLabels.submit}
							</button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

CreateCouponForm.propTypes = {
	initValues: PropTypes.shape({
		promotionId: PropTypes.string.isRequired,
		startDate: PropTypes.string.isRequired,
		expiryDate: PropTypes.string.isRequired,
		couponType: PropTypes.string.isRequired
	}).isRequired,
	submitHandler: PropTypes.func
};
export default CreateCouponForm;
