import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import LoginPage from './Login/Login';
import MainPage from './MainContent/MainPage';
import Loader from './Components/Loader/Loader';
import axios from './libraries/axios';
import { clearUserSession } from './utils/helperFunction';
import { Toasty } from './Components/Toaster/Toaster';

class App extends Component {
	state = {
		domain: ''
	};
	UNSAFE_componentWillMount() {
		const propertiesUrl = 'web-app.properties';
		clearUserSession();
		fetch(propertiesUrl)
			.then((r) => r.json())
			.then((data) => {
				axios.defaults.baseURL = data.propertySources[0].source.apiUrl;

				sessionStorage.setItem(
					'applctnUrls',
					JSON.stringify(data.propertySources[0].source)
				);

				sessionStorage.setItem('couponServiceUrl', '');
				sessionStorage.setItem('binServiceUrl', '');
			});

		const parsedData = window.location.pathname;
		let domain1 = parsedData;
		this.setState({ domain: domain1 });
	}

	render() {
		const { propLoading, propLoading2 } = this.props;

		return (
			<div className="container-fluid">
				{propLoading || propLoading2 ? <Loader /> : ''}
				<BrowserRouter basename={this.state.domain}>
					<Switch>
						<Route exact path="/">
							<LoginPage />
						</Route>
						<Route path="/MainPage">
							<MainPage />
						</Route>
					</Switch>
				</BrowserRouter>
				<div className="d-none">
					<iframe
						title="iFrame"
						id="getLeaf"
						src="../../channel-tree/getLeafNode.html"
						width="100%"
						height="85%"
						frameBorder="0"
					></iframe>
				</div>
				<Toasty />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		propLoading: state.loading,
		propLoading2: state.loading2
	};
};

export default connect(mapStateToProps)(App);
