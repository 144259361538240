import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './createOffer.scss';
import { Formik } from 'formik';
import EligibilityComponentPP from './components/EligibilityComponentPP';
import * as Yup from 'yup';
import * as RestSvc from '../../libraries/offer-services';
import * as urlTypes from '../../libraries/offer-service-urls';
import { isObjectEmpty, trimDate, getOfferFlowType } from '../../utils/helperFunction';
import omitEmpty from 'omit-empty';
import chevronDown from '../../assets/images/chevron-down.png';
import { Link, Element } from 'react-scroll';
import { ConfirmationModal } from '../../MainContent/ConfirmationModal';
import { InformationModal } from '../../MainContent/InformationModal';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import Toaster from '../../Components/Toaster/Toaster';

//Local const
const localConst = {
	offerMaintainance: 'Offer Maintenance',
	basicDetails: 'Basic Details',
	PromotionType: 'Promotion Type',
	description: 'Description',
	combinationPromotion: 'Combination with other Promotions',
	shares: 'Shares',
	redemptionSettings: 'Redemption Settings',
	others: 'Others',
	applicationEligibility: 'Applicable Eligibility',
	channelEligibility: 'Channel Eligibility',
	privilegeCustomer: 'Privilege Customer Eligibility',
	anonymousCostomer: 'Anonymous Customer Eligibility',
	employeeEligibility: 'Employee Eligibility',
	loyaltyCustomer: 'Loyalty Customer Eligibility',
	customerSegments: 'Customer Segments Eligibility',
	loyaltyCard: 'LoyaltyCard Customer Eligibility',
	happyHours: 'Happy Hours',
	collectionRequired: 'Collection Required',
	eligibilitySettings: 'Eligibility Settings'
};

let initialValues = {
	packageMultibuyEligibility: {
		group: [
			{
				multibuyProduct: [],
				trigger: true,
				getReward: true,
				packageType: 'PRODUCT'
			}
		],
		/*  group1: [
      {
        merchandizeHierarchyGroup: [],
        trigger: true,
        getReward: true,
      },
    ], */
		thresholdTypeCode: '',
		exclusions: {
			excludedItemIDList: {
				excludedItemID: []
			},
			merchandizeHierarchyGroup: []
		},
		paymentEligibility: {
			allowAggAndIssuer: false,
			applicableOn: ''
		},
		coupons: {},
		benefit: {
			benefitType: ''
		}
	}
};

const SignupSchema = Yup.object().shape({
	packageMultibuyEligibility: Yup.object().shape({
		triggerOperatorValue: Yup.string().when('triggerOperator', {
			//  is: triggerOperator => ("<" || ">" || ">=" || "<=" || "="),
			is: (triggerOperator) => {
				if (
					triggerOperator == '<' ||
					triggerOperator == '=' ||
					triggerOperator == '>' ||
					triggerOperator == '>=' ||
					triggerOperator == '<='
				) {
					return triggerOperator;
				}
			},
			then: Yup.string()
				.required('Please define price range value')
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price range value should be between 0.01 to 999999.99'
				)
		}),
		triggerOperatorValueFrom: Yup.string().when('triggerOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value From should be between 0.01 to 999999.99'
				)
				.required('Please define price range value from')
				.test(
					'triggerOperatorValueFrom_test',
					'Price Range Value From should be less than the Price Range Value To',
					function (value) {
						const { triggerOperatorValueTo } = this.parent;
						return (
							parseFloat(value) < triggerOperatorValueTo ||
							parseFloat(value) == triggerOperatorValueTo
						);
					}
				)
		}),
		triggerOperatorValueTo: Yup.string().when('triggerOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value To should be between 0.01 to 999999.99'
				)
				.required('Please define price range value to')
				.test(
					'triggerOperatorValueTo_test',
					'Price Range Value To should not less than the Price Range Value From',
					function (value) {
						const { triggerOperatorValueFrom } = this.parent;
						return (
							parseFloat(value) > triggerOperatorValueFrom ||
							parseFloat(value) == triggerOperatorValueFrom
						);
					}
				)
		}),
		rewardOperatorValue: Yup.string().when('rewardOperator', {
			// is: rewardOperator => ("<" || ">" || ">=" || "<=" || "="),
			is: (rewardOperator) => {
				if (
					rewardOperator == '<' ||
					rewardOperator == '=' ||
					rewardOperator == '>' ||
					rewardOperator == '>=' ||
					rewardOperator == '<='
				) {
					return rewardOperator;
				}
			},
			then: Yup.string()
				.required('Please define price range value')
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price range value should be between 0.01 to 999999.99'
				)
		}),
		rewardOperatorValueFrom: Yup.string().when('rewardOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value From should be between 0.01 to 999999.99'
				)
				.required('Please define price range value from')
				.test(
					'RewardOperatorValueFrom_test',
					'Price Range Value From should be less than the Price Range Value To',
					function (value) {
						const { rewardOperatorValueTo } = this.parent;
						return (
							parseFloat(value) < rewardOperatorValueTo ||
							parseFloat(value) == rewardOperatorValueTo
						);
					}
				)
		}),
		rewardOperatorValueTo: Yup.string().when('rewardOperator', {
			is: 'BETWEEN',
			then: Yup.string()
				.matches(
					/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
					'Price Range Value To should be between 0.01 to 999999.99'
				)
				.required('Please define price range value to')
				.test(
					'rewardOperatorValueTo_test',
					'Price Range Value To should not less than the Price Range Value From',
					function (value) {
						const { rewardOperatorValueFrom } = this.parent;
						return (
							parseFloat(value) > rewardOperatorValueFrom ||
							parseFloat(value) == rewardOperatorValueFrom
						);
					}
				)
		}),

		thresholdTypeCode: Yup.string().required('Please provide a Threshold Type value'),
		groupTriggerCombinationCode: Yup.string().when('thresholdTypeCode', {
			is: !{},
			then: Yup.string().required('Please define a Group Trigger Combination Code value')
		}),

		groupRewardCombinationCode: Yup.string().when('thresholdTypeCode', {
			is: !{},
			then: Yup.string().required('Please define a Group Reward Combination Code value')
		}),

		quantityLimit: Yup.string().when('thresholdTypeCode', {
			is: 'PACKAGE_LIMIT_QTY_THRESHOLD',
			then: Yup.string()
				.required('Please define a quantity limit value')
				.matches(
					/^(?=.*[1-9])\d{0,6}$/,
					'Quantity Limit value should be between 1 to 999999'
				)
				.test(
					'val_test',
					'Quantity Limit value should be between 1 to 999999',
					function (value) {
						return 1 <= parseInt(value) && parseInt(value) <= 999999;
					}
				)
		}),

		groupRewardQuantity: Yup.string().when('thresholdTypeCode', {
			is: (thresholdTypeCode) => {
				if (
					thresholdTypeCode == 'PACKAGE_INTERVAL_QTY_THRESHOLD' ||
					thresholdTypeCode == 'PACKAGE_QTY_THRESHOLD' ||
					thresholdTypeCode == 'PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
					thresholdTypeCode == 'PACKAGE_AMOUNT_THRESHOLD' ||
					thresholdTypeCode == 'PACKAGE_RANGE_QTY_THRESHOLD' ||
					thresholdTypeCode == 'PACKAGE_RANGE_AMOUNT_THRESHOLD' ||
					thresholdTypeCode == 'PACKAGE_LIMIT_QTY_THRESHOLD'
				) {
					return thresholdTypeCode;
				}
			},
			then: Yup.string()
				.matches(/^(?=.*[1-9])\d{0,6}$/, 'Reward quantity must be between 1 and 999999')
				.required('Please define reward quantity value')
				.test('val_test', 'Reward quantity must be between 1 and 999999', function (value) {
					return 1 <= parseInt(value) && parseInt(value) <= 999999;
				})
		}),

		//add conditonal render
		groupThresholdQuantity: Yup.lazy(() =>
			Yup.string().when(
				['thresholdTypeCode', 'groupMaximumThresholdQuantity'],
				(thresholdTypeCode, groupMaximumThresholdQuantity, schema) => {
					if (
						thresholdTypeCode == 'PACKAGE_INTERVAL_QTY_THRESHOLD' ||
						thresholdTypeCode == 'PACKAGE_QTY_THRESHOLD' ||
						thresholdTypeCode == 'PACKAGE_LIMIT_QTY_THRESHOLD' ||
						thresholdTypeCode == 'PACKAGE_RANGE_QTY_THRESHOLD'
					) {
						if (thresholdTypeCode == 'PACKAGE_RANGE_QTY_THRESHOLD') {
							return schema
								.matches(
									/^(?=.*[1-9])\d{0,6}$/,
									'Purchased quantity must be between 1 and 999999'
								)
								.required('Please define purchased quantity value')
								.test(
									'val_test',
									'Maximum quantity threshold specified should be greater than minimum quantity purchased',
									function (value) {
										return (
											parseInt(value) < groupMaximumThresholdQuantity ||
											parseInt(value) == groupMaximumThresholdQuantity
										);
									}
								);
						} else if (
							thresholdTypeCode == 'PACKAGE_INTERVAL_QTY_THRESHOLD' ||
							thresholdTypeCode == 'PACKAGE_QTY_THRESHOLD' ||
							thresholdTypeCode == 'PACKAGE_LIMIT_QTY_THRESHOLD'
						) {
							return schema
								.matches(
									/^(?=.*[1-9])\d{0,6}$/,
									'Purchased quantity must be between 1 and 999999'
								)
								.required('Please define purchased quantity value')
								.test(
									'val_test',
									'Purchased quantity must be between 1 and 999999',
									function (value) {
										//debugger
										return 1 <= parseInt(value) && parseInt(value) <= 999999;
									}
								);
						}
					}
				}
			)
		),

		groupMaximumThresholdQuantity: Yup.lazy(() =>
			Yup.string().when(
				['thresholdTypeCode', 'groupThresholdQuantity'],
				(thresholdTypeCode, groupThresholdQuantity, schema) => {
					if (thresholdTypeCode == 'PACKAGE_RANGE_QTY_THRESHOLD') {
						return schema
							.matches(
								/^(?=.*[1-9])\d{0,6}$/,
								'Maximum purchased quantity must be between 1 and 999999'
							)
							.required('Please define maximum purchased quantity value')
							.test(
								'val_test',
								'Maximum quantity threshold specified should be greater than minimum quantity purchased',
								function (value) {
									//debugger
									return (
										parseInt(value) > groupThresholdQuantity ||
										parseInt(value) == groupThresholdQuantity
									);
								}
							);
					}
				}
			)
		),

		groupThresholdAmount: Yup.lazy(() =>
			Yup.string().when(
				['thresholdTypeCode', 'groupMaximumThresholdAmount'],
				(thresholdTypeCode, groupMaximumThresholdAmount, schema) => {
					if (
						thresholdTypeCode == 'PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
						thresholdTypeCode == 'PACKAGE_AMOUNT_THRESHOLD' ||
						thresholdTypeCode == 'PACKAGE_RANGE_AMOUNT_THRESHOLD'
					) {
						if (thresholdTypeCode == 'PACKAGE_RANGE_AMOUNT_THRESHOLD') {
							return schema
								.matches(
									/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
									'Amount Purchased must be between 0.01 to 999999.99'
								)
								.required('Please define purchased amount value')
								.test(
									'val_test',
									'Maximum amount threshold specified should be greater than minimum amount purchased',
									function (value) {
										return (
											parseFloat(value) < groupMaximumThresholdAmount ||
											parseFloat(value) == groupMaximumThresholdAmount
										);
									}
								);
						} else if (
							thresholdTypeCode == 'PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
							thresholdTypeCode == 'PACKAGE_AMOUNT_THRESHOLD'
						) {
							return schema
								.matches(
									/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
									'Amount Purchased must be between 0.01 to 999999.99'
								)
								.required('Please define purchased amount value')
								.test(
									'val_test',
									'Amount Purchased must be between 0.01 to 999999.99',
									function (value) {
										return (
											0.01 <= parseFloat(value) &&
											parseFloat(value) <= 999999.99
										);
									}
								);
						}
					}
				}
			)
		),

		groupMaximumThresholdAmount: Yup.lazy(() =>
			Yup.string().when(
				['thresholdTypeCode', 'groupThresholdAmount'],
				(thresholdTypeCode, groupThresholdAmount, schema) => {
					if (thresholdTypeCode == 'PACKAGE_RANGE_AMOUNT_THRESHOLD') {
						return schema
							.matches(
								/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
								'Maximum Amount Purchased must be between 0.01 to 999999.99'
							)
							.required('Please define maximum purchased amount value')
							.test(
								'val_test',
								'Maximum amount threshold specified should be greater than minimum amount purchased',
								function (value) {
									return (
										parseFloat(value) > groupThresholdAmount ||
										parseFloat(value) == groupThresholdAmount
									);
								}
							);
					}
				}
			)
		),

		group: Yup.array().when('thresholdTypeCode', {
			is: (thresholdTypeCode) => {
				if (
					thresholdTypeCode == 'HIERARCHY_PACKAGE_AMOUNT_THRESHOLD' ||
					thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_AMOUNT_THRESHOLD' ||
					thresholdTypeCode == 'HIERARCHY_PACKAGE_QTY_THRESHOLD' ||
					thresholdTypeCode == 'HIERARCHY_PACKAGE_INTERVAL_QTY_THRESHOLD'
				) {
					return thresholdTypeCode;
				}
			},
			then: Yup.array().of(
				Yup.object().shape({
					getReward: Yup.boolean(),
					trigger: Yup.boolean(),
					memberGroupRewardQuantity: Yup.string().when('getReward', {
						is: true,
						then: Yup.string().matches(
							/^(?=.*[1-9])\d{0,6}$/,
							'Group Reward Quantity value should be between 1 to 999999'
						)
					}),
					memberGroupThresholdQuantity: Yup.string().when('trigger', {
						is: true,
						then: Yup.string()
							/*   .required("Please define a Group Threshold Quantity value") */
							.matches(
								/^(?=.*[1-9])\d{0,6}$/,
								'Group Threshold Quantity value should be between 1 to 999999'
							)
					}),
					memberGroupThresholdAmount: Yup.string().when('trigger', {
						is: true,
						then: Yup.string().matches(
							/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
							'Group Threshold Amount value should be between 0.01 to 999999.99'
						)
					})
				})
			)
		}),

		coupons: Yup.object().shape({
			couponThresholdQuantity: Yup.string().when('coupons', {
				is: (couponType) => {
					if (
						couponType == 'GENERIC' ||
						couponType == 'PERSONALIZED' ||
						couponType == 'TARGETED'
					) {
						return couponType;
					}
				},
				then: Yup.string()
					.matches(
						/^[0-9]+$/,
						'Required Coupon Quantity must be between 1 and six 9s (both inclusive)'
					)
					.test(
						'val_test',
						'Required Coupon Quantity must be between 1 and six 9s (both inclusive)',
						function (value) {
							return 1 <= parseInt(value) && parseInt(value) <= 999999;
						}
					)
			}),

			coupon: Yup.array()
				.of(
					Yup.object().shape({
						couponId: Yup.string()
							.matches(
								/^.[a-zA-Z0-9]+$/,
								'Coupon Id length should be greater than 2 and less than 50 (both inclusive). Only Alphanumeric is supported.'
							)
							.min(
								2,
								'Coupon Id length should be greater than 2 and less than 50 (both inclusive). Only Alphanumeric is supported.'
							)
							.max(
								50,
								'Coupon Id length should be greater than 2 and less than 50 (both inclusive). Only Alphanumeric is supported.'
							)
							.required(
								'Coupon Id length should be greater than 2 and less than 50 (both inclusive). Only Alphanumeric is supported.'
							),
						couponLimit: Yup.string()
							.matches(
								/^[0-9]+$/,
								'Required: Redemption Limit per coupon must be between 1 and nine 9s (both inclusive)'
							)
							.test(
								'val_test',
								'Required: Redemption Limit per coupon must be between 1 and nine 9s (both inclusive)',
								function (value) {
									return 1 <= parseInt(value) && parseInt(value) <= 999999999;
								}
							),
						expireOn: Yup.object().shape({
							date: Yup.date(),
							/*  .min(
              new Date(),
              "Entered validity date and time cannot be lesser than the promotion start date and time"
            ), */
							days: Yup.string().when('couponExpiry', {
								is: 'Days',
								then: Yup.string()
									.required('Please define a days value')
									.matches(
										/^(?=.*[1-9])\d{0,3}$/,
										'Please enter a value greater than 1 to 999.'
									)
							}),
							months: Yup.string().when('couponExpiry', {
								is: 'Months',
								then: Yup.string()
									.required('Please define a months value')
									.matches(
										/^(?=.*[1-9])\d{0,3}$/,
										'Please enter a value greater than 1 to 999.'
									)
							}),
							hours: Yup.string().when('couponExpiry', {
								is: 'Hours',
								then: Yup.string().required('Please define a hours value')
							})
						})
					})
				)
				.unique('Coupon Id Already Exists.', (a) => a.couponId)
		}),
		paymentEligibility: Yup.object().shape({
			applicableOn: Yup.string(),
			paymentThresholdTypeCode: Yup.string(),
			/* .when("applicableOn",
        {
          is: applicableOn => {
            if (applicableOn == "TENDER_VALUE" ||
              applicableOn == "TRANSACTION_VALUE") {
              return applicableOn;
            }
          },
          then: Yup.string()
            .required("Please define a payment threshold type code value")
        }
      ), */
			payment:
				/* Yup.object().when("paymentThresholdTypeCode", {
         is: "PAYMENT_AMOUNT_THRESHOLD", */
				Yup.object().when('applicableOn', {
					is: (applicableOn) => {
						if (applicableOn == 'TENDER_VALUE' || applicableOn == 'TRANSACTION_VALUE') {
							return applicableOn;
						}
					},
					then: Yup.object().shape({
						paymentThresholdAmount: Yup.string()
							.required('Please define a payment threshold amount value')
							.matches(
								/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
								'Payment Threshold Amount should be between 0.01 to 999999.99'
							),
						paymentInformation: Yup.string(),
						emiType: Yup.string(),
						paymentGroup: Yup.array().of(
							Yup.object().shape({
								// aggregators: Yup.array().required("Please define an aggregator"),
								issuers: Yup.array().of(
									Yup.object().shape({
										emi: Yup.object().shape({
											emiScale: Yup.array().of(
												Yup.object().shape({
													tenurePeriodInMonths: Yup.string()
														.required(
															'Please define a tenure period in months value'
														)
														.matches(
															/^(?=.*[1-9])\d{0,3}$/,
															'Tenure Period in Months should be between 1 to 999'
														),
													interestRate: Yup.string()
														.required(
															'Please define a value for Interest rates'
														)
														.matches(
															/^(?=.*[1-9])\d{0,4}(?:\.\d{0,2})?$/,
															'Tenure Percentage should be between 0.01 to 100.00'
														)
														.test(
															'percent_test',
															'Tenure Percentage should be between 0.01 to 100.00',
															function (value) {
																return parseFloat(value) < 100.01;
															}
														),
													cappedAmount: Yup.string().matches(
														/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
														'Amount Capping should be between 0.01 to 999999.99'
													)
												})
											),
											coolOffPeriod: Yup.object().shape({
												coolOffPeriodType: Yup.string(),
												months: Yup.string().when('coolOffPeriodType', {
													is: 'Months',
													then: Yup.string()
														.required('Please define months value')
														.matches(
															/^(?=.*[1-9])\d{0,3}$/,
															'Months should be between 1 to 999'
														)
												}),
												days: Yup.string().when('coolOffPeriodType', {
													is: 'Days',
													then: Yup.string()
														.required('Please define days value')
														.matches(
															/^(?=.*[1-9])\d{0,3}$/,
															'Days should be between 1 to 999'
														)
												}),
												years: Yup.string().when('coolOffPeriodType', {
													is: 'Years',
													then: Yup.string()
														.required('Please define years value')
														.matches(
															/^(?=.*[1-9])\d{0,3}$/,
															'Years should be between 1 to 999'
														)
												})
											})
										})
									})
								)
							})
						),
						emi: Yup.object().shape({
							emiScale: Yup.array().of(
								Yup.object().shape({
									tenurePeriodInMonths: Yup.string()
										.required('Please define a tenure period in months value')
										.matches(
											/^(?=.*[1-9])\d{0,3}$/,
											'Tenure Period in Months should be between 1 to 999'
										),
									interestRate: Yup.string()
										.required('Please define a value for Interest rates')
										.matches(
											/^(?=.*[1-9])\d{0,4}(?:\.\d{0,2})?$/,
											'Tenure Percentage should be between 0.01 to 100.00'
										)
										.test(
											'percent_test',
											'Tenure Percentage should be between 0.01 to 100.00',
											function (value) {
												return parseFloat(value) < 100.01;
											}
										),
									cappedAmount: Yup.string().matches(
										/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
										'Amount Capping should be between 0.01 to 999999.99'
									)
								})
							),
							coolOffPeriod: Yup.object().shape({
								coolOffPeriodType: Yup.string(),
								months: Yup.string().when('coolOffPeriodType', {
									is: 'Months',
									then: Yup.string()
										.required('Please define months value')
										.matches(
											/^(?=.*[1-9])\d{0,3}$/,
											'Months should be between 1 to 999'
										)
								}),
								days: Yup.string().when('coolOffPeriodType', {
									is: 'Days',
									then: Yup.string()
										.required('Please define days value')
										.matches(
											/^(?=.*[1-9])\d{0,3}$/,
											'Days should be between 1 to 999'
										)
								}),
								years: Yup.string().when('coolOffPeriodType', {
									is: 'Years',
									then: Yup.string()
										.required('Please define years value')
										.matches(
											/^(?=.*[1-9])\d{0,3}$/,
											'Years should be between 1 to 999'
										)
								})
							})
						})
					})
				})
		}),
		benefit: Yup.object().shape({
			benefitType: Yup.string().required('Required: Benefit Type must be selected'),
			priceModificationMethodCode: Yup.string().when('benefitType', {
				is: (benefitType) => {
					if (
						benefitType == 'INSTANT' ||
						benefitType == 'CASHBACK' ||
						benefitType == 'LOYALTY_POINTS' ||
						benefitType == 'FREEBIE' ||
						benefitType == 'EMI'
					) {
						return benefitType;
					}
				},
				then: Yup.string().required('Please define price modification method code value')
			}),

			priceModificationPercent: Yup.string().when('benefitType', {
				//is: benefitType =>  ("INSTANT" || "CASHBACK" || "FREEBIE" ),
				is: (benefitType) => {
					if (
						benefitType == 'INSTANT' ||
						benefitType == 'CASHBACK' ||
						benefitType == 'LOYALTY_POINTS' ||
						benefitType == 'EMI'
					) {
						return benefitType;
					}
				},
				then: Yup.string().when('priceModificationMethodCode', {
					is: (priceModificationMethodCode) => {
						if (
							priceModificationMethodCode == 'PERCENTAGE_OFF' ||
							priceModificationMethodCode == 'APPORTIONED_PERCENTAGE_OFF'
						) {
							return priceModificationMethodCode;
						}
					},
					//  priceModificationMethodCode => ("PERCENTAGE_OFF" || "APPORTIONED_PERCENTAGE_OFF"),
					then: Yup.string()
						.required('Please define price modification percent value')
						.matches(
							/^(?=.*[1-9])\d{0,4}(?:\.\d{0,2})?$/,
							'Price Modification Percent should be between 0.01 to 100.00'
						)
						.test(
							'percent_test',
							'Price Modification Percent should be between 0.01 to 100.00',
							function (value) {
								return parseFloat(value) < 100.01;
							}
						)
				})
			}),
			priceModificationAmount: Yup.string().when('priceModificationMethodCode', {
				// is:  priceModificationMethodCode => ("AMOUNT_OFF" || "APPORTIONED_AMOUNT_OFF"),
				is: (priceModificationMethodCode) => {
					if (
						priceModificationMethodCode == 'AMOUNT_OFF' ||
						priceModificationMethodCode == 'APPORTIONED_AMOUNT_OFF'
					) {
						return priceModificationMethodCode;
					}
				},
				then: Yup.string()
					.required('Please define price modification amount value')
					.matches(
						/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
						'Price Modification Amount should be between 0.01 to 999999.99'
					)
			}),

			//MARKDOWN KIT_MARKDOWN
			newPriceAmount: Yup.string().when('priceModificationMethodCode', {
				// is:  priceModificationMethodCode => ("MARKDOWN" || "KIT_MARKDOWN"),
				is: (priceModificationMethodCode) => {
					if (
						priceModificationMethodCode == 'MARKDOWN' ||
						priceModificationMethodCode == 'KIT_MARKDOWN'
					) {
						return priceModificationMethodCode;
					}
				},
				then: Yup.string()
					.required('Please define new price amount value')
					.matches(
						/^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
						'Price Modification Amount should be between 0.01 to 999999.99'
					)
			}),

			priceModificationQuantity: Yup.string().when('priceModificationMethodCode', {
				is: 'QUANTITY_PRICING',
				then: Yup.string()
					.required('Please define quantity value')
					.matches(
						/^(?=.*[1-9])\d{0,6}$/,
						'Price Modification Quantity should be between 1 to 999999'
					)
			}),

			/*  cashback: Yup.object().shape({
         creditBy: Yup.object().shape({
            date: Yup.date()
           .min(
             new Date(),
             "Date should not be less than the Current Date"
           ), 
           days: Yup.string().matches(/^[0-9]+$/, "Invalid"),
           months: Yup.string().matches(/^[0-9]+$/, "Invalid"),
         }), 
         expireOn: Yup.object().shape({
           date: Yup.date().min(
             new Date(),
             "Date should not be less than the Current Date"
           ),
           days: Yup.string().matches(/^[0-9]+$/, "Invalid"),
           months: Yup.string().matches(/^[0-9]+$/, "Invalid"),
         }),
       }), */

			loyalty: Yup.object().when('priceModificationMethodCode', {
				is: (priceModificationMethodCode) => {
					if (
						priceModificationMethodCode == 'LOYALTY_POINTS_MULTIPLIER' ||
						priceModificationMethodCode == 'APPORTIONED_LOYALTY_MULTIPLIER_ON_POINTS' ||
						priceModificationMethodCode ==
							'APPORTIONED_LOYALTY_MULTIPLIER_ON_UNIT_PRICE'
					) {
						return priceModificationMethodCode;
					}
				},
				then: Yup.object().shape({
					loyaltyMultiplier: Yup.string()
						.required('Please define loyalty multiplier point value')
						.matches(
							/^(?=.*[1-9])\d{0,6}$/,
							'Loyalty Multiplier Point should be between 1 to 999999'
						)
					/*  expireOn: Yup.object().shape({
             date: Yup.date().min(
               new Date(),
               "Date should not be less than the Current Date"
             ),
             days: Yup.string().matches(/^[0-9]+$/, "Invalid"),
             months: Yup.string().matches(/^[0-9]+$/, "Invalid"),
           }), */
				})
				/* otherwise: Yup.object().shape({
            loyaltyAdditionalPoint: Yup.string()
              // .required("Required")
              .matches(
                /^(?=.*[1-9])\d{0,6}(?:\.\d{0,2})?$/,
                "Loyalty Additional Point should be between 0.01 to 999999.99"
              ),
          }), */
			})
		})
	})
});

const EligibilityForm = ({ handleSubmit, ...props }) => {
	//state and props
	const [PKGProductTableData, setPKGProductTableData] = useState({});
	const [merchTableData] = useState([]);
	const location = useLocation();

	let history = useHistory();
	let myparam = {};
	if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
		myparam = props.auditDetails;
	} else {
		myparam = location.state?.params;
	}
	const [initialData, setInitialData] = useState({});
	const [informationModalShow, setInformationModalShow] = useState(false);
	const [informationMsg, setInformationMsg] = useState('');
	const [informationTitle] = useState('Error');
	const myparam2 = location.state?.MTMBProdTableDatafromBasicDetails;

	useEffect(() => {
		if (myparam) {
			if (
				JSON.parse(myparam).promotionPolicy.priceDerivationRuleEligibility
					.packageMultibuyEligibility != undefined
			) {
				const prevData = JSON.parse(myparam).promotionPolicy.priceDerivationRuleEligibility;

				const packageProductData = prevData.packageMultibuyEligibility;

				let appendablePkgProdData = { group: [] };
				//  let appendablePkgProdData1 = { "group1": [] };

				packageProductData.group.map((val) => {
					if (val?.multibuyProduct?.length > 0) val['packageType'] = 'PRODUCT';
					else if (val?.merchandizeHierarchyGroup?.length > 0) val['packageType'] = 'SH';
				});
				appendablePkgProdData.group = JSON.parse(JSON.stringify(packageProductData.group));

				// Modify Settings
				if (myparam2 === false) {
					setPKGProductTableData(appendablePkgProdData);
				}
				//setMerchTableData(appendablePkgProdData1);
				if (myparam2 === null) {
					/* null means brand eligibility select input change */
					prevData.packageMultibuyEligibility.exclusions.excludedItemIDList.excludedItemID =
						[];
					prevData.packageMultibuyEligibility.exclusions.merchandizeHierarchyGroup = [];

					const tempParam2 = {
						group: packageProductData.group.map((val, ind) => {
							return { ...val, merchandizeHierarchyGroup: [], multibuyProduct: {} };
						})
					};
					setPKGProductTableData(tempParam2);
				}
				setInitialData(prevData);
			} else {
				initialValues.packageMultibuyEligibility.exclusions.excludedItemIDList.excludedItemID =
					[];
				initialValues.packageMultibuyEligibility.exclusions.merchandizeHierarchyGroup = [];
				setInitialData(initialValues);
			}
		}

		if (myparam2) {
			setPKGProductTableData(JSON.parse(myparam2));
		}
		/* if (myparam4) {

      setMerchTableData(JSON.parse(myparam4));


      // merchandizeGroupTable = JSON.parse(myparam4);
    }  */
		return () => {
			setPKGProductTableData([]);
			//setMerchTableData([]);
		};
	}, []);

	const validateFormikExcludedData = () => {
		let isNonFormikDataValid = true;
		let tempArray = [];
		PKGProductTableData.group.map((Grp) => {
			if (
				Grp?.multibuyProduct &&
				Grp?.multibuyProduct.length > 0 &&
				Grp?.multibuyProduct != undefined
			) {
				tempArray.push(Grp);
			}
			if (
				Grp?.merchandizeHierarchyGroup &&
				Grp?.merchandizeHierarchyGroup.length > 0 &&
				Grp?.merchandizeHierarchyGroup != undefined
			) {
				tempArray.push(Grp);
			}
		});

		if (tempArray.length == 0) {
			setInformationMsg(`Please enter the product data or selling hierarchy data at Group`);
			setInformationModalShow(true);
			isNonFormikDataValid = false;
		}

		return isNonFormikDataValid;
	};

	//local functions

	const validatePaymentData = (consolidatedData) => {
		let isNonFormikDataValid1 = true;
		const Pay =
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.packageMultibuyEligibility.paymentEligibility;

		if (!(Object.keys(Pay).length === 0) && Pay.constructor === Object) {
			const { applicableOn } =
				consolidatedData.promotionPolicy.priceDerivationRuleEligibility
					.packageMultibuyEligibility?.paymentEligibility || {};
			if (applicableOn) {
				const { paymentInformation } =
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.packageMultibuyEligibility?.paymentEligibility?.payment || {};
				const { paymentGroup } =
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.packageMultibuyEligibility?.paymentEligibility?.payment || {};
				if (paymentInformation == undefined && paymentGroup == undefined) {
					setInformationMsg('Please enter payment details');
					setInformationModalShow(true);
					isNonFormikDataValid1 = false;
				} else if (
					paymentInformation &&
					!(Object.keys(paymentInformation).length === 0) &&
					paymentInformation.constructor === Object
				) {
					const { paymentMode, provider, paymentType, paymentSubType } =
						consolidatedData.promotionPolicy.priceDerivationRuleEligibility
							.packageMultibuyEligibility.paymentEligibility?.payment
							?.paymentInformation || {};

					let Arr = [];
					if (paymentMode) Arr.push(paymentMode);
					if (provider) Arr.push(provider);
					if (paymentType) Arr.push(paymentType);
					if (paymentSubType) Arr.push(paymentSubType);
					//debugger
					if (Arr.length < 1) {
						setInformationMsg('Please enter payment details');
						setInformationModalShow(true);
						isNonFormikDataValid1 = false;
					}
				}
			}
		}
		return isNonFormikDataValid1;
	};

	const submitConsolidatedForm = (values) => {
		const consolidatedData = getConsolidatedData(JSON.parse(JSON.stringify(values)));
		if (validateFormikExcludedData()) {
			if (validatePaymentData(consolidatedData)) {
				// let consolidatedData = JSON.parse(myparam)
				//
				// consolidatedData.promotionPolicy.priceDerivationRuleEligibility = props.values

				// alert('hey'+JSON.stringify(consolidatedData))
				// postApi(apiURL, consolidatedData)
				//     .then((res) => {
				//         alert('sucess ' + JSON.stringify(res))
				//     })
				//     .catch((err) => {
				//         alert('failed ' + JSON.stringify(err))

				//     })

				let businessUnitFunctionID = JSON.parse(
					sessionStorage.getItem('taggedBrandToLoggedInUser')
				).businessUnitGroupFunctionID;
				const newConsolidatedData = omitEmpty(consolidatedData);

				const svPromUrl = JSON.parse(
					sessionStorage.getItem('applctnUrls')
				).savePromotionsUrl;

				let offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));

				if (offerFlow.type == 'modify-offer-flow') {
					let dataToSend = {
						modifyPromotionRequest: {
							user: {
								userID: sessionStorage.getItem('loggedInUserId')
							},
							promotionId: offerFlow.slctdPromotionId,
							inputJson: JSON.stringify(newConsolidatedData).replace(/\"/g, '"')
						}
					};
					props.showLoadingButton(true);
					RestSvc.postData(
						urlTypes.save_promotion_end_points.modifyPromotionUrl,
						dataToSend,
						svPromUrl
					)
						.then((response) => {
							props.showLoadingButton(false);
							var result = response.data.modifyPromotionResponse;
							if (result.businessError) {
								Toaster({ message: result.businessError.errorMessage });
							} else {
								if (
									consolidatedData?.promotionPolicy.priceDerivationRuleEligibility
										?.packageMultibuyEligibility?.paymentEligibility
										?.isBinRangePromotion
								) {
									if (
										JSON.parse(offerFlow.data).promotionPolicy
											.priceDerivationRuleEligibility
											?.packageMultibuyEligibility?.paymentEligibility
											?.isBinRangePromotion
									) {
										Toaster({
											type: 'warn',
											message:
												'Please remember you have modified a BIN promotion, if required revisit your BIN setup.'
										});
									} else {
										Toaster({
											type: 'warn',
											message:
												'Please ensure to upload BINs as the promotion is modified into BIN promotion.'
										});
									}
								}
								const promotionSuccessMsg =
									"Promotion '" +
									result.promotionId +
									"' is modified successfully.";
								Toaster({ type: 'success', message: promotionSuccessMsg });
								props.setDisableItem(false);
								history.push('/MainPage/offerconfigurator');
							}
						})
						.catch((error) => {
							props.showLoadingButton(false);
							console.error(error);
						});
				} else {
					let dataToSend = {
						savePromotionJsonRequest: {
							user: {
								userID: sessionStorage.getItem('loggedInUserId')
							},
							businessUnitGroupFunctionID: businessUnitFunctionID,
							inputJson: JSON.stringify(newConsolidatedData).replace(/\"/g, '"')
							//"inputJson": inputJson
						}
					};
					props.showLoadingButton(true);
					RestSvc.postData(
						urlTypes.save_promotion_end_points.savePromotionJsonUrl,
						dataToSend,
						svPromUrl
					)
						.then((response) => {
							props.showLoadingButton(false);
							var result = response.data.savePromotionJsonResponse;
							if (result.businessError) {
								Toaster({ message: result.businessError.errorMessage });
							} else {
								if (
									consolidatedData?.promotionPolicy
										?.priceDerivationRuleEligibility?.packageMultibuyEligibility
										?.paymentEligibility?.isBinRangePromotion
								) {
									Toaster({
										type: 'warn',
										message:
											'Please ensure to upload BINs for the BIN based promotion created. '
									});
								}
								const promotionSuccessMsg =
									"Promotion '" + result.promotionId + "' is saved successfully.";
								Toaster({ type: 'success', message: promotionSuccessMsg });
								props.setDisableItem(false);

								history.push('/MainPage/offerconfigurator');
							}
						})
						.catch((error) => {
							props.showLoadingButton(false);
							console.error(error);
						});
				}
			}
		}
	};

	const getConsolidatedData = (values) => {
		let consolidatedData = JSON.parse(myparam);

		consolidatedData.promotionPolicy.promotionID = consolidatedData.promotionPolicy.promotionID
			? consolidatedData.promotionPolicy.programID +
			  ':' +
			  consolidatedData.promotionPolicy.promotionID
			: '';
		consolidatedData.promotionPolicy.priceDerivationRuleEligibility = values;
		//consolidatedData.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.group = PKGProductTableData;

		/*  if (!isObjectEmpty(PKGProductTableData)) {

     try {
        const productData = { ...PKGProductTableData }
        let newPriceDerivationRuleEligibility = consolidatedData.promotionPolicy.priceDerivationRuleEligibility
        //debugger
        const newPackageProductEligibility = newPriceDerivationRuleEligibility.packageMultibuyEligibility.group.map((response, j) => {
          //let newArr = { ...response }
          //debugger
            return { ...response, multibuyProduct: productData.group[j].multibuyProduct }      
        })
        //debugger
        newPriceDerivationRuleEligibility.packageMultibuyEligibility = newPackageProductEligibility
        consolidatedData.promotionPolicy.priceDerivationRuleEligibility = newPriceDerivationRuleEligibility
      } catch (err) {
        
      }
    }  */
		//debugger
		if (!isObjectEmpty(PKGProductTableData)) {
			try {
				//const merchData = { ...merchTableData };
				let newPriceDerivationRuleEligibility =
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility;
				//debugger;
				/*  const newGroup = newPriceDerivationRuleEligibility.packageMultibuyEligibility.group.map(
          (response, j) => {
            let newArr = { ...response };
            //debugger;
            response.packageType = undefined;
            if(productData.group[j].multibuyProduct.length>0)
            {  
              response.multibuyProduct = productData.group[j].multibuyProduct
              response.merchandizeHierarchyGroup =  undefined
           
          } else
           if(productData.group[j].merchandizeHierarchyGroup.length>0){
            
            response.multibuyProduct= undefined
            response.merchandizeHierarchyGroup= productData.group[j].merchandizeHierarchyGroup
            
          }
          }
        ) */
				// const newGroup = newPriceDerivationRuleEligibility.packageMultibuyEligibility.group
				let table = JSON.parse(JSON.stringify(PKGProductTableData));

				// array.forEach(function(v){ delete v.bad });
				let tab1 = { group: [] };
				table.group.forEach(function (g, i) {
					//g.packageType = undefined;
					delete g.packageType;
					if (
						(g?.merchandizeHierarchyGroup && g?.merchandizeHierarchyGroup.length > 0) ||
						(g?.multibuyProduct && g?.multibuyProduct.length > 0)
					) {
						g['trigger'] =
							newPriceDerivationRuleEligibility.packageMultibuyEligibility.group[
								i
							].trigger;
						g['getReward'] =
							newPriceDerivationRuleEligibility.packageMultibuyEligibility.group[
								i
							].getReward;
						if (
							newPriceDerivationRuleEligibility.packageMultibuyEligibility.group[i]
								.memberGroupRewardQuantity
						)
							g['memberGroupRewardQuantity'] =
								newPriceDerivationRuleEligibility.packageMultibuyEligibility.group[
									i
								].memberGroupRewardQuantity;
						else g['memberGroupRewardQuantity'] = undefined;
						if (
							newPriceDerivationRuleEligibility.packageMultibuyEligibility.group[i]
								.memberGroupThresholdQuantity
						)
							g['memberGroupThresholdQuantity'] =
								newPriceDerivationRuleEligibility.packageMultibuyEligibility.group[
									i
								].memberGroupThresholdQuantity;
						else g['memberGroupThresholdQuantity'] = undefined;
						if (
							newPriceDerivationRuleEligibility.packageMultibuyEligibility.group[i]
								.memberGroupThresholdAmount
						)
							g['memberGroupThresholdAmount'] =
								newPriceDerivationRuleEligibility.packageMultibuyEligibility.group[
									i
								].memberGroupThresholdAmount;
						else g['memberGroupThresholdAmount'] = undefined;
						tab1.group.push(g);
					}

					// return g;
				});

				// let concatedData = newGroup //.concat(newGroup1)

				newPriceDerivationRuleEligibility.packageMultibuyEligibility.group = tab1.group;
				//newPriceDerivationRuleEligibility.packageMultibuyEligibility.group1 = undefined;
				consolidatedData.promotionPolicy.priceDerivationRuleEligibility =
					newPriceDerivationRuleEligibility;
			} catch (err) {
				console.error('Error in table data consolidation ' + JSON.stringify(err));
			}
		}

		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.packageMultibuyEligibility.coupons.coupon != undefined
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.coupons.coupon =
				trimDate(
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.packageMultibuyEligibility.coupons.coupon,
					'expireOn',
					'couponExpiry'
				);
		}

		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.packageMultibuyEligibility.benefit.cashback != undefined
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.benefit.cashback =
				trimDate(
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.packageMultibuyEligibility.benefit.cashback,
					'creditBy',
					'cashbackCreditData'
				);
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.benefit.cashback =
				trimDate(
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.packageMultibuyEligibility.benefit.cashback,
					'expireOn',
					'cashbackExpireData'
				);
		}
		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.packageMultibuyEligibility.benefit.loyalty != undefined
		) {
			// consolidatedData.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.benefit.loyalty =
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.benefit.loyalty =
				trimDate(
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.packageMultibuyEligibility.benefit.loyalty,
					'expireOn',
					'loyaltyExpireData'
				);
		}

		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.packageMultibuyEligibility.paymentEligibility?.payment?.emi != undefined
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.paymentEligibility.payment.emi =
				trimDate(
					consolidatedData.promotionPolicy.priceDerivationRuleEligibility
						.packageMultibuyEligibility.paymentEligibility.payment.emi,
					'coolOffPeriod',
					'coolOffPeriodType'
				);
		}
		if (
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.packageMultibuyEligibility.paymentEligibility?.payment?.paymentGroup != undefined
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.paymentEligibility.payment.paymentGroup.map(
				(item) => {
					item.issuers &&
						item.issuers.map((item1) => {
							if (item1.cashbackInformation) {
								item1.cashbackInformation = trimDate(
									item1.cashbackInformation,
									'creditBy',
									'cashbackCreditData'
								);
								item1.cashbackInformation = trimDate(
									item1.cashbackInformation,
									'expireOn',
									'cashbackExpireData'
								);
							}
							if (item1.emi) {
								item1.emi = trimDate(
									item1.emi,
									'coolOffPeriod',
									'coolOffPeriodType'
								);
							}
						});
				}
			);
		}
		if (
			!consolidatedData.promotionPolicy.priceDerivationRuleEligibility
				.packageMultibuyEligibility.paymentEligibility?.applicableOn
		) {
			consolidatedData.promotionPolicy.priceDerivationRuleEligibility.packageMultibuyEligibility.paymentEligibility.allowAggAndIssuer =
				undefined;
		}

		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.blackOutPeriod[0]={};
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.includedDaysOfTheWeek.includedDay=[];
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDaysOfTheWeek.excludedDaysOfTheWeek.excludedDay=[];
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.includedDatesOfTheMonth.includedDate=[];
		// consolidatedData.promotionPolicy.promotionApplicableEligibility.happyHoursApplicableEligibility.eligibleDatesOfTheMonth.excludedDatesOfTheMonth.excludedDate=[];
		// if(consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity&&consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity.date==""){
		//     consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity.date= undefined
		// }
		// if(!consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.customerActivationRequired){
		//     consolidatedData.promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity= undefined
		// }
		consolidatedData = JSON.parse(
			JSON.stringify(consolidatedData, function (key, value) {
				return key !== 'selectedCoupon' ? value : undefined;
			})
		);

		return consolidatedData;
	};

	const gotoCreateOffer = async (values) => {
		// const consolidatedData = getConsolidatedData(values);
		const dataFromBasicDetails = JSON.parse(myparam);
		//const tableData = JSON.parse(myparam2);
		dataFromBasicDetails.promotionPolicy.priceDerivationRuleEligibility = values;
		const consolidatedData = dataFromBasicDetails;
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditView) {
			await history.push('/MainPage/auditMaintenance/offerBasicDetails', {
				params: JSON.stringify(consolidatedData),
				MTMBProdTableData: JSON.stringify(PKGProductTableData),
				SellingHierarchyTableData: JSON.stringify(merchTableData)
			});
		} else {
			await history.push('/MainPage/offerconfigurator/offerBasicDetails', {
				params: JSON.stringify(consolidatedData),
				MTMBProdTableData: JSON.stringify(PKGProductTableData),
				SellingHierarchyTableData: JSON.stringify(merchTableData)
			});
		}
	};

	const onPKGProductTableChange = (val) => {
		setPKGProductTableData(val);
	};

	const onMerchTableDataChange = (val) => {
		// setMerchTableData(val);
		setPKGProductTableData(val);
	};

	const linkText = {
		elibilitySettings: [
			'Purchase and Reward Conditions',
			'Exclusions Settings',
			'Payment Conditions',
			'Coupon Conditions',
			'Benefit Settings'
		]
	};

	const [confirmationModalShow, setCnfrmatnModalShow] = useState(false);
	const showCancelConfirmation = () => {
		setCnfrmatnModalShow(true);
	};

	const confirmationModalClose = () => {
		setCnfrmatnModalShow(false);
	};

	const proceedToOperation = () => {
		props.setDisableItem(false);
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditView) {
			history.push('/MainPage/auditMaintenance');
		} else history.push('/MainPage/offerconfigurator');
	};

	const offerFlow = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));

	const onMouseLeaveHandler = () => {
		props.setMousePlace('');
	};

	return (
		<div
			className={
				getOfferFlowType() == 'View Offer'
					? 'main-container create-offer-container viewOfferContainer'
					: 'main-container create-offer-container'
			}
		>
			<div className="header-flex">
				<div className="back-div" onClick={showCancelConfirmation}>
					<div className="pr-2">
						<img src={chevronDown} alt="" />
					</div>
					<div>Back</div>
				</div>

				<div className="header-text">{getOfferFlowType()}</div>
			</div>
			<div className="flex-mainContent">
				<div className="left-navigation br-right">
					<div className="text-header">Package</div>
					{linkText.elibilitySettings.map((val, ind) => (
						<div key={ind} className="text-content">
							<Link
								//activeClass="activePromotionSection"
								activeClass=""
								className={
									'scrollTo' + val.replace(/\s/g, '') == props.curntMousePlace
										? 'activePromotionSection'
										: ''
								}
								to={'scrollTo' + val.replace(/\s/g, '')}
								spy={true}
								smooth={true}
								duration={250}
								containerId="containerElement"
							>
								{val}
							</Link>
						</div>
					))}
				</div>
				<Element
					id="containerElement"
					className="right-content"
					onMouseLeave={onMouseLeaveHandler}
				>
					<>
						{!isObjectEmpty(initialData) && (
							<Formik
								initialValues={initialData}
								onSubmit={async (values) => {
									await new Promise((resolve) => setTimeout(resolve, 500));
									await submitConsolidatedForm(values);
								}}
								validateOnChange={
									false
								} /* package for PaymentConditionForm browser hang prevent */
								validationSchema={SignupSchema}
							>
								{(props) => {
									const { isSubmitting, handleSubmit } = props;
									return (
										<form onSubmit={handleSubmit}>
											<div className="item-title">
												{localConst.eligibilitySettings}
											</div>
											<EligibilityComponentPP
												onPKGProductTableChange={onPKGProductTableChange}
												dataPKGtable={PKGProductTableData}
												merdata={merchTableData}
												onMerchTableDataChange={onMerchTableDataChange}
												fullValue={myparam}
												submitCnt={props.submitCount}
											/>
											<div className="footer">
												<button
													type="button"
													className="goTo-basicDetails mr-3"
													onClick={async () => {
														await gotoCreateOffer(props.values);
													}}
												>
													Go To Basic Details
												</button>
												{offerFlow.type == 'view-offer-flow' ? null : (
													<button
														type="submit"
														className="submit-class"
														disabled={isSubmitting}
													>
														Submit
													</button>
												)}
											</div>
										</form>
									);
								}}
							</Formik>
						)}
					</>
				</Element>
			</div>
			{confirmationModalShow ? (
				<ConfirmationModal
					show={confirmationModalShow}
					onHide={confirmationModalClose}
					onProceed={proceedToOperation}
					confirmmsg={'Are you sure you want to navigate away?'}
				/>
			) : null}
			{informationModalShow ? (
				<InformationModal
					show={informationModalShow}
					onHide={() => {
						setInformationModalShow(false);
					}}
					infoMsg={informationMsg}
					infoTitle={informationTitle}
				/>
			) : null}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		curntMousePlace: state.currentMousePlace
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setMousePlace: (val) => dispatch({ type: actionTypes.MOUSE_PLACE, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityForm);
