/* Reference: https://fkhadra.github.io/react-toastify/introduction/ */
import React from 'react';
import PropTypes from 'prop-types';

import toaster from 'toasted-notes';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import './toaster.scss';

const toasterType = {
	info: 'bg-info',
	success: 'bg-success',
	warn: 'bg-warning',
	error: 'toasterRed'
};

const Toaster = ({
	className = '',
	message = '',
	position = 'top',
	duration = 10000,
	type = 'error',
	closeAll = false
}) => {
	if (!message) {
		return null;
	}
	closeAll && toaster.closeAll();
	const toasterTypeClass = toasterType[type] || toasterType.error;
	return toaster.notify(
		({ onClose }) => (
			<div className={`Toaster-message-wrapper ${className}`}>
				<div>
					<div id="1" className={`Toaster__alert ${toasterTypeClass}`}>
						<div className="Toaster__alert_text">{message}</div>
						<button
							className="Toaster__alert_close"
							type="button"
							aria-label="Close"
							onClick={onClose}
						>
							<span aria-hidden="true">×</span>
						</button>
					</div>
				</div>
			</div>
		),
		{
			position,
			duration
		}
	);
};

Toaster.propTypes = {
	message: PropTypes.string,
	className: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
	duration: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.bool
	]) /* This notification will not automatically close  */,
	position: PropTypes.oneOf([
		'top-left',
		'top',
		'top-right',
		'bottom-left',
		'bottom',
		'bottom-right'
	]),
	type: PropTypes.oneOf(['info', 'warn', 'error']),
	closeAll: PropTypes.bool
};

const Toasty = () => {
	return (
		<ToastContainer
			position="top-center"
			autoClose={10000}
			hideProgressBar={false}
			// newestOnTop={false}
			closeOnClick
			// rtl={false}
			// pauseOnFocusLoss
			draggable
			pauseOnHover={false}
			theme="colored"
			// transition="Bounce"
		/>
	);
};

/**
 * @description use this function to generate the toast
 * @example: toastNotify({message: 'this from fun' position:'top-right', type:'info'}); toastNotify({message:'this from fun'})
 * @param {string} msg
 * @param {object} {position= top-center type=info/success/warn/error/default/'', autoClose= number}
 * @returns
 */

const toastNotify = (
	{
		message = 'This is default toast message',
		type = 'default', //info/success/warn/error
		position = 'top-center',
		autoClose = 10000
	} = {}
) => {
	const toastOption = {
		position,
		autoClose
	};

	if (type === 'default') {
		return toast(message, toastOption);
	}
	return toast[type](message, toastOption);
};

export { Toasty, toastNotify, toast };
export default Toaster;
