import React, { useEffect, useState } from 'react';
import * as RestSvc from '../../../../libraries/offer-services';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actionTypes from '../../../../store/actions';
import Toaster from '../../../../Components/Toaster/Toaster';

const AuditChannel = ({ ...props }) => {
	const [storeIdNameObj, setStrIdNameObj] = useState({});
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	const getAdded = (products, products1) => {
		var added = [];
		for (var i = 0; i < products.length; i++) {
			var id = products[i];
			var flag = false;
			for (var j = 0; j < products1.length; j++) {
				var id2 = products1[j];
				if (id === id2) {
					flag = true;
				}
			}
			if (!flag) {
				added.push(products[i]);
			}
		}
		return added;
	};

	const getStoreName = (stores) => {
		let dataToSend = {
			getBusinessUnitIdNamesRequest: {
				businessUnitIds: stores
			}
		};

		RestSvc.postData('/employee/getBusinessUnitIdNames', dataToSend)
			.then((response) => {
				var result = response.data.getBusinessUnitIdNamesResponse;
				if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });
				} else {
					setStrIdNameObj(result.businessUnitIdNameMap);
				}
			})
			.catch((error) => {
				props.showLoadingButton(false);
				console.error(error);
			});
	};

	const getChannelTree = () => {
		let dataToSend = {
			fetchEnterpriseHierarchyRequest: { user: { userID: props.userFullName.operatorID } }
		};
		RestSvc.postData('/employee/getBelowEnterpriseHierarchy', dataToSend)
			.then((response) => {
				var result = response.data.FetchEnterpriseHierarchyResponse;
				if (result.businessError) {
					Toaster({ message: result.businessError.errorMessage });
				} else {
					setChannelTree(result.organizationHierarchy);
				}
			})
			.catch((error) => {
				props.showLoadingButton(false);
				console.error(error);
			});
	};
	const [storesAdded, setStoresAdded] = useState([]);
	const [storesRemoved, setStoresRemoved] = useState([]);
	const [showAllAdded, setShowAllAdded] = useState(false);
	const [showAllRemoved, setShowAllRemoved] = useState(false);
	const [channelTree, setChannelTree] = useState([]);

	useEffect(() => {
		if (isAuditFlw) {
			getChannelTree();
		}
		return () => {};
	}, []);

	useEffect(() => {
		if (channelTree.length > 0) {
			let OldChannels = props.oldJson;
			let NewChannels = props.newJson.promotionPolicy.promotionApplicableEligibility
				?.channelEligibility?.channel
				? props.newJson.promotionPolicy.promotionApplicableEligibility?.channelEligibility
						?.channel
				: [];
			let oldStores = OldChannels.map((item) => {
				return item.applicableIds.id.map((a) => {
					return a;
				});
			}).flat();
			let newStores = NewChannels.map((item) => {
				return item.applicableIds.id.map((a) => {
					return a;
				});
			}).flat();

			let oldStoresChildren = [],
				newStoresChildren = [];
			// setTimeout(() => {

			// document.getElementById("getLeafNode").contentWindow[
			//     "treeArray"
			// ] = channelTree;
			// document.getElementById("getLeafNode").contentWindow[
			//     "prevSelectedData"
			// ] = oldStores;
			oldStoresChildren = document
				.getElementById('getLeaf')
				.contentWindow?.getAllChildren(channelTree, oldStores);
			newStoresChildren = document
				.getElementById('getLeaf')
				.contentWindow?.getAllChildren(channelTree, newStores);

			let tempRemoved = getAdded(oldStoresChildren, newStoresChildren);
			let tempAdded = getAdded(newStoresChildren, oldStoresChildren);

			setStoresAdded(tempAdded);
			setStoresRemoved(tempRemoved);
			// let tempstores = tempAdded.concat(tempRemoved)
			let tempstores = oldStoresChildren.concat(newStoresChildren);
			if (tempstores.length > 0) getStoreName(tempstores);
			// }, 5000);
		}
	}, [channelTree]);

	return (
		<>
			<div
				className={
					storesAdded.length == 0 && storesRemoved.length == 0
						? 'd-none'
						: 'box-blackout-period lightBlue container pl-2'
				}
			>
				{storesAdded.length > 0 ? (
					<>
						<div className="issuerOuterBox mb-3">
							<div className="pl-2">
								<label className="font-weight-bold">
									Added ({storesAdded.length} store/s)
								</label>
							</div>
							<div className="row w-auto">
								{storesAdded.map((item, i) => {
									if (i < 10 || showAllAdded)
										return (
											<span
												key={`channel-${i}`}
												className="channelNodeAudit mb-2"
											>
												{storeIdNameObj[item]}
											</span>
										);
								})}
								{showAllAdded ? (
									<div className="p-1">
										<span
											className="channelLink"
											onClick={() => {
												setShowAllAdded(false);
											}}
										>
											View Less
										</span>
									</div>
								) : storesAdded.length > 10 ? (
									<div className="p-1">
										<span
											className="channelLink"
											onClick={() => {
												setShowAllAdded(true);
											}}
										>
											View All
										</span>
									</div>
								) : null}
							</div>
						</div>
					</>
				) : undefined}
				{storesRemoved.length > 0 ? (
					<>
						<div className="issuerOuterBox mb-3">
							<div className="pl-2">
								<label className="font-weight-bold">
									Removed ({storesRemoved.length} store/s)
								</label>
							</div>
							<div className="row w-auto">
								{storesRemoved.map((item, i) => {
									if (i < 10 || showAllRemoved)
										return (
											<span
												key={`store-${i}`}
												className="channelNodeAudit text-danger  mb-2"
											>
												{storeIdNameObj[item]}
											</span>
										);
								})}
								{showAllRemoved ? (
									<div className="p-1">
										<span
											className="channelLink"
											onClick={() => {
												setShowAllRemoved(false);
											}}
										>
											View Less
										</span>
									</div>
								) : storesRemoved.length > 10 ? (
									<div className="p-1">
										<span
											className="channelLink"
											onClick={() => {
												setShowAllRemoved(true);
											}}
										>
											View All
										</span>
									</div>
								) : null}
							</div>
						</div>
					</>
				) : undefined}
			</div>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuditChannel));
