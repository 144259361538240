import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import greenTick from '../assets/images/greenTick.png';
import ErrorBullet from '../assets/images/ErrorBullet.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './groupInformationModal.scss';
import Input from '../Components/Input/Input';
import { useFirstRender } from '../Components/ToggleSwitch/ToggleSwitch';
import _ from 'lodash';
import Toaster from '../Components/Toaster/Toaster';

const GroupInformationModal = (props) => {
	const [errorList, setErrorList] = useState([]);
	const [filterErrorList, setFilterErrorList] = useState([]);
	const [filterVal, setFilterVal] = useState('');
	const isFirstRender = useFirstRender();

	const debouncer = useCallback(
		_.debounce((filterVal) => {
			let filterErrorList = [];
			if (Array.isArray(errorList)) {
				filterErrorList = errorList.filter((msg) =>
					msg.toLocaleLowerCase().includes(filterVal)
				);
			} else {
				filterErrorList = errorList;
			}

			setFilterErrorList(filterErrorList);
		}, 300),
		[errorList]
	);

	useEffect(() => {
		const { errorMsg, splitErrorMsg, addFilter } = props;
		let msg = [];

		if (errorMsg?.length > 0) {
			try {
				if (splitErrorMsg || addFilter) {
					msg =
						typeof errorMsg === 'string'
							? errorMsg.split('|').filter((v) => v)
							: errorMsg;
				} else {
					msg = errorMsg;
				}
			} catch (e) {
				msg = errorMsg;
			}
		}
		setErrorList(msg);
		setFilterErrorList(msg);
	}, [props]);

	useEffect(() => {
		if (isFirstRender) return;
		debouncer(filterVal?.toLocaleLowerCase() || '');
	}, [filterVal, errorList, isFirstRender, debouncer]);

	const filterChangeHandler = (query) => {
		setFilterVal(query || '');
	};

	const renderInfo = (infoMsg) => {
		if (!infoMsg) {
			return null;
		}
		return (
			<div className="d-flex">
				<img src={greenTick} alt="" className="h-30p" />
				<span className="roboto-b-16 pt-1 pl-1">{infoMsg}</span>
			</div>
		);
	};

	const renderErrorMsg = () => {
		const { addFilter } = props;

		let msg = addFilter ? filterErrorList : errorList;
		let msgLength = msg?.length;

		if (msgLength > 0) {
			let msgElm = '';
			if (Array.isArray(msg)) {
				msgElm = msg.map((error, i) => {
					let errorMsg = '';
					try {
						const errorSplit = error.split('#').filter((v) => v);
						errorMsg = errorSplit.reduce((pre, cur, i) => {
							let newRecuder = [...pre];
							pre.length >= 1 && newRecuder.push(<br key={i} />);
							newRecuder.push(cur);
							return newRecuder;
						}, []);
					} catch (e) {
						errorMsg = error;
					}

					return (
						<div key={i} className="d-flex roboto-r-14 bg-errgrp ">
							<img src={ErrorBullet} alt="error" />
							<span className="msg">{errorMsg}</span>
						</div>
					);
				});
			} else {
				msgLength = 1;
				msgElm = (
					<div key={0} className="d-flex roboto-r-14 bg-errgrp ">
						<img src={ErrorBullet} alt="error" />
						<span className="msg">{msg}</span>
					</div>
				);
			}

			return (
				<>
					<div className="d-flex roboto-b-14 pl-1 pt-2 pb-2">
						{msgLength} issues found.
					</div>
					<div className="errorGrpBox pl-1">{msgElm}</div>
				</>
			);
		}

		return null;
	};

	const renderCopyToClipboard = () => {
		const { addFilter } = props;
		let msg = addFilter ? filterErrorList : errorList;
		let msgLength = msg?.length;

		if (msgLength > 0) {
			let msgFormated = msg;
			if (Array.isArray(msg)) {
				msgFormated = msg.map((error, i) => {
					let errorMsg = '';
					try {
						errorMsg = error
							.split('#')
							.filter((v) => v)
							.join('\n');
					} catch (e) {
						errorMsg = error;
					}
					return errorMsg;
				});
				msgFormated = msgFormated.join('\n\n');
			}

			return (
				<CopyToClipboard
					text={msgFormated}
					onCopy={() => {
						Toaster({ type: 'info', message: 'Information copied to clipboard!' });
					}}
				>
					<button className="cancel-btn1 roboto-b-16">Copy to Clipboard</button>
				</CopyToClipboard>
			);
		}
		return null;
	};

	const { show, onHide, infoTitle, infoMsg, errorMsg, addFilter } = props;
	return (
		<div>
			<Modal
				dialogClassName="group-information-modal"
				show={show}
				onHide={onHide}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				{/*  <Modal.Header closeButton > */}

				<Modal.Header>
					<Modal.Title id="contained-modal-title-vcenter">{infoTitle}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{renderInfo(infoMsg)}
					{infoMsg && errorMsg?.length > 0 && <br />}
					{addFilter && errorMsg?.length > 0 && (
						<>
							<div className="filter-box">
								<Input
									elementType="text"
									changed={(e) => filterChangeHandler(e.target?.value)}
									elementConfig={{
										type: 'text',
										placeholder: 'Filter by text',
										labelname: 'Filter by text'
									}}
									value={filterVal}
									shouldValidate={{
										required: false
									}}
								/>
								<button
									className={`clrBtn ${!filterVal?.length ? 'd-none' : ''}`}
									onClick={() => setFilterVal('')}
								>
									X
								</button>
							</div>
							{!filterErrorList?.length && (
								<div className="d-flex roboto-b-14 pl-1 pt-2 pb-2">
									No match found
								</div>
							)}
						</>
					)}
					{renderErrorMsg()}
				</Modal.Body>
				<Modal.Footer>
					{renderCopyToClipboard()}
					<button className="btnNxtPrev ml-2 roboto-b-16" onClick={onHide}>
						Ok
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

GroupInformationModal.propTypes = {
	show: PropTypes.bool,
	onHide: PropTypes.func,
	infoTitle: PropTypes.string,
	infoMsg: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
	errorMsg: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	splitErrorMsg: PropTypes.bool,
	addFilter: PropTypes.bool
};

export default GroupInformationModal;
