/* Reference: https://react-bootstrap-table.github.io/react-bootstrap-table2/ */
import React from 'react';
import PropTypes from 'prop-types';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from '../../utils/reactBootstrapTable2Toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory from 'react-bootstrap-table2-editor';
import './reactTable.scss';
import appLabels from '../../utils/appLabels';

const { SearchBar, ClearSearchButton } = Search;

const _selectRowOptions = {
	mode: 'checkbox',
	clickToSelect: false
	// selected: [],
	// onSelect: (row, isSelect) => {},
	// onSelectAll: (isSelect, rows) => {},
	// headerColumnStyle: {},
	// selectColumnStyle: {},
	// hideSelectAll: true,
	// selectionHeaderRenderer: () => 'X',
	// selectionRenderer: ({ mode, ...rest }) => {}
};

const _cellEditOptions = {
	mode: 'click',
	blurToSave: true
	// afterSaveCell: (oldValue, newValue, row, column) => { console.log('After Saving Cell!!'); }
};

const _expandRow = {
	showExpandColumn: true,
	expandByColumnOnly: true
};

const sizePerPageRenderer = ({ options, currSizePerPage, onSizePerPageChange }) => (
	<div className="btn-group" role="group">
		{options.map((option) => {
			const isSelect = currSizePerPage === `${option.page}`;
			return (
				<button
					key={option.text}
					type="button"
					onClick={() => onSizePerPageChange(option.page)}
					className={`btn ${isSelect ? 'btn-primary' : 'btn-secondary'}`}
				>
					{option.text}
				</button>
			);
		})}
	</div>
);

const pageButtonRenderer = ({ page, active, disable, title, onPageChange }) => {
	const handleClick = (e) => {
		e.preventDefault();
		onPageChange(page);
	};
	const btnClass = active ? 'btn-primary' : 'btn-outline-primary';
	return (
		<li key={page} className="page-item">
			<button className={`page-btn btn ${btnClass}`} onClick={handleClick}>
				{page}
			</button>
		</li>
	);
};

const setPaginationOptions = ({ sizePerPageList, totalSize }) => {
	const minSizePerPage = sizePerPageList?.[0]?.value || 0;
	const isHideSizePerPage = minSizePerPage > totalSize;
	return {
		sizePerPageRenderer,
		sizePerPageList,
		pageButtonRenderer,
		hideSizePerPage: isHideSizePerPage,
		hidePageListOnlyOnePage: false
	};
};

const ReactTable = ({
	columns = [],
	data = [],
	keyField = 'id',
	searchBarEnable = false,
	selectRowOptions = null,
	paginationEnable = false,
	sizePerPageList = [
		{ text: 10, value: 10 },
		{ text: 25, value: 25 },
		{ text: 50, value: 50 },
		{ text: 'All', value: data.length }
	],
	noDataInfo = appLabels.table.noDataInfo,
	customClasses = '',
	cellEditOptions,
	searchOptions = {},
	rowClasses,
	expandRow,
	children,
	tableCondensed = true
}) => {
	const extraOptions = {};

	if (selectRowOptions) {
		extraOptions.selectRow = {
			..._selectRowOptions,
			...selectRowOptions
		};
	}

	if (cellEditOptions) {
		extraOptions.cellEdit = cellEditFactory({
			..._cellEditOptions,
			...cellEditOptions
		});
	}

	if (typeof rowClasses === 'function') {
		extraOptions.rowClasses = rowClasses;
	}

	if (expandRow) {
		extraOptions.expandRow = {
			..._expandRow,
			...expandRow
		};
	}

	if (paginationEnable) {
		extraOptions.pagination = paginationFactory(
			setPaginationOptions({
				sizePerPageList,
				totalSize: data?.length || 0
			})
		);
	}

	return (
		<div className={`react-table ${customClasses}`}>
			<ToolkitProvider bootstrap4 keyField={keyField} columns={columns} data={data} search>
				{(props) => (
					<>
						{searchBarEnable && (
							<>
								<section className="search-section">
									<strong className="search-hearing">
										{searchOptions.searchLabel || 'Search'} :
									</strong>
									<SearchBar {...props.searchProps} {...searchOptions} />
									<ClearSearchButton
										className="btn-outline-primary"
										{...props.searchProps}
									/>
								</section>
								<hr className="search-separator" />
							</>
						)}

						<BootstrapTable
							{...props.baseProps}
							noDataIndication={() => (
								<div className="no-data-info">{noDataInfo}</div>
							)}
							filter={filterFactory()}
							condensed={tableCondensed}
							hover
							{...extraOptions}
						/>
						{children}
					</>
				)}
			</ToolkitProvider>
		</div>
	);
};

ReactTable.propTypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			dataField: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired
		})
	).isRequired,
	data: PropTypes.arrayOf(PropTypes.shape({})),
	keyField: PropTypes.string.isRequired,
	searchBarEnable: PropTypes.bool,
	paginationEnable: PropTypes.bool,
	noDataInfo: PropTypes.string,
	customClasses: PropTypes.string,
	sizePerPageList: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		})
	),
	cellEditOptions: PropTypes.shape({
		afterSaveCell: PropTypes.func.isRequired
	}),
	searchOptions: PropTypes.shape({
		placeholder: PropTypes.string
	}),
	rowClasses: PropTypes.func,
	expandRow: PropTypes.shape({
		renderer: PropTypes.func.isRequired
	}),
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	tableCondensed: PropTypes.bool,
	selectRowOptions: PropTypes.shape({
		mode: PropTypes.string,
		onSelect: PropTypes.func,
		onSelectAll: PropTypes.func,
		selected: PropTypes.arrayOf(PropTypes.string)
	})
};
export default ReactTable;

/**
 * @description use this function to add filter input first consistency everywhere
 * @param {*} column
 * @param {*} colIndex
 * @param {*} param2
 * @returns
 */
export const headerFormatter = (column, colIndex, { sortElement, filterElement }) => {
	let text = column.text;
	if (text) {
		return (
			<div className="header-formatter">
				<div className="filter-wrapper">{filterElement}</div>
				<div className="text-wrapper">
					{text}
					{sortElement}
				</div>
			</div>
		);
	}
};

export { textFilter, selectFilter, filterFactory };
