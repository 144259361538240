import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CopyToClipboard from 'react-copy-to-clipboard';

import './couponPattern.scss';

import plusCircle from '../../assets/images/plus-circle.png';
import * as actionTypes from '../../store/actions';
import appLabels from '../../utils/appLabels';
import * as urlTypes from '../../libraries/offer-service-urls';
import * as RestSvc from '../../libraries/offer-services';
import Toaster from '../../Components/Toaster/Toaster';
import couponEdit from '../../assets/images/couponEdit.png';
import ReactTable from '../../Components/ReactTable/ReactTable';
import Delete from '../../assets/images/deleteIcon.png';
import ModalPopup from '../../Components/ModalPopup/ModalPopup';
import PatternForm from './PatternForm';
import PatternList from './PatternList';

const {
	common: commonLabels,
	table: tableLabels,
	page: { couponPattern: pageLabels }
} = appLabels;

const modifyDataToSend = ({ patterns = [], businessUnit }) => {
	const dataToSend = [];

	patterns.forEach(({ separator, pattern }, ind) => {
		const data = {
			idBsngpFnc: businessUnit,
			separator: separator?.value || separator || '',
			pattern: pattern.trim()
		};
		let index = dataToSend.findIndex((o) => o.separator === separator);

		if (index != -1) {
			const _pattern = dataToSend[index].pattern;
			dataToSend[index].pattern = `${_pattern},${pattern}`;
		} else {
			dataToSend.push(data);
		}
	});

	return dataToSend;
};

const CopyToClipboardBtn = ({ text }) => {
	return (
		<CopyToClipboard
			text={text}
			onCopy={() => {
				Toaster({ message: commonLabels.copyToClipboardSussesMsg });
			}}
		>
			<button className="cancel-btn1 roboto-b-16">{commonLabels.copyToClipboard}</button>
		</CopyToClipboard>
	);
};

const StatusBarSection = ({
	couponPatterns,
	selectedPatterns,
	selectedPatternHandler,
	bulkDeleteHandler
}) => (
	<div className="row d-flex pb-2">
		<div className="col-sm-4">
			<p className="my-2 roboto-r-14 grey">
				{couponPatterns.length} {pageLabels.pattern}
				{couponPatterns.length > 1 ? 's' : ''} {pageLabels.found}.
				{selectedPatterns.length != 0 && (
					<span className="">
						<button className="selected-patterns-btn" onClick={selectedPatternHandler}>
							{selectedPatterns.length} {pageLabels.patternsSelected}
						</button>
					</span>
				)}
			</p>
		</div>
		<div className="col-sm-1 d-flex justify-content-end">
			<div
				className={`pointer pt-3 ${selectedPatterns.length ? '' : 'disabled'}`}
				onClick={bulkDeleteHandler}
			>
				<img alt="" src={Delete} title="Bulk Delete" className="imageDelete highlight" />
			</div>
		</div>
	</div>
);

const CouponPattern = (props) => {
	useEffect(() => {
		return () => {
			props?.onCloseHandler(false);
		};
	}, []);

	const viewPatternRefBtn = useRef();
	const formResetRefBtn = useRef();

	const [pageView, setPageView] = useState('create'); // view/ create
	const [couponPatterns, setCouponPatterns] = useState([]);
	const [infoModal, setInfoModal] = useState({});
	const [selectAll, setSelectAll] = useState(false);
	const [selectedPatterns, setSelectedPatterns] = useState([]);
	const [infoCopyList, setInfoCopyList] = useState('');

	const saveResponseHandler = (data) => {
		const { businessCouponPatternList = [], duplicatePatternList = [] } = data || {};
		let _ind = 0;
		const successList = businessCouponPatternList?.reduce((pre, cur) => {
			const itemArray = cur.pattern.split(',').map((value, i) => {
				_ind++;
				return {
					ind: _ind,
					status: 'Saved',
					value
				};
			});
			const temp = [...pre, ...itemArray];
			return temp;
		}, []);

		const duplicateList = duplicatePatternList.map((value) => {
			_ind++;
			return {
				ind: _ind,
				status: 'Duplicate',
				value
			};
		});

		const _result = [...successList, ...duplicateList];
		return _result;
	};

	const saveCouponPatterns = (data, patterns) => {
		props.showLoadingButton(true);
		const dataToSend = {
			List: [...data]
		};

		RestSvc.postData(urlTypes.generateCoupon.saveCouponPatterns, dataToSend)
			.then((response) => {
				props.showLoadingButton(false);
				var result = response.data?.businessUnitCouponPatternResponse || {};

				if (result?.businessError) {
					Toaster({ message: result.businessError.errorMessage });
					return;
				}

				let statusList = saveResponseHandler(result);

				/* todo api should return only the status of payload data,
				  right now it return all the saved pattern which increase the data load
				  remove the statusList.filter when implemented by backend api */
				statusList = statusList.filter((v, i) => {
					const isInd = patterns.findIndex(({ pattern }) => pattern === v.value.trim());
					return isInd !== -1;
				});

				let copyList = [];
				statusList.forEach((item, ind) => {
					const { value, status } = item;
					copyList.push(`${ind + 1}       ${value.trim()}		 :  ${status} \n`);
				});

				if (copyList.length) {
					const countStm = [
						`${tableLabels.sNo}  ${pageLabels.pattern}     :  ${commonLabels.status} \n`
					];
					copyList = [...countStm, ...copyList];
				}

				formResetRefBtn.current.click();
				setInfoModal({
					infoShow: true,
					infoTitle: pageLabels.patternCreatedInfoTitle,
					infoMsg: (
						<ReactTable
							keyField="ind"
							data={statusList}
							columns={[
								{
									isDummyField: true,
									dataField: 'srNo',
									text: tableLabels.sNo,
									headerAlign: 'center',
									headerStyle: {
										width: '55px'
									},
									formatter: (cellContent, row, index) => index + 1
								},
								{
									dataField: 'value',
									text: tableLabels.pattern,
									headerAlign: 'center'
								},
								{
									dataField: 'status',
									text: tableLabels.status,
									headerAlign: 'center'
								}
							]}
							paginationEnable
						/>
					),
					additionalActionBtn: true
				});
				setInfoCopyList(copyList.join(''));
			})
			.catch((error) => {
				props.showLoadingButton(false);
				console.error(error);
			});
	};

	const submitHandler = (values) => {
		const { patterns } = values;
		const businessUnit = props.loggedInBrand.businessUnitGroupFunctionID;
		const dataToSend = modifyDataToSend({ patterns, businessUnit });

		dataToSend.length && saveCouponPatterns(dataToSend, patterns);
	};

	const fetchCouponPatterns = () => {
		props.showLoadingButton(true);
		const businessUnit = props.loggedInBrand.businessUnitGroupFunctionID;
		const dataToSend = {
			businessUnitCouponPatternRequest: { idBsngpFnc: businessUnit }
		};

		RestSvc.postData(urlTypes.generateCoupon.fetchCouponPatterns, dataToSend)
			.then((response) => {
				props.showLoadingButton(false);
				var result = response.data?.businessUnitCouponPatternResponse || {};
				const { businessCouponPatternList = [] } = result;

				if (result?.businessError) {
					Toaster({ message: result.businessError.errorMessage });
					return;
				}

				const _couponPatterns = [];
				let _ind = 0;
				businessCouponPatternList?.forEach(({ separator, pattern }, ind) => {
					const temp = [];
					const _pattern = pattern.split(',');
					_pattern.map((pat, i) => {
						_ind++;
						temp.push({
							ind: _ind,
							separator: separator || '',
							pattern: pat,
							isSelected: false
						});
					});
					_couponPatterns.push(...temp);
				});

				setCouponPatterns(_couponPatterns);
			})
			.catch((error) => {
				props.showLoadingButton(false);
				console.error(error);
			});
	};

	const deleteCouponPattern = (patterns = []) => {
		props.showLoadingButton(true);
		const businessUnit = props.loggedInBrand.businessUnitGroupFunctionID;
		const data = modifyDataToSend({ patterns, businessUnit });
		const dataToSend = {
			List: [...data]
		};

		RestSvc.postData(urlTypes.generateCoupon.deleteCouponPatterns, dataToSend)
			.then((response) => {
				props.showLoadingButton(false);
				var result = response.data?.businessUnitCouponPatternResponse || {};

				if (result?.businessError) {
					Toaster({ message: result.businessError.errorMessage });
					return;
				}

				/* reset selected rows */
				setSelectAll(false);
				setSelectedPatterns([]);
				viewPatternRefBtn.current.click();
			})
			.catch((error) => {
				props.showLoadingButton(false);
				console.error(error);
			});
	};

	const rowSelectAllHandler = () => {
		const _selectAll = !selectAll;

		setSelectAll(_selectAll);
		setCouponPatterns((couponPatterns) => {
			let _couponPatterns = [...couponPatterns];
			_couponPatterns = _couponPatterns.map((val) => {
				let _val = { ...val, isSelected: _selectAll };
				return _val;
			});
			return _couponPatterns;
		});
		let newSelectedPatterns = _selectAll ? [...couponPatterns] : [];
		setSelectedPatterns(newSelectedPatterns);
	};

	const rowSelectHandler = (e, ind, pattern) => {
		setCouponPatterns((couponPatterns) => {
			const isRowSelected = e.target.checked;
			let _couponPatterns = [...couponPatterns];
			const patInd = _couponPatterns.findIndex((v) => v.ind === pattern.ind);
			_couponPatterns[patInd] = {
				...pattern,
				isSelected: isRowSelected
			};

			let newSelectedPatterns = _couponPatterns.filter((v) => v.isSelected);
			setSelectedPatterns(newSelectedPatterns);
			setSelectAll(newSelectedPatterns.length === _couponPatterns.length);
			return _couponPatterns;
		});
	};

	const selectedPatternHandler = () => {
		const selectedPatternID = selectedPatterns.reduce((pre, cur, i) => {
			let _pattern = [...pre];
			_pattern.push(cur.pattern);
			return _pattern;
		}, []);

		setInfoModal({
			infoShow: true,
			infoTitle: pageLabels.selectedPattedId,
			infoMsg: selectedPatternID.join(', '),
			additionalActionBtn: false
		});
	};

	const bulkDeleteHandler = () => {
		deleteCouponPattern(selectedPatterns);
	};

	const viewPatternsHandler = () => {
		setPageView('view');
		fetchCouponPatterns();
		setSelectAll(false);
		setSelectedPatterns([]);
	};

	return (
		<div className="coupon-pattern-container">
			<div className="row">
				<div className="col d-flex justify-content-between">
					<h1 className="roboto-b-24 m-0">{pageLabels.couponPattern}</h1>
					<button
						type="button"
						className="btn btn-outline-primary btn-sm pt-0 pb-0"
						onClick={props?.onCloseHandler}
					>
						{`<  `}
						{commonLabels.back}
					</button>
				</div>
			</div>
			<hr />
			<div className="row">
				<div className="col d-flex btn-container">
					<button
						className="generate-btn roboto-b-16 btn"
						onClick={() => {
							setPageView('create');
						}}
					>
						<img className="mr-2" alt="img" src={plusCircle} />
						{pageLabels.createPattern}
					</button>
					<button
						ref={viewPatternRefBtn}
						type="button"
						className="btnNxtPrev roboto-b-16"
						onClick={viewPatternsHandler}
					>
						{pageLabels.viewPatterns}
						<span className="ml-2">
							<img alt="img" src={couponEdit} />
						</span>
					</button>
				</div>
			</div>
			<hr />

			<div className="content">
				{pageView === 'create' && (
					<PatternForm
						submitHandler={submitHandler}
						formResetRefBtn={formResetRefBtn}
						onCloseHandler={props.onCloseHandler}
					/>
				)}

				{pageView === 'view' && (
					<>
						<StatusBarSection
							couponPatterns={couponPatterns}
							selectedPatterns={selectedPatterns}
							selectedPatternHandler={selectedPatternHandler}
							bulkDeleteHandler={bulkDeleteHandler}
						/>

						<PatternList
							couponPatterns={couponPatterns}
							deleteCouponPattern={deleteCouponPattern}
							rowSelectAllHandler={rowSelectAllHandler}
							rowSelectHandler={rowSelectHandler}
							selectAll={selectAll}
						/>
					</>
				)}
			</div>
			<>
				<ModalPopup
					title={infoModal.infoTitle}
					closeHandler={() => {
						setInfoModal({ infoShow: false });
					}}
					showModal={infoModal.infoShow}
					additionalActionBtn={
						<>
							{infoModal.additionalActionBtn && (
								<CopyToClipboardBtn text={infoCopyList} />
							)}
						</>
					}
				>
					{infoModal.infoMsg}
				</ModalPopup>
			</>
		</div>
	);
};

CouponPattern.propTypes = {
	onCloseHandler: PropTypes.func,
	loggedInBrand: PropTypes.shape({})
};

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CouponPattern);
