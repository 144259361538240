import React from 'react';
import { withRouter } from 'react-router-dom';
import * as RestSvc from '../../libraries/offer-services';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import * as urlTypes from '../../libraries/offer-service-urls';
import moment from 'moment';
import DropdownIcon from './../../assets/images/dropdownIcon.png';
import Filter from './../../assets/images/Filter.png';
import GreenDot from './../../assets/images/GreenDot.png';
import Plus from './../../assets/images/plus-circle.png';
import UserDetails from './UserDetails';
// import nodes from './data.json';
import omniEmpty from 'omit-empty';
import cancelIcon from './../../assets/images/plus-circle-cancel.png';
import resetIcon from './../../assets/images/undo.png';
import { ConfirmationModal } from '../ConfirmationModal';
import leftArrow from './../../assets/images/left-arrow.png';
import rightArrow from './../../assets/images/right-arrow.png';
import SearchPopup from './SearchUser';
import Datetime from 'react-datetime';
import $ from 'jquery';
import './scroll.scss';
import Toaster from '../../Components/Toaster/Toaster';
var nodesData = [];

// var copyNodes = [];

class UserMaintenance extends React.Component {
	userCreateForm = {
		userId: {
			elementType: 'input',
			elementConfig: {
				type: 'text',
				placeholder: 'Enter user id',
				labelname: 'User ID'
			},
			value: '',
			validation: {
				required: true,
				isAlphaNumeric: true,
				maxLength: 10
			},
			attrValidation: {},
			validationMessage: '',
			valid: false,
			touched: false
		},
		userStatus: {
			elementType: 'select',
			elementConfig: {
				type: '',
				placeholder: 'Select status',
				labelname: 'Status',
				options: [
					{ value: 'A', displayValue: 'Active' },
					{ value: 'I', displayValue: 'Inactive' }
				]
			},
			value: 'A',
			validation: {
				required: true
			},
			validationMessage: '',
			valid: true,
			touched: false
		},
		userFirstName: {
			elementType: 'input',
			elementConfig: {
				type: 'text',
				placeholder: 'Enter first name',
				labelname: 'First Name'
			},
			value: '',
			validation: {
				required: true,
				isAlphabets: true,
				maxLength: 10
			},
			attrValidation: {},
			validationMessage: '',
			valid: false,
			touched: false
		},
		userMiddleName: {
			elementType: 'input',
			elementConfig: {
				type: 'text',
				placeholder: 'Enter middle name',
				labelname: 'Middle Name'
			},
			value: '',
			validation: {
				required: false,
				isAlphabets: true,
				maxLength: 10
			},
			attrValidation: {},
			validationMessage: '',
			valid: true,
			touched: false
		},
		userLastName: {
			elementType: 'input',
			elementConfig: {
				type: 'text',
				placeholder: 'Enter last name',
				labelname: 'Last Name'
			},
			value: '',
			validation: {
				required: true,
				isAlphabets: true,
				maxLength: 10
			},
			attrValidation: {},
			validationMessage: '',
			valid: false,
			touched: false
		},
		userLevelId: {
			elementType: 'select',
			elementConfig: {
				type: '',
				placeholder: 'Select level',
				labelname: 'Level Name',
				options: []
			},
			value: '',
			validation: {
				required: true
			},
			validationMessage: '',
			valid: false,
			touched: false
		},
		userStartDateTime: {
			elementType: 'dateTime',
			elementConfig: {
				type: '',
				placeholder: '',
				labelname: 'Start Date & Time'
			},
			value: new Date(),
			validation: {
				required: true
			},
			validationMessage: '',
			valid: true,
			touched: false
		},
		userEndDateTime: {
			elementType: 'dateTime',
			elementConfig: {
				type: '',
				placeholder: '',
				labelname: 'End Date & Time'
			},
			value: '',
			validation: {
				required: false
			},
			validationMessage: '',
			valid: true,
			touched: false
		},
		userEmailId: {
			elementType: 'input',
			elementConfig: {
				type: 'text',
				placeholder: 'Enter email id',
				labelname: 'Email ID'
			},
			value: '',
			validation: {
				required: false,
				isEmail: true
			},
			attrValidation: {
				maxLength: 40
			},
			validationMessage: '',
			valid: true,
			touched: false
		},
		userPhoneNumber: {
			elementType: 'input',
			elementConfig: {
				type: 'text',
				placeholder: 'Enter phone number',
				labelname: 'Phone Number'
			},
			value: '',
			validation: {
				required: false,
				isPhoneNumber: true,
				maxLength: 10
			},
			attrValidation: {},
			validationMessage: '',
			valid: true,
			touched: false
		},
		userBrand: {
			elementType: 'select',
			elementConfig: {
				type: '',
				placeholder: 'Select brand',
				labelname: 'Brand',
				options: []
			},
			value: '',
			validation: {
				required: true
			},
			validationMessage: '',
			valid: true,
			touched: false
		}
	};

	initialState = {
		createUserForm: this.userCreateForm,
		formIsValid: false,
		Users: [],
		Levels: [],
		status: 'Select',
		endDate: '',
		tempendDate: '',
		tempstartDate: '',
		editScreen: true,
		showCreateUser: false,
		basicDetails: true,
		selectedOption: 'basic',
		selectedBrand: '',
		show: false,
		selectedUser: undefined,
		confirmationModalShow: false,
		clickPreview: false,
		checkAll: false,
		addBrand: [],
		selectBrandArr: [],
		isThisExistingUser: false,
		existingWorkerID: '',
		createdOn: '',
		displayUser: '',
		selectedUserId: '',
		storeBrand: [],
		storesOfBrand: nodesData.map(() => []),
		searchPopup: false,
		searchForm: '',
		endDateSet: false,
		userLevelHierarcy: '',
		userCreatedBy: '',
		showEnd: true,
		brandDropdown: 'Select Brand'
	};
	state = this.initialState;

	componentDidMount() {
		this.fetchAllLevels();

		window.dispatchEvent(new CustomEvent('navigationhandler'));
	}

	onReady = (val, validation, id) => {
		var brand = val.split('/')[1];
		this.setState({ brandDropdown: brand });
		this.onInputChangeDropdown(val, validation, id);
	};

	fetchAllLevels() {
		this.props.showLoadingButton(true);
		let dataToSend = {
			fetchAllExistingLevelRequest: {
				level: {
					levelID: this.props.loggedInLevelID
				}
			}
		};
		RestSvc.postData(urlTypes.user_maintenance.fetchLevelsForDropdown, dataToSend)
			.then((response) => {
				this.props.showLoadingButton(false);
				this.fetchEnterprise();
				const inputIdentifier = 'userLevelId';
				const updatedLoginForm = { ...this.state.createUserForm };
				const updatedFormElement = { ...updatedLoginForm[inputIdentifier] };

				updatedLoginForm[inputIdentifier] = updatedFormElement;
				let tempArray = JSON.parse(
					JSON.stringify(response.data.fetchAllExistingLevelResponse.allLevels)
				);
				tempArray.sort((a, b) => {
					if (a['hierarchicalLevel'] > b['hierarchicalLevel']) {
						return 1;
					} else if (a['hierarchicalLevel'] < b['hierarchicalLevel']) {
						return -1;
					}
					return 0;
				});
				updatedFormElement.elementConfig.options = tempArray;
				this.setState({ Levels: tempArray, createUserForm: updatedLoginForm });
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	}

	fetchAllUsers = () => {
		this.setState({ searchForm: '' });
		this.props.showLoadingButton(true);
		const dataToSend = {
			operatorLookupRequest: {
				header: {
					operatorID: this.props.userFullName.operatorID
				},
				operatorSearchCriteria: {
					createdBy: this.props.userFullName.operatorID
				}
			}
		};
		RestSvc.postData(urlTypes.user_maintenance.fetchUsers, dataToSend)
			.then((response) => {
				this.props.showLoadingButton(false);
				this.setState({ Users: response.data.operatorLookupResponse.operatorSearchResult });
				if (this.state.Users.length === 0) {
					this.setState({ selectedUser: 0 });
				} else {
					this.setState({ selectedUser: undefined });
					this.showCardDetails(this.state.Users[0]);
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	};

	handleFormReset = () => {
		this.resetHandler();
	};

	onSelectChange = (event) => {
		this.setState({ status: event.target.value });
	};

	onSubmit = (event) => {
		event.preventDefault();
		this.setState({ editScreen: false });
	};

	createLevel = () => {
		let dataToSend = {
			createLevelRequest: {
				level: {
					parentLevelID: 1,
					description: this.state.createUserForm.levelDescription.value,
					levelName: this.state.createUserForm.levelName.value,
					status: this.state.status,
					effectiveStartDate: this.state.tempstartDate,
					effectiveEndDate: this.state.tempendDate,
					createdBy: 'kiran',
					hierarchicalLevel: 3
				}
			}
		};

		this.props.showLoadingButton(true);
		RestSvc.postData(urlTypes.create_level_maintenance.createLevel, dataToSend)
			.then(() => {
				this.props.showLoadingButton(false);
				this.fetchAllLevels();
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	};

	handleOnCreateUserSubmit = () => {
		if (this.state.isThisExistingUser) {
			let dataToSend = {
				updateWorkerOperatorRequest: {
					header: {
						operatorID: this.props.userFullName.operatorID
					},
					user: {
						userID: this.state.createUserForm.userId.value,
						statusCode: this.state.createUserForm.userStatus.value
					},
					worker: {
						workerID: this.state.existingWorkerID,
						firstName: this.state.createUserForm.userFirstName.value,
						lastName: this.state.createUserForm.userLastName.value,
						emailID: this.state.createUserForm.userEmailId.value,
						phoneNumber: this.state.createUserForm.userPhoneNumber.value,
						middleName: this.state.createUserForm.userMiddleName.value
					},
					userAssignStatus: this.state.createUserForm.userStatus.value,
					effectiveStartDateTime: moment(
						this.state.createUserForm.userStartDateTime.value
					).format('YYYY-MM-DD HH:mm:ss'),
					effectiveEndDateTime: this.state.createUserForm.userEndDateTime.value
						? moment(this.state.createUserForm.userEndDateTime.value).format(
								'YYYY-MM-DD HH:mm:ss'
						  )
						: '',
					businessUnitGroupID: '',
					requestUserID: this.props.userFullName.operatorID,
					level: {
						levelID: this.state.createUserForm.userLevelId.value.split('/')[1]
					},
					businessUnitGroupFunctionID:
						this.state.createUserForm.userBrand.value.split('/')[0]
				}
			};

			this.props.showLoadingButton(true);
			RestSvc.postData(urlTypes.user_maintenance.updateUser, dataToSend)
				.then((response) => {
					this.props.showLoadingButton(false);
					var result = response.data.updateWorkerOperatorResponse;
					if (result.offersConfigException) {
						Toaster({ message: result.offersConfigException.message });
					} else {
						this.props.setDisableItem(false);
						if (this.state.searchForm === '') {
							this.fetchAllUsers();
						} else {
							this.searchUser();
						}
						Toaster({ type: 'success', message: result.operationStatus });
					}
				})
				.catch((error) => {
					this.props.showLoadingButton(false);
					console.error(error);
				});
		} else {
			let dataToSend = {
				createWorkerOperatorRequest: {
					header: {
						operatorID: this.props.userFullName.operatorID
					},
					user: {
						userID: this.state.createUserForm.userId.value,
						statusCode: this.state.createUserForm.userStatus.value
					},
					worker: {
						firstName: this.state.createUserForm.userFirstName.value,
						lastName: this.state.createUserForm.userLastName.value,
						emailID: this.state.createUserForm.userEmailId.value,
						phoneNumber: this.state.createUserForm.userPhoneNumber.value,
						createdBy: this.props.userFullName.operatorID,
						middleName: this.state.createUserForm.userMiddleName.value
					},
					userAssignStatus: this.state.createUserForm.userStatus.value,
					typeCode: 'CreateUser',
					effectiveStartDateTime: moment(
						this.state.createUserForm.userStartDateTime.value
					).format('YYYY-MM-DD HH:mm:ss'),
					effectiveEndDateTime: this.state.createUserForm.userEndDateTime.value
						? moment(this.state.createUserForm.userEndDateTime.value).format(
								'YYYY-MM-DD HH:mm:ss'
						  )
						: '',
					businessUnitGroupID: '',
					requestUserID: this.props.userFullName.operatorID,
					level: {
						levelID: this.state.createUserForm.userLevelId.value.split('/')[1]
					},
					businessUnitGroupFunctionID:
						this.state.createUserForm.userBrand.value.split('/')[0]
				}
			};

			this.props.showLoadingButton(true);
			RestSvc.postData(urlTypes.user_maintenance.createUser, dataToSend)
				.then((response) => {
					this.props.showLoadingButton(false);
					var result = response.data.createWorkerOperatorResponse;

					if (result.offersConfigException) {
						Toaster({ message: result.offersConfigException.message });
					} else {
						this.props.setDisableItem(false);
						this.fetchAllUsers();
						Toaster({
							type: 'info',
							message: result.operationStatus + ' ' + result.password
						});
					}
				})
				.catch((error) => {
					this.props.showLoadingButton(false);
					console.error(error);
				});
		}
	};
	inputChangedHandler = (value, isValidObj, inputIdentifier, dateTimeRangeChanged) => {
		const updatedLoginForm = { ...this.state.createUserForm };
		const updatedFormElement = { ...updatedLoginForm[inputIdentifier] };

		updatedFormElement.value = value;
		updatedFormElement.valid = isValidObj.value;
		updatedFormElement.validationMessage = isValidObj.message;
		updatedFormElement.touched = true;

		updatedLoginForm[inputIdentifier] = updatedFormElement;

		if (dateTimeRangeChanged) {
			const updatedFormStartDateTimeElement = { ...updatedLoginForm['userStartDateTime'] };
			const updatedFormEndDateTimeElement = { ...updatedLoginForm['userEndDateTime'] };

			if (updatedFormStartDateTimeElement.value && updatedFormEndDateTimeElement.value) {
				if (
					moment(updatedFormStartDateTimeElement.value) >
					moment(updatedFormEndDateTimeElement.value)
				) {
					updatedFormEndDateTimeElement.valid = false;
					updatedFormEndDateTimeElement.validationMessage = 'Invalid date time range';
				} else {
					updatedFormEndDateTimeElement.valid = isValidObj.value;
					updatedFormEndDateTimeElement.validationMessage = isValidObj.message;
				}
			}

			updatedLoginForm['userEndDateTime'] = updatedFormEndDateTimeElement;
		}

		let formIsValid = true;
		for (let inputIdentifier in updatedLoginForm) {
			formIsValid = updatedLoginForm[inputIdentifier].valid && formIsValid;
		}
		this.setState({ createUserForm: updatedLoginForm, formIsValid: formIsValid });
	};

	onInputChange = (event, definedRules, inputIdentifier) => {
		let value = event.target.value;
		let rules = definedRules;
		let isValid = { value: true, message: '' };

		if (rules) {
			if (rules.required) {
				isValid.value = value.trim() !== '';
				isValid.message = isValid.value ? '' : 'Field required';
			}

			if (rules.minLength) {
				isValid.value = value.length >= rules.minLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Minimum length is ' + rules.minLength;
			}

			if (rules.maxLength) {
				isValid.value = value.length <= rules.maxLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Maximum length is ' + rules.maxLength;
			}

			if (rules.isEmail && value) {
				let regEx = new RegExp(
					/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid email';
			}

			if (rules.isPhoneNumber && value) {
				let regEx = new RegExp(
					/^\+?(\d+|(\(\d+\))+)+((([ ]?(\d+|(\(\d+\))+)+)*)|((\-\d+)*)|((\.\d+)*))*$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid phone number';
			}

			if (rules.isAlphabets && value) {
				let regEx = new RegExp('^[a-zA-Z]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets';
			}

			if (rules.isAlphaNumeric && value) {
				let regEx = new RegExp('^[a-zA-Z0-9]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets and numbers';
			}
		}

		this.inputChangedHandler(value, isValid, inputIdentifier, false);
	};

	onInputChangeDropdown = (val, definedRules, inputIdentifier) => {
		let value = val;
		let rules = definedRules;
		let isValid = { value: true, message: '' };

		if (rules) {
			if (rules.required) {
				isValid.value = value.trim() !== '';
				isValid.message = isValid.value ? '' : 'Field required';
			}

			if (rules.minLength) {
				isValid.value = value.length >= rules.minLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Minimum length is ' + rules.minLength;
			}

			if (rules.maxLength) {
				isValid.value = value.length <= rules.maxLength && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Maximum length is ' + rules.maxLength;
			}

			if (rules.isEmail && value) {
				let regEx = new RegExp(
					/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid email';
			}

			if (rules.isPhoneNumber && value) {
				let regEx = new RegExp(
					/^\+?(\d+|(\(\d+\))+)+((([ ]?(\d+|(\(\d+\))+)+)*)|((\-\d+)*)|((\.\d+)*))*$/
				);
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Not a valid phone number';
			}

			if (rules.isAlphabets && value) {
				let regEx = new RegExp('^[a-zA-Z]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets';
			}

			if (rules.isAlphaNumeric && value) {
				let regEx = new RegExp('^[a-zA-Z0-9]*$');
				isValid.value = regEx.test(value) && isValid.value;
				isValid.message = isValid.value
					? ''
					: isValid.message
					? isValid.message
					: 'Enter only alphabets and numbers';
			}
		}

		this.inputChangedHandler(value, isValid, inputIdentifier, false);
	};

	onDateChange = (startDate, definedRules, inputIdentifier) => {
		let value = startDate;
		let rules = definedRules;
		let isValid = { value: true, message: '' };

		if (rules) {
			if (rules.required) {
				isValid.value = value !== '';
				isValid.message = isValid.value ? '' : 'Field required';
			}
		}

		this.inputChangedHandler(value, isValid, inputIdentifier, true);
	};

	onChangeStartDate = (startDate) => {
		const tempstartDate = moment(startDate).format('YYYY-MM-DD hh:mm:ss');
		this.setState({ tempstartDate: tempstartDate });
		this.setState({ startDate: startDate });
	};

	onChangeEndDate = (endDate) => {
		//   const tempendDate=endDate.toISOString().replace(/T|Z|\.\d{3}/g, ' ').trim();
		const tempendDate = moment(endDate).format('YYYY-MM-DD hh:mm:ss');
		this.setState({ tempendDate: tempendDate });
		this.setState({ endDate: endDate });
	};

	handleSelection = (selected, toChange) => {
		if (toChange === 'reset') {
			this.setState({
				selectedBrand: '',
				selectBrandArr: [],
				addBrand: []
			});
		} else if (toChange.includes('remove')) {
			let removeArr = toChange.split('#');
			let newItems = [...this.state.storesOfBrand];
			let temp = newItems[parseInt(removeArr[1])];
			newItems[parseInt(removeArr[1])] = [];
			this.setState({
				selectBrandArr: [...this.state.selectBrandArr.filter((d) => d != selected)],
				addBrand: [...this.state.addBrand.filter((d) => d != selected)],
				storesOfBrand: newItems,
				storeBrand: [...this.state.storeBrand.filter((el) => !temp.includes(el))]
			});
		} else {
			let other =
				toChange === 'selectedUser'
					? 'showCreateUser'
					: toChange === 'showCreateUser'
					? 'selectedUser'
					: '';
			let addBrand =
				toChange === 'addBrand'
					? this.state.addBrand.includes(selected)
						? [...this.state.addBrand]
						: [...this.state.addBrand, selected]
					: toChange === 'selectBrandArr'
					? this.state.selectBrandArr.includes(selected)
						? [...this.state.selectBrandArr.filter((d) => d !== selected)]
						: [...this.state.selectBrandArr, selected]
					: '';
			if (other === '') {
				if (toChange === 'selectBrandArr') {
					let indexOfBrand = nodesData.findIndex((x) => x.label === selected);
					let storeArrayToAdd = [...this.state.storesOfBrand];
					let tempArr = this.getNodeIds([nodesData[indexOfBrand]]);
					if (!this.state.selectBrandArr.includes(selected)) {
						storeArrayToAdd[indexOfBrand] = tempArr;
						this.setState({
							[toChange]: addBrand,
							storesOfBrand: [...new Set(storeArrayToAdd)],
							storeBrand: tempArr
						});
					} else {
						let tempToAdd = this.state.storeBrand.filter(
							(d) => !storeArrayToAdd[indexOfBrand].includes(d)
						);
						storeArrayToAdd[indexOfBrand] = [];
						this.setState({
							[toChange]: addBrand,
							storesOfBrand: storeArrayToAdd,
							storeBrand: tempToAdd
						});
					}
				} else if (toChange === 'addBrand') {
					this.setState({ [toChange]: addBrand });
				} else {
					this.setState({ [toChange]: selected });
				}
			} else {
				this.setState({ [toChange]: selected, [other]: !selected });
			}
		}
	};

	handleSelectionNew = (flag, data) => {
		this.setState({
			show: flag,
			selectedBrand: data
		});
	};

	buildFullTree = (departmentTree, category, data = []) => {
		const findPath = (node, category) => {
			//If current node matches search node, return tail of path result
			if (node && node.value === category) {
				return [node];
			} else {
				//If current node not search node match, examine children. For first
				//child that returns an array (path), prepend current node to that
				//path result
				if (node && node.children && node.children.length > 0) {
					for (const child of node.children) {
						const childPath = findPath(child, category);
						if (Array.isArray(childPath)) {
							childPath.unshift(child);
							return childPath;
						}
					}
				}
			}
		};
		const foundPath = findPath(departmentTree, category);
		// If search from root returns a path, prepend root node to path in
		// data result
		if (Array.isArray(foundPath)) {
			data.push(departmentTree);
			data.push(...foundPath);
		}

		return data;
	};

	addStores = (store, nodesVal) => {
		let tmpStore = [];
		for (let i = 0; i < store.length; i++) {
			let pathArray = this.buildFullTree(nodesVal, parseInt(store[i]));
			pathArray.pop();
			if (pathArray.length > 0) {
				if (
					pathArray[pathArray.length - 1]['isStore'] &&
					pathArray[pathArray.length - 1]['isStore'] === 'Y'
				) {
					tmpStore = [...tmpStore, pathArray[pathArray.length - 1].value];
				}
			}
		}
		return tmpStore;
	};

	getNodeIds = (nodes) => {
		let ids = [];
		nodes.forEach(({ value, children }) => {
			children
				? (ids = [...ids, value, ...this.getNodeIds(children)])
				: (ids = [...ids, value]);
		});
		return ids;
	};

	addDataofStores = (data, i, select) => {
		let tempStoresOfBrand = [...this.state.storesOfBrand];
		let storesWithParent = [];
		data.forEach((e) => {
			let pathArray = this.buildFullTree(nodesData[i], parseInt(e));
			pathArray.pop();
			storesWithParent = [
				...new Set([...storesWithParent, ...pathArray.map((d) => d.value)])
			];
		});

		tempStoresOfBrand[i] = storesWithParent;
		this.setState(
			{
				storesOfBrand: tempStoresOfBrand,
				show: false,
				selectBrandArr: !select
					? this.state.selectBrandArr.filter((e) => e !== nodesData[i].label)
					: [...new Set([...this.state.selectBrandArr, nodesData[i].label])]
			},
			() => {
				this.setState({
					storeBrand: Array.prototype.concat(...this.state.storesOfBrand),
					selectedBrand: ''
				});
			}
		);
	};

	editSelection = (selected) => {
		this.props.setDisableItem(true);
		this.setState({ showCreateUser: true, selectedOption: selected });
	};
	showCardDetails = (existingUser) => {
		this.setState({
			createUserForm: this.userCreateForm,
			clickPreview: true,
			isThisExistingUser: true,
			selectedUserId: existingUser?.operatorID,
			endDateSet: existingUser?.effectiveEndDate ? true : false,
			addBrand: []
		});
		let searchOperatorResponse;
		this.props.showLoadingButton(true);
		const dataToSend = {
			getOperatorDetailRequest: {
				operatorID: existingUser?.operatorID
			}
		};
		RestSvc.postData(urlTypes.user_maintenance.userDetails, dataToSend)
			.then((response) => {
				this.props.showLoadingButton(false);
				this.setState({
					userLevelHierarcy: response.data.SearchOperatorResponse.level.hierarchicalLevel,
					userCreatedBy: response.data.SearchOperatorResponse.worker.createdBy
				});
				searchOperatorResponse = response.data.SearchOperatorResponse;
				const createUserForm = { ...this.userCreateForm };

				createUserForm.userId.value = existingUser?.operatorID;
				createUserForm.userId.valid = true;
				createUserForm.userStatus.valid = true;
				createUserForm.userFirstName.valid = true;
				createUserForm.userMiddleName.valid = true;
				createUserForm.userLastName.valid = true;
				createUserForm.userStartDateTime.valid = true;
				createUserForm.userLevelId.valid = true;
				createUserForm.userEmailId.valid = true;
				createUserForm.userPhoneNumber.valid = true;
				createUserForm.userEndDateTime.valid = true;
				createUserForm.userBrand.valid = true;
				createUserForm.userStatus.value = searchOperatorResponse.operator.statusCode;
				createUserForm.userFirstName.value = searchOperatorResponse.worker.shortName;
				createUserForm.userMiddleName.value = searchOperatorResponse.worker.middleName
					? searchOperatorResponse.worker.middleName
					: '';
				createUserForm.userLastName.value = searchOperatorResponse.worker.lastName;
				createUserForm.userStartDateTime.value = new Date(existingUser?.effectiveStartDate);
				createUserForm.userEndDateTime.value = existingUser?.effectiveEndDate
					? new Date(existingUser.effectiveEndDate)
					: '';
				createUserForm.userLevelId.value =
					searchOperatorResponse.level.levelName +
					'/' +
					searchOperatorResponse.level.levelID;
				createUserForm.userEmailId.value = searchOperatorResponse.worker.emailID;
				createUserForm.userPhoneNumber.value = searchOperatorResponse.worker.phoneNumber;
				createUserForm.userBrand.value =
					searchOperatorResponse.businessUnitGroupFunctionList[0]
						.businessUnitGroupFunctionID +
					'/' +
					searchOperatorResponse.businessUnitGroupFunctionList[0].name;
				this.setState({
					brandDropdown: searchOperatorResponse.businessUnitGroupFunctionList[0].name
				});
				this.setState(
					{
						createUserForm: createUserForm,
						formIsValid: true,
						existingWorkerID: searchOperatorResponse.worker.workerID,
						createdOn: searchOperatorResponse.worker.createdOn
						// storeBrand: searchOperatorResponse.operator.operatorBusinessUnitAssignment.map(d => d.businessUnitGroupID),
					},
					() => {}
				);
			})
			.catch((error) => {
				this.props.showLoadingButton(false);

				console.error(error);
			});
	};

	cancelCreatingUser = () => {
		this.props.setDisableItem(false);
		this.resetHandler();

		this.setState((prevState) => {
			prevState.showCreateUser = false;
			prevState.showPreview = true;
			prevState.selectBrandArr = [];
			prevState.addBrand = [];
			prevState.endDateSet = false;
			return prevState;
		});
		this.setState({ showCreateLevel: false, showPreview: false, displayUser: '' });
	};

	confirmationModalClose = () => {
		this.setState({ confirmationModalShow: false });
	};

	proceedToOperation = () => {
		this.setState({ confirmationModalShow: false });
		if (this.state.confirmType === 'Cancel') {
			this.cancelCreatingUser();
			this.showCardDetails(this.state.Users[0]);
		} else if (this.state.confirmType === 'Delete') {
			this.deleteUser();
		}
	};

	showCancelConfirmation = () => {
		if (
			this.state.createUserForm.userStatus.touched == true ||
			this.state.createUserForm.userId.touched == true ||
			this.state.createUserForm.userFirstName.touched == true ||
			this.state.createUserForm.userMiddleName.touched == true ||
			this.state.createUserForm.userLastName.touched == true ||
			this.state.createUserForm.userLevelId.touched == true ||
			this.state.createUserForm.userLastName.touched == true ||
			this.state.createUserForm.userEmailId.touched == true ||
			this.state.createUserForm.userPhoneNumber.touched == true ||
			this.state.createUserForm.userStartDateTime.touched == true ||
			this.state.createUserForm.userEndDateTime.touched == true ||
			this.state.createUserForm.userBrand.touched == true
		) {
			this.setState({
				confirmationModalShow: true,
				confirmType: 'Cancel',
				confirmMsg:
					'Are you sure you want to navigate away? The changes you made will be lost.'
			});
		} else {
			this.cancelCreatingUser();
			this.showCardDetails(this.state.Users[0]);
		}
	};

	resetHandler = () => {
		this.setState((prevState) => {
			let previousState = { ...prevState };
			previousState.createUserForm.userId.value = '';
			previousState.createUserForm.userId.touched = false;
			previousState.createUserForm.userId.valid = false;
			previousState.createUserForm.userStatus.value = 'A';
			previousState.createUserForm.userFirstName.value = '';
			previousState.createUserForm.userMiddleName.value = '';
			previousState.createUserForm.userLastName.value = '';
			previousState.createUserForm.userFirstName.touched = false;
			previousState.createUserForm.userFirstName.valid = false;
			previousState.createUserForm.userLastName.valid = false;
			previousState.createUserForm.userLastName.touched = false;
			previousState.createUserForm.userStartDateTime.value = new Date();
			previousState.createUserForm.userEndDateTime.value = '';
			previousState.createUserForm.userLevelId.value = '';
			previousState.createUserForm.userLevelId.valid = false;
			previousState.createUserForm.userLevelId.touched = false;
			previousState.createUserForm.userEmailId.value = '';
			previousState.createUserForm.userPhoneNumber.value = '';
			previousState.createUserForm.userBrand.value = '';
			previousState.createUserForm.userEndDateTime.valid = true;
			previousState.createUserForm.userEndDateTime.validationMessage = '';
			previousState.createUserForm.userId.validationMessage = '';
			previousState.createUserForm.userFirstName.validationMessage = '';
			previousState.createUserForm.userMiddleName.validationMessage = '';
			previousState.createUserForm.userLastName.validationMessage = '';
			previousState.createUserForm.userEmailId.validationMessage = '';
			previousState.createUserForm.userPhoneNumber.validationMessage = '';
			previousState.createUserForm.userEmailId.valid = true;
			previousState.createUserForm.userPhoneNumber.valid = true;
			previousState.createUserForm.userMiddleName.valid = true;
			return previousState;
		});
		this.setState({ endDateSet: false, endDate: '', showEnd: false }, () => {
			this.setState({ showEnd: true });
		});
	};

	showDeleteConfirmation = () => {
		this.setState({
			confirmationModalShow: true,
			confirmType: 'Delete',
			confirmMsg: 'Are you sure you want to delete this user?'
		});
	};

	deleteUser = () => {
		//Call for Delete Level
		this.props.showLoadingButton(true);
		const dataToSend = {
			deleteWorkerOperatorRequest: {
				header: {
					operatorID: this.props.userFullName.operatorID
				},
				user: {
					userID: this.state.createUserForm.userId.value
				},
				worker: {
					workerID: this.state.existingWorkerID
				}
			}
		};
		RestSvc.postData(urlTypes.user_maintenance.deleteUser, dataToSend)
			.then((response) => {
				this.props.showLoadingButton(false);
				// if (response.data.offersConfigHeader.operationStatus === "User Deleted successfully.") {
				//     Toaster({type:'info' message: response.data.offersConfigHeader.operationStatus});
				// }
				var result = response.data.deleteWorkerOperatorResponse;
				if (result.offersConfigException) {
					Toaster({ message: result.offersConfigException.message });
				} else {
					this.props.setDisableItem(false);
					if (this.state.searchForm === '') {
						this.fetchAllUsers();
					} else {
						this.searchUser();
					}
					Toaster({ type: 'success', message: result.operationStatus });
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	};

	fetchEnterprise() {
		this.props.showLoadingButton(true);

		let dataToSend = {
			getFunctionNameRequest: {
				user: {
					userID: this.props.userFullName.operatorID
				}
			}
		};
		RestSvc.postData(urlTypes.user_maintenance.getFunctionNames, dataToSend)
			.then((response) => {
				this.props.showLoadingButton(false);
				this.fetchAllUsers();
				let nodeStr = JSON.stringify(
					response.data.getFunctionNameResponse.businessUnitGroupFunctionList
				)
					.replace(/name/g, 'label')
					.replace(/businessUnitGroupFunctionID/g, 'value');
				nodesData = omniEmpty(JSON.parse(nodeStr));
				// copyNodes = JSON.parse(JSON.stringify(nodesData));
				const inputIdentifier = 'userBrand';
				const updatedLoginForm = { ...this.state.createUserForm };
				const updatedFormElement = { ...updatedLoginForm[inputIdentifier] };
				updatedLoginForm[inputIdentifier] = updatedFormElement;
				updatedFormElement.elementConfig.options = nodesData;
				this.setState({ createUserForm: updatedLoginForm });
			})
			.catch((error) => {
				this.props.showLoadingButton(false);

				console.error(error);
			});
	}

	toggleSearchPopup = (flag) => {
		this.setState({
			searchPopup: flag
		});
	};

	handleSearch = (searchform) => {
		this.setState({ searchForm: searchform }, () => {
			this.searchUser();
		});
	};

	searchUser = () => {
		this.props.showLoadingButton(true);
		const dataToSend = {
			operatorLookupRequest: {
				header: {
					operatorID: this.props.userFullName.operatorID
				},
				operatorSearchCriteria: {
					firstName: this.state.searchForm.firstName.value
						? this.state.searchForm.firstName.value
						: null,
					lastName: this.state.searchForm.lastName.value
						? this.state.searchForm.lastName.value
						: null,
					phoneNumber: this.state.searchForm.phoneNo.value
						? this.state.searchForm.phoneNo.value
						: null,
					operatorID: this.state.searchForm.userId.value
						? this.state.searchForm.userId.value
						: null
				}
			}
		};
		RestSvc.postData(urlTypes.user_maintenance.fetchUsers, dataToSend)
			.then((response) => {
				this.props.showLoadingButton(false);
				this.setState({
					Users: response.data.operatorLookupResponse.operatorSearchResult,
					searchPopup: false
				});
				if (this.state.Users.length === 0) {
					this.setState({ selectedUser: 0 });
					Toaster({ message: 'No users found.' });
				} else {
					this.setState({ selectedUser: undefined });
					this.showCardDetails(this.state.Users[0]);
				}
			})
			.catch((error) => {
				this.props.showLoadingButton(false);
				console.error(error);
			});
	};

	resetBrands = () => {
		this.setState((prevState) => {
			let previousState = { ...prevState };
			previousState.createUserForm.userBrand.value = '';
			previousState.brandDropdown = 'Select Brand';
			return previousState;
		});
		$('.dropdown-menu').children().removeClass('dropdown-item-checked');
	};

	render() {
		const formElementsArray = [];
		const { selectedOption, showCreateUser, selectedUser, Users } = this.state;
		for (let key in this.state.createUserForm) {
			formElementsArray.push({
				id: key,
				config: this.state.createUserForm[key]
			});
		}
		return (
			<React.Fragment>
				<h1 className="roboto-b-24 m-0">User Management</h1>
				<p className="my-2 roboto-r-14 grey">{Users.length} Users Available</p>
				<div className="m-0 row">
					<div className="col-md-3 create-user-block p-0">
						<div className="media create-block">
							<div className="media-body ml-2">
								<span
									className={
										this.props.disableItem ||
										!(this.props.assignedResources.indexOf('OC004') != -1)
											? 'create-user-button roboto-b-16 disabled'
											: 'create-user-button roboto-b-16'
									}
									onClick={() => {
										this.cancelCreatingUser();
										this.setState({
											showCreateUser: true,
											isThisExistingUser: false,
											formIsValid: false,
											createdOn: '',
											selectedOption: 'basic',
											selectedUserId: '',
											storeBrand: [],
											storesOfBrand: nodesData.map(() => []),
											addBrand: [],
											brandDropdown: 'Select Brand'
										});
										this.props.setDisableItem(true);
									}}
								>
									<img src={Plus} alt="create" />
									<span className="ml-2">Create User</span>
								</span>
							</div>
							<div className="media-right">
								<span className="dropdownImage mx-2">
									<img src={DropdownIcon} alt="" />
								</span>
								<span
									className="highlight filterImage mx-2 pointer"
									onClick={() => this.toggleSearchPopup(true)}
								>
									<img src={Filter} alt="" />
								</span>
							</div>
						</div>
						<ul
							className={
								this.props.disableItem ? 'createdUsers disabled' : 'createdUsers'
							}
						>
							{Users.map((user, i) => (
								<li
									key={i}
									onClick={() => {
										this.handleSelection(user, 'selectedUser');
										this.showCardDetails(user);
									}}
									className={`user-list p-3 ${
										user.operatorID === this.state.selectedUserId
											? 'selectedBox'
											: ''
									}`}
								>
									<div className="media">
										<div className="media-left ml-2">
											<h6 className="roboto-m-14">{user.operatorID}</h6>
											<p className="blue-circle-sm">
												{user.hierarchicalLevel}
											</p>
										</div>
										<div className="media-body ml-2">
											<h6 className="media-heading roboto-m-14">
												{user.userName}
											</h6>
											<p className="mb-1 roboto-r-12">{user.emailID}</p>
										</div>
										<div
											className={
												user.statusCode == 'A' ||
												user.statusCode == 'Active'
													? 'media-right'
													: ' media-right greyscale'
											}
										>
											<img
												alt=""
												src={GreenDot}
												className="media-object green-dot"
											/>
										</div>
									</div>
									<section>
										<p className="brand-outline roboto-r-11">
											{user.levelName}
										</p>
										<p className="created-date roboto-r-11">
											Created on{' '}
											{moment(user.createdOn, 'YYYY-MM-DD HH:mm:ss').format(
												'DD-MM-YYYY'
											)}{' '}
											{user.effectiveEndDate
												? '| Ends on ' +
												  moment(
														user.effectiveEndDate,
														'YYYY-MM-DD HH:mm:ss'
												  ).format('DD-MM-YYYY')
												: ''}
										</p>
									</section>
								</li>
							))}
						</ul>
					</div>
					{/* Create Level Part */}
					{showCreateUser ? (
						<div className="col-md-9 position-relative">
							<p className="textcreateLevel">Create User</p>
							<div className="m-0 row main-body">
								<div className="col-md-3 mt-55 pr-0">
									<div
										onClick={() =>
											this.handleSelection('basic', 'selectedOption')
										}
										className={
											selectedOption === 'basic'
												? 'parentDivSelected d-flex flex-column parentDiv'
												: 'd-flex flex-column parentDiv font-grey'
										}
									>
										<span className="span1">Basic Details</span>
										<span className="span2">{this.state.displayUser}</span>
									</div>
									<div
										onClick={() => {
											this.handleSelection('brand', 'selectedOption');
											this.setState({
												displayUser: this.state.createUserForm.userId.value
											});
										}}
										className={
											this.state.formIsValid === false
												? 'disabled d-flex flex-column parentDiv font-grey'
												: selectedOption === 'brand'
												? 'parentDivSelected d-flex flex-column parentDiv'
												: 'd-flex flex-column parentDiv font-grey'
										}
									>
										<span className="span1">Select Brand</span>
										<span className="span2">{this.state.displayUser}</span>
									</div>
								</div>
								{selectedOption === 'basic' ? (
									<div className="col-md-9 pl-0">
										<form
											method="POST"
											action="javascript:void(0);"
											id="userCreation"
										>
											<div className="createuserform roboto-b-16">
												<div className="row m-0 my-4 pt-4">
													<div
														className={
															this.state.isThisExistingUser
																? 'col-md-6 disabled'
																: 'col-md-6'
														}
													>
														<input
															type={
																formElementsArray[0].config
																	.elementConfig.type
															}
															className={`form_control ${
																!formElementsArray[0].config
																	.valid &&
																formElementsArray[0].config.touched
																	? 'Invalid'
																	: ''
															}`}
															value={
																formElementsArray[0].config.value
															}
															placeholder={
																formElementsArray[0].config
																	.elementConfig.placeholder
															}
															onChange={(ev) =>
																this.onInputChange(
																	ev,
																	formElementsArray[0].config
																		.validation,
																	formElementsArray[0].id
																)
															}
															{...formElementsArray[0].config
																.attrValidation}
														/>
														<label
															className={`${
																formElementsArray[0].config
																	.validation.required
																	? 'require'
																	: ''
															}`}
														>
															{
																formElementsArray[0].config
																	.elementConfig.labelname
															}
														</label>
														<p className="errorMessage">
															{
																formElementsArray[0].config
																	.validationMessage
															}
														</p>
													</div>
													<div className="col-md-6">
														<select
															className={`form_control customSelectIcon ${
																!formElementsArray[1].config
																	.valid &&
																formElementsArray[1].config.touched
																	? 'Invalid'
																	: ''
															}`}
															value={
																formElementsArray[1].config.value
															}
															onChange={(ev) =>
																this.onInputChange(
																	ev,
																	formElementsArray[1].config
																		.validation,
																	formElementsArray[1].id
																)
															}
														>
															{formElementsArray[1].config.elementConfig.options.map(
																(option) => (
																	<option
																		key={option.value}
																		value={option.value}
																	>
																		{option.displayValue}
																	</option>
																)
															)}
														</select>
														<label
															className={`${
																formElementsArray[1].config
																	.validation.required
																	? 'require'
																	: ''
															}`}
														>
															{
																formElementsArray[1].config
																	.elementConfig.labelname
															}
														</label>
													</div>
												</div>
												<div className="row m-0 my-4">
													<div className=" col-md-6">
														<input
															type={
																formElementsArray[2].config
																	.elementConfig.type
															}
															className={`form_control ${
																!formElementsArray[2].config
																	.valid &&
																formElementsArray[2].config.touched
																	? 'Invalid'
																	: ''
															}`}
															value={
																formElementsArray[2].config.value
															}
															placeholder={
																formElementsArray[2].config
																	.elementConfig.placeholder
															}
															onChange={(ev) =>
																this.onInputChange(
																	ev,
																	formElementsArray[2].config
																		.validation,
																	formElementsArray[2].id
																)
															}
															{...formElementsArray[2].config
																.attrValidation}
														/>
														<label
															className={`${
																formElementsArray[2].config
																	.validation.required
																	? 'require'
																	: ''
															}`}
														>
															{
																formElementsArray[2].config
																	.elementConfig.labelname
															}
														</label>
														<p className="errorMessage">
															{
																formElementsArray[2].config
																	.validationMessage
															}
														</p>
													</div>
													<div className="col-md-6">
														<input
															type={
																formElementsArray[3].config
																	.elementConfig.type
															}
															className={`form_control ${
																!formElementsArray[3].config
																	.valid &&
																formElementsArray[3].config.touched
																	? 'Invalid'
																	: ''
															}`}
															value={
																formElementsArray[3].config.value
															}
															placeholder={
																formElementsArray[3].config
																	.elementConfig.placeholder
															}
															onChange={(ev) =>
																this.onInputChange(
																	ev,
																	formElementsArray[3].config
																		.validation,
																	formElementsArray[3].id
																)
															}
															{...formElementsArray[3].config
																.attrValidation}
														/>
														<label
															className={`${
																formElementsArray[3].config
																	.validation.required
																	? 'require'
																	: ''
															}`}
														>
															{
																formElementsArray[3].config
																	.elementConfig.labelname
															}
														</label>
														<p className="errorMessage">
															{
																formElementsArray[3].config
																	.validationMessage
															}
														</p>
													</div>
												</div>
												<div className="row m-0">
													<div className="col-md-6">
														<input
															type={
																formElementsArray[4].config
																	.elementConfig.type
															}
															className={`form_control ${
																!formElementsArray[4].config
																	.valid &&
																formElementsArray[4].config.touched
																	? 'Invalid'
																	: ''
															}`}
															value={
																formElementsArray[4].config.value
															}
															placeholder={
																formElementsArray[4].config
																	.elementConfig.placeholder
															}
															onChange={(ev) =>
																this.onInputChange(
																	ev,
																	formElementsArray[4].config
																		.validation,
																	formElementsArray[4].id
																)
															}
															{...formElementsArray[4].config
																.attrValidation}
														/>
														<label
															className={`${
																formElementsArray[4].config
																	.validation.required
																	? 'require'
																	: ''
															}`}
														>
															{
																formElementsArray[4].config
																	.elementConfig.labelname
															}
														</label>
														<p className="errorMessage">
															{
																formElementsArray[4].config
																	.validationMessage
															}
														</p>
													</div>
													<div className="col-md-6">
														<select
															className={`form_control customSelectIcon ${
																!formElementsArray[5].config
																	.valid &&
																formElementsArray[5].config.touched
																	? 'Invalid'
																	: ''
															}`}
															value={
																formElementsArray[5].config.value
															}
															onChange={(ev) =>
																this.onInputChange(
																	ev,
																	formElementsArray[5].config
																		.validation,
																	formElementsArray[5].id
																)
															}
														>
															<option value="">Select level</option>
															{formElementsArray[5].config.elementConfig.options.map(
																(option) => (
																	<option
																		key={option.levelID}
																		value={
																			option.levelName +
																			'/' +
																			option.levelID
																		}
																	>
																		{option.levelName}
																	</option>
																)
															)}
														</select>
														<label
															className={`${
																formElementsArray[5].config
																	.validation.required
																	? 'require'
																	: ''
															}`}
														>
															{
																formElementsArray[5].config
																	.elementConfig.labelname
															}
														</label>
													</div>
												</div>
												<div className="row m-0">
													<div
														className={
															this.state.isThisExistingUser
																? ' disabled col-md-6 usDate'
																: 'col-md-6 usDate'
														}
													>
														<label
															className={`labelStyle ${
																formElementsArray[6].config
																	.validation.required
																	? 'require'
																	: ''
															}`}
														>
															{
																formElementsArray[6].config
																	.elementConfig.labelname
															}
														</label>
														<div
															className={`${
																!formElementsArray[6].config
																	.valid &&
																formElementsArray[6].config.touched
																	? 'Invalid'
																	: ''
															}`}
														>
															<Datetime
																className={`${
																	!formElementsArray[6].config
																		.valid &&
																	formElementsArray[6].config
																		.touched
																		? 'Invalid'
																		: ''
																}`}
																locale="en-GB"
																onChange={(ev) =>
																	this.onDateChange(
																		ev,
																		formElementsArray[6].config
																			.validation,
																		formElementsArray[6].id
																	)
																}
																//For min and max date
																isValidDate={(current) => {
																	return current.isAfter(
																		moment().subtract(1, 'days')
																	);
																}}
																// locale="fr-ca"
																dateFormat="DD-MM-YYYY"
																inputProps={{
																	placeholder: 'DD-MM-YYYY HH:mm'
																}}
																value={
																	formElementsArray[6].config
																		.value
																}
																closeOnSelect={true}
																onViewModeChange={() => {
																	if (
																		formElementsArray[6].config
																			.value == ''
																	) {
																		formElementsArray[6].config.value =
																			new Date();
																	}
																}}
																timeFormat="HH:mm"
															/>
														</div>
													</div>
													<div className="col-md-6 ueDate">
														<label
															className={`labelStyle ${
																formElementsArray[7].config
																	.validation.required
																	? 'require'
																	: ''
															}`}
														>
															{
																formElementsArray[7].config
																	.elementConfig.labelname
															}
														</label>
														<div>
															<div className="row pl-3">
																<Datetime
																	className={`${
																		!formElementsArray[7].config
																			.valid &&
																		formElementsArray[7].config
																			.touched
																			? 'Invalid w270px'
																			: 'w270px'
																	}`}
																	locale="en-GB"
																	onChange={(ev) =>
																		this.onDateChange(
																			ev,
																			formElementsArray[7]
																				.config.validation,
																			formElementsArray[7].id
																		)
																	}
																	//For min and max date
																	isValidDate={(current) => {
																		return current.isAfter(
																			moment(
																				formElementsArray[6]
																					.config.value
																			).subtract(1, 'days')
																		);
																	}}
																	// locale="fr-ca"
																	dateFormat="DD-MM-YYYY"
																	inputProps={{
																		placeholder:
																			'DD-MM-YYYY HH:mm'
																	}}
																	value={
																		formElementsArray[7].config
																			.value
																	}
																	closeOnSelect={true}
																	onViewModeChange={() => {
																		if (
																			formElementsArray[7]
																				.config.value == ''
																		) {
																			this.onDateChange(
																				new Date(),
																				formElementsArray[7]
																					.config
																					.validation,
																				formElementsArray[7]
																					.id
																			);
																		}
																	}}
																	timeFormat="HH:mm"
																/>
																<button
																	className="clrBtn"
																	onClick={() => {
																		this.setState(
																			(prevState) => {
																				let previousState =
																					{
																						...prevState
																					};
																				previousState.createUserForm.userEndDateTime.value =
																					'';
																				previousState.createUserForm.userEndDateTime.valid = true;
																				previousState.createUserForm.userEndDateTime.validationMessage =
																					'';
																				return previousState;
																			}
																		);
																	}}
																>
																	X
																</button>
															</div>
															<p className="errorMessage">
																{
																	formElementsArray[7].config
																		.validationMessage
																}
															</p>
														</div>
													</div>
												</div>
												<div className="row m-0 my-4">
													<div className="col-md-6">
														<input
															type={
																formElementsArray[8].config
																	.elementConfig.type
															}
															className={`form_control ${
																!formElementsArray[8].config
																	.valid &&
																formElementsArray[8].config.touched
																	? 'Invalid'
																	: ''
															}`}
															value={
																formElementsArray[8].config.value
															}
															placeholder={
																formElementsArray[8].config
																	.elementConfig.placeholder
															}
															onChange={(ev) =>
																this.onInputChange(
																	ev,
																	formElementsArray[8].config
																		.validation,
																	formElementsArray[8].id
																)
															}
															{...formElementsArray[8].config
																.attrValidation}
														/>
														<label
															className={`${
																formElementsArray[8].config
																	.validation.required
																	? 'require'
																	: ''
															}`}
														>
															{
																formElementsArray[8].config
																	.elementConfig.labelname
															}
														</label>
														<p className="errorMessage">
															{
																formElementsArray[8].config
																	.validationMessage
															}
														</p>
													</div>
													<div className="col-md-6">
														<input
															type={
																formElementsArray[9].config
																	.elementConfig.type
															}
															className={`form_control ${
																!formElementsArray[9].config
																	.valid &&
																formElementsArray[9].config.touched
																	? 'Invalid'
																	: ''
															}`}
															value={
																formElementsArray[9].config.value
															}
															placeholder={
																formElementsArray[9].config
																	.elementConfig.placeholder
															}
															onChange={(ev) =>
																this.onInputChange(
																	ev,
																	formElementsArray[9].config
																		.validation,
																	formElementsArray[9].id
																)
															}
															{...formElementsArray[9].config
																.attrValidation}
														/>
														<label
															className={`${
																formElementsArray[9].config
																	.validation.required
																	? 'require'
																	: ''
															}`}
														>
															{
																formElementsArray[9].config
																	.elementConfig.labelname
															}
														</label>
														<p className="errorMessage">
															{
																formElementsArray[9].config
																	.validationMessage
															}
														</p>
													</div>
												</div>
											</div>
										</form>
										<div className="m-0 row footer">
											<div className="col-md-3 offset-md-2">
												<p
													onClick={this.handleFormReset}
													className="grey-button roboto-b-16"
												>
													<img src={resetIcon} alt="Rest" /> Reset
												</p>
												<p
													onClick={this.showCancelConfirmation}
													className="grey-button roboto-b-16"
												>
													<img src={cancelIcon} alt="Cancel" /> Cancel
												</p>
											</div>
											<div className="col-md-4 offset-md-1">
												<button className="btnNxtPrev op30 roboto-b-16">
													<img src={leftArrow} alt="Preview" /> Previous
												</button>
												<button
													className={`btnNxtPrev ml-2 roboto-b-16 ${
														!this.state.formIsValid ? '' : ''
													}`}
													disabled={!this.state.formIsValid}
													onClick={() => {
														this.handleSelection(
															'brand',
															'selectedOption'
														);
														this.setState({
															clickPreview: false,
															displayUser:
																this.state.createUserForm.userId
																	.value
														});
														if (
															formElementsArray[7].config.value !== ''
														) {
															this.setState({ endDateSet: true });
														}
													}}
												>
													Next <img src={rightArrow} alt="img" />
												</button>
											</div>
										</div>
									</div>
								) : null}
								{selectedOption === 'brand' ? (
									<div className="col-md-9 pl-0">
										<div className="createuserform">
											<div className="row m-0 h-100 pt-5">
												<div className="col-md-6 brandDropDown">
													<input
														type="text"
														className={`form_control btn btn-secondary dropdown-toggle dropdownInput dropdownInputBox ${
															!formElementsArray[10].config.valid &&
															formElementsArray[10].config.touched
																? 'Invalid'
																: ''
														}`}
														readOnly
														value={this.state.brandDropdown}
														data-toggle="dropdown"
													/>
													<label
														className={`${
															formElementsArray[10].config.validation
																.required
																? 'require'
																: ''
														}`}
													>
														{
															formElementsArray[10].config
																.elementConfig.labelname
														}
													</label>
													<div className="dropdown-menu p-0">
														{formElementsArray[10].config.elementConfig.options.map(
															(option) => (
																<div
																	onClick={() => {
																		this.onReady(
																			option.value +
																				'/' +
																				option.label,
																			formElementsArray[10]
																				.config.validation,
																			formElementsArray[10].id
																		);
																	}}
																	key={option.value}
																	value={
																		option.value +
																		'/' +
																		option.label
																	}
																	className={`dropdown-item ${
																		this.state.brandDropdown ===
																		option.label
																			? 'dropdown-item-checked'
																			: ''
																	}`}
																>
																	{option.label}
																</div>
															)
														)}
													</div>
												</div>
											</div>
										</div>
										<div className="m-0 row footer">
											<div className="col-md-3 offset-md-2">
												<p
													onClick={() => this.resetBrands()}
													className="grey-button roboto-b-16"
												>
													<img src={resetIcon} alt="img" /> Reset
												</p>
												<p
													onClick={this.showCancelConfirmation}
													className="grey-button roboto-b-16"
												>
													<img src={cancelIcon} alt="img" /> Cancel
												</p>{' '}
											</div>
											<div className="col-md-4 offset-md-1">
												<button
													className="btnNxtPrev roboto-b-16"
													onClick={() => {
														this.handleSelection(
															'basic',
															'selectedOption'
														);
													}}
												>
													<img src={leftArrow} alt="img" /> Previous
												</button>
												<button
													className={
														formElementsArray[10].config.value == ''
															? 'disabled btnNxtPrev ml-2 roboto-b-16'
															: 'btnNxtPrev ml-2 roboto-b-16'
													}
													onClick={() => {
														this.handleSelection(true, 'selectedUser');
														this.setState({ clickPreview: false });
													}}
												>
													Next <img src={rightArrow} alt="img" />
												</button>
											</div>
										</div>
									</div>
								) : null}
							</div>
						</div>
					) : selectedUser !== 0 ? (
						<UserDetails
							inputToUserDetails={this.state.createUserForm}
							onCreateUserSubmit={this.handleOnCreateUserSubmit}
							handleSelection={this.editSelection}
							cancelCall={this.showCancelConfirmation}
							clickPreview={this.state.clickPreview}
							deleteUser={this.showDeleteConfirmation}
							createdOn={this.state.createdOn}
							selectedBrandNames={this.state.addBrand}
							userLevelHierarcy={this.state.userLevelHierarcy}
							userCreatedBy={this.state.userCreatedBy}
							isExistingUser={this.state.isThisExistingUser}
						/>
					) : null}
				</div>

				<div className={this.state.searchPopup ? 'modal in-right d-block' : 'modal'}>
					<div className="modal-dialog m-0 search-modal-bottom-right" role="document">
						<div className="modal-content h-100">
							<div className="modal-header modal-title-custom p-2 b-radius-none d-flex">
								<p
									className="modal-title ml-2 roboto-r-18"
									style={{ flex: '1' }}
									id="exampleModalLabel"
								>
									Search User
								</p>
								<span className="xSCloseButtonSpan">
									<button
										className="xSCloseButton"
										onClick={() => {
											this.toggleSearchPopup();
										}}
									>
										X
									</button>
								</span>
							</div>

							<SearchPopup
								handleSelection={this.toggleSearchPopup}
								handleSearch={this.handleSearch}
								resetUsersArray={this.fetchAllUsers}
							/>
						</div>
					</div>
				</div>
				{this.state.confirmationModalShow ? (
					<ConfirmationModal
						show={this.state.confirmationModalShow}
						onHide={this.confirmationModalClose}
						onProceed={this.proceedToOperation}
						confirmmsg={this.state.confirmMsg}
					/>
				) : null}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		disableItem: state.disableItem,
		assignedResources: state.assignedResources,
		loggedInLevelID: state.loggedInLevelID,
		loggedInLevelHierarchy: state.loggedInLevelHierarchy
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserMaintenance));
