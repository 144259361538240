import React from 'react';
import PropTypes from 'prop-types';

import appLabels from '../../utils/appLabels';
import ReactTable from '../../Components/ReactTable/ReactTable';
import deleteIcon from '../../assets/images/delete.png';
import Input from '../../Components/Input/Input';

const { common: commonLabels, table: tableLabels } = appLabels;

const PatternList = ({
	couponPatterns = [],
	deleteCouponPattern,
	rowSelectAllHandler,
	rowSelectHandler,
	selectAll = false
}) => {
	// if(!couponPatterns.length) {
	// 	return null;
	// }

	const actionFormatter = ({ value, row, index }) => {
		return (
			<div className="action-container">
				<span
					className="pointer"
					onClick={() => {
						deleteCouponPattern([row]);
					}}
				>
					<img
						alt=""
						src={deleteIcon}
						title="Click to generate coupon"
						className="highlight"
					/>
				</span>
			</div>
		);
	};

	const checkFormatter = ({ value, pattern, ind, isSelected, key }) => {
		return (
			<Input
				customClasses="table-checkbox"
				elementType="checkbox"
				changed={(e) => {
					rowSelectHandler(e, ind, pattern);
				}}
				checked={pattern.isSelected}
				elementConfig={{
					type: 'checkbox',
					id: `row-select-${ind}`
				}}
			/>
		);
	};

	const getTableData = () => {
		const columns = [
			{
				isDummyField: true,
				dataField: 'selectColumn',
				text: 'Select ',
				headerAlign: 'center',
				align: 'center',
				headerStyle: {
					width: '50px'
				},
				style: {
					width: '50px'
				},
				headerFormatter: (column, colIndex, { sortElement, filterElement }) => {
					return (
						<Input
							customClasses="table-checkbox"
							elementType="checkbox"
							changed={rowSelectAllHandler}
							checked={selectAll}
							elementConfig={{
								type: 'checkbox',
								id: 'row-select-all'
							}}
						/>
					);
				},
				formatter: (value, row, index) =>
					checkFormatter({
						value,
						pattern: row,
						ind: index
					})
			},
			{
				dataField: 'ind',
				text: tableLabels.sNo,
				headerAlign: 'center',
				headerStyle: {
					width: '55px'
				}
			},
			{
				dataField: 'separator',
				text: tableLabels.separator,
				headerAlign: 'center',
				formatter: (value, row, index) => (value === '' ? 'No Separator' : value)
			},
			{
				dataField: 'pattern',
				text: tableLabels.pattern,
				headerAlign: 'center'
			},

			{
				isDummyField: true,
				dataField: 'actionColumn',
				text: tableLabels.action,
				searchable: false,
				headerAlign: 'center',
				align: 'center',
				headerStyle: {
					width: '55px'
				},
				formatter: (value, row, index) =>
					actionFormatter({
						value,
						row,
						index
					})
			}
		];

		return {
			columns,
			data: couponPatterns,
			keyField: 'ind',
			noDataInfo: commonLabels.nothingToShow,
			paginationEnable: true,
			customClasses: 'coupon-pattern-table col-sm-5',
			searchBarEnable: true
		};
	};

	return (
		<div className="coupon-pattern-list row">
			<ReactTable {...getTableData()} />
		</div>
	);
};

PatternList.propTypes = {
	couponPatterns: PropTypes.arrayOf(PropTypes.shape({})),
	deleteCouponPattern: PropTypes.func,
	rowSelectAllHandler: PropTypes.func,
	rowSelectHandler: PropTypes.func,
	selectAll: PropTypes.bool
};

export default PatternList;
