import React, { useEffect, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import CustomInputRadio from './customfields/CustomInputRadio';
import CustomInputRadioScroll from './customfields/CustomInputRadioScroll';
import CollapseHeaderBar from './CollapseHeaderBar';
import CollapseBody from './CollapseBody';
import 'bootstrap/dist/css/bootstrap.css';
import './BasicDetails.scss';
import CustomDateTime from './customfields/CustomDateTime';
import CustomHoursPicker from './customfields/CustomHoursPicker';
import HappyHoursNew from './HappyHoursNew';
import CustomSelect from './customfields/CustomSelect';
import CustomInputText from './customfields/CustomInputText';
import moment from 'moment';
import { Element } from 'react-scroll';
import Segments from './Segments';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions';
import AuditSegments from './Audit/AuditSegments';
import $ from 'jquery';

import * as RestSvc from '../../../libraries/offer-services';
import * as urlTypes from '../../../libraries/offer-service-urls';

import Toaster from '../../../Components/Toaster/Toaster';
import appLabels from '../../../utils/appLabels';
import appConstants from '../../../utils/appConstants';

const localConst = {
	allowPrivilege: 'Allow Privilege Customer Sale',
	subAllowPrivilege: 'Determines if the promotion is applicable for privilege customers',
	privilegeCustomer: 'Privilege Customer Eligibility',
	//employeeEligibility: 'Allow Employee Sale',
	subEmployeeEligibility: 'Determines if the promotion is applicable for employees',
	allowAnonymous: 'Allow Anonymous Customer Sale',
	subAllowAnonymous: 'Determines if the promotion is applicable for anonymous customers',
	allowLoyalty: 'Allow Loyalty Customer Sale',
	subAllowLoyalty: 'Determines if the promotion is applicable for loyalty customer segment',
	collectionRequired: 'Collection Required',
	subCollectionRequired:
		'Specifies the validity period of the collected/activated promotion in either Months Days Hours (HH:MM:SS) Date and Time',
	subMultimapRequired: 'Specifies the Multi map period of the collected/activated promotion',
	allowCustomerSegments: 'Allow Customer Segments',
	//loyaltyCard: 'Allow Loyalty Card Customer Sale',
	subAllowCustomerSegments:
		'Determines if the promotion is applicable for regular customers segment',
	channelEligibility: 'Channel Eligibility',
	anonymousCostomer: 'Anonymous Customer Eligibility',
	employeeEligibility: 'Employee Eligibility',
	allowEmployeeEligibility: 'Allow Employee Eligibility',
	loyaltyCustomer: 'Loyalty Customer Eligibility',
	customerSegments: 'Customer Segments Eligibility',
	loyaltyCard: 'Loyalty Card Customer Eligibility',
	AllowloyaltyCard: 'Allow Loyalty Card Customer Eligibility',
	subLoyaltyCardCustomerEligibility:
		'Determines if the promotion is applicable for Loyalty card customers',
	happyHours: 'Happy Hours',
	multimapRequired: 'Multimap Required',
	loggedInLoyalityEligibility: 'Logged In Loyalty Eligibility',
	LoyaltyOffer: 'Loyalty Offer',
	tSSSPromotionEligibility: 'Determines if the promotion is applicable for TSSS Eligibility',
	tSSSEligibility: 'TSSS Eligibility',
	brandEligibility: 'Brand Eligibility',
	selectOne: 'Select One'
};

const getCollectionValidityDropdown = () => {
	return dropdownValues.collectionValidity.map((val, index) => {
		return (
			<option key={index + ''} value={val.value}>
				{val.name}
			</option>
		);
	});
};

const getBrandsCategoryOptions = (data, field) => {
	const opts = data?.map((val, ind) => {
		const _code = `${val[field]?.code}`;
		const _name = `${val[field]?.name}`;
		return (
			<option key={`${ind}-opt-${_code}`} value={_code}>
				{appLabels[`${field === 'brand' ? 'brands' : 'brandsCategory'}`][_name] || _name}
			</option>
		);
	});
	return [
		<option key="opt-0" value="">
			{localConst.selectOne}
		</option>,
		...opts
	];
};

const dropdownValues = {
	empSegment: [
		{
			value: 'Gold',
			name: 'Gold',
			selected: false
		},
		{
			value: 'Silver',
			name: 'Silver',
			selected: false
		},
		{
			value: 'Platinum',
			name: 'Platinum',
			selected: false
		}
	],
	collectionValidity: [
		{
			value: '',
			name: 'Select one'
		},
		{
			value: 'Date',
			name: 'Date'
		},
		{
			value: 'Months',
			name: 'Months'
		},
		{
			value: 'Days',
			name: 'Days'
		},
		{
			value: 'Hours',
			name: 'Hours'
		}
	]
};

const getParentBrandsList = async (buID) => {
	const url = `${urlTypes.offers_maintenance.getParentBrands}${buID}`;
	try {
		const response = await RestSvc.getData(url);
		const result = response.data.BrandCategoryDetailsResponse;
		const { brandCategoryList, businessError = null } = result;
		businessError &&
			Toaster({ message: `${businessError.errorID}: ${businessError.errorMessage}` });
		return brandCategoryList || [];
	} catch (error) {
		console.error(error);
		return [];
	}
};

const EligibilitySubForm = ({ name, errors, touched, getFieldProps, ...props }) => {
	const [field] = useField(props);
	const { setFieldValue } = useFormikContext();
	const fieldPrmtnAplcblEligibility = field.value.promotionPolicy.promotionApplicableEligibility;
	let typeofOfferFlw = JSON.parse(sessionStorage.getItem('typeOfOffersFlow'));
	let isAuditFlw = typeofOfferFlw.isAuditFlow;
	let newJson = JSON.parse(sessionStorage.getItem('NewPolicy'));
	const [parentBrands, setParentBrands] = useState([]);
	const [isGeoFencedBU, setIsGeoFencedBU] = useState(false);

	const customDateValidate = (value) => {
		let error;

		if (value) {
			if (moment(field.value.promotionPolicy.effectiveEndDate) < moment(value)) {
				error =
					'Entered validity date and time cannot be greater than the promotion end date and time';
			} else if (moment(field.value.promotionPolicy.effectiveStartDate) > moment(value)) {
				error =
					'Entered validity date and time cannot be less than the promotion start date';
			}
		}

		return error;
	};

	useEffect(() => {
		const _isGeoFencedBU =
			props.loggedInBrand.businessUnitGroupFunctionID === appConstants.buBrand.GEOFENCED.BUID;
		setIsGeoFencedBU(_isGeoFencedBU);
		if (JSON.parse(sessionStorage.getItem('typeOfOffersFlow')).isAuditFlow) {
			if (
				newJson?.promotionPolicy?.promotionApplicableEligibility
					?.promotionActivationEligibility?.customerActivationRequired !=
				fieldPrmtnAplcblEligibility?.promotionActivationEligibility
					?.customerActivationRequired
			) {
				let tempName =
					'promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.customerActivationRequired';
				$('[name="' + tempName + '"]')
					.closest('.container')
					.nextAll()
					.addClass('hide-nochange-section-for-audit');
			} else if (
				newJson?.promotionPolicy?.promotionApplicableEligibility
					?.promotionActivationEligibility?.activationValidity
					?.promotionCollectionValidityData !=
				fieldPrmtnAplcblEligibility?.promotionActivationEligibility?.activationValidity
					?.promotionCollectionValidityData
			) {
				let tempName =
					'promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity.promotionCollectionValidityData';
				$('[name="' + tempName + '"]')
					.closest('.container')
					.nextAll()
					.addClass('hide-nochange-section-for-audit');
			}
		}
		props.isBrandEligible && initParentBrandsList();
	}, []);

	const initParentBrandsList = async () => {
		const {
			showLoadingButton,
			loggedInBrand: { businessUnitGroupFunctionID }
		} = props;
		showLoadingButton(true);

		const result = await getParentBrandsList(businessUnitGroupFunctionID);

		showLoadingButton(false);
		setParentBrands(result);
	};

	useEffect(() => {
		if (
			fieldPrmtnAplcblEligibility?.promotionActivationEligibility?.activationValidity
				?.promotionCollectionValidityData == 'Date' &&
			fieldPrmtnAplcblEligibility.promotionActivationEligibility.activationValidity.date ==
				undefined
		) {
			fieldPrmtnAplcblEligibility.promotionActivationEligibility.activationValidity.date =
				field.value.promotionPolicy.effectiveEndDate;
		}
	}, [fieldPrmtnAplcblEligibility]);

	useEffect(() => {
		if (
			fieldPrmtnAplcblEligibility?.promotionActivationEligibility
				?.customerActivationRequired == false
		) {
			if (fieldPrmtnAplcblEligibility.promotionActivationEligibility.activationValidity) {
				delete fieldPrmtnAplcblEligibility.promotionActivationEligibility
					.activationValidity;
			}
		}
	}, [fieldPrmtnAplcblEligibility?.promotionActivationEligibility?.customerActivationRequired]);

	useEffect(() => {
		if (fieldPrmtnAplcblEligibility?.promotionActivationEligibility?.multimapPromotion) {
			fieldPrmtnAplcblEligibility.promotionActivationEligibility.customerActivationRequired = true;
		}
		// else {
		//fieldPrmtnAplcblEligibility.promotionActivationEligibility.customerActivationRequired = false;
		//}
	}, [fieldPrmtnAplcblEligibility?.promotionActivationEligibility?.multimapPromotion]);

	useEffect(() => {
		{
			/* skip for geoFencedBu */
		}
		if (!isGeoFencedBU) {
			const { brand, category = '' } = fieldPrmtnAplcblEligibility.brandEligibility;
			let brandCategory = parentBrands.filter((val) => val.brand.code == brand)[0]?.category;
			fieldPrmtnAplcblEligibility.brandEligibility.category = brandCategory?.code || category;
		}
	}, [fieldPrmtnAplcblEligibility.brandEligibility.brand]);

	const onMouseEnterHandler = (evt, mouseIsON) => {
		if (mouseIsON != props.curntMousePlace) {
			props.setMousePlace(mouseIsON);
		}
	};

	return (
		<div className="eligibility-sub-form">
			{/* Privilege Customer Eligibility */}
			{/* collapse start */}
			<div className="accordion" id="accordionExample">
				<Element
					onMouseEnter={(e) =>
						onMouseEnterHandler(e, 'scrollToPrivilegeCustomerEligibility')
					}
					name="scrollToPrivilegeCustomerEligibility"
					class="card"
					style={{ border: 'none' }}
				>
					<CollapseHeaderBar
						text={localConst.privilegeCustomer}
						dataTarget={'#collapseEight'}
						headingId={'headingEight'}
					/>
					<CollapseBody id="collapseEight" headingId={'headingEight'}>
						<CustomInputRadio
							name="promotionPolicy.promotionApplicableEligibility.allowPrivilegeCustomerSale"
							label={localConst.allowPrivilege}
							required={true}
							label1="Yes"
							label2="No"
							option1={true}
							option2={false}
							smallText={localConst.subAllowPrivilege}
						/>
					</CollapseBody>
				</Element>
			</div>
			{/* collapse start */}
			<div className="accordion" id="accordionExample">
				<Element
					onMouseEnter={(e) =>
						onMouseEnterHandler(e, 'scrollToAnonymousCustomerEligibility')
					}
					name="scrollToAnonymousCustomerEligibility"
					class="card"
					style={{ border: 'none' }}
				>
					<CollapseHeaderBar
						text={localConst.anonymousCostomer}
						headingId={'headingNine'}
						dataTarget={'#collapseNine'}
					/>
					<CollapseBody id="collapseNine" headingId={'headingNine'}>
						<CustomInputRadio
							name="promotionPolicy.promotionApplicableEligibility.allowAnonymousCustomerSale"
							label={localConst.allowAnonymous}
							required={true}
							label1="Yes"
							label2="No"
							option1={true}
							option2={false}
							smallText={localConst.subAllowAnonymous}
						/>
					</CollapseBody>
				</Element>
			</div>

			{/* collapse start */}
			<div className="accordion" id="accordionExample">
				<Element
					onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToEmployeeEligibility')}
					name="scrollToEmployeeEligibility"
					class="card"
					style={{ border: 'none' }}
				>
					<CollapseHeaderBar
						text={localConst.employeeEligibility}
						headingId={'headingTen'}
						dataTarget={'#collapseTen'}
					/>
					<CollapseBody id="collapseTen" headingId={'headingTen'}>
						<CustomInputRadio
							name="promotionPolicy.promotionApplicableEligibility.employeeEligibility.allowEmployeeSale"
							label={localConst.allowEmployeeEligibility}
							required={true}
							label1="Yes"
							label2="No"
							option1={true}
							option2={false}
							smallText={localConst.subEmployeeEligibility}
						/>
						{fieldPrmtnAplcblEligibility.employeeEligibility.allowEmployeeSale ==
						true ? (
							<>
								{/* <div className="row pt-4">
              <div className="col-sm-6 font-weight-bold">Include</div>
              <div className="col-sm-6 font-weight-bold">Exclude</div>
              </div>
                <div className='purchaseContainer mt-0'>
        <div className="purchaseRow d-block">
        <CustomMultiSelect name="promotionPolicy.promotionApplicableEligibility.employeeEligibility.includedEmployeeSegments.employeeSegmentId" label='Employee Segment Inclusion' options={getEmpSegmentDropdown()} />

          </div>
          <div className="purchaseRow d-block">

          <CustomMultiSelect name="promotionPolicy.promotionApplicableEligibility.employeeEligibility.excludedEmployeeSegments.employeeSegmentId" label='Employee Segment Exclusion' options={getEmpSegmentDropdown()} />

          </div>
          </div> */}
								{/* <div className="container"> */}
								{isAuditFlw ? (
									<>
										{fieldPrmtnAplcblEligibility.employeeEligibility
											.allowEmployeeSale == true &&
										newJson.promotionPolicy.promotionApplicableEligibility
											.employeeEligibility.allowEmployeeSale ==
											false ? null : (
											<AuditSegments
												oldIncludedSegments={
													fieldPrmtnAplcblEligibility.employeeEligibility
														.includedEmployeeSegments?.employeeSegmentId
												}
												newIncludedSegments={
													newJson.promotionPolicy
														.promotionApplicableEligibility
														.employeeEligibility
														.includedEmployeeSegments?.employeeSegmentId
														? newJson.promotionPolicy
																.promotionApplicableEligibility
																.employeeEligibility
																.includedEmployeeSegments
																?.employeeSegmentId
														: []
												}
												oldExcludedSegments={
													fieldPrmtnAplcblEligibility.employeeEligibility
														.excludedEmployeeSegments?.employeeSegmentId
												}
												newExcludedSegments={
													newJson.promotionPolicy
														.promotionApplicableEligibility
														.employeeEligibility
														.excludedEmployeeSegments?.employeeSegmentId
														? newJson.promotionPolicy
																.promotionApplicableEligibility
																.employeeEligibility
																.excludedEmployeeSegments
																?.employeeSegmentId
														: []
												}
											/>
										)}
									</>
								) : (
									<>
										<Segments type="employee" />
									</>
								)}
								{/* </div> */}
							</>
						) : (
							((fieldPrmtnAplcblEligibility.employeeEligibility.includedEmployeeSegments =
								undefined),
							(fieldPrmtnAplcblEligibility.employeeEligibility.excludedEmployeeSegments =
								undefined))
						)}
					</CollapseBody>
				</Element>
			</div>

			{/* collapse start */}
			<div className="accordion" id="accordionExample">
				<Element
					onMouseEnter={(e) =>
						onMouseEnterHandler(e, 'scrollToLoyaltyCustomerEligibility')
					}
					name="scrollToLoyaltyCustomerEligibility"
					class="card"
					style={{ border: 'none' }}
				>
					<CollapseHeaderBar
						text={localConst.loyaltyCustomer}
						headingId={'headingEleven'}
						dataTarget={'#collapseEleven'}
					/>
					<CollapseBody id="collapseEleven">
						<CustomInputRadio
							name="promotionPolicy.promotionApplicableEligibility.loyaltyCustomerEligibility.allowLoyaltyCustomerSale"
							label={localConst.allowLoyalty}
							required={true}
							label1="Yes"
							label2="No"
							option1={true}
							option2={false}
							smallText={localConst.subAllowLoyalty}
						/>
						{fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
							.allowLoyaltyCustomerSale == true ? (
							<>
								{/* <div className="row pt-4">
              <div className="col-sm-6 font-weight-bold">Include</div>
              <div className="col-sm-6 font-weight-bold">Exclude</div>
              </div>
                <div className='purchaseContainer mt-0'>
        <div className="purchaseRow d-block">
        <CustomMultiSelect name="promotionPolicy.promotionApplicableEligibility.loyaltyCustomerEligibility.includedLoyaltyCustomerSegments.loyaltyCustomerSegmentsId" label='Employee Segment Inclusion' options={getEmpSegmentDropdown()} />

          </div>
          <div className="purchaseRow d-block">

          <CustomMultiSelect name="promotionPolicy.promotionApplicableEligibility.loyaltyCustomerEligibility.excludedLoyaltyCustomerSegments.loyaltyCustomerSegmentsId" label='Employee Segment Exclusion' options={getEmpSegmentDropdown()} />

          </div>
          </div> */}
								{/* <div className="container"> */}
								{isAuditFlw ? (
									<>
										{fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility
											.allowLoyaltyCustomerSale == true &&
										newJson.promotionPolicy.promotionApplicableEligibility
											.loyaltyCustomerEligibility.allowLoyaltyCustomerSale ==
											false ? null : (
											<AuditSegments
												oldIncludedSegments={
													fieldPrmtnAplcblEligibility
														.loyaltyCustomerEligibility
														.includedLoyaltyCustomerSegments
														?.loyaltyCustomerSegment
												}
												newIncludedSegments={
													newJson.promotionPolicy
														.promotionApplicableEligibility
														.loyaltyCustomerEligibility
														.includedLoyaltyCustomerSegments
														?.loyaltyCustomerSegment
														? newJson.promotionPolicy
																.promotionApplicableEligibility
																.loyaltyCustomerEligibility
																.includedLoyaltyCustomerSegments
																?.loyaltyCustomerSegment
														: []
												}
												oldExcludedSegments={
													fieldPrmtnAplcblEligibility
														.loyaltyCustomerEligibility
														.excludedLoyaltyCustomerSegments
														?.loyaltyCustomerSegment
												}
												newExcludedSegments={
													newJson.promotionPolicy
														.promotionApplicableEligibility
														.loyaltyCustomerEligibility
														.excludedLoyaltyCustomerSegments
														?.loyaltyCustomerSegment
														? newJson.promotionPolicy
																.promotionApplicableEligibility
																.loyaltyCustomerEligibility
																.excludedLoyaltyCustomerSegments
																?.loyaltyCustomerSegment
														: []
												}
											/>
										)}
									</>
								) : (
									<>
										<Segments type="loyaltyCustomer" />
									</>
								)}
								{/* </div> */}
							</>
						) : (
							((fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility.includedLoyaltyCustomerSegments =
								undefined),
							(fieldPrmtnAplcblEligibility.loyaltyCustomerEligibility.excludedLoyaltyCustomerSegments =
								undefined))
						)}
					</CollapseBody>
				</Element>
			</div>

			{/* collapse start */}
			<div className="accordion" id="accordionExample">
				<Element
					onMouseEnter={(e) =>
						onMouseEnterHandler(e, 'scrollToCustomerSegmentsEligibility')
					}
					name="scrollToCustomerSegmentsEligibility"
					class="card"
					style={{ border: 'none' }}
				>
					<CollapseHeaderBar
						text={localConst.customerSegments}
						dataTarget={'#collapseTwelve'}
						headingId={'headingTwelve'}
					/>
					<CollapseBody id="collapseTwelve" headingId={'headingTwelve'}>
						<CustomInputRadio
							name="promotionPolicy.promotionApplicableEligibility.customerSegmentEligibility.allowSegmentCustomerSale"
							label={localConst.allowCustomerSegments}
							required={true}
							label1="Yes"
							label2="No"
							option1={true}
							option2={false}
							smallText={localConst.subAllowCustomerSegments}
						/>
						{fieldPrmtnAplcblEligibility.customerSegmentEligibility
							.allowSegmentCustomerSale == true ? (
							<>
								{/* <div className="row pt-4">
              <div className="col-sm-6 font-weight-bold">Include</div>
              <div className="col-sm-6 font-weight-bold">Exclude</div>
              </div>
                <div className='purchaseContainer mt-0'>
        <div className="purchaseRow d-block">
        <CustomMultiSelect name="promotionPolicy.promotionApplicableEligibility.customerSegmentEligibility.includedCustomerSegments.customerSegmentId" label='Employee Segment Inclusion' options={getEmpSegmentDropdown()} />

          </div>
          <div className="purchaseRow d-block">

          <CustomMultiSelect name="promotionPolicy.promotionApplicableEligibility.customerSegmentEligibility.excludedCustomerSegments.customerSegmentId" label='Employee Segment Exclusion' options={getEmpSegmentDropdown()} />

          </div>
          </div> */}
								{/* <div className="container"> */}
								{isAuditFlw ? (
									<>
										{fieldPrmtnAplcblEligibility.customerSegmentEligibility
											.allowSegmentCustomerSale == true &&
										newJson.promotionPolicy.promotionApplicableEligibility
											.customerSegmentEligibility.allowSegmentCustomerSale ==
											false ? null : (
											<AuditSegments
												oldIncludedSegments={
													fieldPrmtnAplcblEligibility
														.customerSegmentEligibility
														.includedCustomerSegments?.customerSegmentId
												}
												newIncludedSegments={
													newJson.promotionPolicy
														.promotionApplicableEligibility
														.customerSegmentEligibility
														.includedCustomerSegments?.customerSegmentId
														? newJson.promotionPolicy
																.promotionApplicableEligibility
																.customerSegmentEligibility
																.includedCustomerSegments
																?.customerSegmentId
														: []
												}
												oldExcludedSegments={
													fieldPrmtnAplcblEligibility
														.customerSegmentEligibility
														.excludedCustomerSegments?.customerSegmentId
												}
												newExcludedSegments={
													newJson.promotionPolicy
														.promotionApplicableEligibility
														.customerSegmentEligibility
														.excludedCustomerSegments?.customerSegmentId
														? newJson.promotionPolicy
																.promotionApplicableEligibility
																.customerSegmentEligibility
																.excludedCustomerSegments
																?.customerSegmentId
														: []
												}
											/>
										)}
									</>
								) : (
									<>
										<Segments type="customer" />
									</>
								)}
								{/* </div> */}
							</>
						) : (
							((fieldPrmtnAplcblEligibility.customerSegmentEligibility.includedCustomerSegments =
								undefined),
							(fieldPrmtnAplcblEligibility.customerSegmentEligibility.excludedCustomerSegments =
								undefined))
						)}
					</CollapseBody>
				</Element>
			</div>

			{/* collapse start */}
			<div className="accordion" id="accordionExample">
				{/* Loyalty Card Customer Eligibility */}
				<Element
					onMouseEnter={(e) =>
						onMouseEnterHandler(e, 'scrollToLoyaltyCardCustomerEligibility')
					}
					name="scrollToLoyaltyCardCustomerEligibility"
					class="card"
					style={{ border: 'none' }}
				>
					<CollapseHeaderBar
						text={localConst.loyaltyCard}
						headingId={'headingThirteen'}
						dataTarget={'#collapseThirteen'}
					/>
					<CollapseBody id="collapseThirteen" headingId={'headingThirteen'}>
						<CustomInputRadio
							name="promotionPolicy.promotionApplicableEligibility.loyaltyCardCustomerEligibility.allowLoyaltyCardCustomerSale"
							label={localConst.AllowloyaltyCard}
							required={true}
							label1="Yes"
							label2="No"
							option1={true}
							option2={false}
							smallText={localConst.subLoyaltyCardCustomerEligibility}
						/>
					</CollapseBody>
				</Element>
				{/* Logged In Loyalty Eligibility */}
				<Element
					onMouseEnter={(e) =>
						onMouseEnterHandler(e, 'scrollToLoyaltyInLoyaltyEligibility')
					}
					name="scrollToLoyaltyInLoyaltyEligibility"
					class="card"
					style={{ border: 'none' }}
				>
					<CollapseHeaderBar
						text={localConst.loggedInLoyalityEligibility}
						headingId={'headingThirteen'}
						dataTarget={'#collapseThirteen'}
					/>
					<CollapseBody id="collapseThirteen" headingId={'headingThirteen'}>
						<CustomInputRadio
							name="promotionPolicy.promotionApplicableEligibility.loggedInLoyaltyEligibility.loyaltyOffer"
							label={localConst.LoyaltyOffer}
							required={true}
							label1="Yes"
							label2="No"
							option1={true}
							option2={false}
							/* smallText={localConst.subLoyaltyCardCustomerEligibility} */
						/>
					</CollapseBody>
				</Element>
				{/* TSSS start */}
				<Element
					onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToTSSSEligibility')}
					name="scrollToTSSSEligibility"
					class="card"
					style={{ border: 'none' }}
				>
					<CollapseHeaderBar
						text={localConst.tSSSEligibility}
						headingId={'headingThirteen'}
						dataTarget={'#collapseThirteen'}
					/>
					<CollapseBody id="collapseThirteen" headingId={'headingThirteen'}>
						<CustomInputRadio
							name="promotionPolicy.promotionApplicableEligibility.tsssEligibility.allowTsssSegment"
							label={localConst.tSSSEligibility}
							required={true}
							label1="Yes"
							label2="No"
							option1={true}
							option2={false}
							smallText={localConst.tSSSPromotionEligibility}
						/>

						{fieldPrmtnAplcblEligibility.tsssEligibility.allowTsssSegment == true ? (
							<>
								{isAuditFlw ? (
									<>
										{fieldPrmtnAplcblEligibility.tsssEligibility
											.allowTsssSegment == true &&
										newJson.promotionPolicy.promotionApplicableEligibility
											.tsssEligibility.allowTsssSegment == false ? null : (
											<AuditSegments
												oldIncludedSegments={
													fieldPrmtnAplcblEligibility.tsssEligibility
														.includedTsssSegments?.tsssSegmentId
												}
												newIncludedSegments={
													newJson.promotionPolicy
														.promotionApplicableEligibility
														.tsssEligibility.includedTsssSegments
														?.tsssSegmentId
														? newJson.promotionPolicy
																.promotionApplicableEligibility
																.tsssEligibility
																.includedTsssSegments?.tsssSegmentId
														: []
												}
												oldExcludedSegments={
													fieldPrmtnAplcblEligibility.tsssEligibility
														.excludedTsssSegments?.tsssSegmentId
												}
												newExcludedSegments={
													newJson.promotionPolicy
														.promotionApplicableEligibility
														.tsssEligibility.excludedTsssSegments
														?.tsssSegmentId
														? newJson.promotionPolicy
																.promotionApplicableEligibility
																.tsssEligibility
																.excludedTsssSegments?.tsssSegmentId
														: []
												}
											/>
										)}
									</>
								) : (
									<>
										<Segments type="tsssEligibility" />
									</>
								)}
								{/* </div> */}
							</>
						) : (
							((fieldPrmtnAplcblEligibility.tsssEligibility.includedTsssSegments =
								undefined),
							(fieldPrmtnAplcblEligibility.tsssEligibility.excludedTsssSegments =
								undefined))
						)}
					</CollapseBody>
				</Element>
				{/* TSSS end */}

				{props.isBrandEligible && (
					<Element
						onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToBrandEligibility')}
						name="scrollToBrandEligibility"
						class="card"
						style={{ border: 'none' }}
					>
						<CollapseHeaderBar
							text={localConst.brandEligibility}
							headingId={'headingThirteen'}
							dataTarget={'#collapseThirteen'}
						/>
						<CollapseBody id="collapseThirteen" headingId={'headingThirteen'}>
							<CustomSelect
								name="promotionPolicy.promotionApplicableEligibility.brandEligibility.brand"
								required={true}
								label="Brand"
								options={getBrandsCategoryOptions(parentBrands, 'brand')}
								onChange={(e) => {
									props.resetAllTables();
									setFieldValue(
										'promotionPolicy.promotionApplicableEligibility.brandEligibility.brand',
										e.target.value
									);
								}}
							/>

							{/* skip for geoFencedBu */}
							{!isGeoFencedBU && (
								<CustomSelect
									name="promotionPolicy.promotionApplicableEligibility.brandEligibility.category"
									required={true}
									label="Category"
									customClass="brandEligibility-category"
									options={getBrandsCategoryOptions(parentBrands, 'category')}
									disabled
								/>
							)}
						</CollapseBody>
					</Element>
				)}
			</div>
			{/* <div className="item-before">{localConst.happyHours}</div> */}
			<div className="accordion" id="accordionExample">
				<Element
					onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToHappyHours')}
					name="scrollToHappyHours"
					class="card happy-hours-card"
					style={{ border: 'none' }}
				>
					<CollapseHeaderBar
						text={localConst.happyHours}
						headingId={'headingFourteen'}
						dataTarget={'#collapseFourteen'}
					/>
					<CollapseBody id="collapseFourteen" headingId={'headingFourteen'}>
						{/* <CustomInputRadio name="promotionPolicy.promotionApplicableEligibility.collectionRequired" label={localConst.collectionRequired} required={true} label1='Yes' label2='No' option1={true} option2={false} smallText={localConst.subCollectionRequired} /> */}
						{/* <HappyHours /> */}
						{/* <Field
                    name="happyHoursApplicableEligibility"
                    // component={HappyHours}
                    render={formikProps => 
                      <HappyHours  {...formikProps} test="Hello" /> 
                   }
                  /> */}
						<HappyHoursNew />
					</CollapseBody>
				</Element>
			</div>

			<div className="accordion" id="accordionExample">
				<Element
					onMouseEnter={(e) => onMouseEnterHandler(e, 'scrollToCollectionRequired')}
					name="scrollToCollectionRequired"
					class="card"
					style={{ border: 'none' }}
				>
					<CollapseHeaderBar
						text={localConst.collectionRequired}
						headingId={'headingFifteen'}
						dataTarget={'#collapseFifteen'}
					/>
					<CollapseBody id="collapseFifteen" headingId={'headingFifteen'}>
						<CustomInputRadioScroll
							name="promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.multimapPromotion"
							label={localConst.multimapRequired}
							required={true}
							label1="Yes"
							label2="No"
							option1={true}
							option2={false}
							smallText={localConst.subMultimapRequired}
							isBottomScrolling={true}
						/>

						<CustomInputRadioScroll
							name="promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.customerActivationRequired"
							label={localConst.collectionRequired}
							required={true}
							label1="Yes"
							label2="No"
							option1={true}
							option2={false}
							disabledVal={
								fieldPrmtnAplcblEligibility?.promotionActivationEligibility
									?.multimapPromotion
							}
							smallText={localConst.subCollectionRequired}
							isBottomScrolling={true}
						/>

						{fieldPrmtnAplcblEligibility.promotionActivationEligibility
							.customerActivationRequired == true ? (
							<>
								<CustomSelect
									name="promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity.promotionCollectionValidityData"
									required={true}
									label="Collection Validity"
									options={getCollectionValidityDropdown()}
									isBottomScroll={true}
								/>

								{fieldPrmtnAplcblEligibility.promotionActivationEligibility
									.activationValidity &&
								fieldPrmtnAplcblEligibility.promotionActivationEligibility
									.activationValidity.promotionCollectionValidityData ==
									'Date' ? (
									<>
										<CustomDateTime
											name="promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity.date"
											required={true}
											label="Date(yyyy-mm-dd)"
											validate={customDateValidate}
											valueDate={
												fieldPrmtnAplcblEligibility
													.promotionActivationEligibility
													.activationValidity.date
											}
											isBottomDateTimePicker={true}
										/>
									</>
								) : fieldPrmtnAplcblEligibility.promotionActivationEligibility
										.activationValidity ? (
									(fieldPrmtnAplcblEligibility.promotionActivationEligibility.activationValidity.date =
										undefined)
								) : null}

								{fieldPrmtnAplcblEligibility.promotionActivationEligibility
									.activationValidity &&
								fieldPrmtnAplcblEligibility.promotionActivationEligibility
									.activationValidity.promotionCollectionValidityData ==
									'Months' ? (
									<>
										<CustomInputText
											name="promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity.months"
											required={true}
											label="Months"
										/>
									</>
								) : fieldPrmtnAplcblEligibility.promotionActivationEligibility
										.activationValidity ? (
									(fieldPrmtnAplcblEligibility.promotionActivationEligibility.activationValidity.months =
										undefined)
								) : null}

								{fieldPrmtnAplcblEligibility.promotionActivationEligibility
									.activationValidity &&
								fieldPrmtnAplcblEligibility.promotionActivationEligibility
									.activationValidity.promotionCollectionValidityData ==
									'Days' ? (
									<>
										<CustomInputText
											name="promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity.days"
											required={true}
											label="Days"
										/>
									</>
								) : fieldPrmtnAplcblEligibility.promotionActivationEligibility
										.activationValidity ? (
									(fieldPrmtnAplcblEligibility.promotionActivationEligibility.activationValidity.days =
										undefined)
								) : null}

								{fieldPrmtnAplcblEligibility.promotionActivationEligibility
									.activationValidity &&
								fieldPrmtnAplcblEligibility.promotionActivationEligibility
									.activationValidity.promotionCollectionValidityData ==
									'Hours' ? (
									<>
										{/* <CustomTime name="promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity.hours" required={true} label='Hours(HH:mm)' /> */}
										<CustomHoursPicker
											name="promotionPolicy.promotionApplicableEligibility.promotionActivationEligibility.activationValidity.hours"
											required={true}
											isBottomDateTimePicker={true}
											label="Hours(HH:mm)"
											valueHour={
												fieldPrmtnAplcblEligibility
													.promotionActivationEligibility
													.activationValidity.hours
											}
										/>
									</>
								) : fieldPrmtnAplcblEligibility.promotionActivationEligibility
										.activationValidity ? (
									(fieldPrmtnAplcblEligibility.promotionActivationEligibility.activationValidity.hours =
										undefined)
								) : null}
							</>
						) : null}
					</CollapseBody>
				</Element>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		userFullName: state.fullName,
		assignedResources: state.assignedResources,
		disableItem: state.disableItem,
		loggedInBrand: state.loggedInBrand,
		curntMousePlace: state.currentMousePlace
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showLoadingButton: (val) => dispatch({ type: actionTypes.LOADING_BUTTON, value: val }),
		setDisableItem: (val) => dispatch({ type: actionTypes.DISABLE_ITEM, value: val }),
		setMousePlace: (val) => dispatch({ type: actionTypes.MOUSE_PLACE, value: val })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(EligibilitySubForm);
