import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const TabsContainer = ({ defaultTab, list = [], fill = false, customClass = '' }) => {
	const [key, setKey] = useState(defaultTab);

	return (
		<Tabs
			className={`mb-3 ${customClass}`}
			id="tabs-container"
			activeKey={key}
			onSelect={(k) => setKey(k)}
			fill={fill}
		>
			{list.map(({ eventKey, component, title = null, disabled = false }, ind) => {
				if (!eventKey) return null;
				return (
					<Tab key={ind} eventKey={eventKey} title={title || key} disabled={disabled}>
						<div>{component}</div>
					</Tab>
				);
			})}
		</Tabs>
	);
};

TabsContainer.propTypes = {
	defaultTab: PropTypes.string,
	list: PropTypes.arrayOf(
		PropTypes.shape({
			eventKey: PropTypes.string.isRequired,
			title: PropTypes.string,
			component: PropTypes.oneOfType([
				PropTypes.func,
				PropTypes.shape({ current: PropTypes.instanceOf(Element) })
			]),
			disabled: PropTypes.bool
		})
	).isRequired,
	fill: PropTypes.bool,
	customClass: PropTypes.string
};

export default TabsContainer;
